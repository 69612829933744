/* eslint-disable */
import React, { useEffect, useReducer } from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import { Title } from 'components/commons/Typography';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import { UFService } from 'core/uf/UFService';
import { CityService } from 'core/city/CityService';
import { Snackbar, TextField } from '@material-ui/core';
import TextUtil from 'core/utils/TextUtil';
import { BoardLocation } from 'core/board-location/BoardLocation';

// Traduções.
const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
const TEXT_CITY = I18nTranslator.t('CITY') + " *";
const TEXT_SELECT = I18nTranslator.t('SELECT');
const TEXT_SELECT_STATE = I18nTranslator.t('SELECT_STATE');
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM");
const TEXT_SAVING = I18nTranslator.t("SAVING"); // Salvando
const TEXT_SUCCESS = I18nTranslator.t("SUCCESS"); // Sucesso
const TEXT_ERROR_REGISTER_LOCATION = I18nTranslator.t("ERROR_REGISTER_LOCATION"); // Ocorreu um erro ao tentar cadastrar o local, tente novamente mais tarde!
const TEXT_REGISTER_NEW_LOCATION = I18nTranslator.t("REGISTER_NEW_LOCATION"); // Cadastrar novo local
const TEXT_NAME = I18nTranslator.t("NAME"); // Nome.
const TEXT_ENTER_THE_NAME = I18nTranslator.t("ENTER_THE_NAME"); // Informe o nome
const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD"); // Preencher campo
const TEXT_CANCEL = I18nTranslator.t("CANCEL"); // Cancelar
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_INFORM_EIN = I18nTranslator.t("INFORM_EIN"); // Informe o CNPJ
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição Estadual

const initialState = {
  uf: { list: [], filter: [], loading: false, selected: null, value: '', success: null, label: '' },
  cidade: { list: [], filter: [], loading: false, selected: null, value: '', success: null, label: '' },
  nome: { value: '', success: null },
  cnpj: { value: '', success: null },
  inscricao_estadual: { value: '', success: null },
  button_submit: { value: TEXT_CONFIRM, disabled: false },
  snackbar: { active: false, value: '' },
}

const ACTIONS = {
  CHANGE_LIST: 'change_list',
  CHANGE_VALUE: 'change_value',
  CHANGE_VALUE_AND_VALIDATE: 'validate'
}

function reducer(state, action) {
  let name = action.payload ? action.payload.name : '';
  let value = action.payload ? action.payload.value : '';
  switch(action.type) {
    case ACTIONS.CHANGE_LIST:
      let listObject = { ...state[name] }
      if (action.payload.list) listObject.list = action.payload.list;
      if (action.payload.filter) listObject.filter = action.payload.filter;
      if (typeof action.payload.loading === 'boolean') listObject.loading = action.payload.loading;
      if (typeof action.payload.selected === 'object') listObject.selected = action.payload.selected;
      if (typeof action.payload.value === 'string') listObject.value = action.payload.value;
      if (typeof action.payload.label === 'string') listObject.label = action.payload.label;
      return { ...state, [name]: listObject }

    case ACTIONS.CHANGE_VALUE:
      return { ...state, [name]: { ...state[name], ...action.payload, value: value } }

    case ACTIONS.CHANGE_VALUE_AND_VALIDATE:
      return { ...state, [name]: { ...state[name], value: value, success: (value !== '') } }

    default:
      return state;
  }
}

const NewBoardLocation = ({ onClick, onCreateNewBoardLoaction }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  const handleList = ({ name, list, loading, selected, filter, value, label }) => {
    dispatch({ type: ACTIONS.CHANGE_LIST, payload: { name, list, loading, selected, filter, value, label }})
  }

  const handleChange = e => {
    dispatch({ type: ACTIONS.CHANGE_VALUE, payload: { name: e.target.getAttribute('name'), value: e.target.value } })
  }

  const handleChangeValidate = e => {
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: e.target.value, name: e.target.getAttribute('name') } })
  }

  const handleButtonSubmit = (value, disabled = false) => {
    dispatch({ type: ACTIONS.CHANGE_VALUE, payload: { value: value, disabled: disabled, name: 'button_submit' } });
  }

  const handleSnackbar = (value, active = true) => {
    dispatch({ type: ACTIONS.CHANGE, payload: { value: value, name: 'snackbar', active: active } })
  }

  useEffect(() => {
    setTimeout(() => getUfList(), 1000);
    setTimeout(() => getCityList(), 2000);
  }, []);

  // Busca lista de estados (UF).
  const getUfList = () => {
    handleList({ name: 'uf', loading: true });
  
    UFService.getUfList()
    .then((response) => {
      if (Array.isArray(response)) {
        handleList({ name: 'uf', list: response, loading: false });
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() =>handleList({ name: 'uf', loading: false }));
  };

  // Busca lista de cidades.
  const getCityList = () => {
    handleList({ name: 'cidade', loading: true });

    CityService.getCityList()
    .then((response) => {
      if (Array.isArray(response)) {
        handleList({ name: 'cidade', list: response, filter: response });
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => handleList({ name: 'cidade', loading: false }))
  }

  // Manipula o evento de mudança no campo UF
  // Filtra as Cidades apartir do Estado (UF) selecionado.
  const handleChangeUf = (e, newUf) => {
    let uf = newUf ? newUf.est_sigla : '';
    
    if (state.uf.selected && state.uf.selected.est_sigla !== uf) {
      handleList({ name: 'cidade', selected: null, value: '', label: '' });
    }
    
    handleList({ name: 'uf', selected: newUf });
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: newUf ? newUf.est_sigla : '', name: 'uf' }})

    if (uf === "") {
      handleList({ name: 'cidade', filter: [], selected: null, value: '', label: '' });
      return;
    }
    
    if (state.cidade.list) {
      let filterCity = state.cidade.list.filter((city) => {
        return city.estado.est_sigla === uf;
      });

      handleList({ name: 'cidade', filter: filterCity })
    }
  }

  // Manipula o evento de mudança no campo Cidade.
  const handleChangeCity = (e, selectedCity) => {
    handleList({ name: 'cidade', selected: selectedCity, value: selectedCity ? selectedCity.cid_codigo : '', label: selectedCity ? selectedCity.cid_nome : '' });
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: selectedCity ?  selectedCity.cid_codigo : '', name: 'cidade' }})
  }

  const handleSubmit = e => {
    e.preventDefault();
    
    handleButtonSubmit(`${TEXT_SAVING}...`, true);

    let params = {
      cid_codigo: state.cidade.value,
      loc_nome: state.nome.value,
      loc_cpfcnpj: state.cnpj.value,
      loc_ie: state.inscricao_estadual.value
    }
    BoardLocation.create(params)
    .then((response) => {
      if (!response.success) return errorValidator();

      handleButtonSubmit(`${TEXT_SUCCESS}!`, true);
      onClick();
      response.local_embarque.cidNome = state.cidade.label;
      response.local_embarque.estSigla = state.uf.value;
      onCreateNewBoardLoaction(response);

      setTimeout(() => {
        handleButtonSubmit(TEXT_CONFIRM);
      }, 1500);
    })
    .catch((error) => errorValidator(error))
    .finally(() => handleButtonSubmit(TEXT_CONFIRM))
  }

  const validateAllElements = () => {
    Object.keys(state).map(key => {
      let row = state[key];
      if (typeof row === 'object') {
        dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: state[key].value, name: key } })
      }
    })
  }

  const errorValidator = (error) => {
    handleButtonSubmit(TEXT_CONFIRM);
    switch(error) {
      case 'not_enough_params':
        validateAllElements();
        return;
      default:
        handleSnackbar(TEXT_ERROR_REGISTER_LOCATION);
        return;
    }
  }

  return (
    <Box>
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
          }}
          key={`snackbar`}
          open={state.snackbar.active}
          onClose={() => handleSnackbar('', false)}
          autoHideDuration={4000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{state.snackbar.value}</span>}
      />
      <Button onClick={onClick} className="button-close" />
      <Title className='s12'>
        {TEXT_REGISTER_NEW_LOCATION}
      </Title>
      <Vspace className='lg'/>
      <form onSubmit={handleSubmit}>
        <Row className='bottom-xs'>
          <Col className='col-xs-2 col-lg-2 col-md-12'>
            <Autocomplete
              id="field_uf"
              options={state.uf.list}
              loading={state.uf.loading}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              onChange={handleChangeUf}
              getOptionLabel={(option) => option.est_sigla}
              disableAnimation={true}
              renderInput={(params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label="UF *"
                    error={typeof state.uf.success === 'boolean' && !state.uf.success}
                    placeholder={"..."}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>
          <Col className='col-xs-10 col-lg-10 col-md-12'>
            <Autocomplete
              id="field_cidade"
              value={state.cidade.selected}
              options={state.uf.selected ? state.cidade.filter : []}
              loading={state.cidade.loading}
              loadingText={TEXT_LOADING}
              noOptionsText={state.uf.selected ? TEXT_NO_OPTIONS : TEXT_SELECT_STATE}
              onChange={handleChangeCity}
              getOptionLabel={(option) => option.cid_nome}
              disableAnimation={true}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_CITY}
                    error={typeof state.cidade.success === 'boolean' && !state.cidade.success}
                    placeholder={state.uf.selected ? TEXT_SELECT : TEXT_SELECT_STATE}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={`${TEXT_NAME} *`}
              name='nome'
              defaultValue={state.nome.value}
              feedback={TEXT_ENTER_THE_NAME}
              severity={state.nome.success === false && 'danger'}
              onChange={(e) => {
                e.target.value = TextUtil.capitalizeFirstLetters(e.target.value);
                handleChangeValidate(e);
              }}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={`${TEXT_EIN} *`}
              name='cnpj'
              defaultValue={state.cnpj.value}
              feedback={`${TEXT_INFORM_EIN}!`}
              severity={state.cnpj.success === false && 'danger'}
              onChange={(e) => {
                e.target.value = TextUtil.formatCpfCnpj(e.target.value);
                handleChangeValidate(e);
              }}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={TEXT_STATE_REGISTRATION}
              name='inscricao_estadual'
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
        </Row>
        <Vspace className='xxlg'/>
        <hr />
        <Group className='spread fluid'>
          <Button type="reset" onClick={onClick} className='outlined'>
            {TEXT_CANCEL}
          </Button>
          <Button type="submit" disabled={state.button_submit.disabled}>
            {state.button_submit.value}
          </Button>
        </Group>
      </form>
    </Box>
  )
}

export default NewBoardLocation