import React from 'react';
import GoogleMapReact from 'google-map-react';
import './Maps.scss';

class GoogleMap extends React.Component {
    state = {
        showZoomControl: false,
        mapType: "roadmap",
        styles: [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
    };

    constructor(props) {
        super(props);

        this.changeMapType = this.changeMapType.bind(this);
    }

    changeMapType = () => {
        let newMapType;
        if (this.state.mapType === "roadmap")
            newMapType = "satellite";
        else
            newMapType = "roadmap";

        this.setState({...this.state, mapType: newMapType});
    };

    render() {
        return (
            <div style={{
                width: "100%",
                height: "100%"
            }}>
                <button
                    style={this.state.showZoomControl ? {bottom: "105px"} : null}
                    className="btnChangeMapType"
                    value={this.state.mapType}
                    onClick={this.changeMapType}
                    type={"button"}
                >
                    Alterar
                </button>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyAY4raNgBvX8BPZzqtocmDHE5nDWNHLOS8",
                    }}
                    options={{
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: this.state.showZoomControl,
                        mapTypeId: this.state.mapType,
                        styles: this.state.styles,
                        gestureHandling: 'greedy'
                    }}
                    {...this.props}
                    ref={(map) => this.map = map}
                >
                    {this.props.children}
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;