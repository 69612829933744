import { configuration } from "config";
import { userAccessService } from "core/user-access/UserAccessService";
import fetch from "isomorphic-fetch";


export class CargoService {
    static filter(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/cargas?${urlSearchParams.toString()}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static getDocumentsList(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/documento_ordem_carga?${urlSearchParams.toString()}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static chatVisualization(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/chat_visualization?${urlSearchParams.toString()}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static uploadAttachFile(form_data: any) {

        const url = `${configuration.AUTH_BASE_URL}cliconnect/documento_ordem_carga`;

        const headerConfig: any = {
            method: "POST",
            body:form_data,
            headers: {
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }

    static downloadXmlList(iac_laudos: string, fileName: string) {
        if (!iac_laudos.length) return;
        
        const url = `${configuration.AUTH_BASE_URL}cliconnect/download/cargas_xml?iac_laudos=${iac_laudos}`;
        
        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "text/xml",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.blob())
            .then(blob => {
                const fileUrl = window.URL.createObjectURL(blob);
                
                let a = document.createElement("a");
                a.download = fileName ? fileName : iac_laudos.substring(0, 6) + Math.random().toString(36).substring(7) + ".xml";
                a.href = fileUrl;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                resolve(blob);
            })
            .catch((error) => reject(error))
        })
    }
}