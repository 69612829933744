import React, {useEffect, useState} from 'react';
import {Dropdown} from "../../../../components/commons/Dropdown";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from '@material-ui/icons/Notifications';
import {AvisosService} from "../../../../core/avisos/AvisosService";
import {Text} from "../../../../components/commons/Typography";
import {I18nTranslator} from "../../../../core/i18n/I18nTranslator";
import TextUtil from "../../../../core/utils/TextUtil";
import {List, ListItemIcon, ListItemText} from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import Vspace from "../../../../components/commons/Vspace";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ListSubheader from "@material-ui/core/ListSubheader";
import DateUtil from "../../../../core/utils/DateUtil";
import {green} from "@material-ui/core/colors";
import {Route} from 'react-router-dom'

const NotificationDropdown = ({ disabled }) => {
    const [active, setActive] = useState(false)
    const [timeoutId, setTimeoutId] = useState(undefined)
    const [loading, setLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);

    const handleClick = () => {
        let newState = !active;
        if (newState === true) {
            if (!loading) getNotifications()
        }
        setActive(newState)
    }

    const handleBlur = () => setTimeoutId(setTimeout(() => setActive(false)))
    const handleFocus = () => clearTimeout(timeoutId)

    const getNotifications = () => {
        setLoading(true)
        AvisosService.getList()
        .then(response => {
            let totalUnreadNotifications = getTotalUnreadNotifications(response)
            setTotalUnreadNotifications(totalUnreadNotifications)
            setNotificationList(response)
        })
        .finally(() => setTimeout(() => setLoading(false), 1000))
    }

    const getTotalUnreadNotifications = (list = notificationList) => {
        let total = 0;
        if (Array.isArray(list)) {
            for (let i = 0; i < list.length; i++) {
                let notification = list[i];
                if (!notification.mcv_dt_visualizacao) total++;
            }
        }
        return total;
    }

    const readMessage = (message) => {
        if (message.mcv_dt_visualizacao) return;

        message.mcv_dt_visualizacao = new Date();
        AvisosService.readMessage({id: message.mcv_id})
        setTotalUnreadNotifications(prevState => (prevState > 0 ? prevState - 1 : 0) )
    }

    const seeAll = () => {
        if (totalUnreadNotifications) {
            let messagesId = [];
            for (let i = 0; i < notificationList.length; i++) {
                let message = notificationList[i];
                if (message.mcv_dt_visualizacao) continue;

                message.mcv_dt_visualizacao = new Date();
                messagesId.push(message.mcv_id)
            }
            AvisosService.readMessage({ids: messagesId.join(",")})
            setTotalUnreadNotifications(prevState => (prevState > 0 ? prevState - messagesId.length : 0))
        }
    }

    const renderNotifications = () => {
        let total = 0;
        let limite = 5;
        return notificationList.map((message) =>
            {
                if (total === limite) return null;
                const { mc_id, mc_titulo, mc_mensagem, mc_dt_cadastro, mcv_dt_visualizacao } = message;
                total++;
                const props = { style: {fontSize: 15, color: '#FFF'} }
                if (!mcv_dt_visualizacao) {
                    props.style = { ...props.style, color: green[500] }
                }
                return (
                    <React.Fragment key={mc_id}>
                        <Route render={({ history }) => (
                            <ListItem button onClick={() => { setActive(false); readMessage(message); history.push(`/notice-board?id=${mc_id}`)}}>
                                <ListItemIcon>
                                    <FiberManualRecordIcon {...props} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={TextUtil.summarize(mc_titulo, 20, 0)}
                                    secondary={`${mc_dt_cadastro ? `${DateUtil.howLongHasPassed(mc_dt_cadastro, I18nTranslator.getLanguage())} - ` : ''}${TextUtil.summarize(TextUtil.removeLineBreaks(mc_mensagem), 10, 0)}`}
                                />
                            </ListItem>
                        )}/>
                    </React.Fragment>
                )
            }
        )
    }

    useEffect(() => {
        getNotifications();
    }, [])

    return (
        <div onBlur={handleBlur} onFocus={handleFocus}>
            <Dropdown
                className={`lg ${ active ? 'active' : '' }`}
                noPadding={true}
                trigger={
                    <IconButton color="inherit" onClick={handleClick} disabled={disabled}>
                        <Badge color={"secondary"} badgeContent={totalUnreadNotifications}>
                            <NotificationsIcon className={"color-white-mobile"} color={"action"} />
                        </Badge>
                    </IconButton>
                }
            >
                <List
                    aria-labelledby={"subheader-notifications-list"}
                    subheader={
                        <ListSubheader component={"div"} id={"subheader-notifications-list"}>
                            {I18nTranslator.t("NOTIFICATIONS")}
                        </ListSubheader>
                    }
                >
                    {(!notificationList || !notificationList.length) && !loading ? (
                        <ListItem button onClick={() => setActive(false)}>
                            <Text className={'f-h-center'}>{I18nTranslator.t("NOTHING_FOUND")}</Text>
                        </ListItem>
                    ) : renderNotifications()}
                    { !loading ? (notificationList.length > 0 ? (
                        <Route render={({ history }) => (
                            <ListItem button onClick={() => { setActive(false); seeAll(); history.push('/notice-board') }}>
                                <Text className={'f-h-center'}>{I18nTranslator.t("SEE_ALL")}</Text>
                            </ListItem>
                        )}/>
                    ) : null) : <React.Fragment><Vspace/><div className={"loading"} /></React.Fragment> }
                </List>
            </Dropdown>
        </div>
    )
}

export default NotificationDropdown