/* eslint-disable */
import React, {useEffect, useState} from 'react';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Group from 'components/commons/Group';
import Input from 'components/commons/Input';
import DatePicker from 'components/commons/DatePicker';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {ClientService} from 'core/client/ClientService';
import {UFService} from 'core/uf/UFService';
import {AtendenteService} from 'core/atendente/AtendenteService';
import {CityService} from 'core/city/CityService';
import DateUtil from 'core/utils/DateUtil';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import {Text} from 'components/commons/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "../../../../../../../components/commons/Switch";
import {userAccessService} from "../../../../../../../core/user-access/UserAccessService";


const AdvancedFilter = (props) => {

    let {filterActive} = props;


    // UF.
    const [ufList, setUfList] = useState([]);
    const [loadingUfList, setLoadingUfList] = useState(false);
    const [ufSelected, setUfSelected] = useState(null);

    // Cidade.
    const [cityList, setCityList] = useState([]);
    const [loadingCityList, setLoadingCityList] = useState(false);
    const [filterCityList, setFilterCityList] = useState([]);
    const [citySelected, setCitySelected] = useState(null);


    // Atendente.
    const [atendenteList, setAtendenteList] = useState([]);
    const [loadingAtendenteList, setLoadingAtendenteList] = useState(false);
    const [filterAtendenteList, setFilterAtendenteList] = useState([]);
    const [atendenteSelected, setAtendenteSelected] = useState(null);



    const [filterEmiteCteList, setFilterEmiteCteList] = useState([]);
    const [emiteCteSelected, setEmiteCteSelected] = useState("");


    // Regional.
    const [regionalList, setRegionalList] = useState([]);
    const [loadingRegionalList, setLoadingRegionalList] = useState(false);
    const [regionalSelected, setRegionalSelected] = useState(null);

    // Filial.
    const [filialList, setFilialList] = useState([]);
    const [loadingFilialList, setLoadingFilialList] = useState(false);
    const [filterFilialList, setFilterFilialList] = useState([]);
    const [filialSelected, setFilialSelected] = useState(null);

    // Traduções.
    const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
    const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
    const TEXT_SYSTEM_ORDER = I18nTranslator.t('SYSTEM_ORDER');
    const TEXT_FILL_FIELD = I18nTranslator.t('FILL_FIELD');
    const TEXT_REGIONAL_CUSTOMER = I18nTranslator.t('REGIONAL_CUSTOMER');
    const TEXT_BRANCH_CUSTOMER = I18nTranslator.t('BRANCH_CUSTOMER');
    const TEXT_CITY = I18nTranslator.t('CITY');
    const TEXT_ATENDENTE = I18nTranslator.t('ATENDENTE');
    const TEXT_SELECT = I18nTranslator.t('SELECT');
    const TEXT_SELECT_STATE = I18nTranslator.t('SELECT_STATE');
    const TEXT_SELECT_ATENDENTE = I18nTranslator.t('SELECT_ATENDENTE');


    useEffect(() => {
        setTimeout(() => getRegionalList(), 1000);
        setTimeout(() => getFilialList(), 2000);
        setTimeout(() => getUfList(), 3000);
        setTimeout(() => getAtendenteList(), 4000);
        setTimeout(() => getCityList(), 5000);
    }, []);

    const getRegionalList = () => {
        ClientService.getRegionalList()
            .then((response) => {
                if (Array.isArray(response)) {
                    setRegionalList(response);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getFilialList = () => {
        ClientService.getFilialList()
            .then((response) => {
                if (Array.isArray(response)) {
                    setFilialList(response);
                    setFilterFilialList(response);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    // Busca lista de estados (UF).
    const getUfList = () => {
        setLoadingUfList(true);

        UFService.getUfList()
            .then((response) => {
                if (Array.isArray(response)) {
                    setUfList(response);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingUfList(false));
    };

    // Busca lista de cidades.
    const getCityList = () => {
        setLoadingCityList(true);





        CityService.getCityList()
            .then((response) => {
                if (Array.isArray(response)) {
                    setCityList(response);
                    setFilterCityList(response);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingCityList(false))
    }

    // Manipula o evento de mudança no campo UF
    // Filtra as Cidades apartir do Estado (UF) selecionado.
    const handleChangeUf = (e, newUf) => {
        let uf = newUf ? newUf.est_sigla : '';

        if (ufSelected && ufSelected.est_sigla !== uf) {
            setCitySelected(null);
        }

        setUfSelected(newUf);

        if (uf === "") {
            setFilterCityList(cityList);
            return;
        }

        setUfSelected(newUf);

        if (cityList) {
            let filterCity = cityList.filter((city) => {
                return city.estado.est_sigla === uf;
            });

            setFilterCityList(filterCity);
        }
    }

    // Manipula o evento de mudança do atendente
    const handleChangeAtendente = (e, newAtendente) => {

        let atendente = newAtendente ? newAtendente.usu_codigo : '';

        setAtendenteSelected(newAtendente);

        if (atendente === "") {
            setFilterAtendenteList(atendenteList);
            return;
        }

    }

    // Manipula o evento de mudança do emiteCTE
    const handleEmiteCte = (e, newEmitecte) => {

        let emiteCte = newEmitecte ? newEmitecte.itemKey : '';

        setEmiteCteSelected(newEmitecte);

        if (emiteCte === "") {
            setFilterEmiteCteList("");
            return;
        }

    }

    // Busca lista de atendente.
    const getAtendenteList = () => {
        setLoadingAtendenteList(true);

        AtendenteService.getAtendenteList()
            .then((response) => {
                if (Array.isArray(response)) {
                    setAtendenteList(response);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingAtendenteList(false));
    };


    // Manipula o evento de mudança no campo Cidade.
    const handleChangeCity = (e, newValue) => {
        setCitySelected(newValue ? newValue : null);
    }

    // Manipula o evento de mudança no campo Cliente Regional
    // Filtra os Clientes Filiais apartir do Cliente Regional selecionado.
    const handleChangeRegional = (e, newRegional) => {
        let cnpj = newRegional ? newRegional.ent_cpfcnpj : '';

        if (regionalSelected && cnpj !== regionalSelected.ent_cpfcnpj) {
            setFilialSelected(null);
        }

        setRegionalSelected(newRegional);

        if (cnpj === "") {
            setFilterFilialList(filialList);
            return;
        }

        if (filialList) {
            let filterFilial = filialList.filter((filial) => {
                return filial.regional.ent_cpfcnpj === cnpj;
            });

            setFilterFilialList(filterFilial);
        }
    }

    // Manipula o evento de mudança no campo Cliente Filial.
    const handleChangeFilial = (e, newValue) => {
        setFilialSelected(newValue ? newValue : null);
    }

    let campoAtendente = ""
    if (userAccessService.hasPermission("permAtendenteGeldCampo")){
        campoAtendente = <Col className="col-xs-3 col-lg-12">

            <Autocomplete
                id="field_atendente"
                value={atendenteSelected}
                options={atendenteList}
                loading={loadingAtendenteList}
                loadingText={TEXT_LOADING}
                noOptionsText={TEXT_NO_OPTIONS}
                onChange={handleChangeAtendente}
                getOptionLabel={(option) => option.usu_nome}
                disableAnimation={true}
                renderInput={(params) =>
                    <>
                        <TextField
                            {...params}
                             autoComplete='off'
                            label={TEXT_ATENDENTE}
                            placeholder={atendenteSelected ? TEXT_SELECT : TEXT_SELECT_ATENDENTE}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                        <input
                            type="hidden"
                            name="usu_atendente"
                            value={atendenteSelected ? atendenteSelected.usu_codigo : ''}
                        />
                    </>
                }
            />
        </Col>
    }



    return (
        <>
            <Box className={`collapsed ${filterActive === true ? 'active' : ''}`}>
                <Row className="bottom-xs g4">
                    <Col className="col-xs-4 col-lg-12">
                        <Input
                            id="field_os"
                            type="number"
                            name="os"
                            label={TEXT_SYSTEM_ORDER}
                            placeholder={TEXT_FILL_FIELD}
                        />
                    </Col>
                    <Col className="col-xs-4 col-lg-12">
                        <Autocomplete
                            id="field_cliente_regional"
                            options={regionalList}
                            loading={loadingRegionalList}
                            loadingText={TEXT_LOADING}
                            noOptionsText={TEXT_NO_OPTIONS}
                            getOptionLabel={(option) => option.ent_nome}
                            onChange={handleChangeRegional}
                            disableAnimation={true}
                            renderInput={(params) =>
                                <>
                                    <TextField
                                        {...params}
                                         autoComplete='off'
                                        label={TEXT_REGIONAL_CUSTOMER}
                                        placeholder={TEXT_SELECT}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    <input
                                        type="hidden"
                                        name="ent_cnpj"
                                        value={regionalSelected ? regionalSelected.ent_cpfcnpj : ''}
                                    />
                                </>
                            }
                        />
                    </Col>
                    <Col className="col-xs-4 col-lg-12">
                        <Autocomplete
                            id="field_cliente_filial"
                            value={filialSelected}
                            options={filterFilialList}
                            loading={loadingFilialList}
                            loadingText={TEXT_LOADING}
                            noOptionsText={TEXT_NO_OPTIONS}
                            getOptionLabel={(option) => option.cli_nome}
                            onChange={handleChangeFilial}
                            disableAnimation={true}
                            renderInput={(params) =>
                                <>
                                    <TextField
                                        {...params}
                                         autoComplete='off'
                                        label={TEXT_BRANCH_CUSTOMER}
                                        placeholder={TEXT_SELECT}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    <input
                                        type="hidden"
                                        name="cli_cnpj"
                                        value={filialSelected ? filialSelected.cli_cpfcnpj : ''}
                                    />
                                </>
                            }
                        />
                    </Col>
                    <Col className="col-xs-4 col-lg-12">
                        <Row>
                            <Col className="col-xs-3 col-lg-4">

                                <Autocomplete
                                    id="field_uf"
                                    options={ufList}
                                    loading={loadingUfList}
                                    loadingText={TEXT_LOADING}
                                    noOptionsText={TEXT_NO_OPTIONS}
                                    onChange={handleChangeUf}
                                    getOptionLabel={(option) => option.est_sigla}
                                    disableAnimation={true}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                 autoComplete='off'
                                                label="UF"
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                            <input
                                                type="hidden"
                                                name="cid_uf"
                                                value={ufSelected ? ufSelected.est_sigla : ''}
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className="col-xs-9 col-lg-8">
                                <Autocomplete
                                    id="field_cidade"
                                    value={citySelected}
                                    options={ufSelected ? filterCityList : []}
                                    loading={loadingCityList}
                                    loadingText={TEXT_LOADING}
                                    noOptionsText={ufSelected ? TEXT_NO_OPTIONS : TEXT_SELECT_STATE}
                                    onChange={handleChangeCity}
                                    getOptionLabel={(option) => option.cid_nome}
                                    disableAnimation={true}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                 autoComplete='off'
                                                label={TEXT_CITY}
                                                placeholder={ufSelected ? TEXT_SELECT : TEXT_SELECT_STATE}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                            <input
                                                type="hidden"
                                                name="cid_codigo"
                                                value={citySelected ? citySelected.cid_codigo : ''}
                                            />
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-xs-8 col-lg-12">
                        <Text className="fw500 s12 ksm">
                            {I18nTranslator.t("CLASSIFICATION_DATE")}
                        </Text>
                        <Vspace className="sm"/>
                        <Box className="form-group">
                            <Row>
                                <Col className="col-xs-6 col-lg-12">
                                    <DatePicker
                                        id="field_data_finalizacao"
                                        name="data_class_inicial"
                                        label={I18nTranslator.t("INITIAL")}
                                        defaultValue={DateUtil.getToday()}
                                    />
                                </Col>
                                <Col className="col-xs-6 col-lg-12">
                                    <DatePicker
                                        id="field_data_class_final"
                                        name="data_class_final"
                                        defaultValue={DateUtil.getToday()}
                                        label={I18nTranslator.t("FINAL")}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Col>
                    <Col className="col-xs-3 col-lg-12">
                        <Input
                            id="field_no_laudo"
                            name="numero_laudo"
                            filter="like"
                            label={I18nTranslator.t("REPORT_NO")}
                            placeholder={I18nTranslator.t('FILL_FIELD')}
                        />
                    </Col>
                    <Col className="col-xs-6 col-lg-12">
                        <Input
                            id="field_transportadora"
                            type="number"
                            name="transportadora"
                            label={I18nTranslator.t("SHIPPING_COMPANY")}
                            placeholder={I18nTranslator.t('FILL_FIELD')}
                        />
                    </Col>
                    <Col className="col-xs-3 col-lg-12">
                        <Input
                            id="field_placa"
                            name="placa"
                            filter="like"
                            label={I18nTranslator.t("CAR_PLATE")}
                            placeholder={I18nTranslator.t('FILL_FIELD')}
                        />
                    </Col>
                    <Col className="col-xs-3 col-lg-12">

                        <Autocomplete
                            id="field_tipo_carga"
                            options={[
                                {item: I18nTranslator.t("ALL"), itemKey: ""},
                                {item: I18nTranslator.t("ISSUE_CTE"), itemKey: "S"},
                                {item: I18nTranslator.t("DOES_NOT_ISSUE_CTE"), itemKey: "N"}
                            ]}
                            loadingText={TEXT_LOADING}
                            noOptionsText={TEXT_NO_OPTIONS}
                            onChange={handleEmiteCte}
                            getOptionLabel={(option) => option.item}
                            disableAnimation={true}
                            defaultValue={{item: I18nTranslator.t("ALL"), itemKey: ""}}
                            renderInput={(params) =>
                                <>
                                    <TextField
                                        {...params}
                                        autoComplete='off'
                                        label={I18nTranslator.t("TYPE_OF_CARGO")}
                                        placeholder={TEXT_SELECT}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    <input
                                        type="hidden"
                                        name="imprime_arquivos"
                                        value={emiteCteSelected ? emiteCteSelected.itemKey : ''}
                                    />
                                </>
                            }
                        />
                    </Col>
                    {campoAtendente}
                    <Col className="col-xs-2 col-lg-12">
                        <Switch
                            label={I18nTranslator.t("LOADS_WITH_PHOTOS")}
                            status="opened"
                            value="S"
                            type="checkbox"
                            name="carga_fotos"
                            id="switch_cargas_com_foto_anexada"
                        />
                    </Col>
                    <Col className="col-xs-2 col-lg-12">
                        <Switch
                            label={I18nTranslator.t("LOADS_WITH_UNREAD_MESSAGES")}
                            status="opened"
                            value="S"
                            name="mensagem_nao_lidas"
                            type="checkbox"
                            id="switch_cargas_com_mensagens"
                        />
                    </Col>
                    <Col className="col-xs-2 col-lg-12">
                        <Switch
                            label={I18nTranslator.t("LOADS_WITH_ALL_DOCUMENTS_ATTACHED")}
                            status="opened"
                            value="S"
                            type="checkbox"
                            name="iac_fotos_doc_enviadas"
                            id="switch_cargas_todos_documentos_anexados"
                        />
                    </Col>
                </Row>

                <Vspace className="lg"/>

                <Group className="r g2 m-spread">
                    <Button type="reset" className="outlined">
                        {I18nTranslator.t('CLEAR_FIELDS')}
                    </Button>
                    <Button type="submit" className="" id="btn_advanced_filter_cargo">
                        {I18nTranslator.t('FILTRATE')}
                    </Button>
                </Group>
            </Box>
            <Vspace className='hom'/>
        </>
    );
};

export default AdvancedFilter;
