import React from "react";

const Image = ({ className, src, alt, height, width, style, onClick , crossOrigin }) => {
  return (
    <img
      onClick={onClick}
      src={src}
      style={style}
      className={`wac-image ${className}`} 
      alt={alt} 
      height={height} 
      width={width}
    />
  )
};

export default Image;
