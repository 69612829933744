import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";
import {I18nTranslator} from "../i18n/I18nTranslator";

export class UserService {


    static getUser(username: string, password: string, empresa: string) {
        const url = configuration.AUTH_BASE_URL + "authenticate/";

        const body = new FormData();
        body.append('empresa', empresa);
        body.append('user', username);
        body.append('password', password);

        let headers = new Headers();
        headers.append('Client-Code', configuration.AUTH_CLICONNECT_CODE);

        const newVar: any = {
            body: body,
            method: "POST",
            headers: headers
        };

        return new Promise((resolve, reject) => {

            fetch(url, newVar)
                .then(async (response) => response.text())
                .then(async (response: string) => {
                    var jsonObj = JSON.parse(response);
                    if (!jsonObj.success) {
                        reject(jsonObj.message);
                    }
                    else {
                        resolve({
                            user: jsonObj.return_data,
                            empresa,
                            session_id: jsonObj.session_id
                        });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    reject({
                        requestError: true
                    });
                }
            );

        });
    }

    static async login(username: string, password: string, empresa: string) {

        return new Promise((resolve, reject) => {

            this.getUser(username, password, empresa).then((response) => {
                userAccessService.setUser(response);

                resolve(response);
            }).catch((error) => {
                let message = I18nTranslator.t("LOGIN_ERROR");

                if (error.requestError) {
                    message = I18nTranslator.t("LOGIN_REQUEST_ERROR");
                } else if (error.userInvalid) {
                    message = I18nTranslator.t("LOGIN_USER_INVALID_ERROR");
                }

                /* Exibir diretamente a mensagem de erro retornada do servidor */
                else if (typeof(error) === 'string') message = error;

                reject({message});
            });

        })

    }

    public static logout() {
        // Remove a sessão no servidor
        const url = configuration.AUTH_BASE_URL + "authenticate/logout";

        let headers = new Headers();
        headers.append('Client-Code', configuration.AUTH_CLICONNECT_CODE);
        headers.append('Session-ID', userAccessService.getSessionId());

        const newVar: any = {
            method: "POST",
            headers: headers
        };

        return new Promise((resolve, reject) => {
            fetch(url, newVar)
            .then((response) => response.text())
            .then((response: string) => {
                var jsonObj = JSON.parse(response);

                if (!jsonObj.success) {
                    return reject(jsonObj.error);
                }
                
                    
                return resolve(response);
            })
            .catch((error) => {
                reject(I18nTranslator.t("LOGOUT_REQUEST_ERROR"));
            })
            .finally(() => {
                userAccessService.setUser(null);
                document.location.href = '/login';
            })
        });
    }

    public static validateSession() {
        const url = configuration.AUTH_BASE_URL + "authenticate/validate-session";

        const headerConfig: any = {
            method: "GET",
            headers: { 
                "Content-Type": "application/json",
                "Session-ID": userAccessService.getSessionId()
            }
        }
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response)

                if (!response.success) {
                    UserService.logout()
                }
            })
            .catch((error) => {
                reject(error)
            });
        });
    }

}