/* eslint-disable */
import React, {useState} from 'react';
import Button from '../Button';
import { Dropdown } from '../Dropdown';
import { Text } from '../Typography';
import Vspace from '../Vspace';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';

export const EXPORT_OPTIONS = {
    PDF: 'PDF',
    XLS: 'XLS'
}

export const EXPORT_FUNCTIONS = {
    [EXPORT_OPTIONS.PDF]: (columns, data, filename) => {
        const orientation = columns.length > 6 ? 'l' : 'p';
        const doc = new jsPDF(orientation);

        const newColumns = []
        columns.map(column => {
            if (column.length) newColumns.push(column);
        });
        console.log(data)
        const newBody = data.map((row) => {
            let newData = [];
            columns.map(column => {
                if (row[column] && typeof row[column] !== 'object') 
                    newData.push(row[column]);
            })
            return newData;
        });

        doc.autoTable({
            theme: 'striped',
            head: [newColumns],
            body: newBody
        })
        doc.save(`${filename}.pdf`);
    },
    [EXPORT_OPTIONS.XLS]: (columns, data, filename) => {
        const newColumns = []
        columns.map(column => {
            if (column.length) newColumns.push(column);
        });
        const newBody = data.map((row) => {
            let newData = {};
            columns.map(column => {
                if (row[column] && typeof row[column] !== 'object') 
                    newData[column] = (row[column]);
            })
            return newData;
        });

        const workSpace = XLSX.utils.json_to_sheet(newBody);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSpace, filename);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
    }
}
const defaultI18n = { 
    EXPORT: 'Export'
};
const DropdownExport = ({ columns, data, filename = 'generated', exportItems, i18n = defaultI18n}) => {
    const [active, setActive] = useState(false);
    const [timeoutId, setTimeoutId] = useState(undefined);

    const handleClickExport = (action) => {
        try {
            setActive(false)

            if (EXPORT_FUNCTIONS[action]) {
                EXPORT_FUNCTIONS[action](columns, data, filename);
                return;
            }

            if (typeof exportItems[action] === 'function') {
                exportItems[action](columns, data, filename);
            }
        }catch (e){
            console.error(e)
        }
    }

    const onFocus = () => {
        clearTimeout(timeoutId)
    }

    const onBlur = () => {
        setTimeoutId(
            setTimeout(() => setActive(false))
        )
    }

    return (
        <div className="wac-overlaid-button" onBlur={onBlur} onFocus={onFocus}>
            <Dropdown
                className={`form-input sm mla ${ active ? 'active' : ''}`}
                trigger={
                <Button 
                    onClick={() => setActive(prevState => !prevState)} 
                    className="outlined" 
                    title={i18n.EXPORT}
                >
                    {i18n.EXPORT}
                </Button>
                }>
                <Text className='s11'>
                    {i18n.EXPORT}
                </Text>
                <hr className='sm' />
                {
                    exportItems.map(exportItem => {
                        return (
                            <>
                                <Button 
                                    key={exportItem}
                                    className='inline'
                                    onClick={() => handleClickExport(exportItem)}
                                >
                                    <Text className='s11'>{exportItem}</Text>
                                </Button>
                                <Vspace className='sm' />
                            </>
                        )
                    })
                }
            </Dropdown>
        </div>
    )
};


DropdownExport.defaultProps = {
    columns: [],
    data: [],
    filename: 'generated',
    exportItems: [...Object.keys(EXPORT_OPTIONS)]
}

DropdownExport.propTypes = {
    columns: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    filename: PropTypes.string,
    exportItems: PropTypes.array
}

export default DropdownExport;