import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";

export class ChatService {

    static getChatList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/chat" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }

    // ENVIA MENSAGEM NOVA
    static sendNewMessage(filter_parameter: any) {
        const url = `${configuration.AUTH_BASE_URL}cliconnect/chat_painel_cargas`;

        const headerConfig: any = {
            method: "POST",
            body:JSON.stringify(filter_parameter),
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }

    // ATUALIZA MENSSAGEM
    static loadingMessage(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/chat_painel_cargas?${urlSearchParams.toString()}`;

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }
}