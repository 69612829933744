import React, { useEffect } from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import Checkbox from 'components/commons/Checkbox';
import { Title } from 'components/commons/Typography';
import { Col, Row } from 'components/commons/FlexBoxGrid';


const FormAdd = ({ onClick, documents, onChange = () => {} }) => {
  useEffect(() => {
    console.log(documents);
  }, [documents]);
  
  const handleChangeDocumentsMotorista = name => e => {
    if (!documents || !documents.motorista) return;
    let newDocuments = documents;

    if (!e.target.checked) {
      newDocuments.motorista = newDocuments.motorista.filter(item => item !== name);
    }
    else {
      newDocuments.motorista = [...newDocuments.motorista, name];
    }

    onChange(newDocuments);
  }
  const handleChangeDocumentsCliente = name => e => {
    if (!documents || !documents.cliente) return;
    let newDocuments = documents;

    if (!e.target.checked) {
      newDocuments.cliente = newDocuments.cliente.filter(item => item !== name);
    }
    else {
      newDocuments.cliente = [...newDocuments.cliente, name];
    }

    onChange(newDocuments);
  }

  const documentosMotorista = [
    { id: 1, name: 'Ct-e' },
    { id: 5, name: 'Agendamento' },
    { id: 2, name: 'MDF-e' },
    { id: 6, name: 'RAE ou NF50' },
    { id: 3, name: 'Nota Fiscal' },
    { id: 7, name: 'Contrato REPOM' },
    { id: 4, name: 'Contrato' },
    { id: 8, name: 'Outros' },
  ]

  const documentosTransportadora = [
    { id: 1, name: 'Cartão Frete' },
    { id: 5, name: 'NF de Venda' },
    { id: 9, name: 'NF Auxiliar' },
    { id: 2, name: 'Cartão Pedágio' },
    { id: 6, name: 'Ordem de carregamento' },
    { id: 3, name: 'CNH do Motorista' },
    { id: 10, name: 'Comprovante de Pedágio' },
    { id: 7, name: 'Ticket da Balança' },
  ]
  
  return (
    <Box>
      <Button onClick={onClick} className="button-close" />
      {
        documents && documents.transp_nome ?
        (<React.Fragment>
          <Title className='s11 fw500'>
            Transportadora : {documents.transp_nome}
          </Title>
          <Vspace className='lg'/>
        </React.Fragment>) : null
      }
      <Title className='s11 fw500'>
        Documentos que o classificador deve entregar para o motorista *
      </Title>
      <Vspace className='lg'/>
      <Row>
        {
          documentosMotorista.map((documento) => (
            <Col className='col-xs-3 col-lg-6 col-md-12'>
              <Checkbox 
                label={documento.name}
                onChange={handleChangeDocumentsMotorista(documento.id)}
                isChecked={documents && documents.motorista ? documents.motorista.includes(documento.id) : false}
              />
            </Col>
          ))
        }
      </Row>
      <Vspace className='xlg'/>
      <Title className='s11 fw500'>
        Documentos que o classificador deve enviar ao cliente/transportadora *
      </Title>
      <Vspace  className='lg'/>
      <Row>´
        {
          documentosTransportadora.map((documento) => (
            <Col className='col-xs-3 col-lg-6 col-md-12'>
              <Checkbox 
                label={documento.name}
                onChange={handleChangeDocumentsCliente(documento.id)}
                isChecked={documents && documents.cliente ? documents.cliente.includes(documento.id) : false}
              />
            </Col>
          ))
        }
      </Row>
      <Vspace className='xlg'/>
      <hr />
      <Group className='spread fluid'>
        <Button className='outlined' onClick={onClick}>
          Cancelar
        </Button>
        <Button onClick={onClick}>
          Confirmar
        </Button>
      </Group>
    </Box>
  )
}

export default FormAdd