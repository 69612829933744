import { configuration } from 'config'
import { userAccessService } from 'core/user-access/UserAccessService';
import React, { useState } from 'react'

export default function PanoramaBI() {
    const url = `${configuration.AUTH_BASE_URL}authorize-app?response_type=code&scope=user_email&client_id=PariPassu&session_id=${userAccessService.getSessionId()}&control_data=panorama`;
    const style = { width: '100%', height: '100%' };
    const [loading, isLoading] = useState(true);
    return (
    <div className={loading ? 'loading' : ''} style={style}>
        <iframe 
            title="Panorama"
            onLoad={() => isLoading(false)}
            style={style} 
            src={url} 
        />         
    </div>);
}
