import React from "react";
import { SearchIcon } from "components/Icons";

const Search = ({ className, placeholder, id, children, ...props }) => {
  return (
    <div className={`search control has-icons-left ${className}`}>
      <input
        list={id}
        className="wac-input light"
        type="text" 
        {...props}
        placeholder={placeholder} />

      <span className="wac-icon small is-left">
        <SearchIcon />
      </span>
    </div>
  )
};

export default Search;
