import React from "react";

export const Title = ({ className, children }) => {
  return (
    <h3 className={`wac-title ${className}`}>
      {children}
    </h3>
  );
};

export const Text = ({ className, children }) => {
  return (
    <span className={`wac-text ${className}`}>
      {children}
    </span>
  );
};

export const Paragraph = ({ className, children, title }) => {
  return (
    <p className={`wac-text ${className}`} title={title}>
      {children}
    </p>
  );
};