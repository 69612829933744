import React from "react";

export const LayoutMain = ({ className, children, onClick = () => {} }) => {
  return (
    <div className={`wac-main ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const Layout = ({ className, children }) => {
  return (
    <div className={`wac-layout ${className}`}>
      {children}
    </div>
  );
};

export const Sidebar = ({ className, children, onClick, activeMenu, onBlur, onFocus}) => {

  return (
    <div className={`wac-layout-aside ${className} ${ activeMenu ? 'active' : ''}`} onBlur={onBlur} onFocus={onFocus}>
      {children}
      <button 
        onClick={onClick}
        className="wac-layout-aside-trigger hom" />
    </div>
  );
};

export const Content = ({ className, children, id, onClick, onScroll }) => {
  return (
    <div className={`wac-layout-content ${className}`} id={id} onClick={onClick} onScroll={onScroll}>
      {children}
    </div>
  );
};

export const Subcontent = ({ className, children }) => {
  return (
    <div className={`wac-layout-subcontent ${className}`}>
      {children}
    </div>
  );
};

export const Footer = ({ className, children }) => {
  return (
    <div className={`wac-layout-footer ${className}`}>
      {children}
    </div>
  );
};

