import React, { useState } from "react";
import Table from "components/commons/Table";
import { Text, Title } from 'components/commons/Typography';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import Modal from 'components/commons/Modal';
import TextUtil from "core/utils/TextUtil";
import Paginator from "components/commons/Paginator";
import NewBoardLocation from "./NewBoardLocation";
import { I18nTranslator } from "core/i18n/I18nTranslator";


const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND"); // Nada encontrado
const TEXT_SEARCH_RESULT = I18nTranslator.t("SERCH_RESULT"); // Resultado da busca
const TEXT_CITY = I18nTranslator.t("CITY"); // Cidade
const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição Estadual
const TEXT_REGISTER_NEW_LOCATION_DESTINO = I18nTranslator.t("REGISTER_NEW_LOCATION_DESTINO");
const TEXT_REMOVE_DEST = I18nTranslator.t("REMOVE_DEST_SELECT");



const FilterList = ({ onClickChecked, data, loading, values, handleChange, onCreateNewBoardLoaction, showPaginator = true }) => {
  const [ newBoardLocation, setNewBoardLocation ] = useState(false)
  const [ selectRow, setSelectRow ] = useState(values.lista_destino_selecionada || []);
  const [ offset, setOffset ] = useState(0);
  
  const pageSize = 5;


    function handleSelectRow(row) {
    if(row)  {
      if (!values.lista_destino_check) {
        handleChange('lista_destino_selecionada', row)
        adicionaItemListaSelecionados(values.lista_destino_selecionada, row)
        setSelectRow(row);
      }
    }
  }

  function handleNewBoardLocation() {
    setNewBoardLocation( newBoardLocation === false )
  }

  const [listaItensSelecionados, setListaItensSelecionados] = useState(values.lista_destino_selecionada || []);


  const adicionaItemListaSelecionados = (item) => {
    let listaCopy = [...listaItensSelecionados];
    let dest_codigo = item.dest_codigo;
    let tem = false
    listaCopy.forEach(function(name){
      if(name.dest_codigo == dest_codigo){
      tem = true
      }
    });
    if(!tem) {
    listaCopy.push(item);
    }
    setListaItensSelecionados(listaCopy);
    handleChange('lista_destino_selecionada', listaCopy)
  };

   const removeItemListaSelecionados = (item) => {
    let listaCopy = [...listaItensSelecionados];
    const a = (element) => element.dest_codigo == item.dest_codigo;
    let index = listaCopy.findIndex(a)
    listaCopy.splice(index, 1);
    setListaItensSelecionados(listaCopy);
    handleChange('lista_destino_selecionada', listaCopy)

   };

  function aceito(selectRow) {

      if (!listaItensSelecionados.length) {
        return (
          <tr>
            <td colSpan="6">{TEXT_NOTHING_FOUND}.</td>
          </tr>
        )
      }

      let rows = (listaItensSelecionados);
      let i = 0;

      return rows.map(row => {
      
      let id = row.dest_nome;
      let sumarizeCityUff = `${row.cidNome}/${row.estSigla}` || '';
      let ccpf = row.dest_cpfcnpj;
      let ie = row.dest_insc_estadual;

      let sumarizeCityUf = `${row.cidNome}/${row.estSigla}` || '';
      let cityUfProps = {};
      if (sumarizeCityUff.length > 25) {
        cityUfProps.status = sumarizeCityUf;
        sumarizeCityUff = TextUtil.summarize(sumarizeCityUff, 25, 3);
      i++;
      }
      
      
      return (
        <React.Fragment key={row.dest_codigo}>
          <tr>
            <td>
              {row.dest_codigo ? row.dest_codigo : 'Não Informado'  }
            </td>
            <td>
              <Text className='uppercase'>
                {id ? id : 'Não Informado'}
              </Text>
            </td>
            <td {...cityUfProps}>
              <Text className='uppercase'>
                {sumarizeCityUff ? sumarizeCityUff : 'Não Informado' }
              </Text>
            </td>
            <td>
              {ccpf ? TextUtil.formatCpfCnpj(ccpf) : 'Não Informado' }
            </td>
            <td>
              {ie ? ie : 'Não Informado'}
            </td>
            <td>
            {/* style={{ width:`170px`, marginLeft:`-60px`, marginRight: `10px`}} */}
            <Button className="show-when inline" onClick={() => removeItemListaSelecionados(row)}>
                {TEXT_REMOVE_DEST.toLowerCase()}
            </Button>
            </td>
          </tr>
        </React.Fragment>
      )
    })
  }

  function renderBody(selectRow) {

    if (!data.length) {
      return (
        <tr>
          <td colSpan="6">{TEXT_NOTHING_FOUND}.</td>
        </tr>
      )
    }

    let rows = (pageSize ? data.slice(offset, offset + pageSize) : data);

    return rows.map(row => {
      let sumarizeLocNome = row.dest_nome;
      let locNomeProps = {};
      if (row.dest_nome && row.dest_nome.length > 30) {
        sumarizeLocNome = TextUtil.summarize(row.dest_nome, 30);
        locNomeProps.status = row.dest_nome;
      }

      let sumarizeCityUf = `${row.cidNome}/${row.estSigla}` || '';
      let cityUfProps = {};
      if (sumarizeCityUf.length > 25) {
        cityUfProps.status = sumarizeCityUf;
        sumarizeCityUf = TextUtil.summarize(sumarizeCityUf, 25, 3);
      }

      let sumarizeIe = row.dest_insc_estadual;
      let ieProps = {};
      if (row.dest_insc_estadual && row.dest_insc_estadual.length > 20) {
        ieProps.status = row.dest_insc_estadual;
        sumarizeIe = TextUtil.summarize(row.dest_insc_estadual, 20);
      }

      let selected = selectRow.dest_codigo === row.dest_codigo ? 'selected' : '';
      
      return (
        <React.Fragment key={row.dest_codigo}>
          <tr onClick={() => handleSelectRow(selected === 'selected' ? false : row)}
            onT 
            className={`collapsed ${selected}`}>
            <td>
              {row.dest_codigo ? row.dest_codigo : 'Não Informado' }
            </td>
            <td {...locNomeProps}>
              <Text className='uppercase'>
                {sumarizeLocNome ? sumarizeLocNome : 'Não Informado' }
              </Text>
            </td>
            <td {...cityUfProps}>
              <Text className='uppercase'>
                {sumarizeCityUf ? sumarizeCityUf : 'Não Informado' }
              </Text>
            </td>
            <td>
              {row.dest_cpfcnpj ? TextUtil.formatCpfCnpj(row.dest_cpfcnpj) : 'Não Informado'}
            </td>
            <td {...ieProps}>
              {sumarizeIe ? sumarizeIe : 'Não Informado' }
            </td>
          </tr>
        </React.Fragment>
      )
    })
  }
  

  return (
    <>
      <Box>
        <Title className="s13 uppercase">
          {TEXT_SEARCH_RESULT}
        </Title>
        <Vspace />
        <div className={`wac-table-container ${loading ? 'loading' : ''}`}>
          <Table className='featured'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Local de Destino</th>
                <th>{TEXT_CITY}/UF</th> 
                <th>{TEXT_ITIN}/{TEXT_EIN}</th>
                <th>{TEXT_STATE_REGISTRATION}</th>
              </tr>
            </thead>
            <tbody>
              {renderBody(selectRow)}
            </tbody>
          </Table>
          {
            showPaginator ?
            (<Paginator
              paginatorSize={data.length}
              pageSize={pageSize}
              onOffsetChange={setOffset}
              setRowsLabel={({ from, to, count }) => `${from}-${to} of ${count}`}
            />) : ''
          }
        </div>

        <Vspace />
          <Group className="r g2">
            <Button className={`${ Object.keys(selectRow).length ? '' : ''}`} 
            onClick={handleNewBoardLocation}>
              {TEXT_REGISTER_NEW_LOCATION_DESTINO}
            </Button>
          </Group>
      </Box>

      {values.lista_destino_selecionada >= [1] ? (
          <Box>
          <Title className="s13 uppercase">
            Destinos Selecionados
          </Title>
          <Vspace />
          <div className={`wac-table-container ${loading ? 'loading' : ''}`}>
          <Table className='featured'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Local de Destino</th>
                <th>{TEXT_CITY}/UF</th> 
                <th>{TEXT_ITIN}/{TEXT_EIN}</th>
                <th>{TEXT_STATE_REGISTRATION}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {aceito(selectRow)}
            </tbody>
          </Table>
          {
            showPaginator ?
            (<Paginator
              paginatorSize={listaItensSelecionados.length}
              pageSize={pageSize}
              onOffsetChange={setOffset}
              setRowsLabel={({ from, to, count }) => `${from}-${to} of ${count}`}
            />) : ''
          }
          </div>
          </Box> ) : null 
          }

      <Modal className={`md ${ newBoardLocation ? 'active' : ''}`}>
        <NewBoardLocation 
          onClick={handleNewBoardLocation} 
          onCreateNewBoardLoaction={onCreateNewBoardLoaction}/>
      </Modal>
    </>
  );
};

export default FilterList