import React, {useState} from "react";
import InputButton from "../InputButton";
import Button from "../Button";
import {I18nTranslator} from "../../../core/i18n/I18nTranslator";
import TextUtil from "../../../core/utils/TextUtil";
import Icon from "../Icon";
import {DownloadIcon} from 'components/Icons';


const InputFile = ({id, label, defaultPlaceHolder,link="", accept, onChange}) => {


    const TEXT_DELETE = I18nTranslator.t('DELETE');
    const TEXT_SEARCH = I18nTranslator.t('SEARCH');
    const TEXT_SELECT_A_FILE = I18nTranslator.t('SELECT_A_FILE');

    const handdleClickDownload = () => {

        window.open(link, "_system")

    }

    const [placeholder, setPlaceHolder] = useState((TextUtil.summarize(defaultPlaceHolder.split("/").pop(), 20, 3) || TEXT_SELECT_A_FILE));
    const [textButton, setTextButton] = useState(defaultPlaceHolder === TEXT_SELECT_A_FILE ? TEXT_SEARCH : TEXT_DELETE);
    const [buttonDelete, setButtonDelete] = useState(defaultPlaceHolder === TEXT_SELECT_A_FILE ? "" : <Button className="outlined small" onClick={handdleClickDownload}><Icon color='grey-2' component={<DownloadIcon/>}/></Button>);

    const handdleChange = (e) => {
        setPlaceHolder(e.target.files.length ? e.target.files[0].name : TEXT_SELECT_A_FILE)
        setTextButton(e.target.files.length ? TEXT_DELETE : TEXT_SEARCH)
        onChange(e)
        setButtonDelete(<Button className="outlined small" onClick={handdleClickDownload}><Icon color='grey-2' component={<DownloadIcon/>}/></Button>)
    }

    const handdleClick = () => {
        if (textButton === TEXT_SEARCH) {
            document.getElementById(`inputFile-${id}`).click()

        } else {
            setButtonDelete("")
            setPlaceHolder(TEXT_SELECT_A_FILE)
            setTextButton(TEXT_SEARCH)
        }
    }


    return (
        <InputButton
            label={label}
            disabled="disabled"
            id={`input-${id}`}
            placeholder={placeholder}>
            <Button onClick={handdleClick} className="outlined small">
                {textButton}
            </Button>
            {buttonDelete}
            <input accept={accept}
                   type="file"
                   id={`inputFile-${id}`}
                   style={{display: "none"}}
                   onChange={handdleChange}/>
        </InputButton>
    );
}
export default InputFile;