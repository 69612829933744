/* eslint-disable */
import React, {useEffect, useState} from 'react';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Vspace from 'components/commons/Vspace';
import Box from 'components/commons/Box';
import DatePicker from 'components/commons/DatePicker';
import Input from 'components/commons/Input';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {Text, Title} from 'components/commons/Typography';
import {Radio, RadioOption} from 'components/commons/Radio';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import DateUtil from 'core/utils/DateUtil';
import TextUtil from 'core/utils/TextUtil';
import {CityService} from 'core/city/CityService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Snackbar, TextField} from '@material-ui/core';
import {UFService} from 'core/uf/UFService';
import Feedback from 'components/commons/Feedback';
import {Code, ReportProblemSharp} from "@material-ui/icons";
import {PlusCircleIcon} from 'components/Icons';
import Icon from 'components/commons/Icon';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Search from 'components/commons/Search';
import TypeServiceLocationDestino from '../LotAndDestination/TypeServiceLocationDestino';

const criaDestino = (values = {}) => {
  let destino = {};
  destino.slo_codigo = values.slo_codigo || '';
  destino.slo_uf = values.slo_uf || '';
  destino.cid_coddestino = values.cid_coddestino || '';
  destino.slo_cidade = values.slo_cidade || '';
  destino.uf_selected = values.uf_selected;
  destino.filter_city_list = values.filter_city_list;
  destino.cidade = values.cidade;
  return destino;
}

const criaNovoModeloDestino = (values = {}) => {
  let modelonovo = {};
  modelonovo.slo_codigo = values.slo_codigo || '';
  modelonovo.slo_uf = values.slo_uf || '';
  modelonovo.cid_coddestino = values.cid_coddestino || '';
  modelonovo.slo_cidade = values.slo_cidade || '';
  modelonovo.uf_selected = values.uf_selected
  modelonovo.cidade = values.cidade;
  return modelonovo;
}

function LotAndDestination({nextStep, prevStep, handleChange, values}, props) {
  
  // const [novoModDestino, setNovoModDestino] = useState(values.lista_destino_selecionada && values.lista_destino_selecionada.length ? values.lista.lista_destino_selecionada : [criaNovoModeloDestino()]);
  const [destinos, setDestinos] = useState(values.destinos && values.destinos.length ? values.destinos : [criaDestino()]); 
  // UF.
  const [ufList, setUfList] = useState([]);
  const [loadingUfList, setLoadingUfList] = useState(false);

  // Cidade.
  const [cityList, setCityList] = useState([]);
  const [loadingCityList, setLoadingCityList] = useState(false);
  const [filterCityList, setFilterCityList] = useState([]);

  // Snackbar.
  const [ openSnackbar, setOpenSnackbar ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState('');

  const [validation, setValidation] = useState({
    market_type: { feedback: "Informe o tipo do mercado!", error: false },
    request_date: { feedback: "Informe a data de solicitação!", error: false },
    end_date: { feedback: "Informe a data de retirada!", error: false },
    trucks_day: { feedback: "Informe a quantidade de caminhões por dia!", error: false },
    lot_size: { feedback: "Informe o tamanho do lote!", error: false },
    estimated: { feedback: "Informe se a carga é estimada!", error: false },
    contract: { feedback: "Informe o número do contrato!", error: false },
    contact_name: { feedback: "Informe o nome do contato!", error: false },
    contact_phone: { feedback: "Informe o telefone do contato!", error: false },
    destiny: { feedback: "Informe o(s) destino(s)!", error: false },
  });


  const adicionaDestino = () => {
    setDestinos(prevState => {
      let novoDestinos = [...prevState, criaDestino({ uf_list: ufList, city_list: cityList })]
      handleChange("destinos",novoDestinos);
      return novoDestinos;
    })
    
  }

  const removeDestino = (index) => {
    setDestinos(prevState => {
      let novoDestinos = prevState.slice(0, index).concat(prevState.slice(index + 1));
      handleChange("destinos",novoDestinos);
      return novoDestinos;
    })
  }

  // Busca lista de estados (UF).
  const getUfList = () => {
    setLoadingUfList(true);
  
    UFService.getUfList()
    .then((response) => {
    if (Array.isArray(response)) {
      setUfList(response);
    }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingUfList(false));
  }

  // Busca lista de cidades.
  const getCityList = () => {
    setLoadingCityList(true);

    CityService.getCityList()
    .then((response) => {
      if (Array.isArray(response)) {
        setCityList(response);
        setFilterCityList(response);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingCityList(false))
  }

  const handleUf = (index, novaUf) => {
    let novoDestinos = [...destinos];
    novoDestinos[index].uf_selected = novaUf;

    if (novaUf) {
      novoDestinos[index].filter_city_list = (cityList.filter(city => city.estado.est_sigla === novaUf.est_sigla));
    }
    else {
      novoDestinos[index].cidade = null;
    }
    
    setDestinos(novoDestinos);
    handleChange("destinos",novoDestinos);
  }

  const handleCidade = (index, novaCidade) => {
    let novoDestinos = [...destinos];
    novoDestinos[index].cidade = novaCidade;
    setDestinos(novoDestinos);
    handleChange("destinos",novoDestinos);
  }

  const handleEndereco = (index, endereco) => {
    let novoDestinos = [...destinos];
    novoDestinos[index].slo_cidade = endereco;
    setDestinos(novoDestinos);
    handleChange("destinos",novoDestinos);
  }

  function showSnackBar(message) {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  }

  const next = () => {
    if (!validate()) nextStep();
  }

  const validate = () => {
    let error = false;
    let newValidation = {...validation};

    Object.keys(newValidation).map((key) => {
      newValidation[key].error = false;
    });

    if (values.tipo_mercado === "") {
      newValidation.market_type.error = error = true;
    }

    if (values.data_solicitacao === "") {
      newValidation.request_date.error = error = true;
    }

    if (values.data_retirada === "") {
      newValidation.end_date.error = error = true;
    }

    if (!values.qtd_caminhoes) {
      newValidation.trucks_day.error = error = true;
    }

    if (!values.tamanho_lote) {
      newValidation.lot_size.error = error = true;
    }

    if (!values.lote_estimado) {
      newValidation.estimated.error = error = true;
    }

    if (!values.contrato_compra) {
      newValidation.contract.error = error = true;
    }

    if (!values.nome_contato) {
      newValidation.contact_name.error = error = true;
    }

    if (!values.telefone_contato) {
      newValidation.contact_phone.error = error = true;
    }

    // MODELO ANTIGO DESTINO
    // if (!values.destinos.length) {
    //   newValidation.destiny.error = error = true;
    // }

    // ATIVAR NO MODELO NOVO DESTINO
    if (!values.lista_destino_selecionada.length) {
      newValidation.destiny.error = error = true;
    }


    if (error) {
      showSnackBar("Informe todos os campos!");
    }

    setValidation(newValidation);
    return error;
  }

  useEffect(() => {
    setTimeout(() => getUfList(), 500);
    setTimeout(() => getCityList(), 1000);
  }, []);

  let ativaDestino = 1

  function handleChangeTransport(e, newValue) {
    props.handleChange('transport', newValue);
  }

  return (
    <>
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
          }}
          key={`snackbar`}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          autoHideDuration={4000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackbarMessage}</span>}
      />
      <Vspace />
      <Row className='start-xs'>
        <Col>
          <Title>{values.edit && values.slo_codigo ? `Editar OS ${values.slo_codigo}` : I18nTranslator.t("REGISTER_SERVICE_ORDER")}</Title>
        </Col>
      </Row>
      <Vspace className='hom'/>
      <Box className='ghost'>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>{I18nTranslator.t("INFO_LOTE")}</Text>
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='g3'>
          <Col className='col-xs-3 col-lg-4 col-md-12'>
            <Radio label={I18nTranslator.t("TIP_MERCADO")}>
              <RadioOption 
                id='radio01' 
                name='tipo-mercado' 
                value='Interno'
                onChange={(e) => handleChange("tipo_mercado", e.target.value)}
                isChecked={values && values.tipo_mercado === "Interno"}
              />
              <RadioOption 
                id='radio02' 
                name='tipo-mercado' 
                value='Externo'
                onChange={(e) => handleChange("tipo_mercado", e.target.value)}
                isChecked={values && values.tipo_mercado === "Externo"} 
              /> 
            </Radio>
            {
              validation.market_type.error ?
              (<Feedback severity={"danger"} message={validation.market_type.feedback} />) : ''
            }
          </Col>
          <Col className='col-xs-3 col-lg-4 col-md-12'>
            <DatePicker 
              id='select02' 
              label={I18nTranslator.t("DT_SOLICITACAO")}
              onChange={(e) => handleChange("data_solicitacao", e.target.value)}
              severity={validation.request_date.error && 'danger'}
              feedback={validation.request_date.error && validation.request_date.feedback}
              defaultValue={values.data_solicitacao || DateUtil.getToday()}
            />
          </Col>
          <Col className='col-xs-3 col-lg-4 col-md-12'>
            <DatePicker 
              id='select03' 
              label={I18nTranslator.t("INI_RETIRADA")}
              onChange={(e) => handleChange("data_retirada", e.target.value)}
              severity={validation.end_date.error && 'danger'}
              feedback={validation.end_date.error && validation.end_date.feedback}
              defaultValue={values.data_retirada || DateUtil.getToday()}
            />
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='g3'>
          <Col className='col-xs-3 col-lg-4 col-md-12'>
            <Group className='g2 b'>
              <Input 
                type="number" 
                id='input04' 
                label={I18nTranslator.t("CAMINHOES_DIA")}
                placeholder='00'
                severity={validation.trucks_day.error && 'danger'}
                feedback={validation.trucks_day.error && validation.trucks_day.feedback}
                onChange={(e) => handleChange("qtd_caminhoes", parseInt(e.target.value))}
                defaultValue={values.qtd_caminhoes || ""} 
              />
              <Text className='s13'>{I18nTranslator.t("CA")}</Text>
            </Group>
          </Col>
          <Col className='col-xs-3 col-lg-4 col-md-12'>
            <Group className='g2 b'>
              <Input 
                type="number"
                id='input04' 
                label={I18nTranslator.t("TAM_LOTE")}
                placeholder='00' 
                severity={validation.lot_size.error && 'danger'}
                feedback={validation.lot_size.error && validation.lot_size.feedback}
                onChange={(e) => handleChange("tamanho_lote", parseFloat(e.target.value))}
                defaultValue={values.tamanho_lote || ''}
              />
              <Text className='s13'>{I18nTranslator.t("TON")}</Text>
            </Group>
          </Col>
          <Col className='col-xs-3 col-lg-4 col-md-12'  style={{ marginTop: `-24px`}}>
            <Radio label={I18nTranslator.t("CARGA_ESTIMADA")}>
              <RadioOption 
                id='radio03' 
                name='tipo-carga' 
                isChecked={values.lote_estimado === "Sim"} 
                onChange={(e) => handleChange("lote_estimado", e.target.value)}
                value='Sim' 
              />
              <RadioOption 
                id='radio04' 
                name='tipo-carga' 
                isChecked={values.lote_estimado === "Não"} 
                onChange={(e) => handleChange("lote_estimado", e.target.value)}
                value='Não' 
              />
            </Radio>
            {
              validation.estimated.error ?
              (<Feedback severity={"danger"} message={validation.estimated.feedback} />) : ''
            }
          </Col>
          <Col className='col-xs-4 col-lg-6 col-md-12'>
            <Input
              id='input07'
              label={I18nTranslator.t("NUM_CONT_COMPRA")}
              severity={validation.contract.error && 'danger'}
              feedback={validation.contract.error && validation.contract.feedback}
              defaultValue={values.contrato_compra || ''}
              onChange={(e) => handleChange("contrato_compra", e.target.value)}
              placeholder='00000000000'
            />
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='g3'>
          <Col className='col-xs-4 col-lg-6 col-md-12'>
            <Input 
              id='input08' 
              label={I18nTranslator.t("NOME_CONTATO")}
              placeholder='Nome' 
              severity={validation.contact_name.error && 'danger'}
              feedback={validation.contact_name.error && validation.contact_name.feedback}
              defaultValue={values.nome_contato || ""}
              onChange={(e) => handleChange("nome_contato", e.target.value)}
            />
          </Col>
          <Col className='col-xs-4 col-lg-6 col-md-12'>
            <Input
              id='input09'
              label={I18nTranslator.t("TEL_CONTATO")}
              placeholder='(99) 99999-9999 '
              severity={validation.contact_phone.error && 'danger'}
              feedback={validation.contact_phone.error && validation.contact_phone.feedback}
              defaultValue={values.telefone_contato || ""}
              onChange={(e) => {
                e.target.value = TextUtil.formatPhone(e.target.value)
                handleChange("telefone_contato", e.target.value)
              }}
            />
          </Col>
        </Row>
      </Box>
{ativaDestino === 0 ? 
      <Box className='ghost'>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>{I18nTranslator.t("DESTINO")}</Text>
          </Col>
        </Row>
        <Col>
            <Text className='wac-text s13'>
            - {I18nTranslator.t("DESTINO_PRINCIPAL")}
            </Text>
          </Col>
        <Vspace className="lg" />
        {
          destinos.map((destino, index) => {
            return (
              <React.Fragment>
                {destinos.length > 1 && index != 0 ?
                    <Col>
                        <Text className='wac-text s13'>
                          - {I18nTranslator.t("DESTINO_OUTRO")}
                        </Text>
                      <Vspace className="lg" />
                  </Col> : null
                }
                <Row className='g2' key={destino.slo_cidade}>
                  <Col className='col-xs-1 col-lg-2 col-md-4 '>
                    <Autocomplete
                      id="field_uf"
                      value={destino.uf_selected}
                      options={ufList}
                      loading={loadingUfList}
                      loadingText={"..."}
                      noOptionsText={"Nada"}
                      onChange={(e, novaUf) => handleUf(index, novaUf)}
                      getOptionLabel={(option) => option.est_sigla}
                      disableAnimation={true}
                      renderInput={ (params) => 
                        <>
                          <TextField 
                            {...params}
                             autoComplete='off'
                            label="UF *"
                            placeholder={"..."}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined" 
                          />
                        </>
                      }
                    />
                  </Col>
                  <Col className='col-xs-3 col-lg-4 col-md-12'>
                    <Autocomplete
                      id="field_cidade"
                      value={destino.cidade}
                      options={destino.uf_selected ? destino.filter_city_list : []}
                      loading={loadingCityList}
                      loadingText={"Carregando..."}
                      noOptionsText={destino.uf_selected ? "Nada encontrado" : "Selecione um estado"}
                      onChange={(e, novaCidade) => handleCidade(index, novaCidade)}
                      getOptionLabel={(option) => option.cid_nome}
                      disableAnimation={true}
                      renderInput={ (params) => 
                        <>
                          <TextField 
                            {...params}
                             autoComplete='off'
                            label={I18nTranslator.t("CIDADE")}
                            placeholder={destino.uf_selected ? "Selecione" : I18nTranslator.t("SELECT_ESTADO")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined" 
                          />
                        </>
                      }
                    />
                  </Col>
                  <Col className='col-xs-6 col-lg-4 col-md-12' style={{ marginTop: `2px` }}>
                    <Input
                      id='input12'
                      label={I18nTranslator.t("ENDERECO")}
                      onBlur={(e) => handleEndereco(index, e.target.value)}
                      defaultValue={destino.slo_cidade || ''}
                      placeholder={I18nTranslator.t("RUA_AV")}
                    />
                  </Col>
                  {
                    index != 0 ?
                    (<Col className='col-xs-2 col-lg-2 col-md-12'>
                      <Button className='outlined' onClick={() => removeDestino(index)}>
                        {I18nTranslator.t("REMOVER")}
                      </Button>
                    </Col>) : ''
                  }
                </Row>
                <Vspace className='lg' />
              </React.Fragment>
            )
          })
        }
        <Row className='start-xs'>
          <Col>
            <Button 
              className='inline' 
              onClick={adicionaDestino}>
              <Icon className="sm" component={<PlusCircleIcon />}/>
              <Vspace className='sm'></Vspace>{I18nTranslator.t("ADD_DESTINO")}
            </Button>
          </Col>
        </Row> 
        {
          validation.destiny.error ?
          (<Feedback severity={"danger"} message={validation.destiny.feedback} />) : ''
        }
        <Vspace className='xxxlg' />
        <hr />
        <Group className='spread'>
          <Button onClick={prevStep} className='outlined'>
            {I18nTranslator.t("BACK")}
          </Button>
          <Button onClick={next}>
          {I18nTranslator.t("NEXT")}
          </Button>
        </Group>
      </Box>
   : ''}
   
   {ativaDestino === 1 ?
    <Box>
        <TypeServiceLocationDestino values={values} handleChange={handleChange}  />
        {
          validation.destiny.error ?
          (<Feedback severity={"danger"} message={validation.destiny.feedback} />) : ''
        }
    </Box> : ''}
    {ativaDestino === 1 ?
    <Box className='ghost'style={{ marginTop: `-30px` }}>
        <Group className='spread'>
          <Button onClick={prevStep} className='outlined'>
            {I18nTranslator.t("BACK")}
          </Button>
          <Button onClick={next}>
          {I18nTranslator.t("NEXT")}
          </Button>
        </Group>
    </Box>
    : ''}
    </>
  );
}

export default LotAndDestination;

// Descomentar validacao e codigo do destino antigo