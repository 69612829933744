export default class TextUtil {
    static summarize(text: string, maxLength: number, lastCharacters: number) {
        try {

            if (!maxLength) 
                maxLength = 10;
            
            let summary = `${text.substring(0, maxLength)}...` + 
                (lastCharacters ? text.substring(text.length - lastCharacters, text.length) : '');

            return text.length > maxLength ? summary : text;
        }
        catch(error) {
            return '';
        }
    }

    static abbreviate(text: string) {
        try {

            let textArray = text.split(" ");
            return textArray.map((word) => word[0]).join(".");
        }
        catch(error) {
            return '';
        }
    }

    static formatCpfCnpj(text: string) {
        try {
            text = this.removeFormat(text);
            if (text.length <= 11) {
                return this.formatCpf(text);
            }

            return this.formatCnpj(text);
        }
        catch(error) {
            return '';
        }
    }

    static formatCpf(text: string) {
        try {
            return text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4");
        }
        catch(error) {
            return '';
        } 
    }

    static formatCnpj(text: string) {
        try {
            return text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
        }
        catch(error) {
            return '';
        }
    }

    static removeFormat(text: string) {
        try {
            return text.replace(/(\.|\/|-)/g,"");
        }
        catch(error) {
            return '';
        }
    }

    static capitalizeFirstLetters(text: string) {
        try {
            return text.toLowerCase().replace(/^\w|\s\w/g, letter => letter.toUpperCase());
        }
        catch(error) {
            return '';
        }
    }

    static formatPhone(text: string) {
        try {
            return text.replace(/\D/g,'').replace(/(^\d{2})(\d)/,'($1) $2').replace(/(\d{4,5})(\d{4}$)/,'$1-$2');
        }
        catch(error) {
            return text
        }
    }
    static removeLineBreaks(text: string) {
        try {
            return text.replace(/(\r\n|\n|\r|\\n|\\r)/gm," ")
        }
        catch (error) {
            return text
        }
    }
}