import React from 'react';
import PropTypes from 'prop-types';
import { PhoneIcon } from 'components/Icons';
import Icon from '../Icon';

const PhoneLink = ({tel}) => {
    if (tel) {
        return (
           <span style={{wordBreak: 'break-all'}}>
           {tel}&nbsp;
            <a href={`tel:${tel.replace(/\D/g, '')}`} style={{verticalAlign: 'sub'}}>
                <Icon className="sm" component={<PhoneIcon />} />
            </a>
           </span>
        )
    }
    
    return null;
}

PhoneLink.defaultProps = {
    tel: ""
}

PhoneLink.propTypes = {
    tel: PropTypes.string
}

export default PhoneLink;