/* eslint-disable */
import React, { useState, useEffect } from "react";
import Box from "components/commons/Box";
import Group from "components/commons/Group";
import Vspace from "components/commons/Vspace";
import { Link } from "react-router-dom";
import { Text, Title } from "components/commons/Typography";
import { Col, Row } from 'components/commons/FlexBoxGrid';
import TextUtil from "core/utils/TextUtil";
import { OSService } from "core/os/OSService";
import { Snackbar } from "@material-ui/core";
import { userAccessService } from "core/user-access/UserAccessService";
import Button from "components/commons/Button";
import {I18nTranslator} from "../../../../core/i18n/I18nTranslator";

function Resume({ nextStep, prevStep, values }) {
  const [testesSelecionados, setTestesSelecionados] = useState([]);
  const [resume, setResume] = useState(false);
  const [loading, setLoading] = useState(false);
  
  
  // Snackbar.
  const [ openSnackbar, setOpenSnackbar ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState('');

  const user = userAccessService.getUser();

  const tryThis = (callback) => {
    try {
      return callback();
    }
    catch(error) {
      console.log(error);
      return false;
    }
  }

  function showSnackBar(message) {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  }

  
  const submit = () => {
    
    try {
      // Create the Order

      const listaProtocolosAtualizados = [];
          if (values.protocolo_selecionado) {

            for (let i = 0; i < values.protocolo_selecionado.length; i++) {
              let protocolos = values.protocolo_selecionado[i];
              let a = protocolos.protocolo_selecionado.split('@')
              let ptc_codigo = a[0];
              let cpt_codigo = a[1];
              let fita = ''
              if (typeof protocolos.fita_selecionada !== 'undefined') {
                fita = protocolos.fita_selecionada.value
              }
              let novoProtocolo = {
                //slo_codigo: values.slo_codigo,
                tip_codigo: protocolos.tipo_analise_selecionado.tip_codigo.toString(),
                cpt_codigo: cpt_codigo,
                ptc_codigo: ptc_codigo,
                usu_codigo: user.usu_codigo,
                spa_codigo: protocolos.spa_codigo,
                spa_ativo: protocolos.spa_ativo,
                spa_forn_fita: fita,
              };
              listaProtocolosAtualizados.push(novoProtocolo);
            }
          }
          
      const data = {
        protocolos: values.additional_service ? listaProtocolosAtualizados : [],
        slo_codigo: (values.slo_codigo || ''),
        slo_dtsolicitacao: values.data_solicitacao,
        slo_dtretirada: values.data_retirada,
        slo_dttermino: values.data_retirada,
        slo_empresa: values.branch.cli_cpfcnpj,
        slo_localemb: tryThis(() => {
        switch(values.tipo_local_servico_index) {
            case 0:
              return values.terminal.trans_nome
            case 1:
              return values.moegao.trans_nome
            case 2:
              return values.porto.trans_nome
            case 3:
              return values.linha_embarque_selecionada.loc_nome || values.linha_embarque_selecionada.local_embarque.loc_nome
            default: 
              return "";
          }
        }),
        slo_contato: values.nome_contato,
        // slo_cidade: tryThis(() => values.destinos[0].slo_cidade),
        // slo_uf: tryThis(() => values.destinos[0].cidade.estado.est_sigla),
        // NOVO MODELO DESTINO
        slo_cidade: tryThis(() => values.lista_destino_selecionada[0].dest_nome),
        slo_uf: tryThis(() => values.lista_destino_selecionada[0].estSigla),
        slo_fone: values.telefone_contato,
        slo_email: tryThis(() => values.branch.cli_email),
        slo_produto: tryThis(() => values.produto.pro_codigo),
        slo_lote: values.tamanho_lote,
        slo_solicitante: tryThis(() => userAccessService.getUser().usu_nome),
        cli_cpfcnpj: values.branch.cli_cpfcnpj,
        slo_tipo_transporte: values.transport.tra_nome,
        slo_tamanhoestimado: (values.lote_estimado === 'Sim' ? 'S' : 'N'),
        slo_contratocompra: values.contrato_compra,
        slo_phonelocalemb: tryThis(() => values.linha_embarque_selecionada ? values.linha_embarque_selecionada.loc_telefone || values.linha_embarque_selecionada.local_embarque.loc_telefone : ''),
        slo_localembcid: tryThis(() =>  {
          if (typeof values.linha_embarque_selecionada === 'object' && Object.keys(values.linha_embarque_selecionada).length) {
            if (values.linha_embarque_selecionada.cidNome) 
              return values.linha_embarque_selecionada.cidNome;
              
            return values.linha_embarque_selecionada.local_embarque.cidade.cid_nome;
          }
          return values.supervisao.cidade.cid_nome;
        }),
        cid_codembarque: tryThis(() => {
          if (typeof values.linha_embarque_selecionada === 'object' && Object.keys(values.linha_embarque_selecionada).length) {
            if (values.linha_embarque_selecionada.cidade) 
              return values.linha_embarque_selecionada.cidade.cid_codigo;

            return values.linha_embarque_selecionada.local_embarque.cidade.cid_codigo;
          }
          return values.supervisao.cid_cod || values.supervisao.cid_codigo;
        }),
        cid_locembuf: tryThis(() => values.linha_embarque_selecionada ? values.linha_embarque_selecionada.estSigla || values.linha_embarque_selecionada.cidade.estado.est_sigla : ''),
        slo_totallote: values.tamanho_lote,
        slo_faturamento: values.branch.cli_cpfcnpj,
        // // NOVO MODELO DESTINO
        cid_coddestino:  tryThis(() => values.lista_destino_selecionada[0].dest_cidade),
        // cid_coddestino:  tryThis(() => values.destinos[0].cidade.cid_codigo),
        cli_insc_estadual: values.branch.cli_insc_estadual,
        serv_codigo: values.service.serv_codigo,
        slo_servico: values.service.serv_descricao,
        loc_codigo: tryThis(() => {
          if (typeof values.linha_embarque_selecionada === 'object' && Object.keys(values.linha_embarque_selecionada).length) {
            return values.linha_embarque_selecionada.loc_codigo || values.linha_embarque_selecionada.local_embarque.loc_codigo;
          }
          return ''
        }),
        tle_codigo: tryThis(() => {
          switch(values.tipo_local_servico_index) {
            case 0:
              return 1
            case 1:
              return 7
            case 2:
              return 2
            case 3:
              return 9
            default:
              return ""
          }
        }),
        slo_mercadoexterno: (values.tipo_mercado === 'Externo' ? 'S' :  'N'),
        bas_codigo: values.supervisao.bas_codigo || values.linha_embarque_selecionada.bas_codigo,
        slo_teste_mictoxinas: (listaProtocolosAtualizados.findIndex(val=>val.tip_codigo === "1" && (val.spa_ativo==="S" || val.spa_ativo==="")) >= 0 ? 'S' : 'N' ),
        tpg_codigo: values.tipo_produto.tpg_codigo,
        slo_qtd_caminhoes_dia: values.qtd_caminhoes,
        // slo_qtd_destinos: values.destinos.length,
        // NOVO MODELO DESTINO
        slo_qtd_destinos: values.lista_destino_selecionada.length,
        itens_classificacao: values.itens_classificacao,
        // destinos: values.destinos,
        // NOVO MODELO DESTINO
        destinos: values.lista_destino_selecionada,
        dest_codigo : tryThis(() => values.lista_destino_selecionada[0].dest_codigo),
        dest_cpfcnpj : tryThis(() => values.lista_destino_selecionada[0].dest_cpfcnpj),
        slo_usuario: user.usu_codigo,
        //protocolo: values.additional_service ? (tryThis(() => Object.keys(values.protocolo).length && values.protocolo)) : '',
        tipo_analise_selecionado: values.tipo_analise_selecionado,
        protocolo_selecionado: values.protocolo_selecionado,
        fita_selecionada: values.fita_selecionada,
        transportadoras: values.additional_service && values.transportadoras,
        tra_codigo: values.transport ? values.transport.tra_codigo : '',
        trans_codigo: tryThis(() => {
          if (values.tipo_local_servico_index === 0) {
            return values.terminal.trans_codigo;
          } else if (values.tipo_local_servico_index === 1) {
            return values.moegao.trans_codigo;
          } else if (values.tipo_local_servico_index === 2) {
            return values.porto.trans_codigo;
          }
          return '';
        }),
        slo_tipomoega: (values.tipo_embarque.value || ''),
        slo_cif_fob: (values.slo_cif_fob || ''),
        slo_qtd_outros_testes: (listaProtocolosAtualizados.filter(val=>val.tip_codigo != "1" && (val.spa_ativo==="S" || val.spa_ativo==="")).length),
        slo_realiza_teste_analise: (listaProtocolosAtualizados.filter(val=>val.spa_ativo==="S" || val.spa_ativo==="").length > 0 ? "S" : "N"),
        slo_imprime_arquivos: (values.imprime_arquivos || '')
      }
      setLoading(true)
        console.log(data)
      let action = values.edit ? OSService.update : OSService.create;
      action(data)
      .then((response) => {
        console.log(data)
      
        // nextStep
        if (!response.success) {
          let message = "Não foi possível realizar a ação, tente novamente mais tarde!";
          if (response.error === 'not_enough_params') {
            message = "Informe todos os campos!";    
          }
          showSnackBar(message)
          return;
        }
        nextStep();
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Não foi possível realizar a ação, tente novamente mais tarde!")
      })
      .finally(() => setLoading(false))
    }

    catch(error) {
      // Show error message to user
      console.error(error);
      showSnackBar("Não foi possível realizar a ação, tente novamente mais tarde!")
    }
    
  }
 
  return (
    <>
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        key={`snackbar`}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={4000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
    />
    <Vspace />
      <Row className='start-xs'>
        <Col>
          <Title>{values.edit && values.slo_codigo ? `Editar OS ${values.slo_codigo}` : 'Cadastrar OS'}</Title>
        </Col>
      </Row>
      { loading ? <div className="loading" /> : ''}
      <Vspace className='hom'/>
      {
        !resume ? 
        (<Box className='ghost'>
          <Group className="vc fluid g3">
            <Text>Resumo: Gostaria de ver o resumo do pedido?</Text>
            <Group className='g1'>
              <Link className='wac-button small primary' onClick={() => setResume(true)}>
                Sim
              </Link>
              <Link 
                onClick={submit}
                className='wac-button small'
              >
                Pular
              </Link>
            </Group>
          </Group>
        </Box>) :
        (<React.Fragment>
           <Box>
            <Title className='s11 fw500'>
              Informações do Cliente
            </Title>
            <hr />
            <Group className='fluid g7'>
              <Group className='fdc'>
                <Text className='fw600'>
                  Filial
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_nome)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  UF
                </Text>
                <Text>
                  {tryThis(() => values.branch.cidade.estado.est_sigla)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Cidade
                </Text>
                <Text>
                  {tryThis(() => values.branch.cidade.cid_nome)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  CNPJ
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_cpfcnpj)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Inscrição Estadual
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_insc_estadual)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Endereço
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_endereco)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  CEP
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_cep)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Pessoa de Contato
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_contato)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Telefone
                </Text>
                <Text>
                  {tryThis(() => TextUtil.formatPhone(values.branch.cli_fone))}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  E-mail
                </Text>
                <Text>
                  {tryThis(() => values.branch.cli_email)}
                </Text>
              </Group>
            </Group>
          </Box>
          <Box>
            <Title className='s11 fw500'>
              Produtos e Serviços
            </Title>
            <hr />
            <Group className=' fluid g6'>
              <Group className='fdc'>
                <Text className='fw600'>
                  Serviço
                </Text>
                <Text>
                  {tryThis(() => values.service.serv_descricao)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Tipo Transporte
                </Text>
                <Text>
                  {tryThis(() => values.transport.tra_nome)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Tipo Local Serviço
                </Text>
                <Text>
                  {tryThis(() => {
                    switch(values.tipo_local_servico_index) {
                      case 0:
                        return "Transbordo - Terminal"
                      case 1:
                        return "Transbordo - Moegões"
                      case 2:
                        return "Porto - Terminal"
                      case 3:
                        return "Armazem/Fazenda/Silo"
                      default: 
                        return "Não informado";
                    }
                  })}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Local de Embarque
                </Text>
                <Text>
                  {tryThis(() => {
                   switch(values.tipo_local_servico_index) {
                      case 0:
                        return values.terminal.trans_nome
                      case 1:
                        return values.moegao.trans_nome
                      case 2:
                        return values.porto.trans_nome
                      case 3:
                        return `(${values.linha_embarque_selecionada.loc_codigo || values.linha_embarque_selecionada.local_embarque.loc_codigo}) ${values.linha_embarque_selecionada.loc_nome || values.linha_embarque_selecionada.local_embarque.loc_nome}`
                      default: 
                        return "Não informado";
                    }
                  })}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  UF
                </Text>
                <Text>
                  {tryThis(() => {
                    switch(values.tipo_local_servico_index) {
                      case 0:
                        return values.terminal.cidade.estado.est_sigla
                      case 1:
                        return values.moegao.cidade.estado.est_sigla
                      case 2:
                        return values.porto.cidade.estado.est_sigla
                      case 3:
                        return values.linha_embarque_selecionada.estSigla || values.linha_embarque_selecionada.local_embarque.cidade.estado.est_sigla
                      default: 
                        return "Não informado";
                    }
                  })}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Cidade
                </Text>
                <Text>
                  {tryThis(() => {
                    switch(values.tipo_local_servico_index) {
                      case 0:
                        return values.terminal.cidade.cid_nome
                      case 1:
                        return values.moegao.cidade.cid_nome
                      case 2:
                        return values.porto.cidade.cid_nome
                      case 3:
                        return values.linha_embarque_selecionada.cidNome || values.linha_embarque_selecionada.local_embarque.cidade.cid_nome
                      default: 
                        return "Não informado";
                    }
                  })}
                </Text>
              </Group>
              {
                Object.keys(values.supervisao).length ?
                (<Group className='fdc'>
                  <Text className='fw600'>
                    Supervisão Responsável
                  </Text>
                  <Text>{tryThis(() => `${values.supervisao.cidade.cid_nome} - ${values.supervisao.bas_supervisor}`)}</Text>
                </Group>) : ''
              }
              <Group className='fdc'>
                <Text className='fw600'>
                  Telefone de Embarque
                </Text>
                <Text>
                  {tryThis(() => TextUtil.formatPhone(values.telefone_contato))}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Produto
                </Text>
                <Text>
                  {tryThis(() => values.produto.pro_nome)}
                </Text>
              </Group>
              <Group className='fdc'>
                <Text className='fw600'>
                  Tipo de Produto
                </Text>
                <Text>
                  {tryThis(() => values.tipo_produto.tpg_nome)}
                </Text>
              </Group>
            </Group>


              {/* -------------------------------------------------------------- */}

            <Vspace
              className='lg'
            />
            <Title className='s11 fw500' style={{ marginTop: `50px` }}>
              Serviços Adicionais
            </Title>
            <hr/>
                  <Group className='fluid g7'>
                  {values.protocolo_selecionado > [1] ? 
                  <Group className='fdc'>
                    <Text className='fw600'>
                      Tipo de Análise
                    </Text>
                    <Text>
                    {
                      values.protocolo_selecionado ?
                      values.protocolo_selecionado.map((tpa) => {
                          if(tpa.spa_ativo != "N"){
                          let a = tpa.protocolo_selecionado.split('@')
                          let descricao = a[2]
                            if( descricao === undefined){
                              return <Text>{tpa.tip_descricao} - {tpa.ptc_descricao} <p></p></Text>
                           
                            }else{
                              return <Text>{tpa.tipo_analise_selecionado.tip_descricao} - {descricao} <p></p></Text>
                            }

                          }else{
                            return null
                          }
                      })   : null
                    }
                    </Text>
                  </Group>
                  : null }
                  {values.transportadoras >= [1] ?
                  <Group className='fdc'>
                    <Text className='fw600'>
                      Troca de Nota Fiscal
                    </Text>
                    <Text>
                      Sim
                    </Text>
                  </Group> : null }
                </Group>

              {/* -------------------------------------------------------------- */}

            <Vspace className='xxxlg' />
            <hr />
            <Group className='spread'>
              <Button 
                disabled={loading}
                onClick={prevStep}
                className='outlined'
              >
                {I18nTranslator.t("BACK")}
              </Button>
              <Button
                onClick={submit}
                disabled={loading}
                className={loading ? 'outlined' : ''}
              >
                {loading ? `${I18nTranslator.t("SAVING")}...` : I18nTranslator.t("CONFIRM")}
              </Button>
            </Group>
          </Box>
        </React.Fragment>)
      }
    </>
  );
}

export default Resume;
