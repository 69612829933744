/* eslint-disable */
import React, { useEffect, useState } from "react";
import Group from "components/commons/Group";
import Button from "components/commons/Button";
import Vspace from "components/commons/Vspace";
import Box from "components/commons/Box";
import { Link } from "react-router-dom";
import { Col, Row } from "components/commons/FlexBoxGrid";
import { Text, Title } from "components/commons/Typography";
import { Option, Select } from "components/commons/Select";
import { ProtocoloAnaliseService } from "core/protocolo_analise/ProtocoloAnaliseService";
import { AnaliseTesteTipo } from "core/protocolo_analise/AnaliseTesteTipo";
import { I18nTranslator } from "core/i18n/I18nTranslator";
import "react-tabs/style/react-tabs.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {PlusCircleIcon} from 'components/Icons';
import Icon from 'components/commons/Icon';
import Feedback from 'components/commons/Feedback';
import { ContactsOutlined } from "@material-ui/icons";
import { Resume } from "../../Resume/index";

const criaTeste = (values = {}) => {
  let teste = {};
  teste.protocolo_selecionado = values.protocolo_selecionado || "";
  teste.fita_selecionada = values.fita_selecionada || "";
  teste.filtro_fitas = values.filtro_fitas || "";
  teste.filtro_protocolos = values.filtro_protocolos || "";
  teste.tipo_analise_selecionado = values.tipo_analise_selecionado || "";
  //teste.tip_codigo = values.tip_codigo || "";
  teste.tip_descricao = values.tip_descricao || "";
  teste.tip_ativo = values.tip_ativo || "";
  teste.tip_fornecedor_fita = values.tip_fornecedor_fita || "";
  teste.slo_codigo = values.slo_codigo || "";
  teste.spa_codigo = values.spa_codigo || "";
  teste.spa_ativo = values.spa_ativo || "";
  teste.quantidade = values.quantidade || 0;
  teste.ptc_descricao = values.ptc_descricao || 0;
  return teste;
};


const MSG_ERROR_PROTOCOLO = I18nTranslator.t("MSG_ERROR_PROTOCOLO");
const MSG_ERROR_SELECIONADO = I18nTranslator.t("MSG_PROTOCOLO_SELECIONADO");
const TEXT_SELECT = I18nTranslator.t("SELECT");
const REGISTER_SERVICE_ORDER = I18nTranslator.t("REGISTER_SERVICE_ORDER");
const SELECIONADO = I18nTranslator.t("SELECIONADO");


let repetido = false

function ProtocolTest({ prevStep, nextStep, handleChange, values, setBotaoCheck }) {
  const [protocoloList, setProtocoloList] = useState([]);
  const [loadingProtocoloList, setLoadingProtocoloList] = useState(false);
  const [loadingProtocoloList1, setLoadingProtocoloList1] = useState(false);

  const [teste, setTeste] = useState(
    values.teste && values.teste.length ? values.teste : [criaTeste()]
  );

  const [fitasNome, setFitasNome] = useState([
    { name: "GENESIS - R-BIO SEMI", value: "RS" },
    { name: "GENESIS - R-BIO QUANT", value: "RQ" },
    { name: "CLIENTE - OUTRAS MARCAS", value: "O" },
    { name: "GENESLAB - NEOGEN", value: "N" },
  ]);

  const adicionaTeste = (teste) => {
    setTeste((prevState) => {
      let novoTeste = [
        ...prevState,
        criaTeste({ protocolo_List: protocoloList }),
      ];
      handleChange("teste", novoTeste);
      return novoTeste;
    });
  };

  const removeTeste = (index) => {
        setTeste((prevState) => {
          prevState[index].spa_ativo = 'N'
         
        let novoTeste = [
          ...prevState]
        handleChange("teste", novoTeste);
        repetido = false
        for (let i = 0; i< novoTeste.length; i++){
          let texto = novoTeste[i].tipo_analise_selecionado.tip_descricao
          if(novoTeste[i].spa_ativo !== 'N'){
            for (let j = 0; j< novoTeste.length; j++){
              if(novoTeste[j].tipo_analise_selecionado.tip_descricao == texto && novoTeste[j].spa_ativo !== 'N' && j !== i){
                repetido = true
              }
            }
          }
        }
        return novoTeste
      });
  };

  const getProtocoloAnalise = () => {
    setLoadingProtocoloList(true);
    AnaliseTesteTipo.filter({
      ent_cnpj: values.regional ? values.regional.ent_cpfcnpj : "",
      cli_cnpj: values.branch ? values.branch.cli_cpfcnpj : "",
    })
      .then((response) => {
        if (Array.isArray(response)) {
          setProtocoloList(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoadingProtocoloList(false));
  };

  useEffect(() => {
    getProtocoloAnalise();
    if(values.teste){
      values.teste.map((linhaTeste,i) => {
        handleChangeTipoProtocolo(i,linhaTeste.tipo_analise_selecionado,'1')
      })
    }
  }, []);

  const handleChangeTipoProtocolo = (index, tipoProtocolo,ver) => {
    let novoTestes = [...teste];
    let resultado = -1
    let texto = tipoProtocolo.tip_descricao
    repetido = false
    if(ver == '2'){
      for (let i = 0; i< novoTestes.length; i++){
        if(novoTestes[i].tipo_analise_selecionado.tip_descricao == texto && novoTestes[i].spa_ativo !== 'N'){
          repetido = true
        }
      }
    }
    novoTestes[index].tipo_analise_selecionado = tipoProtocolo;
    if (tipoProtocolo) {
      setLoadingProtocoloList1(true);
      ProtocoloAnaliseService.filter({
        cli_cpfcnpj: values.branch.cli_cpfcnpj,
        ptc_ativo: "",
        cpt_ativo: "",
        tip_codigo: tipoProtocolo.tip_codigo,
      })
        .then((response) => {
          if (Array.isArray(response)) {
            novoTestes[index].filtro_protocolos = response;
            setTeste(novoTestes);
            handleChange("tipo_analise_selecionado", novoTestes);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoadingProtocoloList1(false));
    }
  };

  const handleChangeAnaliseProtocolo = (index, Tipoprotocolo) => {
    let novoTestes = [...teste];
    novoTestes[index].protocolo_selecionado = Tipoprotocolo;
    setTeste(novoTestes);
    handleChange("protocolo_selecionado", novoTestes);
  };

  const handleChangeFitaProtocolo = (index, tipoFita) => {
    let fitasNova = [...teste];
    fitasNova[index].fita_selecionada = tipoFita;
    setTeste(fitasNova);
    handleChange("fita_selecionada", fitasNova);
  };

  return (
    <>
      <Vspace />
      <Row className='start-xs'>
        <Col>
          <Title>{values.edit && values.slo_codigo ? ` ${values.slo_codigo}` : I18nTranslator.t("REGISTER_SERVICE_ORDER")}</Title>
        </Col>
      </Row>
      <Vspace />
      <Box className="ghost">
        <Row className="start-xs">
          <Col>
            <Text className="s11 fw500">
              {I18nTranslator.t("ADDITIONAL_TEST")}
            </Text>
          </Col>
        </Row>
        <Col>
            <Text className='wac-text s13'>
            - {I18nTranslator.t("PASSOS")}
            </Text>
          </Col>
        <Vspace className="sm" />

        <Row className="start-xs">
          <Col>
            <Text className="s11 fw500"></Text>
          </Col>
        </Row>
        
        {!loadingProtocoloList ? teste.map((values, index) => {
          if(values.spa_ativo !== 'N'){
          return (
            <Row className="bottom-xs">
          
              <Col
                className="col-xs-3 col-lg-4 col-md-12"
                style={{ marginTop: `30px` }}
              >
                <Autocomplete
                  
                  value={values.tipo_analise_selecionado}
                  autocomplete="off"
                  disableClearable
                  id="tipoAnalise"
                  name="tipoAnalise"
                  options={protocoloList}
                  loadingText={"..."}
                  noOptionsText={"Carregando"}
                  getOptionLabel={(option) => option.tip_descricao}
                  onChange={(e, tipoTeste) =>
                    handleChangeTipoProtocolo(index, tipoTeste,'2')
                  }
                  disableanimation="true"
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        autoComplete="off"
                        label={I18nTranslator.t("ANALYSIS_TYPE")}
                        placeholder={I18nTranslator.t("SELECT")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </>
                  )}
                />
              </Col>
              {values.tipo_analise_selecionado != 0 && values.filtro_protocolos > [0]? (
              <Col
                className="col-xs-3 col-lg-4 col-md-12"
                style={{ marginTop: `10px` }}
              >
                <Select
                  disabled={values.quantidade != 0}
                  style={{ marginTop: `-2px` }}
                  autocomplete="off"
                  disableClearable
                  id="tipoProtocolo"
                  name="tipoProtocolo"
                  loadingText={"..."}
                  label={I18nTranslator.t("ANALYSIS_PROTOCOL")}
                  onChange={(e) =>
                  handleChangeAnaliseProtocolo(index, e.target.value)
                  }
                >
                  {values.filtro_protocolos && values.filtro_protocolos.length ? (
                    [
                      <Option value="">{I18nTranslator.t("SELECT")}</Option>,
                      values.filtro_protocolos.map((row) => {
                        let value = row.ptc_codigo + '@'+ row.cpt_codigo+'@'+ row.ptc_descricao
                        let aux = row.ptc_codigo + '@'+ row.cpt_codigo
                        if(values.slo_codigo === ""){
                           aux = row.ptc_codigo + '@'+ row.cpt_codigo+'@'+row.ptc_descricao
                        }
                        return (
                          <Option value={value} selected={aux === values.protocolo_selecionado}>
                            {row.ptc_descricao}
                          </Option>
                        );
                      }),
                    ]
                  ) : (
                    <Option value="" selected>{I18nTranslator.t("Não Encontrado")}
                    </Option>
                  )}
                </Select>
              </Col> ) : null
              }
              {values.tipo_analise_selecionado.tip_descricao == "AFLATOXINA" ? (
                <Col className="col-xs-3 col-lg-4 col-md-12">
                  <Autocomplete
                    disabled={values.quantidade != 0}
                    autocomplete="off"
                    disableClearable
                    id="tipoFitaSelecionada"
                    name="tipoFitaSelecionada"
                    loadingText={"..."}
                    disable={
                      values.tipo_analise_selecionado.tip_descricao ==
                      "AFLATOXINA"
                        ? "true"
                        : "false"
                    }
                    id={`nomesFitas${index}`}
                    value={values.fita_selecionada}
                    options={
                      values.tipo_analise_selecionado.tip_descricao ==
                      "AFLATOXINA"
                        ? fitasNome
                        : []
                    }
                    loadingText={"Carregando"}
                    noOptionsText={"Nada encontrado"}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, TipoFita) =>
                      handleChangeFitaProtocolo(index, TipoFita)
                    }
                    disableanimation="true"
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          autoComplete="off"
                          label={I18nTranslator.t("RIBBON_SUPPLIER")}
                          placeholder={I18nTranslator.t("SELECT")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                </Col>
              ) : null}
              {values.quantidade == 0 ? (
                <Col className="col-xs-2 col-lg-2 col-md-12">
                  <Button
                    className="outlined"
                    onClick={() => removeTeste(index)}
                  >
                    {I18nTranslator.t("REMOVER")}
                  </Button>
                </Col>
              ) : null}
              {values.tipo_analise_selecionado != 0 && values.filtro_protocolos < [0] ? (
              <Feedback severity={"danger"} message={MSG_ERROR_SELECIONADO} />  
              ) : null }
               { !repetido ? 
                <Feedback severity={"danger"}/>
               : 
               <Feedback severity={"danger"} message={I18nTranslator.t("MSG_ERROR_PROTOCOLO")}/>}
               { values.quantidade != 0 ?
                  <Feedback severity={"danger"} message={I18nTranslator.t("SELECIONADO")}/>
                : null }
            </Row>
          );
          } else {
            return null
          }
        }): null}
        <Row className="start-xs">
          <Col style={{ marginTop: `14px` }}>
            <Button
              className="inline"
              onClick={adicionaTeste}
            >
              <Icon className="sm" component={<PlusCircleIcon />}/>
              <Vspace className='sm'></Vspace>{I18nTranslator.t("ADD_ANALISE")}
            </Button>
          </Col>
        </Row>
        <Vspace className="xxxlg" />
        <Group className="spread">
          <Link className="wac-button outlined" onClick={prevStep}>
            {I18nTranslator.t("BACK")}
          </Link>
          { !repetido && values.protocolo_selecionado >= [1] ? (      
          <Link          
            id="confirmar"
            className="wac-button"
            disabled="true"
            onClick={(values) => {
                  nextStep(values);
                    // setBotaoCheck(true)
            
            }}
          >
            {I18nTranslator.t("CONFIRM")}
          </Link>): null
           }
        </Group>
      </Box>
    </>
  );
}

export default ProtocolTest;
