/* eslint-disable */
import React, {useEffect, useState} from "react";
import Switch from "components/commons/Switch";
import Group from "components/commons/Group";
import Button from "components/commons/Button";
import Vspace from "components/commons/Vspace";
import Search from "components/commons/Search";
import AdvancedFilter from "./components/InboxList/AdvancedFilter";
import DropdownColumns from "./components/InboxList/DropdownColumns";
import {Paragraph} from "components/commons/Typography";
import InboxList from "./components/InboxList/InboxList";
import {CargoService} from "core/cargo/CargoService";
import Box from 'components/commons/Box';
import {I18nTranslator} from "core/i18n/I18nTranslator";

import DateUtil from "core/utils/DateUtil";
import DropdownExport from "../../../../../components/commons/DropdownExport";

function CargoPanel() {

    // Traduções.
    const TEXT_DATE = I18nTranslator.t('DATE');
    const TEXT_CAR_PLATE = I18nTranslator.t('CAR_PLATE');
    const TEXT_CARRIER = I18nTranslator.t('CARRIER');
    const TEXT_CLASSIFIER = I18nTranslator.t('CLASSIFIER');
    const TEXT_SUPERV = I18nTranslator.t('SUPERV');
    const TEXT_CLIENT = I18nTranslator.t('CLIENT');
    const TEXT_BOARD_LOCATION = I18nTranslator.t('BOARD_LOCATION');
    const TEXT_ISSUE_CTE = I18nTranslator.t('ISSUE_CTE');
    const TEXT_HOUR_DOC_ATTACHMENT = I18nTranslator.t('HOUR_DOC_ATTACHMENT');
    const TEXT_WEIGHT = I18nTranslator.t('WEIGHT');
    const TEXT_INVOICE = I18nTranslator.t('INVOICE');
    const TEXT_GELD_FIELD = I18nTranslator.t('GELD_FIELD');
    const TEXT_MANIFESTED = I18nTranslator.t('MANIFESTED');
    const TEXT_CLIREGIONAL = I18nTranslator.t('REGIONAL_CUSTOMER');
    const TEXT_CLIENTFILIAL = I18nTranslator.t('BRANCH_CUSTOMER');
    const TEXT_HORA_DOC_DEVOLVIDO = I18nTranslator.t('HORA_DOC_DEVOLVIDO');
    const TEXT_MENIFESTADO = I18nTranslator.t('MANIFESTADO');
    const TEXT_ATENDENTE = I18nTranslator.t('ATENDENTE');
    const TEXT_STATUS = I18nTranslator.t('STATUS_CARGA');

    const columnsNameDic = {
        1: 'O.S.',
        2: TEXT_CLIREGIONAL,
        3: TEXT_CLIENTFILIAL,
        4: TEXT_DATE,
        5: TEXT_HOUR_DOC_ATTACHMENT,
        6: TEXT_HORA_DOC_DEVOLVIDO,
        7: TEXT_CAR_PLATE,
        8: TEXT_WEIGHT,
        9: TEXT_INVOICE,
        10: TEXT_CARRIER,
        11: TEXT_BOARD_LOCATION,
        12: TEXT_SUPERV,
        13: TEXT_CLASSIFIER,
        14: TEXT_GELD_FIELD,
        15: TEXT_MENIFESTADO,
        16: TEXT_ATENDENTE,
        17: TEXT_MANIFESTED,
        18: TEXT_ISSUE_CTE,
        19: TEXT_STATUS,
    }


    var defaultColumns = localStorage.getItem('inbox_os_columns_cargo_panel') ? localStorage.getItem('inbox_os_columns_cargo_panel') : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18]

    var [activeColumns, setActiveColumns] = useState(defaultColumns);

    var [activeColumnsName, setActiveColumnsName] = useState(returnColumnsActive(defaultColumns));

    var [showDropdownColumns, openDropdown] = useState(false);

    // Lista com os objetos filtrados da ordem de serviço
    var [filterCargoList, setfilterCargoList] = useState([]);

    // Lista com os valores dos filtros de status selecionados (SWITCH)
    var [statusFilterArray, setStatusFilterArray] = useState([]);

    // Valor do filtro Buscar
    var [search, setSearch] = useState('');

    // Valor booleano que indica se a aba de 'filtro avançado' está habilitada
    var [filterActive, setActive] = useState(false);

    var [exportList, setExportList] = useState([]);

    // Lista de ordem de serviço
    var [cargoList, setCargoList] = useState([]);
    var [loadingCargoList, setloadingCargoList] = useState(false);



    const defaultI18n = {
        NOTHING_FOUND: I18nTranslator.t("NOTHING_FOUND"),
        SET_UP_COLUMNS: I18nTranslator.t("SET_UP_COLUMNS"),
        SELECT: I18nTranslator.t("SELECT"),
        ALL: I18nTranslator.t("ALL"),
        NONE: I18nTranslator.t("NONE"),
        STATUS: I18nTranslator.t("STATUS"),
        EXPORT: I18nTranslator.t("EXPORT")
    };


    function handleFilterActive() {
        setActive(filterActive === false);
    }

    function handleDropdownColumns() {
        openDropdown(showDropdownColumns === false);
    }

    useEffect(() => {

        // Filtra automaticamente as ordens de serviço assim que a tela é carregada.
        let btnAdvancedFiler = document.getElementById('btn_advanced_filter_cargo');
        if (btnAdvancedFiler) btnAdvancedFiler.click();

        // Seleciona todos os status assim que a tela é carregada.
        document.getElementById('switch_aberto').click();
        document.getElementById('switch_finalizados').click();
        document.getElementById('switch_sem_status').click();

    }, []);

    // Manipula o envio do formulario
    function handleSubmit(e) {
        e.preventDefault();

        // Busca os elementos do filtro avançado e armazena em um objeto de parâmetros
        let formElements = document.getElementById('form_os_advanced_filter').elements;
        let buttonFilter = document.getElementById('btn_advanced_filter_cargo');
        let likeFilterElements = ["placa", "transportadora"];
        let filterParameters = {};
        let cargaFoto = document.getElementById("switch_cargas_com_foto_anexada").checked
        let mensagens_nao_lidas = document.getElementById("switch_cargas_com_mensagens").checked
        let cargas_com_mensagens = document.getElementById("switch_cargas_todos_documentos_anexados").checked

        for (let i = 0; i < formElements.length; i++) {
            let item = formElements.item(i);
            if (item.name === "") continue;

            filterParameters[item.name] = (item.value !== '' && likeFilterElements.indexOf(item.name) > -1) ? `%${item.value}%` : item.value;
        }
        if (!cargaFoto) {
            filterParameters["carga_fotos"] = "";
        }
        if (!mensagens_nao_lidas) {
            filterParameters["mensagem_nao_lidas"] = "";
        }
        if (!cargas_com_mensagens) {
            filterParameters["iac_fotos_doc_enviadas"] = "";
        }

        // Desabilita o botão e altera o estado da tabela para 'carregando'
        buttonFilter.disabled = true;
        setloadingCargoList(true);

        // Busca a lista de ordens de serviço e atualiza os estados
        CargoService.filter(filterParameters)
            .then((response) => {

                if (Array.isArray(response)) {
                    setCargoList(response);
                    handleFilterCargoList(response);


                }
            })
            .catch((error) => {
            })
            .finally(() => {
                buttonFilter.disabled = false;
                setloadingCargoList(false);
            });
    }

    // Manipula a ação de filtrar por qualquer campo na tabela
    function handleSearch(e) {
        if (cargoList.length === 0) return;

        let text = e.target.value;

        let filter = cargoList.filter((cargo) => {
                let equals = matchObject(cargo, text) && statusFilterArray.indexOf(cargo.status) > -1;
                setSearch(text);
                return equals;
            }
        );

        let timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => handleFilterCargoList(filter), 1100);
    }

    function handleFilterCargoList(filterList){
        let exportCargoList = formatDataExport(filterList)
        setfilterCargoList(filterList)
        setExportList(exportCargoList)
    }


    // Armazena o estado dos filtros de Switch e popula o array statusFilterArray
    function handleSwitchFilter(e) {
        let statusName = e.target.value;
        let index = statusFilterArray.indexOf(statusName);

        if (index > -1) {
            statusFilterArray.splice(index, 1);
        } else {
            statusFilterArray.push(statusName);
        }

        setStatusFilterArray(statusFilterArray);
        filterStatus();
    }

    function handleChangeColumns(e) {
        try {
            let element = e.target;
            let elementOrder = typeof element.dataset.order != 'undefined' ? parseInt(element.dataset.order) : 0;

            let columns = JSON.parse(localStorage.getItem('inbox_os_columns_cargo_panel')) || [];

            let index = columns.indexOf(elementOrder);

            if (element.checked && index === -1) {
                columns.push(elementOrder);
            } else {
                columns.splice(index, 1);
            }

            localStorage.setItem('inbox_os_columns_cargo_panel', JSON.stringify(columns));
            setActiveColumns(columns);
            setActiveColumnsName(returnColumnsActive(columns))
        } catch (error) {
        }
    }

    // Realiza a busca de um valor dentro de um objeto
    function matchObject(obj, find) {

        let equals = false;

        for (var prop in obj) {
            let comparativeValue = obj[prop];
            if (typeof comparativeValue === 'object') {
                comparativeValue = JSON.stringify(comparativeValue);
            }

            if (comparativeValue) {
                equals = comparativeValue.toString().toLowerCase().includes(find.toLowerCase());
            }

            if (equals) break;
        }

        return equals;
    }

    // Realiza o filtro na lista de ordem de serviço de acordo com o(s) status selecionado(s)
    function filterStatus() {
        if (cargoList.length > 0) {
            let filterCargoList = cargoList.filter((os) => {
                let match = true;
                if (search) {
                    match = matchObject(os, search);
                }

                return match && statusFilterArray.indexOf(os.status) > -1;
            });

            handleFilterCargoList(filterCargoList);
        }
    }

    function returnColumnsActive(columns) {
        let columnsActiveNews = [];
        (typeof columns === "string" ? JSON.parse(columns) : columns).map((col)=>{
            if (columnsNameDic[col]){
                columnsActiveNews.push(columnsNameDic[col])
            }

        })
       return  columnsActiveNews
    }

    
    function formatDataExport(filterCargoList) {
        let arrayData = []
        if (filterCargoList.length) {
            filterCargoList.map((cargo) => {
                var {
                    ordem_servico,
                    regional,
                    iac_dt_servidor,
                    class_nome,
                    iac_imprime_arquivos,
                    iac_hora_fotos_doc_enviadas,
                    iac_nota_fiscal,
                    iac_manifesto_via_integrador,
                    data_hora_manifestado,
                    bas_cidade,
                    slo_faturar_geldnocampo,
                    iacf_data_ult_anexo,
                    nome_atendente,
                    iac_placa,
                    iac_peso,
                    iac_manifesto,
                    transportadora,
                    nome_status
                } = cargo
                var {slo_codigo,local_embarque, filial} = ordem_servico
                var {loc_nome} = local_embarque
                var {cli_nome} = filial

                let nome_transportadora = ""
                    if (transportadora){
                        var {transp_nome} = transportadora
                        nome_transportadora = transp_nome
                }

                if(iac_hora_fotos_doc_enviadas){
                    var dataServidor = new Date(iac_dt_servidor)
                    var horaLaudo = dataServidor.toLocaleTimeString(navigator.language,{hour:'2-digit',minute:"2-digit",second:"2-digit"})
                    var a = horaLaudo.split(':')
                    var b = iac_hora_fotos_doc_enviadas.split(':')
                    var secondsLaudo = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
                    var secondsHoraEnviado = (+b[0]) * 60 * 60 + (+b[1]) * 60 + (+b[2])
                    var resultado = secondsLaudo - secondsHoraEnviado
                        if(resultado > 0 ){
                            var data = new Date('2021-01-01 '+ iac_hora_fotos_doc_enviadas)
                            data.setHours(data.getHours() + 1)
                            iac_hora_fotos_doc_enviadas = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()
                        }
                }

                if(nome_status){
                    var separa = nome_status;
                    var resultado = separa.split(/\d+/);
                    
                }

                arrayData.push({
                    'O.S.': slo_codigo,
                    [TEXT_CLIREGIONAL]: regional,
                    [TEXT_CLIENTFILIAL]: cli_nome,
                    [TEXT_DATE]: `${DateUtil.formatStringDateTime(iac_dt_servidor)} ${DateUtil.formatStringTime(iac_dt_servidor)}`,
                    [TEXT_HOUR_DOC_ATTACHMENT]: iac_hora_fotos_doc_enviadas ? (`${DateUtil.formatStringDateTime(iac_dt_servidor)} ${(iac_hora_fotos_doc_enviadas)}`) : 'Não Anexado',
                    [TEXT_HORA_DOC_DEVOLVIDO]: data_hora_manifestado ? (`${DateUtil.formatStringDateTime(data_hora_manifestado)} ${DateUtil.formatStringTime(data_hora_manifestado)}`): 'Não Manifestado',
                    [TEXT_CAR_PLATE]: iac_placa,
                    [TEXT_WEIGHT]: iac_peso,
                    [TEXT_INVOICE]: iac_nota_fiscal,
                    [TEXT_CARRIER]: nome_transportadora ? nome_transportadora : "-",
                    [TEXT_BOARD_LOCATION]: (loc_nome || ""),
                    [TEXT_SUPERV]: (bas_cidade || ""),
                    [TEXT_CLASSIFIER]: (class_nome || ""),
                    [TEXT_GELD_FIELD]: slo_faturar_geldnocampo === "C" ? I18nTranslator.t("CUSTOMER") : "TRANSP.",
                    [TEXT_MENIFESTADO]: iac_manifesto_via_integrador ? (iac_manifesto_via_integrador === "S" ? 'Integrador' : 'Manual'): "",
                    [TEXT_ATENDENTE]: (nome_atendente|| ""),
                    [TEXT_MANIFESTED]: iac_manifesto ? (iac_manifesto === "S" ? "SIM" : "NÃO") : "-",
                    [TEXT_ISSUE_CTE]: iac_imprime_arquivos ? (iac_imprime_arquivos === "S" ? "SIM" : "NÃO") : "-",
                    [TEXT_STATUS]: nome_status !== null ? nome_status : 'Não Informado',
                })
            })

        }
        return arrayData
    }

    return (
        <>
            <Box className="mobile-box">
                <DropdownExport
                    i18n={{...defaultI18n}}
                    filename={I18nTranslator.t("CARGO_PANEL")}
                    data={exportList}
                    columns={activeColumnsName}
                />
                <Group className="vc g2 mv">
                    <Paragraph className="uppercase fw700">
                        {I18nTranslator.t('STATUS')}
                    </Paragraph>
                    <Group className="g3 m-spread">
                        <Switch
                            className="mv"
                            label={I18nTranslator.t("WITHOUT_STATUS")}
                            value="SS"
                            status="canceled"
                            onChange={handleSwitchFilter}
                            type="checkbox"
                            id="switch_sem_status"
                        />
                        <Switch
                            className="mv"
                            label={I18nTranslator.t("OPENED")}
                            status="pending"
                            value="A"
                            type="checkbox"
                            onChange={handleSwitchFilter}
                            id="switch_aberto"
                        />
                        <Switch
                            className="mv"
                            label={I18nTranslator.t("FINISHED")}
                            value="F"
                            status="complete"
                            type="checkbox"
                            onChange={handleSwitchFilter}
                            id="switch_finalizados"
                        />

                    </Group>
                </Group>
                <Vspace className="lg hom"/>
                <Group className="vc g5 mv">
                    <Search
                        id={`search_field_table_cargo_list`}
                        onKeyUp={handleSearch}
                        placeholder={I18nTranslator.t('SEARCH')}
                    />
                    <Button
                        onClick={handleFilterActive}
                        className={`filter has-arrow ${filterActive === true ? 'active' : ''}`}>
                        {I18nTranslator.t("ADVANCED_FILTER")}
                    </Button>
                    <DropdownColumns
                        showDropdownColumns={showDropdownColumns}
                        activeColumns={activeColumns}
                        handleChange={handleChangeColumns}
                        onClick={handleDropdownColumns}
                    />
                </Group>
                <Vspace/>
                <form id="form_os_advanced_filter" onSubmit={handleSubmit}>
                    <AdvancedFilter filterActive={filterActive}/>
                </form>
            </Box>
            <InboxList
                activeColumns={activeColumns}
                cargoList={filterCargoList}
                loading={loadingCargoList}
                paginationSize={15}
                showDropdownColumns={showDropdownColumns}
                handleChangeColumns={handleChangeColumns}
                onClickColumns={handleDropdownColumns}
            />

        </>
    );
}

export default CargoPanel;
