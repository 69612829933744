/* eslint-disable */
import React, { useState } from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import { Title } from 'components/commons/Typography';
import TextUtil from 'core/utils/TextUtil';
import { CarryingService } from 'core/carrying/CarryingService';
import { Snackbar } from '@material-ui/core';

const FormNew = ({ onClick, onCreate = () => {} }) => {
  const [state, setState] = useState({
    transp_nome: { value: '', required: true, error: false, feedback: 'Informe o nome!'},
    transp_email: { value: '', required: false, error: false },
    transp_cnpj: { value: '', required: true, error: false, feedback: "Informe o CNPJ!"}
  });

  const [button, setButton] = useState({
    label: "Cadastrar",
    disabled: false,
    success: false
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    isOpen: false
  });

  const handleChange = (name, value) => {
    setState({ ...state, [name]: { ...state[name], value: value, error: state[name].required && !value.length } })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    validateAll();

    setButton({ label: "Salvando...", disabled: true });
    let params = {};
    Object.keys(state).map(key => {
      params[key] = state[key].value;
    });
    CarryingService.create(params)
    .then(response => {
      if (response.success) {
        onCreate(response.transportadora);
        setButton({ label: 'Sucesso!', disabled: false, success: true })
      } else {
        let error = response.error || response;
        errorValidation(error)
      }
      console.log("CarryingService.create", response);
    })
    .catch(response => {
      let error = response.error || response;
      errorValidation(error)
    })
    .finally(() => setTimeout(() => setButton({ label: "Cadastrar", disabled: false, success: false}), 3000))
  }

  const errorValidation = (error) => {
    switch(error) {
      case "not_enough_params":
        validateAll();
        break;
      default:
        console.log("Erro não mapeado!");
        setSnackbar({
          isOpen: true,
          message: "Não foi possível realizar o cadastro, tente novamente mais tarde!", 
        });
    }
  }

  const validateAll = () => {
    Object.keys(state).map((key) => {
      let row = state[key];
      row.error = row.required && !row.value.length;
      setState({ ...state, [key]: row });
    })
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        key={`snackbar`}
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ message: '', isOpen: false })}
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbar.message}</span>}
      />
      <Box>
        <Button onClick={onClick} className="button-close" />
        <Title className='s11'>
          Cadastro de transportadora
        </Title>
        <Vspace className='lg'/>
        <form onSubmit={handleSubmit}>
          <Group className='g3 fdc'>
            <Input
              label='Nome'
              name="transp_nome"
              defaultValue={state.transp_nome && state.transp_nome.value}
              feedback={state.transp_nome && state.transp_nome.feedback}
              severity={state.transp_nome && state.transp_nome.error && 'danger'}
              onChange={(e) => {
                e.target.value = TextUtil.capitalizeFirstLetters(e.target.value)
                handleChange(e.target.getAttribute("name"), e.target.value)
              }}
              placeholder='Digite o nome da transportadora'
            />
            <Input
              label='E-mail'
              name="transp_email"
              defaultValue={state.transp_email && state.transp_email.value}
              feedback={state.transp_email && state.transp_email.feedback}
              severity={state.transp_email && state.transp_email.error && 'danger'}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\s+|,/g, ';')
                handleChange(e.target.getAttribute("name"), e.target.value)
              }}
              placeholder='email@email.com'
            />
            <Input
              label='CNPJ'
              name="transp_cnpj"
              defaultValue={state.transp_cnpj && state.transp_cnpj.value}
              feedback={state.transp_cnpj ? state.transp_cnpj.feedback :  ''}
              severity={state.transp_cnpj ? state.transp_cnpj.error : 'danger'}
              onChange={(e) => {
                e.target.value = TextUtil.formatCnpj(e.target.value)
                handleChange(e.target.getAttribute("name"), e.target.value)
              }}
              placeholder='00.000.000/0000-00'
            />
            <Vspace className='lg'/>
            <hr />
            <Group className='spread fluid'>
              <Button type="reset" onClick={onClick} className='outlined'>
                Cancelar
              </Button>
              <Button type="submit" className={button.success && 'primary'} disabled={button.disabled}>
                {button.label}
              </Button>
            </Group>
          </Group>
        </form>
      </Box>
    </React.Fragment>
  )
}

export default FormNew