/* eslint-disable */
import React, {useEffect, useState} from "react";
import Icon from "components/commons/Icon";
import {Text} from 'components/commons/Typography';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import {EyeIcon, ImageIcon, MapPinIcon, MessageCircleIcon, PaperClipIcon} from 'components/Icons';
import {CargoService} from "../../../../../../../core/cargo/CargoService";
import {userAccessService} from "../../../../../../../core/user-access/UserAccessService";
import {I18nTranslator} from "../../../../../../../core/i18n/I18nTranslator";

const PopoverContent = (props) => {
    // Traduções.
    const TEXT_CURRENT_STATUS = I18nTranslator.t("CURRENT_STATUS");
    const TEXT_ATTACH_DOCUMENT = I18nTranslator.t("ATTACH_DOCUMENT");
    const TEXT_VIEW_REPORT = I18nTranslator.t("VIEW_REPORT");
    const TEXT_VIEW_PICTURES = I18nTranslator.t("VIEW_PICTURES");


    const {
        onClickChat,
        numero_mensagem,
        id_chat,
        onClickStatus,
        handleVisualizarDados,
        handleVisualizarGaleriaImagem,
        handleAnexarDocumentos,
        componentWillUnmount,
        galeriaImagem
    } = props;


    const [activePopover, setPopover] = useState(false)
    const [timeOutId, setTimeOutId] = useState(null)
    const [mensageNum, setMensageNum] = useState(null)

    useEffect(() => {
        return () => {
            componentWillUnmount();
        },
        handleMensageNum()
    }, [])


    function handleMensageNum(){
        if (numero_mensagem>0){
            setMensageNum(numero_mensagem)
        }
    }

    function handlePopover() {

        setPopover(activePopover === false);
    }


    function handleBlur() {
        let timeout = setTimeout(() => setPopover(false));
        setTimeOutId(timeout)
    }

    function handleFocus() {
        clearTimeout(timeOutId)
    }

    function handleClickOption(event, callback) {
        if (callback) {
            callback(event)
        }
        handleVizualizaMensage()
        setPopover(false)
    }
    function handleVizualizaMensage(){
            CargoService.chatVisualization(id_chat)
                .then((response) => {
                    }
                )
                .catch((error) => {
                    console.error(error);
                })
        setMensageNum(null)
    }


    let btnGaleriaImagem = <Button className='inline' onClick={handleVisualizarGaleriaImagem}>
        <Icon
            className='df2'
            component={<ImageIcon/>}/>
        <Text className='s13 white uppercase'>
            {TEXT_VIEW_PICTURES}
        </Text>
    </Button>

    let btnVerLaudo = <Button
        onClick={handleVisualizarDados}
        className='inline'>
        <Icon
            className='df2'
            component={<EyeIcon/>}/>
        <Text className='s13 white uppercase'>
            {TEXT_VIEW_REPORT}
        </Text>
    </Button>

    return (
        <div className={`wac-featured-popover ${activePopover ? 'active' : ''}`} onBlur={handleBlur}
             onFocus={handleFocus}>
            <button
                onClick={handlePopover}
                className="wac-featured-popover-trigger"
                counter={mensageNum}
                title="4 alguma coisa">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="5" viewBox="0 0 18 5" fill="none">
                    <path
                        d="M2.392 4.192C1.83733 4.192 1.368 4 0.984 3.616C0.6 3.232 0.408 2.76267 0.408 2.208C0.408 1.65333 0.6 1.184 0.984 0.8C1.368 0.416 1.83733 0.224 2.392 0.224C2.92533 0.224 3.37333 0.416 3.736 0.8C4.12 1.184 4.312 1.65333 4.312 2.208C4.312 2.76267 4.12 3.232 3.736 3.616C3.37333 4 2.92533 4.192 2.392 4.192ZM9.11075 4.192C8.55608 4.192 8.08675 4 7.70275 3.616C7.31875 3.232 7.12675 2.76267 7.12675 2.208C7.12675 1.65333 7.31875 1.184 7.70275 0.8C8.08675 0.416 8.55608 0.224 9.11075 0.224C9.64408 0.224 10.0921 0.416 10.4548 0.8C10.8388 1.184 11.0308 1.65333 11.0308 2.208C11.0308 2.76267 10.8388 3.232 10.4548 3.616C10.0921 4 9.64408 4.192 9.11075 4.192ZM15.8295 4.192C15.2748 4.192 14.8055 4 14.4215 3.616C14.0375 3.232 13.8455 2.76267 13.8455 2.208C13.8455 1.65333 14.0375 1.184 14.4215 0.8C14.8055 0.416 15.2748 0.224 15.8295 0.224C16.3628 0.224 16.8108 0.416 17.1735 0.8C17.5575 1.184 17.7495 1.65333 17.7495 2.208C17.7495 2.76267 17.5575 3.232 17.1735 3.616C16.8108 4 16.3628 4.192 15.8295 4.192Z"
                        fill="#4F4F4F"/>
                </svg>
            </button>
            <div className="wac-featured-popover-content">
                <button onClick={handlePopover} className="wac-featured-drawer-trigger"/>
                <Group className='fdc g3'>
                    <Button
                        onClick={handleAnexarDocumentos}
                        className='inline'>
                        <Icon
                            className='df2'
                            component={<PaperClipIcon/>}/>
                        <Text className='s13 white uppercase'>
                            {TEXT_ATTACH_DOCUMENT}
                        </Text>
                    </Button>

                    {userAccessService.hasPermission("permVerLaudoCarga") ? btnVerLaudo : ""}
                    {galeriaImagem === 'S' ? btnGaleriaImagem : ""}

                    <Button onClick={(e) =>  handleClickOption(e, onClickChat)}
                            counter={mensageNum}
                            className='inline'>
                        <Icon
                            className='df2'
                            component={<MessageCircleIcon/>}/>
                        <Text className='s13 white uppercase'>
                            Chat
                        </Text>
                    </Button>
                    <Button className='inline hod'
                            onClick={(e) => handleClickOption(e, onClickStatus)}>
                        <Icon
                            className='df2'
                            component={<MapPinIcon/>}/>
                        <Text className='s13 white uppercase'>
                            {TEXT_CURRENT_STATUS}
                        </Text>
                    </Button>
                </Group>
            </div>
        </div>
    );
};

export default PopoverContent