/* eslint-disable */
import React, {Fragment} from 'react';
import './Main.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CallIcon from '@material-ui/icons/Call';
import {Drawer, Snackbar, Slide} from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// @ts-ignore
import autobind from 'class-autobind';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import imageCarga from "../../../assets/ic_carga_p.png";
import imageEmbarcado from "../../../assets/ic_embarcado.png";
import imageNHE from "../../../assets/ic_nhe.png";
import imageRefugado from "../../../assets/ic_refugado.png";
import imageFila from "../../../assets/ic_fila.png";
import imageCaminhaoBranco from "../../../assets/ic_branco.png";
import imageMarkerInactive from "../../../assets/mark_cinza.svg";
import imageMarkerWithoutCharge from "../../../assets/mark_vermelho.svg";
import imageMarkerWithLt7Charges from "../../../assets/mark_amarelo.svg";
import imageMarkerWithGt7Charges from "../../../assets/mark_verde.svg";
import imageFormulario from "../../../assets/ic_formulario_g.png";
import folhaClassificacao from "../../../assets/ic_classificacao.png";
import imagePeso from "../../../assets/ic_peso.png";
import Divider from "@material-ui/core/Divider";
import AppSelectModal from "../../utils/AppSelectModal";
import Grid from "@material-ui/core/Grid";
import * as _ from "lodash";
import GoogleMap from "./GoogleMap";
import Marker from "./Marker";
import {I18nTranslator} from "../../../core/i18n/I18nTranslator";
import {OSService} from "../../../core/os/OSService";
import Dialog from "@material-ui/core/Dialog";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import TextField from "@material-ui/core/TextField";
import {userAccessService} from "../../../core/user-access/UserAccessService";
import {UserService} from "../../../core/user/UserService";
import {Redirect} from "react-router";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import Box from "@material-ui/core/Box";

import imageLaudoPP from "../../../assets/ic_laudo_pp.png";
import imageFormularioPP from "../../../assets/ic_formulario_pp.png";
import imageCargaPP from "../../../assets/ic_carga_pp.png";
import imagePesoPP from "../../../assets/ic_peso_pp.png";
import imageFilaRelogio from "../../../assets/ic_fila_g_w.png";
import Button from "@material-ui/core/Button";
import {configuration} from "../../../config";
import {TransitionProps} from '@material-ui/core/transitions';
import Group from 'components/commons/Group';
import NotificationDropdown from "./components/NotificationDropdown";
import {Text} from "../../../components/commons/Typography";
import {BoardingInformationService} from "../../../core/boarding_information/BoardingInformationService";
import DateUtil from "../../../core/utils/DateUtil";
import NotificationMessageButton from "./components/NotificationMessageButton";
import Icon from "../../../components/commons/Icon";
import {ClockIcon} from "../../../components/Icons";
import DataTable from "../../../components/commons/DataTable/DataTable";
import Vspace from "../../../components/commons/Vspace";



interface State {
    logout: boolean,
    drawerLeft: boolean,
    drawerRight: boolean,
    language: string,
    places: any[],
    allPlaces: any[],
    loading: boolean,
    map: any,
    maps: any,
    mapCenter: any[],
    activePlace: any | null,
    activeSupervisor: any,
    activeClassificador: any,
    comment: any,
    loadingFilter: boolean,
    actualFilter: any,
    showEmbarcado: boolean,
    loadingEmbarcado: boolean,
    embarcado: any,
    showFila: boolean,
    loadingFila: boolean,
    fila: any,
    filter: {
        empresa: any,
        matriz: any,
        regional: any,
        filial: any,
        uf: any,
        cidade_embarque: any,
        local_embarque: any,
        produto: any,
        contrato: any,
        ordem_servico: any,
        teste_aflatoxina: any,
    },
    quickFilterActive: {
        osInactive: boolean,
        withoutCharges: boolean,
        lt7Charges: boolean,
        gt7Charges: boolean,
    },
    filterEmbarcado: string | null
    filterFila: string | null,
    activeMarker: string | null,
    messageSnackbar: string | null,
    openSnackbar: boolean,
    messageInterval: any,
    boardingInformation: any[],
    activeBoardingInformation: any,
    loadingBoardingInformation: boolean,
    dataTableRows: any[]

}

class Main extends React.Component<{}, State> {
    private menuRef: React.RefObject<Object>;
    private dataTableColumns: String[];
    private dataTableRowDictionary: { [key: string]: any };

    constructor(props: any) {
        super(props);

        const defaultFilter = {
            empresa: userAccessService.getEmpresa(),
            matriz: userAccessService.getUser().mat_cnpj,
            regional: "",
            filial: "",
            uf: "",
            cidade_embarque: "",
            local_embarque: "",
            produto: "",
            contrato: "",
            ordem_servico: "",
            teste_aflatoxina: "",
        };

        this.state = {
            logout: false,
            drawerLeft: false,
            drawerRight: false,
            language: I18nTranslator.language,
            places: [],
            allPlaces: [],
            loading: true,
            map: null,
            maps: null,
            mapCenter: [-15.78014, -47.92916],
            activePlace: null,
            activeSupervisor: null,
            activeClassificador: null,
            comment: null,
            loadingFilter: false,
            actualFilter: _.clone(defaultFilter),
            filter: _.clone(defaultFilter),
            showEmbarcado: false,
            loadingEmbarcado: false,
            embarcado: null,
            showFila: false,
            loadingFila: false,
            fila: null,
            quickFilterActive: {
                osInactive: false,
                withoutCharges: true,
                lt7Charges: true,
                gt7Charges: true,
            },
            filterEmbarcado: null,
            filterFila: null,
            activeMarker: null,
            messageSnackbar: "",
            openSnackbar: false,
            messageInterval: null,
            boardingInformation: [],
            activeBoardingInformation: null,
            loadingBoardingInformation: false,
            dataTableRows: []
        };
        const uf: string = "UF";
        const os: string = "O.S.";
        const supervisor: string = "Supervisor";
        const localServico: string = "Local serviço";
        const produto: string = "Produto";
        const loteTon: string = "Lote (ton)";
        const volumeRealizado: string = "Volume realizado";
        const volumeDoDia: string = "Volume do dia";
        const filaAguardando: string = "Fila aguardando";
        const filaRealizado: string = "Fila realizado";
        const filaRefugada: string = "Fila refugada";
        const demo: string = "DEMONSTRACAO";

        this.dataTableColumns = [
            uf,
            os,
            supervisor,
            localServico,
            produto,
            loteTon,
            volumeRealizado,
            volumeDoDia,
            filaAguardando,
            filaRealizado
        ];
        const usuario = userAccessService.getUser();
        const apresentacao = usuario.usu_apresentacao;
        
        {apresentacao === "S" ?
        this.dataTableRowDictionary = {
            [uf]: "uf",
            [os]: "osApresentacao",
            [supervisor]: "supervDemonstracao",
            [localServico]: "localDemonstracao",
            [produto]: "pro_nome",
            [loteTon]: "slo_lote",
            [volumeRealizado]: "volumeAcumulado",
            [volumeDoDia]: "volumeCargas",
            [filaAguardando]: "qtdCargasFila",
            [filaRealizado]: "qtdCargas"
        }
        :
        this.dataTableRowDictionary = {
            [uf]: "uf",
            [os]: "slo_codigo",
            [supervisor]: "supervisor_nome",
            [localServico]: "localemb",
            [produto]: "pro_nome",
            [loteTon]: "slo_lote",
            [volumeRealizado]: "volumeAcumulado",
            [volumeDoDia]: "volumeCargas",
            [filaAguardando]: "qtdCargasFila",
            [filaRealizado]: "qtdCargas"
        }
        }

        this.menuRef = React.createRef();

        autobind(this);
    }

    componentDidMount(): void {
        const query = new URLSearchParams(window.location.search)
        const os = query.get("os");

        // Search for new messages every minute
        var messageIntervalId = setInterval(() => this.getBoardingInformation(null), 60000);
        var newState = {...this.state, messageInterval: messageIntervalId};

        this.fetchData(os);
        this.getBoardingInformation(null);
        this.setState(newState);
    }

    componentWillUnmount(): void {
        clearInterval(this.state.messageInterval)
    }

    refresh(): void {
        this.setState({...this.state, loading: true, activeMarker: null});
        this.fetchData(null);
        this.getBoardingInformation(null);
    }

    fetchData(preSelectOs: any) {
        OSService.getOSAndamento()
            .then((places: any) => {
                return _.map(places, (place) => {
                    if (this.isOSInactive(place)) {
                        place.image_marker = imageMarkerInactive;
                    } else if (this.isWithoutCharge(place)) {
                        place.image_marker = imageMarkerWithoutCharge;
                    } else if (this.isWithLt7Charges(place)) {
                        place.image_marker = imageMarkerWithLt7Charges;
                    } else if (this.isWithGt7Charges(place)) {
                        place.image_marker = imageMarkerWithGt7Charges;
                    }

                    place.volumeAcumulado = Number(place.volumeAcumulado);
                    place.volumeCargas = Number(place.volumeCargas);

                    place.classificadores = [];
                    if (place.classificadoresPainel !== "-") {

                        place.classificadores = place.classificadoresPainel.split(",")
                            .map((itemClassificador: string) => {
                                const [cpf, nome, telefone, foto] = itemClassificador.split("@");

                                return {
                                    nome: nome.replace(/'/g, " "),
                                    cpf,
                                    telefone,
                                    foto,
                                };
                            });
                    }

                    place.qualidadePonderadaFiltrada = OSService.mapQualidadePonderada(place);

                    return place;
                });
            })
            .then((items: any[]) => {
                const places = this.filterAllPlaces(items);

                const newState = {
                    ...this.state,
                    allPlaces: items,
                    places: places,
                }

                if (preSelectOs) {
                    const index = newState.places.findIndex(e => e.slo_codigo == preSelectOs);
                    newState.activePlace = newState.places[index];
                    this.getBoardingInformation(preSelectOs);
                }

                type rowMap = { [key: string]: any };
                newState.dataTableRows = places.map((row) => {
                    console.log(row)
                    let rowMap: rowMap = {};
                    Object.keys(this.dataTableRowDictionary).forEach((value) => {
                        rowMap[value] = (
                            typeof row[this.dataTableRowDictionary[value]] == 'number' ?
                                row[this.dataTableRowDictionary[value]].toFixed(2) :
                                row[this.dataTableRowDictionary[value]]
                        ) || "";
                    });
                    rowMap["status"] = this.getDataTableStatus(row);
                    return rowMap;
                });

                this.setState(newState);

                if (this.state.map && this.state.maps) {
                    setTimeout(() => {
                        this.apiIsLoaded(this.state.map, this.state.maps);
                    }, 1000);
                }
            }).catch((error) => {
            console.error("get os andamento", error);
        }).then(() => {
            this.setState({...this.state, loading: false});
        });
    }

    toggleDrawer = (side: any, open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({...this.state, [side]: open});
    };

    onChangeFilter(nameFilter: string) {
        return (value: any) => {
            const newFilter = {
                ...this.state.filter,
                [nameFilter]: value
            };

            this.setState({
                ...this.state,
                filter: newFilter
            });
        };
    }

    onChangeTextFilter(nameFilter: string) {
        return (event: any) => {
            const newFilter = {
                ...this.state.filter,
                [nameFilter]: event.target.value
            };

            this.setState({
                ...this.state,
                filter: newFilter
            });
        };
    }

    toggleQuickFilter(nameFilter: any) {
        return () => {
            // @ts-ignore
            const newValue: any = !this.state.quickFilterActive[nameFilter];

            const newQuickFilter: any = {
                ...this.state.quickFilterActive,
                [nameFilter]: newValue
            };

            this.setState({
                ...this.state,
                quickFilterActive: newQuickFilter
            });

            if (this.state.map && this.state.maps) {
                setTimeout(() => {
                    this.apiIsLoaded(this.state.map, this.state.maps);
                }, 500);
            }
        };
    }

    getMapBounds(map: any, maps: any, places: any) {
        const bounds = new maps.LatLngBounds();

        places.forEach((place: any) => {
            bounds.extend(new maps.LatLng(
                place.latitude,
                place.longitude,
            ));
        });
        return bounds;
    };

    bindResizeListener(map: any, maps: any, bounds: any) {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    }

    apiIsLoaded(map: any, maps: any, places?: any) {
        places = places || this.filterPlaces();
        this.setState({...this.state, map, maps});

        if (places.length > 0) {
            // Get bounds by our places
            const bounds = this.getMapBounds(map, maps, places);
            // Fit map to bounds
            map.fitBounds(bounds);

            // Bind the resize listener
            this.bindResizeListener(map, maps, bounds);
        } else {
            // Se não tiver nenhum local a ser mostrado, centraliza no Brasil
            map.setCenter({lat: -10.78014, lng: -57.92916});
            map.setZoom(3);
        }
    }

    setLanguage(lang: string) {
        I18nTranslator.setLanguage(lang);
        this.setState({...this.state, language: lang});
    }

    filterAllPlaces(places: any, actualFilter?: any) {
        actualFilter = actualFilter || this.state.actualFilter;

        return _.chain(places)
            .filter((place: any) => !this.hasValue(actualFilter.empresa) || place.empresa === actualFilter.empresa || actualFilter.empresa === "TODAS")
            .filter((place: any) => !this.hasValue(actualFilter.matriz) || place.mat_cnpj === actualFilter.matriz)
            .filter((place: any) => !this.hasValue(actualFilter.regional) || place.ent_cnpj === actualFilter.regional)
            .filter((place: any) => !this.hasValue(actualFilter.filial) || place.cli_cnpj === actualFilter.filial)
            .filter((place: any) => !this.hasValue(actualFilter.local_embarque) || place.loc_codigo === actualFilter.local_embarque)
            .filter((place: any) => !this.hasValue(actualFilter.uf) || place.uf === actualFilter.uf)
            .filter((place: any) => !this.hasValue(actualFilter.cidade_embarque) || place.cidCodigo === actualFilter.cidCodigo)
            .filter((place: any) => !this.hasValue(actualFilter.produto) || place.pro_nome === actualFilter.produto)
            .filter((place: any) => !this.hasValue(actualFilter.contrato) || place.slo_contrato === actualFilter.contrato)
            .filter((place: any) => !this.hasValue(actualFilter.ordem_servico) || place.slo_codigo === actualFilter.ordem_servico)
            .filter((place: any) => !this.hasValue(actualFilter.teste_aflatoxina) || place.testeAflatoxina === actualFilter.teste_aflatoxina)
            .value();
    }

    filterPlaces() {
        return _.chain(this.state.places)
            .filter((place) => {
                if (this.state.quickFilterActive.osInactive) {
                    return true;
                }

                return !this.isOSInactive(place);
            })
            .filter((place) => {
                if (this.state.quickFilterActive.withoutCharges) {
                    return true;
                }

                return !this.isWithoutCharge(place);
            })
            .filter((place) => {
                if (this.state.quickFilterActive.lt7Charges) {
                    return true;
                }

                return !this.isWithLt7Charges(place);
            })
            .filter((place) => {
                if (this.state.quickFilterActive.gt7Charges) {
                    return true;
                }

                return !this.isWithGt7Charges(place);
            })
            .value();
    }

    hasValue(v: any) {
        return v && v !== "-";
    }

    isOSInactive(place: any) {
        return place.classificadoresPainel === "-" && place.qtdCargas === 0;
    }

    isWithoutCharge(place: any) {
        if (this.isOSInactive(place)) {
            return false;
        }

        return place.classificadoresPainel !== "-" && place.qtdCargas === 0;
    }

    isWithLt7Charges(place: any) {
        if (this.isOSInactive(place)) {
            return false;
        }

        return place.qtdCargas > 0 && place.qtdCargas <= 7;
    }

    isWithGt7Charges(place: any) {
        if (this.isOSInactive(place)) {
            return false;
        }

        return place.qtdCargas > 7;
    }

    countOSInactive() {
        return _.countBy(this.state.places, this.isOSInactive).true || 0;
    }

    countWithoutCharge() {
        return _.countBy(this.state.places, this.isWithoutCharge).true || 0;
    }

    countLowerThan7Charges() {
        return _.countBy(this.state.places, this.isWithLt7Charges).true || 0;
    }

    countGreaterThan7Charges() {
        return _.countBy(this.state.places, this.isWithGt7Charges).true || 0;
    }

    countOS() {
        const places = this.filterPlaces();
        return places.length;
    }

    countCharges() {
        const qtdCargas = _.map(this.filterPlaces(), 'qtdCargas');
        return _.sum(qtdCargas);
    }

    countVolume() {
        const qtdCargas = _.map(this.filterPlaces(), 'volumeCargas');
        return _.sum(qtdCargas);
    }

    classCheckSquare(value: boolean) {
        return (
            value ? <CheckBoxOutlinedIcon/> : <CheckBoxOutlineBlankOutlinedIcon/>
        );
    }

    // onChildClick callback can take two arguments: key and childProps
    onChildClickCallback = (event: any, key: any) => {
        if (event) event.stopPropagation();
        const index = this.state.places.findIndex(e => e.slo_codigo == key);
        const place = this.state.places[index];
        this.getBoardingInformation(key);
        if (place) this.setState({...this.state, activePlace: place});
    };

    /* -------------------------------------------------------------------------------------------------------------------------------- */
    handleClosePlaceModal() {
        this.setState({...this.state, activePlace: null});
    }

    showSupervisor(place: any) {
        return () => {
            this.setState({...this.state, activeSupervisor: place});
        };
    }

    handleCloseSupervisorModal() {
        this.setState({...this.state, activeSupervisor: null});
    }

    handleCloseCommentModal() {
        this.setState({...this.state, comment: null});
    }

    showEmbarcado() {
        this.setState({
            ...this.state,
            showEmbarcado: true,
            loadingEmbarcado: true,
        });
        OSService.getCargas(this.state.activePlace.slo_codigo, this.state.activePlace.empresa)
            .then((cargas) => {
                if (this.state.showEmbarcado) {
                    this.setState({
                        ...this.state,
                        embarcado: cargas,
                        loadingEmbarcado: false,
                    });
                }
            }).catch(() => {
                if (this.state.showEmbarcado) {
                    this.setState({
                        ...this.state,
                        showEmbarcado: false,
                        loadingEmbarcado: false,
                    });
                }
            }
        );
    }

    showAguardandoFila() {
        this.setState({
            ...this.state,
            showFila: true,
            loadingFila: true,
        });
        OSService.getFila(this.state.activePlace.slo_codigo, this.state.activePlace.empresa)
            .then((fila) => {
                if (this.state.showFila) {
                    this.setState({
                        ...this.state,
                        fila: fila,
                        loadingFila: false,
                    });
                }
            }).catch(() => {
            if (this.state.showFila) {
                this.setState({
                    ...this.state,
                    showFila: false,
                    loadingFila: false,
                });
            }
        });
    }

    _renderModalPlace() {
        if (this.state.activePlace === null) {
            return null;
        }

        const place = this.state.activePlace;
        const usuario = userAccessService.getUser();

        return (
            <Fragment>
                <Dialog fullScreen open={true} onClose={this.handleClosePlaceModal}
                        className={"modal-more-place-details custom-wac"}
                        scroll={"body"}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClosePlaceModal}
                                        aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6">
                                 {usuario.usu_apresentacao === 'S' ? 'DEMONSTRAÇÃO' : place.localemb}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <List style={{
                        paddingTop: "0"
                    }}>
                        {
                            place.ocorrencias !== '-' &&
                            (
                                <ListItem style={{
                                    backgroundColor: "#e1c118",
                                }}>
                                    <ListItemText primary={place.ocorrencias}/>
                                </ListItem>
                            )
                        }


                        <ListItem style={{borderBottom: "1px solid rgb(230,230,230)"}}>
                            <ListItemText primary="Prestadora do serviço" secondary={usuario.usu_apresentacao === 'S' ? 'DEMONSTRAÇÃO' : place.empresa}/>
                        </ListItem>
                        <ListItem>
                            <div className="wac-group vc fmv">
                                <div className="wac-group vc mra">
                                    <img style={{width: "35px", height: "35px"}} src={place.image_marker}
                                         alt={"Marcador"}/>
                                    <ListItemText primary="O.S." secondary={usuario.usu_apresentacao === 'S' ? '34**10' : place.slo_codigo}/>
                                </div>
                                <div className="wac-group g2">
                                    <div className="wac-tag"
                                         style={{backgroundColor: place.qtdCargas === 0 ? "#ffcecb" : "#abffb6",}}>
                                        <ListItemText
                                            primary={place.qtdCargas === 0 ? "SEM EMBARQUES" : "EM ANDAMENTO"}/>
                                    </div>
                                    {
                                        place.testeAflatoxina &&
                                        (
                                            <div className="wac-tag" style={{backgroundColor: "#e1c118"}}>
                                                <ListItemText primary="*** TESTES DE AFLATOXINA"/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </ListItem>
                        <ListItem style={{borderBottom: "1px solid rgb(230,230,230)"}}>
                            <ListItemText primary="Contrato" secondary={usuario.usu_apresentacao === 'S' ? '1012***10**' : place.slo_contrato}/>
                        </ListItem>

                        <ListItem>
                            <div className="wac-group gft">
                                <ListItemText primary="Cliente" secondary={usuario.usu_apresentacao === 'S' ? 'CLIENTE DEMONSTRAÇÃO' : place.cli_nome}/>
                                <ListItemText primary="CPF/CNPJ" secondary={usuario.usu_apresentacao === 'S' ? '10.***.101/1**1-10' : place.mat_cnpj}/>
                                <ListItemText primary="Lote (ton)" secondary={place.slo_lote + " ton."}/>
                                <ListItemText primary="Produto" secondary={place.pro_nome}/>
                                <ListItemText primary="Cidade/UF" secondary={usuario.usu_apresentacao === 'S' ? 'DEMONST/PR' : place.cidade + "/" + place.uf}/>
                                <ListItemText primary="Origem" secondary={usuario.usu_apresentacao === 'S' ? 'FAZENDA SÃO JOÃO' : place.localemb}/>
                                <ListItemText primary="Destino" secondary={usuario.usu_apresentacao === 'S' ? 'DEMONSTRAÇÃO' : place.slo_destino}/>
                            </div>
                        </ListItem>

                    </List>

                    {
                        place.qualidadePonderadaFiltrada.map((qualidadePonderada: any, index: any) => {
                            return (
                                <div style={{
                                    padding: "10px",
                                    marginBottom: "20px"
                                }} key={index}>
                                    <div style={{
                                        padding: "10px",
                                        backgroundColor: "rgb(240,240,240)",
                                        textAlign: "center",
                                        fontSize: "11pt"
                                    }}>
                                        <img src={folhaClassificacao} alt="" className={"iconeFolhaClassificacao"}/>
                                        {I18nTranslator.t("WEIGHTED_QUALITY")}
                                    </div>
                                    <Table size="small" style={{
                                        border: "1px solid #eee"
                                    }}>
                                        <TableBody>
                                            {qualidadePonderada.map((row: any) => (
                                                <TableRow key={index + " - " + row.text}>
                                                    <TableCell component="th" scope="row">
                                                        <b>{row.text}</b>
                                                    </TableCell>
                                                    <TableCell align="right">{row.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            );
                        })
                    }

                    <div className={"qtdes"}>
                        <div className={"item"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imagePeso} alt=""/>
                                <span>{place.volumeAcumulado.toFixed(2)} ton.</span>
                            </div>
                            <div className={"item-desc"}>
                                Volume acumulado
                            </div>
                        </div>
                        <div className={"item"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imagePeso} alt=""/>
                                <span>{place.volumeCargas.toFixed(2)} ton.</span>
                            </div>
                            <div className={"item-desc"}>
                                Volume do dia
                            </div>
                        </div>
                    </div>

                    <div className={"place-items-more-details placeButtons"}>
                        <div className={"item btnCargas"} onClick={this.showEmbarcado}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imageCaminhaoBranco} alt={I18nTranslator.t("SHIPPED")}/>
                                <span>{place.qtdCargas}</span>
                            </div>
                            <div className={"item-desc"}>
                                {place.hora_ultima_carga}
                            </div>
                        </div>
                        <div className={"item btnFila"} onClick={this.showAguardandoFila}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imageFilaRelogio} alt={I18nTranslator.t("QUEUE")}/>
                                <span>{place.qtdCargasFila}</span>
                            </div>
                            <div className={"item-desc"}>
                                {place.hora_ultima_fila}
                            </div>
                        </div>
                    </div>

                    <List>
                        <ListItem>
                            <ListItemText
                                primary={"Informativo de embarque"}
                                secondary={
                                    this.state.activeBoardingInformation ?
                                        [
                                            <Icon className="" size="sm" color="grey-2" component={<ClockIcon/>}/>,
                                            ` Última atualização: ${DateUtil.formatStringDate(
                                                this.state.activeBoardingInformation.sloinfo_data
                                            )} às ${this.state.activeBoardingInformation.sloinfo_hora.substr(
                                                0, this.state.activeBoardingInformation.sloinfo_hora.length - 3
                                            )}`
                                        ] : [
                                            <IconButton
                                                color="inherit"
                                                onClick={() => this.getBoardingInformation(place.slo_codigo)}
                                                disabled={this.state.loadingBoardingInformation}
                                            >
                                                <AutorenewIcon
                                                    className={this.state.loadingBoardingInformation ? "spin-icon" : ""}/>
                                            </IconButton>,
                                            !this.state.loadingBoardingInformation ? " Nenhum informativo até o momento" : " Buscando dados..."
                                        ]
                                }
                            />
                        </ListItem>
                        {
                            this.state.activeBoardingInformation ?
                                (this.state.activeBoardingInformation.sloinfo_destino ||
                                    this.state.activeBoardingInformation.sloinfo_qtd_caminhoes_dia ||
                                    this.state.activeBoardingInformation.sloinfo_emb_parado ?
                                        (<ListItem>
                                            <div className="wac-group gft">
                                                {
                                                    this.state.activeBoardingInformation.sloinfo_destino ?
                                                        (<ListItemText
                                                            primary={"Destino"}
                                                            secondary={usuario.usu_apresentacao === 'S' ? 'DEMONSTRAÇÃO' : this.state.activeBoardingInformation.sloinfo_destino}
                                                        />) : null
                                                }
                                                {
                                                    this.state.activeBoardingInformation.sloinfo_qtd_caminhoes_dia ?
                                                        (<ListItemText
                                                            primary={"Caminhões por dia"}
                                                            secondary={this.state.activeBoardingInformation.sloinfo_qtd_caminhoes_dia}
                                                        />) : null
                                                }
                                                {
                                                    this.state.activeBoardingInformation.sloinfo_emb_parado ?
                                                        (<ListItemText
                                                            primary={"Embarque parado"}
                                                            secondary={this.state.activeBoardingInformation.sloinfo_emb_parado == "S" ? "Sim" : "Não"}
                                                        />) : null
                                                }
                                            </div>
                                        </ListItem>) : null
                                ) : null
                        }
                        {
                            this.state.activeBoardingInformation && this.state.activeBoardingInformation.sloinfo_obs ?
                                (<ListItem>
                                    <div className="wac-group gft">
                                        <ListItemText
                                            primary={"Observações"}
                                            secondary={this.state.activeBoardingInformation.sloinfo_obs}
                                        />
                                    </div>
                                </ListItem>) : null
                        }
                    </List>
                    {
                        this.state.activeBoardingInformation ?
                            (
                                <div className={"place-items-more-details placeButtons"}>
                                    <div className={"item item-small"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageRefugado} alt={"No pátio"}/>
                                            <span>{this.state.activeBoardingInformation.sloinfo_patio || "0"}</span>
                                        </div>
                                        <div className={"item-desc"}>
                                            No pátio
                                        </div>
                                    </div>
                                    <div className={"item item-small"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageFila} alt={"Carregando"}/>
                                            <span>{this.state.activeBoardingInformation.sloinfo_carregando || "0"}</span>
                                        </div>
                                        <div className={"item-desc"}>
                                            Carregando
                                        </div>
                                    </div>
                                    <div className={"item item-small"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageEmbarcado} alt={"Carregado"}/>
                                            <span>{this.state.activeBoardingInformation.sloinfo_carregado || "0"}</span>
                                        </div>
                                        <div className={"item-desc"}>
                                            Carregado
                                        </div>
                                    </div>
                                </div>
                            ) : null
                    }
                    <List>
                        <ListItem>
                            <strong>
                                Supervisor
                            </strong>
                        </ListItem>
                        <ListItem>
                            <Button className={"btnDetalheSupervisor"} onClick={this.showSupervisor(place)}>
                                <span>{usuario.usu_apresentacao === 'S' ? 'SUPERVISOR DEMONSTRAÇÃO' : place.supervisor_nome}</span>
                                <SearchIcon style={{float: "right"}}/>
                            </Button>
                        </ListItem>
                    </List>
                </Dialog>

                {this.dialogEmbarcado()}
                {this.dialogFila()}
                {this.dialogSupervisor()}

            </Fragment>
        );
    }

    showModalComentarioSupervisor(type: string, place: any) {
        return () => {
            this.setState({
                ...this.state, comment: {
                    type,
                    entity: "supervisor",
                    comment: "",
                    empresa: place.empresa
                }
            });
        };
    }

    showModalComentarioClassificador(type: string, place: any, classificador: any) {
        return () => {
            this.setState({
                ...this.state, comment: {
                    type,
                    classificador,
                    entity: "classificador",
                    comment: ""
                }
            });
        };
    }

    dialogSupervisor() {

        if (this.state.activeSupervisor === null) {
            return null;
        }

        const place = this.state.activePlace;

        const usuario = userAccessService.getUser();
        const apresentacao = usuario.usu_apresentacao;

        return (
            <Fragment>
                <Dialog fullScreen open={true} onClose={this.handleCloseSupervisorModal}
                        className={"modal-more-place-details custom-wac modal-sm"}
                        scroll={"body"}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseSupervisorModal}
                                        aria-label="close">
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant="h6" className="flex-grow">
                                Supervisor
                            </Typography>
                            <IconButton color="inherit" onClick={this.showModalComentarioSupervisor('negative', place)}>
                                <ThumbDownIcon/>
                            </IconButton>
                            <IconButton color="inherit" onClick={this.showModalComentarioSupervisor('positive', place)}>
                                <ThumbUpIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {apresentacao == 'S'?
                    <div className="wac-group vc amv">
                        <img src='' alt="" className="wac-avatar"/>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nome" secondary='SUPERVISOR DEMONSTRAÇÃO'/>
                            </ListItem>
                            {
                                    <ListItem>
                                        <ListItemText primary="CPF" secondary='99999999999'/>
                                    </ListItem>
                            }
                            {
                                    <ListItem>
                                        <ListItemText primary="E-mail" secondary='teste@teste.com.br'/>
                                    </ListItem>
                            }
                            {
                                    <ListItem>
                                        <ListItemText primary="Telefone" secondary='(XX)XXXXX-XXXX'/>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end">
                                                <CallIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                            }
                        </List>
                    </div>
                    :
                    <div className="wac-group vc amv">
                        <img src={place.supervisor_foto} alt="" className="wac-avatar"/>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nome" secondary={place.supervisor_nome}/>
                            </ListItem>
                            {
                                place.supervisor_cpf !== "-" && (
                                    <ListItem>
                                        <ListItemText primary="CPF" secondary={place.supervisor_cpf}/>
                                    </ListItem>
                                )
                            }
                            {
                                place.supervisor_email !== "-" && (
                                    <ListItem>
                                        <ListItemText primary="E-mail" secondary={place.supervisor_email}/>
                                    </ListItem>
                                )
                            }
                            {
                                place.supervisor_telefone !== "-" && (
                                    <ListItem>
                                        <ListItemText primary="Telefone" secondary={place.supervisor_telefone}/>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end"
                                                        onClick={() => {
                                                            document.location.href = "tel:" + place.supervisor_telefone;
                                                        }}
                                            >
                                                <CallIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                        </List>
                    </div> }
                </Dialog>

                {this.renderModalComentario()}
            </Fragment>
        );
    }

    handleCloseEmbarcado() {
        this.setState({...this.state, showEmbarcado: false, embarcado: [], filterEmbarcado: null});
    }

    handleCloseFila() {
        this.setState({...this.state, showFila: false, fila: [], filterFila: null});
    }

    resolveImageCarga(carga: any) {
        if (carga.iac_tipo === ("PNE")) {
            return imageRefugado;
        } else if (carga.iac_tipo === ("PFPB")) {
            return imageFila;
        } else if (carga.iac_tipo === ("EMB")) {
            return imageEmbarcado;
        } else {
            return imageNHE;
        }
    }

    filterEmbarcados(valorFiltro: any) {
        this.setState({...this.state, filterEmbarcado: valorFiltro});
    }

    filterFila(valorFiltro: any) {
        this.setState({...this.state, filterFila: valorFiltro});
    }

    countKgEmbarcado() {
        let soma = 0;

        this.state.embarcado.map((item: any) => {
            if (item.iac_tipo === "EMB" || item.iac_tipo === "PFPB")
                soma += Number(item.iac_peso);

            return null;
        });

        return soma;
    }

    openLaudo(item: any) {
        let urlBase = '';

        switch (item.empresa) {
            case "GENESLAB":
                urlBase = configuration.SYS_LAUDO_GENESLAB;
                break;

            case "CLASSNORTE":
                urlBase = configuration.SYS_LAUDO_CLASSNORTE;
                break;

            case "GR":
                urlBase = configuration.SYS_LAUDO_GR;
                break;
        }
        window.open(urlBase + item.iac_laudo, "_system")
    }

    dialogEmbarcado() {

        if (!this.state.showEmbarcado) {
            return null;
        }

        const place = this.state.activePlace;
        const usuario = userAccessService.getUser();
        const apresentacao = usuario.usu_apresentacao;
        return (
            <Fragment>
                <Dialog fullScreen open={true} onClose={this.handleCloseEmbarcado}
                        className={"modal-more-place-details custom-wac  modal-sm"}
                        scroll={"body"}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseEmbarcado}
                                        aria-label="close">
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant="h6" className="flex-grow">
                                Cargas
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {this.state.loadingEmbarcado ? (
                        <div style={{
                            width: "100%",
                            marginTop: "200px",
                            textAlign: "center",
                        }}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6"
                                        style={{
                                            padding: "15px 0",
                                            borderBottom: "1px solid #eee"
                                        }}
                                        className="flex-grow">
                                O.S.: {apresentacao == 'S' ? '101**10' : place.slo_codigo}
                            </Typography>

                            <Box style={{padding: "10px", borderBottom: "1px solid #eee", marginBottom: "5px"}}>
                                <CustomAutocomplete
                                    items={this.state.embarcado}
                                    fields={["iac_placa", "iac_transportadora"]}
                                    label={I18nTranslator.t("FILTER")}
                                    placeholder={I18nTranslator.t("FILTER_BY_PLATE_OR_CARRIER")}
                                    onChange={this.filterEmbarcados}/>
                            </Box>


                            <div className={"qtdes"}>
                                <div className={"item ac"}>
                                    <div className="wac-group g1 vc hc">
                                        <img src={imageCarga} alt="" className={"image-item"}/>
                                        <span className={"desc-item"}>
                                            {this.state.embarcado.length}
                                        </span>
                                    </div>
                                </div>
                                <div className={"item ac"}>
                                    <div className="wac-group g1 vc hc">
                                        <img src={imagePeso} alt="" className={"image-item"}/>
                                        <span className={"desc-item"}>
                                            {this.countKgEmbarcado().toLocaleString(undefined, {maximumFractionDigits: 2})} kg
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={"qtdes cqtd"}>
                                <div className={"item"}>
                                    <div className="wac-group g1 vc hc">
                                        <img src={imageEmbarcado} alt="" className={"image-item"}/>
                                        <span className={"desc-item"}>
                                            CARGA PADRÃO
                                        </span>
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className="wac-group g1 vc hc">
                                        <img src={imageFila} alt="" className={"image-item"}/>
                                        <span className={"desc-item"}>
                                            FORA PADRÃO
                                        </span>
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className="wac-group g1 vc hc">
                                        <img src={imageRefugado} alt="" className={"image-item"}/>
                                        <span className={"desc-item"}>
                                            REFUGADO/NHE
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.embarcado.map((itemEmbarcado: any, index: number) => {
                                    if (
                                        (this.state.filterEmbarcado !== null && (
                                            itemEmbarcado.iac_placa.toLowerCase().includes(this.state.filterEmbarcado.toLowerCase()) ||
                                            itemEmbarcado.iac_transportadora.toLowerCase().includes(this.state.filterEmbarcado.toLowerCase()) ||
                                            this.state.filterEmbarcado === "")) || this.state.filterEmbarcado === null
                                    ) {
                                        return (
                                            <div key={index}>
                                                <Grid container justify="space-between" spacing={1}
                                                      className={"item-embarcado"}>
                                                    <Grid item xs={12} className={"item"}>
                                                        <div className="wac-group g1 vc hc mfi">
                                                            <img src={this.resolveImageCarga(itemEmbarcado)} alt=""
                                                                 className={"image-item"}/>
                                                            {apresentacao == 'S' ?
                                                            <Typography>
                                                            {(itemEmbarcado.iac_placa === "AAA-0000") ? "DIA SEM EMBARQUE" : 'AAA-1010'}
                                                            </Typography>
                                                            :
                                                            <Typography>
                                                                {(itemEmbarcado.iac_placa === "AAA-0000") ? "DIA SEM EMBARQUE" : itemEmbarcado.iac_placa}
                                                            </Typography>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} className={"text-laudo"}>
                                                        <img src={imageLaudoPP} alt={""}
                                                             className={"carga-lista-icone"}/>
                                                        {apresentacao == 'S' ?
                                                        <span>{(itemEmbarcado.iac_tipo === "PNE") ? "CARGA REFUGADA" : 'MOB-1010*******1010'}</span>
                                                        :
                                                        <span>{(itemEmbarcado.iac_tipo === "PNE") ? "CARGA REFUGADA" : `MOB-${itemEmbarcado.iac_laudo}`}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} className={"tipo-produto"}>
                                                        <span>{itemEmbarcado.iac_tipo_produto}</span>
                                                    </Grid>
                                                    <Grid item xs={12} className={"peso"}>
                                                        <img src={imagePesoPP} alt={""}
                                                             className={"carga-lista-icone"}/>
                                                        <span>{`Peso (kg): ${itemEmbarcado.iac_peso}`}</span>
                                                    </Grid>
                                                    <Grid item xs={12} className={"nota-fiscal"}>
                                                        <img src={imageFormularioPP} alt={""}
                                                             className={"carga-lista-icone"}
                                                             style={{marginRight: "6px"}}/>
                                                        {apresentacao == 'S' ?
                                                        <span>{'Nota fiscal: 10101010'}</span>
                                                        :
                                                        <span>{`Nota fiscal: ${itemEmbarcado.iac_nota_fiscal}`}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} className={"transportadora"}>
                                                        <img src={imageCargaPP} alt={""}
                                                             className={"carga-lista-icone"}/>
                                                        {apresentacao == 'S' ?     
                                                        <span>{'Transportadora'}</span>
                                                        :
                                                        <span>{`Transportadora${itemEmbarcado.iac_transportadora !== '-' ? ': ' + itemEmbarcado.iac_transportadora : ' não informada'}`}</span>
                                                        }
                                                    </Grid>

                                                    {itemEmbarcado.itensClassificacaoArray[0].text === "" ? null : (
                                                        <Fragment>
                                                            {itemEmbarcado.resultadoTesteAflatoxina === "-" ? null : (
                                                                <Fragment>
                                                                    <Grid item xs={12}
                                                                          className={"titulo-teste-aflatoxina"}>
                                                                        <img src={folhaClassificacao} alt=""
                                                                             className={"iconeFolhaClassificacao iconeMini"}/>
                                                                        <span>Resultado teste aflatoxina</span>
                                                                    </Grid>
                                                                    <Grid item xs={12} className={"nota-fiscal"}>
                                                                        <span>{`Tipo de teste: ${itemEmbarcado.tipoTesteAflatoxina}`}</span>
                                                                    </Grid>
                                                                    <Grid item xs={12} className={"nota-fiscal"}>
                                                                        <span>{`Resultado: ${itemEmbarcado.resultadoTesteAflatoxina}`}</span>
                                                                    </Grid>
                                                                </Fragment>
                                                            )}

                                                            <Grid item xs={12} className={"resultado-classificacao"}>
                                                                <div style={{
                                                                    padding: "5px",
                                                                    marginBottom: "5px"
                                                                }}>
                                                                    <Table size="small" style={{
                                                                        border: "1px solid #eee"
                                                                    }}>
                                                                        <TableBody>
                                                                            {itemEmbarcado.itensClassificacaoArray.map((row: any, index: any) => (
                                                                                <TableRow
                                                                                    key={index + " - " + row.text}>
                                                                                    <TableCell component="td"
                                                                                               scope="row">
                                                                                        <b>{row.text}</b>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        style={{minWidth: "60px"}}
                                                                                        align="right">{row.value}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            </Grid>
                                                        </Fragment>
                                                    )}


                                                    <Button
                                                        size={"small"}
                                                        className={"btnGreenGG btnFullWidth btnLinkExterno"}
                                                        onClick={() => this.openLaudo(itemEmbarcado)}
                                                    >
                                                        Ver laudo
                                                    </Button>
                                                </Grid>
                                            </ div>
                                        )
                                    }

                                    return null;
                                })
                            }
                        </div>
                    )}
                </Dialog>
            </Fragment>
        );
    }

    dialogFila() {

        if (!this.state.showFila) {
            return null;
        }

        const place = this.state.activePlace;
        const usuario = userAccessService.getUser();
        const apresentacao = usuario.usu_apresentacao;
        return (
            <Fragment>
                <Dialog fullScreen open={true} onClose={this.handleCloseFila}
                        className={"modal-more-place-details custom-wac modal-xsm"}
                        scroll={"body"}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseFila}
                                        aria-label="close">
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant="h6" className="flex-grow">
                                Fila
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {this.state.loadingFila ? (
                        <div style={{
                            width: "100%",
                            marginTop: "200px",
                            textAlign: "center",
                        }}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6"
                                        style={{
                                            padding: "15px 0",
                                            borderBottom: "1px solid #eee"
                                        }}
                                        className="flex-grow">
                                O.S.: {place.slo_codigo}
                            </Typography>

                            <Box style={{padding: "10px", borderBottom: "1px solid #eee", marginBottom: "5px"}}>
                                <CustomAutocomplete
                                    items={this.state.fila}
                                    fields={["fila_placa", "fila_transportadora"]}
                                    label={I18nTranslator.t("FILTER")}
                                    placeholder={I18nTranslator.t("FILTER_BY_PLATE_OR_CARRIER")}
                                    onChange={this.filterFila}/>
                            </Box>

                            <div className={"wac-group g1 vc hc"}>
                                <img src={imageCarga} alt="" className={"image-item"}/>
                                <span className={"desc-item"}>
                                    {this.state.fila.length}
                                </span>
                            </div>

                            {
                                this.state.fila.map((itemEmbarcado: any, index: number) => {
                                    if (
                                        (this.state.filterFila !== null && (
                                            itemEmbarcado.fila_placa.toLowerCase().includes(this.state.filterFila.toLowerCase()) ||
                                            itemEmbarcado.fila_transportadora.toLowerCase().includes(this.state.filterFila.toLowerCase()) ||
                                            this.state.filterFila === "")) || this.state.filterFila === null
                                    ) {
                                        return (
                                            <div key={index}>
                                                <Grid container justify="space-between" spacing={1}
                                                      className={"item-embarcado"}>
                                                    <Grid item xs={12} className={"item"}>
                                                        <Typography variant="h6" className="flex-grow">
                                                            {apresentacao == 'S' ? 
                                                            'AAA-1010'
                                                            :
                                                            itemEmbarcado.fila_placa
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className={"peso"}>
                                                        <span>{`Hora: ${itemEmbarcado.fila_hora}`}</span>
                                                    </Grid>
                                                    <Grid item xs={12} className={"nota-fiscal"}>
                                                        {apresentacao == 'S' ? 
                                                        <span>'Motorista Teste'</span>
                                                        :
                                                        <span>{`Motorista: ${itemEmbarcado.fila_motorista_nome}`}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} className={"nota-fiscal"}>
                                                        {apresentacao == 'S' ?
                                                        <span>'Transportadora'</span>
                                                        :
                                                        <span>{`Transportadora: ${itemEmbarcado.fila_transportadora}`}</span>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </ div>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </div>
                    )}
                </Dialog>
            </Fragment>
        );
    }

    saveComment() {
        const newComment = {
            ...this.state.comment,
            saving: true
        };
        this.setState({...this.state, comment: newComment});


        const place = this.state.activePlace;

        const comentario = {
            ccom_data_hora: new Date(),
            ccom_cpf_cliente: userAccessService.getUser().usu_cpf,
            ccom_cpf_colaborador: this.state.activeSupervisor ? this.state.activeSupervisor.supervisor_cpf : this.state.activeClassificador.classificador.cpf,
            loc_codigo: place.loc_codigo,
            slo_codigo: place.slo_codigo,
            iac_laudo: null,
            ccom_funcao: this.state.activeSupervisor ? "SUPERVISOR" : "CLASSIFICADOR",
            ccom_acao: this.state.comment.type === 'positive' ? "E" : "R",
            ccom_comentario: newComment.comment,
            empresa: place.empresa
        };

        OSService.sendComentario(comentario)
            .then(() => {
                this.handleCloseCommentModal();
            }).catch(() => {
            const newCommentError = {
                ...this.state.comment,
                saving: false
            };
            this.setState({...this.state, comment: newCommentError});
        });
    }

    handleChangeComment(event: any) {
        const newComment = {
            ...this.state.comment,
            comment: event.target.value
        };
        this.setState({...this.state, comment: newComment});
    }

    renderModalComentario() {

        if (this.state.comment === null) {
            return null;
        }

        let nome;

        if (this.state.comment.entity === "supervisor") {
            nome = this.state.activePlace.supervisor_nome;
        } else {
            nome = this.state.comment.classificador.nome;
        }

        return (
            <Fragment>
                <Dialog fullScreen open={true} onClose={this.handleCloseCommentModal}
                        className={"modal-more-place-details custom-wac"}
                        scroll={"body"}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseCommentModal}
                                        disabled={this.state.comment.saving}
                                        aria-label="close">
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant="h6" className="flex-grow">
                                {this.state.comment.type === 'positive' ? "Elogio" : "Reclamação"}
                            </Typography>
                            <IconButton color="inherit" onClick={this.saveComment} disabled={this.state.comment.saving}>
                                <SaveIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <List>
                        <ListItem>
                            <ListItemText primary="Nome" secondary={nome}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="O.S." secondary={this.state.activePlace.slo_codigo}/>
                        </ListItem>
                    </List>

                    <div style={{
                        padding: "0 15px",
                        flexDirection: "column",
                        display: "flex"
                    }}>

                        <TextField
                            id="standard-multiline-flexible"
                            label={"Comentário"}
                            multiline
                            rowsMax="4"
                            value={this.state.comment.comment}
                            onChange={this.handleChangeComment}
                            margin="dense"
                        />

                    </div>

                </Dialog>

            </Fragment>
        );
    }

    openPanorama() {
        const paripassuAppId = 'PariPassu';
        const sistemaPariPassu = 'panorama';
        const authUrl = configuration.AUTH_BASE_URL + 'authorize-app';
        const params = `?response_type=code&scope=user_email&client_id=${paripassuAppId}&session_id=${userAccessService.getSessionId()}&control_data=${sistemaPariPassu}`;

        window.open(authUrl + params, "_system");
    }

    logout() {
        return UserService.logout()
            .then((result) => {
                this.setState({
                    ...this.state,
                    logout: true
                });
            })
            .catch((message) => {
                this.openSnackbar(message, 4500);
            });
    }


    TransitionTop(props: TransitionProps) {
        return <Slide {...props} direction="down"/>;
    }

    handleCloseSnackbar = () => (ev: any, reason: any) => {
        this.setState({...this.state, "openSnackbar": false});
    };

    openSnackbar = (message: string, timeout: number = 1500) => {
        this.setState({
            ...this.state,
            ...{
                "messageSnackbar": message,
                "openSnackbar": true
            }
        });

        setTimeout(() => {
            this.setState({
                ...this.state,
                ...{
                    "openSnackbar": false
                }
            });
        }, timeout);
    };


    cancelFilters() {
        this.setState({
            ...this.state,
            drawerRight: false,
            filter: _.clone(this.state.actualFilter)
        });
    }

    saveFilters() {

        const actualFilter = _.clone(this.state.filter);
        this.setState({
            ...this.state,
            drawerRight: false,
            actualFilter,
            places: this.filterAllPlaces(this.state.allPlaces, actualFilter),
        });

        setTimeout(() => {
            this.apiIsLoaded(this.state.map, this.state.maps);
        }, 1000);
    }

    getHeaderHeight(): number {
        let menuNode = this.menuRef.current || {clientHeight: 48};
        // @ts-ignore
        return menuNode.clientHeight;
    }

    clickMarker(event: any, codigo: string) {
        event.stopPropagation();
        if (this.state.activeMarker === codigo)
            this.setState({...this.state, activeMarker: null});
        else
            this.setState({...this.state, activeMarker: codigo});
    }

    getBoardingInformation(slo_codigo: any): any {
        const filter_parameters = {
            sloinfo_ativo: "S",
            sloinfo_data: DateUtil.getToday(),
            group_by_slo_codigo: "S",
            slo_codigo: ""
        };

        if (slo_codigo) {
            this.setState({...this.state, loadingBoardingInformation: true, activeBoardingInformation: null});
            filter_parameters.slo_codigo = slo_codigo;
        }

        return BoardingInformationService.listAll(filter_parameters)
            .then(response => {
                if (Array.isArray(response)) {
                    if (slo_codigo) {
                        this.setState({...this.state, activeBoardingInformation: response[0]});
                        return;
                    }

                    this.setState({
                        ...this.state,
                        boardingInformation: response,
                    });
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                if (slo_codigo) {
                    this.setState({...this.state, loadingBoardingInformation: false});
                }
            })
    }

    getDataTableStatus(place: any) {
        if (this.isOSInactive(place)) {
            return "inactive";
        }
        else if (this.isWithoutCharge(place)) {
            return "canceled";
        }
        else if (this.isWithLt7Charges(place)) {
            return "pending";
        }
        else if (this.isWithGt7Charges(place)) {
            return "complete";
        }
    }

    render(): any {
        if (this.state.logout) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                    }}
                />
            );
        }
        const places: any[] = this.filterPlaces();

        return (
            <div className="page-main map-area-custom">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    key={`snackbar`}
                    open={this.state.openSnackbar}
                    onClose={this.handleCloseSnackbar}
                    autoHideDuration={4000}
                    TransitionComponent={this.TransitionTop}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messageSnackbar}</span>}
                />

                <Group className='spread'>
                    <h3 className="wac-title">
                        {I18nTranslator.t("REAL_TIME")}
                    </h3>

                    <Typography variant="h6" className="title flex-grow"/>
                    <IconButton color="inherit" onClick={this.refresh} disabled={this.state.loading}>
                        <AutorenewIcon className={this.state.loading ? 'spin-icon' : ''}/>
                    </IconButton>
                    <NotificationDropdown
                        disabled={this.state.loading}
                        totalMessages={this.state.boardingInformation.length}
                    >
                        {
                            this.state.boardingInformation && this.state.boardingInformation.length ?
                                this.state.boardingInformation.map((boardingInfo, index) => {
                                    return (boardingInfo.slo_codigo && boardingInfo.sloinfo_hora) ? (
                                        <React.Fragment>
                                            <NotificationMessageButton
                                                key={boardingInfo.slo_codigo}
                                                message={`Informativo O.S. ${boardingInfo.slo_codigo}`}
                                                time={
                                                    boardingInfo.sloinfo_hora.length === 8 ?
                                                        boardingInfo.sloinfo_hora.slice(0, -3) : boardingInfo.sloinfo_hora
                                                }
                                                onClick={() => this.onChildClickCallback(null, boardingInfo.slo_codigo)}
                                            />
                                            {index < (this.state.boardingInformation.length - 1) ? (
                                                <hr className={"sm"}/>) : null}
                                        </React.Fragment>
                                    ) : null;
                                }) :
                                (
                                    <Text className={"s13"}>Nenhum informativo</Text>
                                )
                        }
                    </NotificationDropdown>
                    <IconButton color="inherit" onClick={this.toggleDrawer('drawerRight', true)}
                                disabled={this.state.loading}>
                        <FilterIcon/>
                    </IconButton>
                </Group>

                <div className="wac-vspace"/>

                <Toolbar/>

                <div>
                    <div className={"section-filters-map"} style={{width: "100vw", zIndex: 5, backgroundColor: "#fff"}}>
                        <div className={"first-row"}>
                            <div className={"os-inactive"} onClick={this.toggleQuickFilter("osInactive")}>
                                <div className={"item-filter"}>
                                    {this.classCheckSquare(this.state.quickFilterActive.osInactive)}

                                    <span>{this.countOSInactive()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("OS_INACTIVE")}
                                </div>
                            </div>
                            <div className={"without-charges"} onClick={this.toggleQuickFilter("withoutCharges")}>
                                <div className={"item-filter"}>
                                    {this.classCheckSquare(this.state.quickFilterActive.withoutCharges)}

                                    <span>{this.countWithoutCharge()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("WITHOUT_CHARGES")}
                                </div>
                            </div>
                            <div className={"lt-7-charges"} onClick={this.toggleQuickFilter("lt7Charges")}>
                                <div className={"item-filter"}>
                                    {this.classCheckSquare(this.state.quickFilterActive.lt7Charges)}

                                    <span>{this.countLowerThan7Charges()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("ONE_TO_SEVEN_CHARGES")}
                                </div>
                            </div>
                            <div className={"gt-7-charges"} onClick={this.toggleQuickFilter("gt7Charges")}>
                                <div className={"item-filter"}>
                                    {this.classCheckSquare(this.state.quickFilterActive.gt7Charges)}

                                    <span>{this.countGreaterThan7Charges()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("GREATHER_SEVEN_CHARGES")}
                                </div>
                            </div>
                        </div>
                        <div className={"second-row"}>
                            <div className={"os-services"}>
                                <div className={"item-filter"}>
                                    <img src={imageFormulario} alt=""/>

                                    <span>{this.countOS()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("SYSTEM_ORDERS")}
                                </div>
                            </div>
                            <div className={"charges"}>
                                <div className={"item-filter"}>
                                    <img src={imageCarga} alt=""/>

                                    <span>{this.countCharges()}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("CHARGES")}
                                </div>
                            </div>
                            <div className={"volume"}>
                                <div className={"item-filter"}>
                                    <img src={imagePeso} alt=""/>

                                    <span>{this.countVolume().toFixed(2)}</span>
                                </div>
                                <div className={"desc-filter"}>
                                    {I18nTranslator.t("VOLUME_TONS")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"maps-container"}
                         style={{height: `calc(100vh - ${this.getHeaderHeight()}px - 112px)`, zIndex: 1}}>
                        <Fragment>
                            <GoogleMap
                                defaultZoom={10}
                                defaultCenter={this.state.mapCenter}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({map, maps}: { map: any, maps: any }) => this.apiIsLoaded(map, maps, places)}
                                onClick={() => this.setState({...this.state, activeMarker: null})}
                            >
                                {places.map(place => (
                                    <Marker
                                        image={place.image_marker}
                                        key={place.slo_codigo}
                                        text={place.slo_codigo}
                                        lat={place.latitude}
                                        lng={place.longitude}
                                        place={place}
                                        show={place.show}
                                        onPinClick={this.clickMarker}
                                        showInfo={this.state.activeMarker === place.slo_codigo}
                                        openFullDetails={this.onChildClickCallback}
                                    />
                                ))}
                            </GoogleMap>
                        </Fragment>
                    </div>
                </div>

                {this._renderModalPlace()}

                <Drawer open={this.state.drawerLeft} onClose={this.toggleDrawer('drawerLeft', false)}
                        className="drawer-left">
                    <div>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PersonIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={userAccessService.getUser().usu_nome}
                                              secondary={userAccessService.getUser().usu_cpf}/>
                            </ListItem>
                        </List>
                        <Divider variant="middle"/>
                        {userAccessService.getUser().usu_usuario_panorama === 'S' ? (
                            <List>
                                <ListItem className={"btnPanorama"} onClick={this.openPanorama}>
                                    <Button size="small" variant="outlined" color="primary" className={"btnPanorama"}
                                            onClick={this.openPanorama} fullWidth>
                                        {I18nTranslator.t("VISIT_PANORAMA")}
                                    </Button>
                                </ListItem>
                            </List>
                        ) : ''}
                    </div>
                    <div>
                        <List>
                            <ListItem className={"btnLogout"} onClick={this.logout}>
                                <ListItemText primary={I18nTranslator.t("LOGOUT")}/>
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
                <Drawer open={this.state.drawerRight}
                        anchor="right"
                        className="drawer-right"
                        onClose={this.cancelFilters}>
                    <AppBar position={"sticky"}>
                        <Toolbar className="main-toolbar">
                            <IconButton edge="start" color="inherit" onClick={this.cancelFilters}>
                                <ArrowBackIcon/>
                            </IconButton>
                            <Typography variant="h6" className="title flex-grow">
                                {I18nTranslator.t("FILTERS")}
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={this.saveFilters}>
                                <SaveIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div className={"form-filters"}>
                        <AppSelectModal label="Empresa"
                                        type={"empresa"}
                                        data={this.state.filter}
                                        value={this.state.filter.empresa}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('empresa')}>

                        </AppSelectModal>
                        <AppSelectModal label="Matriz"
                                        type={"matriz"}
                                        data={this.state.filter}
                                        value={this.state.filter.matriz}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('matriz')}>

                        </AppSelectModal>
                        <AppSelectModal label="Regional"
                                        type={"regional"}
                                        data={this.state.filter}
                                        value={this.state.filter.regional}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('regional')}>

                        </AppSelectModal>
                        <AppSelectModal label="Filial"
                                        type={"filial"}
                                        data={this.state.filter}
                                        value={this.state.filter.filial}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('filial')}>

                        </AppSelectModal>
                        <AppSelectModal label="Local de embarque"
                                        type={"local_embarque"}
                                        data={this.state.filter}
                                        value={this.state.filter.local_embarque}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('local_embarque')}>

                        </AppSelectModal>

                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={4}>
                                <AppSelectModal label="UF"
                                                type={"uf"}
                                                data={this.state.filter}
                                                value={this.state.filter.uf}
                                                places={this.state.allPlaces}
                                                onChange={this.onChangeFilter('uf')}>

                                </AppSelectModal>
                            </Grid>
                            <Grid item xs={8}>
                                <AppSelectModal label="Cidade de embarque"
                                                type={"cidade_embarque"}
                                                data={this.state.filter}
                                                value={this.state.filter.cidade_embarque}
                                                places={this.state.allPlaces}
                                                onChange={this.onChangeFilter('cidade_embarque')}>

                                </AppSelectModal>
                            </Grid>
                        </Grid>
                        <AppSelectModal label="Produto"
                                        type={"produto"}
                                        data={this.state.filter}
                                        value={this.state.filter.produto}
                                        places={this.state.allPlaces}
                                        onChange={this.onChangeFilter('produto')}>

                        </AppSelectModal>
                        <TextField
                            id="filter-contrato"
                            label="Contrato"
                            value={this.state.filter.contrato}
                            onChange={this.onChangeTextFilter('contrato')}
                            style={{
                                width: "100%"
                            }}
                            margin="normal"
                        />

                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filter-ordem-servico"
                                    label="Ordem de serviço"
                                    value={this.state.filter.ordem_servico}
                                    onChange={this.onChangeTextFilter('ordem_servico')}
                                    style={{
                                        width: "100%"
                                    }}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AppSelectModal label="Teste de aflatoxina"
                                                type={"teste_aflatoxina"}
                                                data={this.state.filter}
                                                value={this.state.filter.teste_aflatoxina}
                                                places={this.state.allPlaces}
                                                onChange={this.onChangeFilter('teste_aflatoxina')}>

                                </AppSelectModal>
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
                <Vspace className={"lg"}/>
                <Vspace className={"lg hod"}/>
                <DataTable
                    id={"table_boarding_list"}
                    className={"f-3"}
                    columns={this.dataTableColumns}
                    data={this.state.dataTableRows}
                    filename={"Real time"}
                    statusList={{
                        'inactive': "O.S. INATIVA",
                        'canceled': "SEM CARGA",
                        'pending': "1 À 7 CARGAS",
                        'complete': "MAIS QUE 7 CARGAS"
                    }}
                    noExport={true}
                />
            </div>
        );
    }
}

export default Main;
