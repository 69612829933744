import React from 'react';
import Button from '@material-ui/core/Button';
import './Main.scss';
import {I18nTranslator} from "../../../core/i18n/I18nTranslator";
import imageEmbarcado from "../../../assets/ic_embarcado.png";
import imageFilaRelogio from "../../../assets/ic_fila_g.png";
import imagePeso from "../../../assets/ic_peso.png";
import imageRefugado from "../../../assets/ic_refugado.png";
import imageFila from "../../../assets/ic_fila.png";
import Vspace from "../../../components/commons/Vspace";
import {BoardingInformationService} from "../../../core/boarding_information/BoardingInformationService";
import DateUtil from "../../../core/utils/DateUtil";
import {userAccessService} from "../../../core/user-access/UserAccessService";

class InfoWindow extends React.Component {

    

    state = {
        loadingBoardingInformation: true,
        boardingInformation: []
    }
    

    componentWillMount() {
        if (this.props.place && this.props.place.slo_codigo) {
            BoardingInformationService.listAll({
                sloinfo_ativo: "S",
                sloinfo_data: DateUtil.getToday(),
                group_by_slo_codigo: "S",
                slo_codigo: this.props.place.slo_codigo
            }).then(response => {
                this.setState({...this.state, boardingInformation: response, loadingBoardingInformation: false});
            }).catch(error => {
                this.setState({...this.state, loadingBoardingInformation: false});
                console.error(error);
            });
        }
    }

    
    
    render() {

            const usuario = userAccessService.getUser();
            
            var data1 = new Date();

            var dia     = data1.getDate();           // 1-31
            var dia_sem = data1.getDay();            // 0-6 (zero=domingo)
            var mes     = data1.getMonth();          // 0-11 (zero=janeiro)
            var ano2    = data1.getYear();           // 2 dígitos
            var ano4    = data1.getFullYear();       // 4 dígitos
            var hora    = data1.getHours();          // 0-23
            var min     = data1.getMinutes();        // 0-59
            var seg     = data1.getSeconds();        // 0-59
            var mseg    = data1.getMilliseconds();   // 0-999
            var tz      = data1.getTimezoneOffset(); // em minutos

           
            var str_data = dia + '/' + (mes+1) + '/' + ano4;
            var str_hora = hora + ':' + min + ':' + seg;


        let informacao =  "_*GENESIS - CLICONNECT*_ \n\n"+
                          "_Seu Informativo em Tempo Real !_ \n\n"+
                          "\n*✅ Prestadora de Serviço:*  " + this.props.place.empresa + 
                          "\n*✅ Local de Embarque:*  " + this.props.place.localemb +
                          "\n*✅ O.S.:*  " + this.props.place.slo_codigo +
                          "\n*✅ Contrato:*  " + this.props.place.slo_contrato +
                          "\n*✅ Cliente:*  " +  this.props.place.cli_nome +
                          "\n*✅ CPF/CNPJ:*  " + this.props.place.mat_cnpj +
                          "\n*✅ Cidade/UF:*  " + this.props.place.cidade + "/" + this.props.place.uf + 
                          "\n*✅ Origem:*  " + this.props.place.localemb +
                          "\n*✅ Destino:*  " + this.props.place.slo_destino +
                          "\n*✅ Lote(Ton.):*  " + this.props.place.slo_lote + " ton." +
                          "\n*✅ Produto:*  " + this.props.place.pro_nome +
                          "\n\n*✅ Volume Acumulado:*  " + this.props.place.volumeAcumulado.toFixed(2) +
                          "\n*✅ Volume do Dia:*  " + this.props.place.volumeCargas.toFixed(2) +
                          "\n\n*🚚 Cargas:*  " + this.props.place.qtdCargas +
                          "\n*🚚 Horario última Carga:*  " + this.props.place.hora_ultima_carga +
                          "\n*🚚 Qtd Cargas na Fila:*  " + this.props.place.qtdCargasFila +
                          "\n*🚚 Horário última Fila:*  " + this.props.place.hora_ultima_fila +
                          "\n\n*🚚 Informativos de Embarque:*  "+ this.props.place.hora_ultima_fila +
                          "\n\n*🕑 Horário Informação Compartilhada:*  \n" + str_data + " - " + str_hora +
                          "\n\n\n_Obrigado por ultilizar nossos Serviços !_"

        let data = {
                title: '', 
                text: informacao
        }
        return (
            <div className={"infoWindow"}>
                <div className={"prestadoraServico"}>
                    Prestadora de serviço:
                    {usuario.usu_apresentacao === 'S' ?
                    <b>{'DEMONSTRAÇÃO'}</b> :
                    <b>{this.props.place.empresa}</b>
                    }
                </div>
                <div className={"content"}>

                    <span className={"nomeLocal"}>
                        <img className={"pinLocal"} src={this.props.place.image_marker} alt={"Marcador"}/>
                        {usuario.usu_apresentacao === 'S' ?
                         <strong>{'FAZENDA DEMONSTRAÇÃO'}</strong> :
                        <strong>{this.props.place.localemb}</strong>
                        }
                    </span>

                    <div className="wac-group gft">
                        <span><strong>O.S.:</strong> {usuario.usu_apresentacao === 'S' ? '34**10' : this.props.place.slo_codigo}</span>
                        <span><strong>Contrato:</strong> {usuario.usu_apresentacao === 'S' ? '1012***10**' : this.props.place.slo_contrato}</span>
                        <span><strong>Cliente:</strong>{usuario.usu_apresentacao === 'S' ? 'CLIENTE DEMONSTRAÇÃO' : this.props.place.cli_nome }</span>
                        <span><strong>CPF/CNPJ:</strong> {usuario.usu_apresentacao === 'S' ? '10.***.101/1**1-10' : this.props.place.mat_cnpj}</span>
                        <span><strong>Cidade/UF:</strong> {usuario.usu_apresentacao === 'S' ? 'DEMONST/PR' : this.props.place.cidade + "/" + this.props.place.uf}</span>
                        <span><strong>Origem:</strong> {usuario.usu_apresentacao === 'S' ? 'FAZENDA SÃO JOÃO' : this.props.place.localemb}</span>
                        <span><strong>Destino:</strong> {usuario.usu_apresentacao === 'S' ? 'DEMONSTRAÇÃO' : this.props.place.slo_destino}</span>
                        <span><strong>Lote (ton.):</strong> {this.props.place.slo_lote + " ton."}</span>
                        <span><strong>Produto:</strong> {this.props.place.pro_nome}</span>
                    </div>

                    <div className={"qtdes"}>
                        <div className={"item qtdCargas"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imagePeso} alt=""/>
                                <span className="wac-text">{this.props.place.volumeAcumulado.toFixed(2)}</span>
                            </div>
                            <div className={"item-desc mt0"}>
                                Volume acumulado
                            </div>
                        </div>
                        <div className={"item qtdFila"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imagePeso} alt=""/>
                                <span className="wac-text">{this.props.place.volumeCargas.toFixed(2)}</span>
                            </div>
                            <div className={"item-desc mt0"}>
                                Volume do dia
                            </div>
                        </div>
                    </div>

                    <div className={"qtdes"}>
                        <div className={"item qtdCargas"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imageEmbarcado} alt={I18nTranslator.t("SHIPPED")}/>
                                <span className="wac-text">{this.props.place.qtdCargas}</span>
                            </div>
                            <div className={"item-desc mt0"}>
                                {this.props.place.hora_ultima_carga}
                            </div>
                        </div>
                        <div className={"item qtdFila"}>
                            <div className={"wac-group g1 vc hc"}>
                                <img src={imageFilaRelogio} alt={I18nTranslator.t("QUEUE")}/>
                                <span className="wac-text">{this.props.place.qtdCargasFila}</span>
                            </div>
                            <div className={"item-desc mt0"}>
                                {this.props.place.hora_ultima_fila}
                            </div>
                        </div>
                    </div>

                    <Vspace/>
                    <p className={"fw700"}>Informativo de embarque</p>
                    {
                        this.state.loadingBoardingInformation ? (
                            <p className={"fw300"}>Carregando...</p>
                        ) : !this.state.boardingInformation.length ? (
                            <p className={"fw300"}>Nenhum informativo até o momento</p>
                        ) : (
                            <React.Fragment>
                                <p className={"fw300"}>Última atualização: {
                                    DateUtil.formatStringDate(this.state.boardingInformation[0].sloinfo_data)
                                } às {
                                    this.state.boardingInformation[0].sloinfo_hora.length === 8 ?
                                        this.state.boardingInformation[0].sloinfo_hora.slice(0, -3)
                                        : this.state.boardingInformation[0].sloinfo_hora
                                }
                                </p>
                                <div className={"qtdes"}>
                                    <div className={"item qtdCargas"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageRefugado} alt={"No pátio"}/>
                                            <span className="wac-text">{this.state.boardingInformation[0].sloinfo_patio}</span>
                                        </div>
                                        <div className={"item-desc mt0"}>
                                            No pátio
                                        </div>
                                    </div>
                                    <div className={"item qtdFila border-right"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageFila} alt={"Caregando"}/>
                                            <span className="wac-text">{this.state.boardingInformation[0].sloinfo_carregando}</span>
                                        </div>
                                        <div className={"item-desc mt0"}>
                                            Caregando
                                        </div>
                                    </div>
                                    <div className={"item qtdCargas"}>
                                        <div className={"wac-group g1 vc hc"}>
                                            <img src={imageEmbarcado} alt={"Carregado"}/>
                                            <span className="wac-text">{this.state.boardingInformation[0].sloinfo_carregado}</span>
                                        </div>
                                        <div className={"item-desc mt0"}>
                                            Carregado
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }

                    <Button
                        size={"small"}
                        className={"btnGreenGG btnFullWidth"}
                        onClick={(e) => this.props.openFullDetails(e, this.props.place.slo_codigo)}>
                        Ver mais
                    </Button>
                    <Button
                        size={"small"}
                        className={"btnAmareloGG btnFullWidth"}
                        onClick={() => {navigator.share(data)}} >
                        Compartilhar  
                    </Button>
                </div>  
            </div>
        );
    }
}

export default InfoWindow;