import React from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import {userAccessService} from "./core/user-access/UserAccessService";
import Main from "./view/main/";
import "./App.scss";
import "./components/styles/_all.scss";
import SignInSide from "view/login/SignInSide";

export function PrivateRoute({ Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) =>
        userAccessService.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Route path="/login" exact component={SignInSide} />
        <PrivateRoute path="/" Component={Main} />
      </Router>
    </div>
  );
};

export default App;
