import React, { useEffect,useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {I18nTranslator} from "../../../../core/i18n/I18nTranslator";
import Button from 'components/commons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Title } from 'components/commons/Typography';
import { TermoDeUso } from 'core/termoDeUso/termoDeUso';
import logo from '../../../../assets/logo_gg.png';
import HTMLReactParser from 'html-react-parser';
import Folha from  './styleTermo.css'
import {UserService} from 'core/user/UserService';


const TermsOfUse = (onClick) => {

const [muda, setMuda] = useState(1);
const [termoTexto, setTermoTexto] = useState("");
const [ativa, setAtiva] = React.useState(true);
const [open, setOpen] = React.useState(false);
const [telefone, setTelefone] = useState("");
const [aceitaTermo, setAceitaTermo] = useState("S");
const [state, setState] = React.useState({checkedG: false,});
const styleModal = { width: '100%', height: '100%' };
const styleLogo = { width: '24%', height: '100%' };
const styleAceite = { marginLeft: '50px'};

const next = () => {
    if (!validate()) handleClose();
  }

useEffect(()=>{
  getTermo();
},[muda]);


  const handleSubmit = (e) => {

    
    // e.preventDefault();
 
    let params = {
        aceita: aceitaTermo === "S" ? "S" : "N" ,
    }
    TermoDeUso.create(params)
    .then((response) => {
       console.log(response);
    })
    .catch((error) => {

      })
    .finally(() => setTimeout(() => {
        handleClose();
      }, 1000))
  }

  const [validation, setValidation] = useState({
    market_type: { feedback: "Por favor, aceite os termos de uso para prosseguir", error: false },
    telefone: { feedback: "Por favor, digite corretamente o número de telefone", error: false },
  });

  const validate = () => {
    let error = false;
    let newValidation = {...validation};

    Object.keys(newValidation).map((key) => {
      newValidation[key].error = false;
    });

    if (state.checkedG === false) {
      newValidation.market_type.error = error = true;
    }

    if (telefone === "" || telefone < 14) {
        newValidation.telefone.error = error = true;
    }

    setValidation(newValidation);
    return error;
  }


  const [logout, setLogout] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const naoAceita = () => {
    setAceitaTermo("N");
        let params = {
            aceita: "N" ,
        }
        TermoDeUso.create(params)
        .then((response) => {
        console.log(response);
        })
        .catch((error) => {

        })
        .finally(() => setTimeout(() => {
            handleClose();
            UserService.logout()
            .finally(() => setTimeout(() => setLogout(false), 5000));
        }, 1000))
    setOpen(false);
    
   
  };
  
  
  const GreenCheckbox = withStyles({
    root: {
      color: '#59B224',
      '&$checked': {
        color: '#59B224',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


  const getTermo = (e) => {

    TermoDeUso.getTermo()
    .then((response) => {
      setTermoTexto(response.termo);
      if(response.termo !== 'OK'){
          setOpen(true)
      }
      
    })
    .catch((error) => {
      console.error(error);
    })
    .finally();
  };

  const handleChangeCampo1 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if(ativa){
        setAtiva(false);
    } else{
        setAtiva(true)
    }
  };

 
  return (
      <Dialog open={open} onClose={handleClose}  style = {styleModal} onClose={true}>
        <DialogTitle id="cookie-consent-title">{<img src={logo} style = {styleLogo}/>}</DialogTitle>
        <DialogContent style={Folha.estiloTermo} >
        {HTMLReactParser(termoTexto)}
        </DialogContent>
        <DialogContent>
            </DialogContent>
            <DialogActions>
            <FormControlLabel
                style = {styleAceite}
                className='s16'
                control={
                <GreenCheckbox checked={state.checkedG} onChange={handleChangeCampo1} name="checkedG" />}
                label={<Title className='s14'>{I18nTranslator.t("LIDOTermo")}</Title>}
            />
            <Button onClick={naoAceita} className = "danger" disabled={ativa === false}>
                Não Aceito
            </Button>
            <Button onClick={getTermo,next, handleSubmit} className ="primary" disabled={ativa}>
                Aceito
            </Button>
            </DialogActions>
      </Dialog>
  );
}

export default TermsOfUse