import { configuration } from "config";
import { userAccessService } from "core/user-access/UserAccessService";


export class ProducerService {
    static create(params: object) {
        const url = `${configuration.AUTH_BASE_URL}cliconnect/produtor`;

        const config: object = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            },
            body: JSON.stringify(params)
        }

        return new Promise((resolve, reject) => {
            fetch(url, config)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static filter(params: any) {
        const searchParams = new URLSearchParams(params);
        const url = configuration.AUTH_BASE_URL + "cliconnect/produtor?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }
}