import React from 'react';

const Switch = ({  id, className, status, label, value, type, name, isChecked, ...props }) => {
  return (
    <label htmlFor={id} className={`wac-switch ${className}`}>
      <input 
        id={id} 
        type={type} 
        name={name} 
        value={value}
        checked={isChecked}
        {...props}/>
      <span className={`wac-switch-token ${status}`}></span>
      <span className={'wac-switch-label'}>
        {label}
      </span>
    </label>
  );
};

export default Switch;