/* eslint-disable */
import { Snackbar } from '@material-ui/core';
import Box from 'components/commons/Box';
import Button from 'components/commons/Button';
import Group from 'components/commons/Group';
import Paginator from 'components/commons/Paginator';
import Search from 'components/commons/Search';
import Table, { TableBody, TableHead } from 'components/commons/Table';
import { Title } from 'components/commons/Typography';
import Vspace from 'components/commons/Vspace';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import { ProducerService } from 'core/producer/ProducerService';
import TextUtil from 'core/utils/TextUtil';
import React, { useEffect, useReducer } from 'react'

const TEXT_SEARCH = I18nTranslator.t("SEARCH");
const TEXT_SEARCH_RESULT = I18nTranslator.t("SERCH_RESULT");
const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND");
const TEXT_CANCEL = I18nTranslator.t("CANCEL");
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM");
const TEXT_NAME = I18nTranslator.t("NAME");
const TEXT_ITIN_EIN = `${I18nTranslator.t("ITIN")}/${I18nTranslator.t("EIN")}`; // Cpf/Cnpj
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION");
const TEXT_REGISTRATION = I18nTranslator.t("REGISTRATION"); // Matrícula
const TEXT_ERROR_LINK_PRODUCER = I18nTranslator.t("ERROR_LINK_PRODUCER"); // Não foi possível vincular o produtor ao local, tente novamente mais tarde!
const TEXT_SUCCESS = I18nTranslator.t("SUCCESS"); // Sucesso
const TEXT_LINKING = I18nTranslator.t("LINKING"); // Vinculando
const TEXT_SELECT_PRODUCER = I18nTranslator.t("SELECT_PRODUCER"); // Selecione um produtor

const columns = ['ID', TEXT_NAME, TEXT_ITIN_EIN, TEXT_STATE_REGISTRATION, TEXT_REGISTRATION];
const initialState = {
    table: { 
        columns: columns, 
        data: [], 
        filter: [], 
        loading: false, 
        offset: 0, pageSize: 10, 
        selected: {},
        maxCell: 20,
        i18n: { NOTHING_FOUND: TEXT_NOTHING_FOUND } 
    },
    search: { value: '' },
    confirm_button: { value: TEXT_CONFIRM, disabled: false },
    snackbar: { value: '', active: false },
    select_producer_button: { value: TEXT_SELECT_PRODUCER, className: '', disabled: true }
}

const ACTIONS = {
    SET_DATA: 'set_data',
    SET_PAGE: 'set_page',
    SEARCH: 'search',
    LOADING: 'loading',
    SELECT_ROW: 'select_row',
    SET_CONFIRM_BUTTON: 'set_confirm_button',
    SET_SNACKBAR: "set_snackbar",
    REMOVE_PRODUCER: "remove_producer",
    SET_SELECT_PRODUCER_BUTTON: "set_select_producer_button"
}

function reducer(state, action) {
    try {
        switch(action.type) {
            case ACTIONS.SET_DATA:
                let data = action.payload.data;
                let handledData = data.map(row => {
                   return {
                       "ID": row.pro_id,
                       [TEXT_NAME]: row.pro_nome,
                       [TEXT_ITIN_EIN]: (row.pro_cpf ? TextUtil.formatCpfCnpj(row.pro_cpf) : ''),
                       [TEXT_STATE_REGISTRATION]: row.pro_inscricao_estadual,
                       [TEXT_REGISTRATION]: row.pro_matricula
                   } 
                });
                return { ...state, table: { ...state.table, data: handledData, filter: handledData } };
    
            case ACTIONS.SET_PAGE:
                return { ...state, table: { ...state.table, offset: action.payload.offset } };
    
            case ACTIONS.SEARCH:
                let tableData = state.table.data;
                let search = action.payload.search;
                let handledFilter = tableData.filter((row) => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()))
                return { ...state, table: { ...state.table, filter: handledFilter }, search: { value: search } };
    
            case ACTIONS.LOADING:
                return { ...state, table: { ...state.table, loading: action.payload.loading } };
    
            case ACTIONS.SELECT_ROW:
                let selected = {};
                if (action.payload.selected && Object.keys(action.payload.selected).length) {
                    selected = action.payload.selected['ID'] !== state.table.selected['ID'] ? action.payload.selected : {};
                    data = state.table.data;
                    data.map((row, index) => {
                        data[index].className = (row['ID'] === selected['ID'] ? 'selected' : '');
                    });
                    handledFilter = data;
                    if (state.search.value !== '') {
                        handledFilter = data.filter((row) => JSON.stringify(row).toLowerCase().includes(state.search.value.toLowerCase()))
                    }
                }
                return { ...state, table: { ...state.table, data: data, filter: handledFilter, selected: selected } };
    
            case ACTIONS.SET_CONFIRM_BUTTON:
                return { ...state, confirm_button: { value: action.payload.value, disabled: action.payload.disabled } }
            
            case ACTIONS.SET_SNACKBAR:
                return { ...state, snackbar: { value: action.payload.value, active: action.payload.active } }
            
            case ACTIONS.REMOVE_PRODUCER:
                let producer = action.payload.producer;
                let filter = state.table.filter.filter((row) => row['ID'] !== producer['ID']);
                data = state.table.data.filter((row) => row['ID'] !== producer['ID']);
                return { ...state, table: { ...state.table, filter: filter, data: data, selected: {} } }
            
            case ACTIONS.SET_SELECT_PRODUCER_BUTTON:
                return { ...state, select_producer_button: { value: action.payload.value, className: action.payload.className, disabled: action.payload.disabled } }

            default:
                return state;
        }
    }
    catch(error) {
        return state;
    }
}

export default function SearchProducer({ loading, list = [], onClick, locCodigo, onLink = () => {} }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setData = data => {
        dispatch({ type: ACTIONS.SET_DATA, payload: { data: data } })
    }
    const setPage = offset => {
        dispatch({ type: ACTIONS.SET_PAGE, payload: { offset: offset } })
    }
    const setLoading = loading => {
        dispatch({ type: ACTIONS.LOADING, payload: { loading: loading } })
    }
    const setSearch = search => {
        dispatch({ type: ACTIONS.SEARCH, payload: { search: search } })
    }
    const selectRow = (e, row) => {
        dispatch({ type: ACTIONS.SELECT_ROW, payload: { selected: row } });
    }
    const setConfirmButton = (value, disabled = false) => {
        dispatch({ type: ACTIONS.SET_CONFIRM_BUTTON, payload: { value: value, disabled: disabled } })
    }
    const setSnackbar = (value, active = true) => {
        dispatch({ type: ACTIONS.SET_SNACKBAR, payload: { value: value, active: active } })
    }
    const removeProducer = (producer) => {
        dispatch({ type: ACTIONS.REMOVE_PRODUCER, payload: { producer: producer }});
    }
    const setSelectProducerButton = (value, className = '', disabled = true) => {
        dispatch({ type: ACTIONS.SET_SELECT_PRODUCER_BUTTON, payload: { value: value, className: className, disabled: disabled} });
    }

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        setData(list);
    }, [list]);

    const linkProducer = e => {
        try {
            e.preventDefault();
            if (!Object.keys(state.table.selected).length || !locCodigo) {
                setSnackbar(TEXT_ERROR_LINK_PRODUCER);
                return;
            }
            setConfirmButton(`${TEXT_LINKING}...`, true);
            let params = { pro_id: state.table.selected['ID'], loc_codigo: locCodigo};
            ProducerService.create(params)
            .then((response) => {
                if (!response.success) {
                    setSnackbar(TEXT_ERROR_LINK_PRODUCER);
                    return;
                }
                setSelectProducerButton(`${TEXT_SUCCESS}!`, "primary", false);
                onLink(response);
                removeProducer(state.table.selected);
                setTimeout(() => setSelectProducerButton(TEXT_SELECT_PRODUCER), 2000);
            })
            .catch((error) => { 
                setSnackbar(TEXT_ERROR_LINK_PRODUCER);
            })
            .finally(() => setConfirmButton(TEXT_CONFIRM))
        }
        catch(error) {
            setSnackbar(TEXT_ERROR_LINK_PRODUCER);
        }
    }

    return (
        <React.Fragment>
            <Group className='fluid g5'>
                <Search 
                    className='light'
                    defaultValue={state.search.value}
                    onInput={(e) => setSearch(e.target.value)}
                    placeholder={TEXT_SEARCH} />
            </Group>
            <Vspace />
            <Box>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    key={`snackbar`}
                    open={state.snackbar.active}
                    onClose={() => setSnackbar('', false)}
                    autoHideDuration={4000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{state.snackbar.value}</span>}
                />
                <Title className="s13 uppercase">
                    {TEXT_SEARCH_RESULT}
                </Title>
                <Vspace />
                <div className={`wac-table-container ${state.table.loading ? 'loading' : ''}`}>
                    <div className={`fixed-cols-table f-1`}>
                        <div className="fixed-cols">
                            <Table className="featured">
                                <TableHead columns={state.table.columns} />
                                <TableBody 
                                    onClick={selectRow}
                                    i18n={state.table.i18n}
                                    data={state.table.filter} 
                                    offset={state.table.offset}
                                    maxCell={state.table.maxCell}
                                    columns={state.table.columns} 
                                    pageSize={state.table.pageSize}
                                    onMaxCell={(cellValue) => TextUtil.summarize(cellValue, state.table.maxCell)}
                                />
                            </Table>
                        </div>
                        <div className="scroll-cols">
                            <Table className="featured">
                                <TableHead columns={state.table.columns} />
                                <TableBody
                                    onClick={selectRow}
                                    i18n={state.table.i18n}
                                    data={state.table.filter} 
                                    offset={state.table.offset}
                                    maxCell={state.table.maxCell}
                                    columns={state.table.columns} 
                                    pageSize={state.table.pageSize}
                                    onMaxCell={(cellValue) => TextUtil.summarize(cellValue, state.table.maxCell)}
                                />
                            </Table>
                            <Paginator
                                paginatorSize={state.table.filter.length}
                                pageSize={state.table.pageSize}
                                onOffsetChange={setPage}
                                setRowsLabel={({ from, to, count }) => `${from}-${to} of ${count}`}
                            />
                        </div>
                    </div>
                </div>
                <Vspace />
                <Group className='spread fluid'>
                    <Button type="reset" onClick={onClick} className='outlined'>
                        {TEXT_CANCEL}
                    </Button>
                    {
                        Object.keys(state.table.selected).length ?
                        (<Button onClick={linkProducer} disabled={state.confirm_button.disabled}>
                            {state.confirm_button.value}
                        </Button>) : 
                        (<Button className={state.select_producer_button.className || ''} disabled={state.select_producer_button.disabled}>
                            {state.select_producer_button.value}
                        </Button>)
                    }
                </Group>
            </Box>
        </React.Fragment>
    )
}
