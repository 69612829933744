/* eslint-disable */
import React, {useEffect, useState} from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import FormNew from './FormNew';
import FormAdd from './FormAdd';
import Modal from 'components/commons/Modal';
import {Link} from 'react-router-dom';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {Text, Title} from 'components/commons/Typography';
import {CarryingService} from 'core/carrying/CarryingService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import {I18nTranslator} from 'core/i18n/I18nTranslator';

const criaTransportadora = (values = {}) => {
  let transportadora = {};
  transportadora.transp_id = values.transp_id || "";
  transportadora.transp_nome = values.transp_nome || "";
  transportadora.transp_cnpj = values.transp_cnpj || "";
  transportadora.transp_endereco = values.transp_endereco || "";
  transportadora.transp_complemento = values.transp_complemento || "";
  transportadora.transp_bairro = values.transp_bairro || "";
  transportadora.transp_cep = values.transp_cep || "";
  transportadora.transp_fone = values.transp_fone || "";
  transportadora.email_list = values.email_list || [];
  transportadora.cid_codigo = values.cid_codigo || "";
  transportadora.documentos_motorista = values.documentos_motorista || [];
  transportadora.documentos_cliente = values.documentos_cliente || [];
  return transportadora;
}

function Form({ prevStep, nextStep, handleChange, values }) {

  var [modalNew, setNew] = useState(false);
  var [modalAdd, setAdd] = useState(false);
  var [selectedTransportadoraList, setSelectedTransportadoraList] = useState(
    (Array.isArray(values.transportadoras) && values.transportadoras.length) ? 
    values.transportadoras : [criaTransportadora()]
  );

  var [transportadoraList, setTransportadoraList] = useState([]);
  var [documents, setDocuments] = useState({});

  useEffect(() => {
    setTimeout(() => getCarryingList(), 1000);
  }, [])

  const adicionaTransportadora = () => {
    setSelectedTransportadoraList(prevState => [...prevState, criaTransportadora()]);
  }

  const adicionaTransportadoraList = (transportadora) => {
    setTransportadoraList(prevState => [...prevState, criaTransportadora(transportadora)]);
  }

  const removeTransportadora = (index) => {
    setSelectedTransportadoraList(prevState => {
      let newList = prevState.slice(0, index).concat(prevState.slice(index + 1));
      handleChange('transportadoras', newList);
      return newList;
    });
  }

  const handleChangeEmail = index => e => {
    e.target.value = e.target.value.replace(/\s+|,/g, ';');
    let selectedTransportadoraListCopy = [...selectedTransportadoraList];
    if (selectedTransportadoraListCopy[index]) selectedTransportadoraListCopy[index].email_list = e.target.value.split(";");
    setSelectedTransportadoraList(selectedTransportadoraListCopy);
    handleChange('transportadoras', selectedTransportadoraListCopy);
  }

  const handleChangeTransportadora = (transportadora, index) => {
    let selectedTransportadoraListCopy = [...selectedTransportadoraList];
    selectedTransportadoraListCopy[index] = transportadora;
    setSelectedTransportadoraList(selectedTransportadoraListCopy);
    handleChange('transportadoras', selectedTransportadoraListCopy);
  }

  const handleChangeDocuments = newDocuments => {
    let index = selectedTransportadoraList
      .findIndex(transportadora => newDocuments.transp_cnpj === transportadora.transp_cnpj);
    
    if (index > -1) {
      let selectedTransportadoraListCopy = [...selectedTransportadoraList];
      selectedTransportadoraListCopy[index].documentos_motorista = newDocuments.motorista;
      selectedTransportadoraListCopy[index].documentos_cliente = newDocuments.cliente;
      setSelectedTransportadoraList(selectedTransportadoraListCopy)
      handleChange('transportadoras', selectedTransportadoraListCopy);
    }
  }

  const handleModalNew = () => {
    setNew(modalNew === false);
  }

  const handleModalAdd = () => {
    setAdd(modalAdd === false);
  }

  const getCarryingList = () => {
    CarryingService.getList()
    .then((response) => {
      if (Array.isArray(response)) {
        let newList = [];
        response.map(row => {
          newList.push(criaTransportadora(row));
        });
        setTransportadoraList(newList);
      }
    })
    .catch((error) => console.error("getCarryingList", error))
  }

  return (
    <>
      <Vspace />
      <Row className='start-xs'>
        <Col>
          <Title>{values.edit && values.slo_codigo ? `Editar OS ${values.slo_codigo}` : 'Cadastrar OS'}</Title>
        </Col>
      </Row>
      <Vspace />
      <Box className='ghost'>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>
              Cadastrar serviços adicionais
            </Text>
          </Col>
        </Row>
        <Vspace />
        <Group className='vc fluid g3'>
          <Text className='s11 fw700'>
            Troca de NF
          </Text>
          <Button onClick={handleModalNew}>
            Cadastrar nova transportadora
          </Button>
        </Group>
      </Box>
      <Vspace className='lg'/>
      <Box className='ghost' key={selectedTransportadoraList}>
        {
          selectedTransportadoraList.length ?
          selectedTransportadoraList.map((transportadora, index) => (
            <React.Fragment>
              <Row className='bottom-xs'>
                <Col className='col-xs-4 col-lg-6 col-md-12'>
                  <Autocomplete
                    options={transportadoraList}
                    defaultValue={transportadora}
                    noOptionsText={"Nada encontrado"}
                    getOptionLabel={(option) => option.transp_nome}
                    disableAnimation={true}
                    onChange={(e, newTransportadora) => handleChangeTransportadora(newTransportadora, index)}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                         autoComplete='off'
                        label={"Transportadora"}
                        placeholder={"Selecione"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined" 
                      />
                    }
                  />
                </Col>
                <Col className='col-xs-4 col-lg-6 col-md-12'>
                  <Input
                    id={transportadora ? transportadora.transp_cnpj : ''}
                    label='E-mail do responsável'
                    defaultValue={transportadora ? transportadora.email_list.join(";") : ''}
                    onChange={handleChangeEmail(index)}
                    placeholder='email@email.com.br' />
                </Col>
                <Col className='col-xs-4 col-lg-12 col-md-12'>
                  <Group className='g2'>
                    {
                      transportadora && transportadora.transp_cnpj !== '' ?
                      (<Button onClick={() => {
                        setDocuments({
                          transp_cnpj: transportadora.transp_cnpj,
                          transp_nome: transportadora.transp_nome,
                          motorista: transportadora.documentos_motorista, 
                          cliente: transportadora.documentos_cliente 
                        });
                        handleModalAdd();
                      }}>
                        Editar documentação
                      </Button>) : 
                      (<Button className="outlined" disabled>
                        Selecione uma transportadora
                      </Button>)
                    }
                    {
                      selectedTransportadoraList.length > 1 ? 
                      (<Button className='outlined' onClick={() => removeTransportadora(index)}>
                        Remover
                      </Button>) : ''
                    }
                  </Group>
                </Col>
              </Row>
              <Vspace className='lg'/>
            </React.Fragment>
          )) : ''
        }
        <Row className='start-xs'>
          <Col>
            <Button className='inline' onClick={adicionaTransportadora}>
              Adicionar mais uma transportadora
            </Button>
          </Col>
        </Row>
        <Vspace className='xxxlg'/>
        <hr />
        <Group className='spread'>
          <Link 
            className='wac-button outlined' 
            onClick={prevStep}>
            Voltar
          </Link>
          <Link 
            className='wac-button' 
            onClick={nextStep}>
            {I18nTranslator.t("CONFIRM")}
          </Link>
        </Group>
      </Box>
      <Vspace className='xxxlg'/>
      <Modal className={`sm ${ modalNew ? 'active' : ''}`}>
          <FormNew onClick={handleModalNew} onCreate={adicionaTransportadoraList}/>
      </Modal>
      <Modal className={`md ${ modalAdd ? 'active' : ''}`}>
          <FormAdd 
            onClick={handleModalAdd} 
            documents={documents} 
            onChange={handleChangeDocuments}
          />
      </Modal>
    </>
  );
}

export default Form;
