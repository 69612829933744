/* eslint-disable */
import React, {useEffect, useState} from 'react';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Vspace from 'components/commons/Vspace';
import Box from 'components/commons/Box';
import Icon from 'components/commons/Icon';
import {Link} from 'react-router-dom';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {Text, Title} from 'components/commons/Typography';
import {CloseXIcon, ContainerIcon, SampleIcon, ShipIcon, TrainCarIcon, TruckIcon} from 'components/Icons';
import 'react-tabs/style/react-tabs.css';
import Modal from 'components/commons/Modal';
import BranchData from './components/BranchData';
import ProductPattern from './components/ProductPattern';
import ToogleCard from 'components/commons/ToogleCard';
import TypeServiceLocation from './TypeServiceLocation';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import {ClientService} from 'core/client/ClientService';
import {Snackbar, TextField} from '@material-ui/core';
import {OSService} from 'core/os/OSService';
import {TransportService} from 'core/transport/TransportService';
import {ProductService} from 'core/product/ProductService';
import {values} from 'lodash';
import Feedback from 'components/commons/Feedback';
import {Radio, RadioOption} from 'components/commons/Radio';

// Traduções.
const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
const TEXT_REGIONAL_CUSTOMER = I18nTranslator.t('REGIONAL_CUSTOMER');
const TEXT_BRANCH_CUSTOMER = I18nTranslator.t('BRANCH_CUSTOMER');
const TEXT_SERVICE = I18nTranslator.t('SERVICE');
const TEXT_PRODUCT = I18nTranslator.t('PRODUCT');
const TEXT_SELECT = I18nTranslator.t('SELECT');
const TEXT_SERVER_DOWN = I18nTranslator.t("SERVER_DOWN"); // O servidor está indisponível no momento! Tente novamente mais tarde
const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND"); // Nada encontrado
const TEXT_REGISTER_SERVICE_ORDER = I18nTranslator.t("REGISTER_SERVICE_ORDER"); // Cadastrar OS
const TEXT_CLIENT = I18nTranslator.t("CLIENT"); // Cliente
const TEXT_SEE_BRANCH_DATA = I18nTranslator.t("SEE_BRANCH_DATA"); // ver dados da filial
const TEXT_SERVICE_AND_TRANSPORT = I18nTranslator.t("SERVICE_AND_TRANSPORT"); // Serviço e Transporte
const TEXT_TRANSPORT = I18nTranslator.t("TRANSPORT"); // Transporte
const TEXT_PRODUCT_DATA = I18nTranslator.t("PRODUCT_DATA"); // Informações do produto
const TEXT_SEE_CLASSIFICATION_STANDARDS = I18nTranslator.t("SEE_CLASSIFICATION_STANDARDS"); // ver padrões de classificação
const TEXT_PRODUCT_TYPE = I18nTranslator.t("PRODUCT_TYPE"); // Tipo de Produto
const TEXT_BACK_TO_INBOX = I18nTranslator.t("BACK_TO_INBOX"); // Voltar para inbox
const TEXT_NEXT = I18nTranslator.t("NEXT"); // Próximo
const TEXT_EMBARQUE = I18nTranslator.t("INFO_TYPE_EMBARQUE");


function GeneralInformation(props, values) {

  const [ productPattern, setProductPattern ] = useState(false)
  const [ branchData, setBranchData ] = useState(false)

  // Snackbar.
  const [ openSnackbar, setOpenSnackbar ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState('');

  // Regional.
  const [regionalList, setRegionalList] = useState([]);
  const [loadingRegionalList, setLoadingRegionalList] = useState(false);

  // Filial.
  const [filialList, setFilialList] = useState([]);
  const [loadingFilialList, setLoadingFilialList] = useState(false);
  const [filterFilialList, setFilterFilialList] = useState([]);
  const [branch, setBranch] = useState(props.values.branch || false);

  // Serviço.
  const [serviceList, setServiceList] = useState([]);
  const [loadingServiceList, setLoadingServiceList] = useState(false);
  const [service, setService] = useState(props.values.service || {});

  // Transportes.
  const [transportList, setTransportList] = useState([]);
  const [loadingTransportList, setLoadingTransportList] = useState(false);

  // Produto.
  const [productList, setProductList] = useState([]);
  const [loadingProductList, setLoadingProductList] = useState(false);
  const [product, setProduct] = useState(props.values.produto || {});

  // Itens de classificação do produto.
  const [loadingClassificationItems, setLoadingClassificationItems] = useState(false);

  // Tipo de produto.
  const [productTypeList, setProductTypeList] = useState([]);
  const [loadingProductTypeList, setLoadingProductTypeList] = useState(false);
  const [ mudarCor, setMudarCor ] = useState(false);
  const [ dataSolicitacao, setDataSolicitacao] = useState(props.values.data_solicitacao)
  const [ tipoDeLocalServico, setTipoDeLocalServico] = useState(props.values.tipo_local_servico_index)

  const [validation, setValidation] = useState({
    branch: { feedback: "Informe o cliente!", error: false },
    service: { feedback: "Informe o serviço!", error: false },
    transport: { feedback: "Informe o transporte!", error: false },
    service_location_type: { feedback: "Informe o tipo de local de serviço!", error: false },
    product: { feedback: "Informe o produto!", error: false },
    product_type: { feedback: "Informe o tipo do produto!", error: false },
    product_embarque: { feedback: "Informe um tipo de embarque!", error: false },
  });

  // Busca lista de regionais.
  function getRegionalList() {
    setLoadingRegionalList(true);

    ClientService.getRegionalList()
      .then((response) => {
        if (Array.isArray(response)) {
          setRegionalList(response);
        }
      })
      .catch((error) => {
        showSnackBar(TEXT_SERVER_DOWN);
      })
      .finally(() => setLoadingRegionalList(false));
  }

  // Busca lista de filiais.
  function getFilialList() {
    setLoadingFilialList(true);
    ClientService.getFilialList()
    .then((response) => {
      if (Array.isArray(response)) {
        setFilialList(response);
        setFilterFilialList(response);
      }
    })
    .catch((error) => {
      showSnackBar(TEXT_SERVER_DOWN);
    })
    .finally(() => setLoadingFilialList(false))
  }

  // Busca lista de serviços.
 
  function getServiceList() {
    
    setLoadingServiceList(true);

    const parameters = {
      dataSolicitacao: dataSolicitacao,
    }

    OSService.getServiceList(parameters)
    .then((response) => {
      if (Array.isArray(response)) {
        setServiceList(response);
      }
    })
    .catch(() => {
      showSnackBar(TEXT_SERVER_DOWN);
    })
    .finally(() => setLoadingServiceList(false))
  }

  // Busca a lista de transportes.
  function getTransportList() {
    setLoadingTransportList(true);
    TransportService.getList()
      .then((response) => {
        if (Array.isArray(response)) {
          setTransportList(response);
        }
      })
      .catch(error => {
        showSnackBar(TEXT_SERVER_DOWN);
      })
      .finally(() => setLoadingTransportList(false))
    }


  // Busca a lista de tipos de produto
  function getProductTypeList(pro_codigo) {
    setLoadingProductTypeList(true);

    const parameters = {
      pro_codigo: pro_codigo,
    }
    ProductService.getProductTypeList(parameters)
      .then(response => {
        if (Array.isArray(response)) {
          setProductTypeList(response);
        }
      })
      .catch(error => {
        showSnackBar(TEXT_SERVER_DOWN);
      })
      .finally(() => setLoadingProductTypeList(false))
  }

  // Busca lista de produtos.
  const getProductList = () => {
    setLoadingProductList(true);

    ProductService.getProductList()
    .then((response) => {
      if (Array.isArray(response)) { 
        setProductList(response);
      }
    })
    .catch((error) => {
      showSnackBar(TEXT_SERVER_DOWN);
    })
    .finally(() => setLoadingProductList(false))
  }

  // Busca a lista de itens de classificação por código do produto.
  const getClassificationItems = (pro_codigo) => {
    setLoadingClassificationItems(true);
    const parameters = {
      pro_codigo: pro_codigo,
      item_slo: 'S',
      item_ativo: 'S',
      item_liberado: 'S'
    }
    ProductService.getClassificationItems(parameters)
    .then((response) => {
      if (Array.isArray(response)) {
        props.handleChange('itens_classificacao', response);
      }
    })
    .catch(error => {
      showSnackBar(TEXT_SERVER_DOWN);
    })
    .finally(() => setLoadingClassificationItems(false))
  }

  function handleProductPattern() {
    if (values.produto) getClassificationItems(values.produto.pro_codigo);
    setProductPattern( productPattern === false )

  }

  function handleBranchData() {
    setBranchData( branchData === false )
  }

  function showSnackBar(message) {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  }

  // Manipula o evento de mudança no campo Cliente Regional
  // Filtra os Clientes Filiais apartir do Cliente Regional selecionado.
  function handleChangeRegional(e, newRegional) {
    let cnpj = newRegional ? newRegional.ent_cpfcnpj : '';

    if (props.values.regional && cnpj !== props.values.regional.ent_cpfcnpj) {
      props.handleChange('branch', {});
    }

    props.handleChange('regional', newRegional);

    if (cnpj === ""){
      setFilterFilialList(filialList);
      return;
    }

    if (filialList) {
      let filterFilial = filialList.filter((filial) => {
        return filial.regional.ent_cpfcnpj === cnpj;
      });

      setFilterFilialList(filterFilial);
    }
  }

  // Passa para a próxima etapa.
  const next = (e) => {
    e.preventDefault();
    if (!validate()) {
      props.nextStep();
    }
  }

  const validate = () => {
    let error = false;
    let newValidation = {...validation};

    Object.keys(newValidation).map((key) => {
      newValidation[key].error = false;
    });

    if (!props.values.branch || !Object.keys(props.values.branch).length) {
      newValidation.branch.error = error = true;
    }
    if (!props.values.service || !Object.keys(props.values.service).length) {
      newValidation.service.error = error = true;
    }
    if (!props.values.transport || !Object.keys(props.values.transport).length) {
      newValidation.transport.error = error = true;
    }
    switch (props.values.tipo_local_servico_index) {
      case 0:
        if (
          !Object.keys(props.values.supervisao).length ||
          !Object.keys(props.values.tipo_embarque).length ||
          !Object.keys(props.values.terminal).length
        ) {
          newValidation.service_location_type.error = error = true;
        }
        break;
      case 1:
        if (
          !Object.keys(props.values.supervisao).length ||
          !Object.keys(props.values.tipo_embarque).length ||
          !Object.keys(props.values.moegao).length
        ) {
          newValidation.service_location_type.error = error = true;
        }
        break;
      case 2:
        if (
          !Object.keys(props.values.supervisao).length ||
          !Object.keys(props.values.tipo_embarque).length ||
          !Object.keys(props.values.porto).length
        ) {
          newValidation.service_location_type.error = error = true;
        }
        break;
      case 3:
        if (!Object.keys(props.values.linha_embarque_selecionada).length) {
          newValidation.service_location_type.error = error = true;
        }
        break;
    }

    if (!props.values.produto || !Object.keys(props.values.produto).length) {
      newValidation.product.error = error = true;
    }

    if (!props.values.tipo_produto || !Object.keys(props.values.tipo_produto).length) {
      newValidation.product_type.error = error = true;
    }

    if (!props.values.slo_cif_fob || !Object.keys(props.values.slo_cif_fob)) {
      newValidation.product_embarque.error = error = true;
    }

    if (error) {
      showSnackBar("Informe todos os campos!");
    }

    setValidation(newValidation);
    return error;
  }

  // Manipula o evento de mudança no campo Cliente Filial.
  function handleChangeFilial(e, newValue) {
    props.handleChange('branch', (newValue || {}));
    if (newValue) {
      props.handleChange("nome_contato", newValue.cli_contato);
      props.handleChange("telefone_contato", newValue.cli_fone);
    }
    setBranch(newValue || {});
  }

  // Manipula o evento de mundança no campo Serviço.
  function handleChangeService(e, newValue) {
    props.handleChange('service', (newValue || {}));
    setService(newValue || {})
  }

  function handleChangeTransport(e, newValue) {
    props.handleChange('transport', newValue);
  }

  function handleChangeProductType(e, newValue) {
    props.handleChange('tipo_produto', newValue)
  }

  // Manipula o evento de mudança no campo Produto.
  function handleChangeProduct(e, product) {
    props.handleChange('produto', (product || {}));
    setProduct(product || {});

    if (product) {
      getClassificationItems(parseInt(product.pro_codigo));
      getProductTypeList(parseInt(product.pro_codigo));
    }
  }

  function handleChangeEmbarque(e, newValue) {
    props.handleChange('slo_cif_fob', newValue);
  }


  const TransportCardList = ({ transportList, defaultValue, onChange }) => {
     // Retorna o icone do card de acordo com o transporte
    function getCardIconTransport(id) {
      switch(id) {
        case 1:
          return <Icon component={<TruckIcon />} /> 
        case 2:
          return <Icon component={<TrainCarIcon />} />
        case 3:
          return <Icon component={<ShipIcon />} />
        case 4:
          return <Icon component={<ContainerIcon />} />
        case 5:
          return <Icon component={<SampleIcon />} />
        
        default:
          return <Icon component={<CloseXIcon />} />
      }
    }

    if (!transportList.length) {
      return (
        <ToogleCard 
              key={'none-card'}
              className="lg"
              icon={<Icon component={<CloseXIcon />}/>}
              label={TEXT_NOTHING_FOUND} />
      )
    }
    return (
      <>
        {
          transportList.map(transport => (
            <ToogleCard 
              id={`transport_${transport.tra_codigo}`}
              onChange={(e) => onChange(e, (e.target.checked ? transport : {}))}
              defaultChecked={defaultValue && transport.tra_codigo === defaultValue.tra_codigo}
              key={transport.tra_codigo}
              name="tra_codigo"
              className="lg"
              icon={getCardIconTransport(transport.tra_codigo)}
              label={TransportService.getTranslation(transport.tra_nome)} />
          ))
        }
      </>
    )
  }
  
  const ProductTypeCardList = ({ productTypeList, defaultValue, onChange, disabled }) => {
    if (!productTypeList.length) {
      return (
        <ToogleCard
          label={TEXT_NOTHING_FOUND}
        />
      );
    }
    return (
      <>
        {
          productTypeList.map(productType => {
            return ( 
              <ToogleCard
                  key={productType.tpg_codigo}
                  onChange={(e) => onChange(e, (e.target.checked ? productType : {}))}
                  defaultChecked={defaultValue && productType.tpg_codigo === defaultValue.tpg_codigo}
                  id={`tpg_codigo_${productType.tpg_codigo}`}
                  name={`tpg_codigo`}
                  disabled={disabled}
                  label={productType.tpg_nome}
              />
            )
          })
        }
      </>
    )
  }

  useEffect(() => {
    setLoadingTransportList(true);
    if (!props.values.edit) {
      setTimeout(() => {
        getRegionalList()
      }, 1000)

      setTimeout(() => {
        getFilialList()
      }, 2000)
    }
    setTimeout(() => {
      getServiceList()
    }, 3000)
    setTimeout(() => {
      getTransportList()
    }, 4000)
    setTimeout(() => {
      getProductTypeList()
    }, 5000)
    setTimeout(() => {
      getProductList()
    }, 6000)
  }, []);



  

  return (
    <>
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
          }}
          key={`snackbar`}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          autoHideDuration={4000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackbarMessage}</span>}
      />
      <Vspace className='hom'/>
      <Row className='hom start-xs'>
        <Col>
          <Title>{props.values.edit ? `Editar OS ${props.values.slo_codigo || ''}` : TEXT_REGISTER_SERVICE_ORDER}</Title>
        </Col>
      </Row>
      <Vspace className='hom'/>
      <Box className='ghost'>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>
              {TEXT_CLIENT} - {props.values.mat_nome || ''}
            </Text>
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='start-xs bottom-xs'>
          <Col className='col-xs-4 col-lg-5 col-md-12'>
            <Autocomplete
              id="field_cliente_regional"
              defaultValue={props.values.regional}
              options={regionalList}
              loading={loadingRegionalList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              disabled={props.values.edit}
              getOptionLabel={(option) => option.ent_nome}
              onChange={handleChangeRegional}
              disableanimation="true"
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_REGIONAL_CUSTOMER}
                    placeholder={TEXT_SELECT}
                    error={validation.branch.error}
                    helperText={validation.branch.error && validation.branch.feedback}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>
          <Col className='col-xs-4 col-lg-5 col-md-12'>
            <Autocomplete
              id="field_cliente_filial"
              defaultValue={props.values.branch || {}}
              options={filterFilialList}
              loading={loadingFilialList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              disabled={props.values.edit}
              getOptionLabel={(option) => option.cli_nome}
              onChange={handleChangeFilial}
              disableanimation="true"
              renderInput={ (params) =>
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_BRANCH_CUSTOMER}
                    error={validation.branch.error}
                    helperText={validation.branch.error && validation.branch.feedback}
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </> 
              }
            />
          </Col>
          {
            typeof branch === 'object' && Object.keys(branch).length ?
            (<Col className='col-xs-4 col-lg-5 col-md-12'>
              <Button onClick={handleBranchData} className='featured'>
                <Text className='fw600 ttreset'>
                  {TEXT_SEE_BRANCH_DATA}
                </Text>
              </Button>
            </Col>) : ''
          }
        </Row>
      </Box>
      
      <Box className={`ghost ${loadingTransportList ? 'loading' : ''}`}>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>
              {TEXT_SERVICE_AND_TRANSPORT}
            </Text>
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='start-xs'>
          <Col className='col-xs-4 col-lg-4 col-md-12'>
            <Autocomplete
              id="field_servico"
              defaultValue={props.values.service}
              options={serviceList}
              loading={loadingServiceList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              onChange={handleChangeService}
              getOptionLabel={(option) => option.serv_descricao}
              disableanimation="true"
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_SERVICE}
                    error={validation.service.error}
                    helperText={validation.service.error && validation.service.feedback}
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>
        </Row>
        <Vspace className='lg' />
        <Text className='s11 fw500'>
          {TEXT_TRANSPORT}
        </Text>
        <Vspace className='sm' />
        <Group className={`mhs g2 spread-evenly focus`}>
          <TransportCardList
            onChange={handleChangeTransport}
            defaultValue={props.values.transport}
            transportList={
              typeof service === 'object' && service.tra_codigo ?
                transportList.filter(
                  transport => (service.tra_codigo === transport.tra_codigo)
                ) 
              : transportList
            } 
          />
        </Group>
        
        {
          validation.transport.error ? 
          (<Feedback severity={'danger'} message={validation.transport.feedback} />) : ''
        }
        <Vspace className='lg' />
        <TypeServiceLocation values={props.values} handleChange={props.handleChange} />
        {
          validation.service_location_type.error ? 
          (<Feedback severity={'danger'} message={validation.service_location_type.feedback} />) : ''
        }
        
      </Box>
      <Box className={`ghost ${loadingProductTypeList ? 'loading' : ''}`}>
        <Row className='start-xs'>
          <Col>
            <Text className='s10 fw500'>
              {TEXT_PRODUCT_DATA}
            </Text>
          </Col>
        </Row>
        <Vspace className='lg' />
        <Row className='start-xs bottom-xs'>
          <Col className='col-xs-4 col-lg-4 col-md-12'>
            <Autocomplete
              id="field_produto"
              options={productList}
              loading={loadingProductList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              defaultValue={props.values.produto}
              onChange={handleChangeProduct}
              getOptionLabel={(option) => ProductService.getTranslation(option.pro_nome)}
              disableAnimation={true}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_PRODUCT}
                    error={validation.product.error}
                    helperText={validation.product.error && validation.product.feedback}
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>
          <Col className='col-xs-4 col-lg-5 col-md-12'>
            {
              typeof product === 'object' && Object.keys(product).length ?
              (
              <Button onClick={handleProductPattern} className='featured'>
                <Text className='fw600 ttreset'>
                  {TEXT_SEE_CLASSIFICATION_STANDARDS}
                </Text>
              </Button>
              ) : ''
            }
          </Col>
        </Row>
        <Vspace className='lg' />
        {product.pro_codigo > 0 ? 
        <Text className='s11 fw500'>
          {TEXT_PRODUCT_TYPE}
        </Text>  : '' }
        <Vspace className='sm' /> 
        {
          validation.product_type.error ? 
            (<Feedback severity={'danger'} message={validation.product_type.feedback} />) : ''
        }
        {product.pro_codigo > 0 ? 
        <Group className='m-fdc g4 spread-evenly focus'>
          <ProductTypeCardList 
            defaultValue={props.values.tipo_produto}
            disabled={props.values.edit} 
            productTypeList={productTypeList}
            onChange={handleChangeProductType}
          />
        </Group> : '' }
        {
          validation.product_embarque.error ? 
          (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
        }
        <Vspace className='xxlg' />
        <Group className='spread fluid g3'>
          <Link 
            className='wac-button m-full outlined' 
            to='/inbox'>
            {TEXT_BACK_TO_INBOX}
          </Link>
          <Button onClick={next} className='wac-button m-full'>
            {TEXT_NEXT}
          </Button>
        </Group>
      </Box>
      <Modal className={`sm ${ branchData ? 'active' : ''}`}>
        <BranchData onClick={handleBranchData} data={props.values.branch} />
      </Modal>
      <Modal className={` md ${ productPattern ? 'active' : ''}`}>
        <ProductPattern 
          data={props.values.itens_classificacao}
          onChange={(newData) => props.handleChange('itens_classificacao', newData)}
          onClick={handleProductPattern}
          loading={loadingClassificationItems}
        />
      </Modal>
    </>
  );
}

export default GeneralInformation;
