import React from 'react';
import './AppSelectModal.scss';
// @ts-ignore
import autobind from 'class-autobind';
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {userAccessService} from "../../core/user-access/UserAccessService";
import * as _ from "lodash";
import {I18nTranslator} from "../../core/i18n/I18nTranslator";

interface State {
    items: any[]
}

interface Props {
    label: string,
    type?: string,
    data?: any,
    value: any,
    places: any,
    onChange: (value: any) => void
}

class AppSelectModal extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            items: []
        };

        autobind(this);
    }

    componentDidMount(): void {
        this.updateItems();
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        this.updateItems();
    }

    filterPlacesByEmpresa(place: any) {
        return !this.hasValue(this.props.data.empresa) || place.empresa === this.props.data.empresa || this.props.data.empresa === "TODAS";
    }

    filterPlacesByMatriz(place: any) {
        return !this.hasValue(this.props.data.matriz) || place.mat_cnpj === this.props.data.matriz;
    }

    filterPlacesByRegional(place: any) {
        return !this.hasValue(this.props.data.regional) || place.ent_cnpj === this.props.data.regional;
    }

    filterPlacesByFilial(place: any) {
        return !this.hasValue(this.props.data.filial) || place.cli_cnpj === this.props.data.filial;
    }

    updateItems() {
        let items = [
            this.optionNull()
        ];

        if (this.props.type === "empresa") {
            items = [];
            
            const accessibleCompanies = userAccessService.getUser().empresas.split(',');
            const companies = ["GENESLAB", "CLASSNORTE", "GR"];

            if (accessibleCompanies.length > 1) {
                items.push({ id: "TODAS", text: I18nTranslator.t("ALL").toUpperCase() });
            }

            companies.forEach((companyName: any) => {
                if (accessibleCompanies.includes(companyName)) {
                    items.push({
                        id: companyName,
                        text: companyName
                    });
                }
            });

        } else if (this.props.type === "matriz") {
            /* Filtro original */
            // userAccessService.getData().forEach((i: any) => {
            //     items.push({
            //         id: i.mat_cnpj,
            //         text: i.mat_nome
            //     });
            // });

            _.chain(this.props.places)
                .filter(this.filterPlacesByEmpresa)
                .map((place: any) => {
                    return {
                        id: place.mat_cnpj,
                        text: place.mat_nome
                    };
                })
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();

        } else if (this.props.type === "regional") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .map((place: any) => {
                    return {
                        id: place.ent_cnpj,
                        text: place.ent_nome
                    };
                })
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "filial") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .filter(this.filterPlacesByRegional)
                .map((place: any) => {
                    return {
                        id: place.cli_cnpj,
                        text: place.cli_nome
                    };
                })
                // @ts-ignore
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "local_embarque") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .filter(this.filterPlacesByRegional)
                .filter(this.filterPlacesByFilial)
                .map((place: any) => {
                    return {
                        id: place.loc_codigo,
                        text: `${place.localemb} (${place.uf})`
                    };
                })
                // @ts-ignore
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "uf") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .filter(this.filterPlacesByRegional)
                .filter(this.filterPlacesByFilial)
                .map((place: any) => {
                    return {
                        id: place.uf,
                        text: place.uf
                    };
                })
                // @ts-ignore
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "cidade_embarque") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .filter(this.filterPlacesByRegional)
                .filter(this.filterPlacesByFilial)
                .map((place: any) => {
                    return {
                        id: place.cidCodigo,
                        text: place.cidade
                    };
                })
                // @ts-ignore
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "produto") {
            _.chain(this.props.places)
                .filter(this.filterPlacesByMatriz)
                .filter(this.filterPlacesByRegional)
                .filter(this.filterPlacesByFilial)
                .map((place: any) => {
                    return {
                        id: place.pro_nome,
                        text: place.pro_nome
                    };
                })
                // @ts-ignore
                .uniqBy('id')
                .sortBy('text')
                .forEach((i: any) => items.push(i))
                .value();
        } else if (this.props.type === "teste_aflatoxina") {
            [
                {
                    id: "S",
                    text: "Sim"
                },
                {
                    id: "N",
                    text: "Não"
                },
            ].forEach((i: any) => items.push(i));

        }

        this.setState({
            ...this.state,
            items
        });
    }

    hasValue(v: any) {
        return v && v !== "-";
    }

    optionNull() {
        return {
            id: "",
            text: ""
        };
    }

    handleChange(event: any) {
        this.props.onChange(event.target.value);
    }

    renderOption(item: any) {
        return (
            <option value={item.id} key={item.id}>{item.text}</option>
        );
    }

    render(): any {
        return (
            <div className="app-select-modal" style={{
                marginTop: this.props.type === "teste_aflatoxina" ? "16px" : undefined
            }}>
                <FormControl>
                    <InputLabel htmlFor={"app-select-modal-" + this.props.type}>
                        {this.props.label}
                    </InputLabel>
                    <Select
                        native
                        value={this.props.value}
                        onChange={this.handleChange}
                        inputProps={{
                            name: "app_select_modal_" + this.props.type,
                            id: "app-select-modal-" + this.props.type,
                        }}
                    >
                        {this.state.items.map(this.renderOption)}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default AppSelectModal;
