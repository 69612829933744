import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Box from 'components/commons/Box'
import Button from 'components/commons/Button'
import { Title } from 'components/commons/Typography'
import Vspace from 'components/commons/Vspace'
import React from 'react'
import ProducerRegister from './ProducerRegister'
import SearchProducer from './SearchProducer';
import { I18nTranslator } from 'core/i18n/I18nTranslator';

const TEXT_LINK_NEW_PRODUCER = I18nTranslator.t("LINK_NEW_PRODUCER");
const TEXT_SEARCH_PRODUCER = I18nTranslator.t("SEARCH_PRODUCER"); // Procurar produtor
const TEXT_REGISTER_PRODUCER = I18nTranslator.t("REGISTER_PRODUCER"); // Cadastrar produtor

export default function ProducerLink({ onClick, onCreateNewProducer, locCodigo, list = [], loading }) {
    return (
        <Box>
            <Button onClick={onClick} className="button-close" />
            <Title className='s12'>
                {TEXT_LINK_NEW_PRODUCER} {locCodigo ? `(${locCodigo})` : ''}
            </Title>
            <Vspace className='lg'/>
            <Tabs className={`wac-tabs-featured mv`}>
                <TabList>
                    <Tab>
                        <Button className='f1 ns'>
                            {TEXT_SEARCH_PRODUCER}
                        </Button>
                    </Tab>
                    <Tab>
                        <Button className='f1 ns'>
                            {TEXT_REGISTER_PRODUCER}
                        </Button>
                    </Tab>
                </TabList>
                <TabPanel>
                    <Vspace className='hom' />
                    <SearchProducer 
                        list={list} 
                        loading={loading}
                        onClick={onClick}
                        locCodigo={locCodigo}
                        onLink={onCreateNewProducer}
                    />
                </TabPanel>
                <TabPanel>
                    <Vspace className='hom' />
                    <ProducerRegister 
                        onClick={onClick} 
                        onCreateNewProducer={onCreateNewProducer} 
                        locCodigo={locCodigo} 
                    />
                </TabPanel>
            </Tabs>
            
        </Box>
    )
}
