import React from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import {Text, Title} from 'components/commons/Typography';
import Checkbox from 'components/commons/Checkbox';
import {Col, Row} from 'components/commons/FlexBoxGrid';

const ProductPattern = ({ data = [], loading, onClick, onChange }) => {
  const handleChangeCheckBox = (e, index) => {
    let copyData = data;
    copyData[index].item_obrigatorio = e.target.checked;
    onChange(copyData);
  }
  const handleChangeInput = (e, index) => {
    let copyData = data;
    copyData[index].item_padrao = e.target.value;
    onChange(copyData);
  }

  return (
    <Box>
      <Button onClick={onClick} className="button-close" />
      <Title className='s12'>
        Itens de Classificação
      </Title>
      <Vspace className='lg'/>
      <Row className={`bottom-xs ${loading ? 'loading' : ''}`}>
        { 
          data.length ? (
            data.map((row, index) => 
              <Col key={row.item_codigo} className='col-xs-3 col-lg-3 col-md-6'>
                <Checkbox
                  id={`item_class_${row.item_codigo}`}
                  type={`has-bg`}
                  name={`item_class[]`}
                  onChange={(e) => handleChangeCheckBox(e, index)}
                  label={<Text className='s14'>{row.item_nome}</Text>}
                  defaultChecked={row.item_obrigatorio || row.itemo_obrigatorio || parseFloat(row.item_padrao) || parseFloat(row.itemo_padrao)} 
                />
                <Input 
                  type="number" 
                  defaultValue={row.item_padrao || row.itemo_padrao || 0} 
                  onChange={(e) => handleChangeInput(e, index)} 
                />
              </Col>
            )
          ) : (!loading ? (<Col className='col-md-12'><Text>{'Nada encontrado'}</Text></Col>) : '')
        }
      </Row>
      <Vspace className='xxlg'/>
      <hr />
      <Group className='spread fluid'>
        <Button className="outlined" onClick={onClick}>
          Cancelar
        </Button>
        <Button onClick={onClick}>
          Confirmar
        </Button>
      </Group>
    </Box>
  )
}

export default ProductPattern