import React from "react";

const Input = ({ className, children, placeholder, id, label, feedback, severity, type = 'text', ...props }) => {
  return (
    <div className={`control ${className}`}>
      <label htmlFor={id} className='wac-label' >
        {label}
      </label>
      <input 
        id={id}
        className={`wac-input ${severity || ''}`}
        type={type} 
        placeholder={placeholder}
        {...props}
      />
      {children}
      <span className='wac-input-feedback'>
        {severity !== 'success' && feedback}
      </span>
    </div>
  )
};

export default Input;