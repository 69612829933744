/* eslint-disable */
import React, { useState, useEffect } from "react";
import Group from "components/commons/Group";
import Button from "components/commons/Button";
import Icon from "components/commons/Icon";
import { Link } from "react-router-dom";
import { Paragraph, Text, Title } from "components/commons/Typography";
import { Col, Row } from "components/commons/FlexBoxGrid";
import Vspace from "components/commons/Vspace";
import { SampleIcon, InvoiceIcon } from "components/Icons";
import Box from "components/commons/Box";
import Form from "./Form";
import ProtocolTest from "./Testes/AnaliseTestes";
import Aflatoxina from "./Aflatoxin/index";
import { I18nTranslator } from "core/i18n/I18nTranslator";
import Table from "components/commons/Table";
import { ToggleCard } from "components/commons/Radio";
import color from "@material-ui/core/colors/amber";

const TEXT_SERVICE_LOCATION_TYPE = I18nTranslator.t("SERVICE_LOCATION_TYPE");

const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND"); // Nada encontrado
const TEXT_VIEW_LINKED_PRODUCERS = I18nTranslator.t("VIEW_LINKED_PRODUCERS"); // ver produtores vinculados
const TEXT_EDIT_BOARDING_LOCATION = I18nTranslator.t("EDIT_BOARDING_LOCATION"); // editar local de embarque
const TEXT_SEARCH_RESULT = I18nTranslator.t("SERCH_RESULT"); // Resultado da busca
const TEXT_BOARD_LOCATION = I18nTranslator.t("BOARD_LOCATION"); // Local de Embarque
const TEXT_CITY = I18nTranslator.t("CITY"); // Cidade
const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição Estadual
const TEXT_REGISTRATION = I18nTranslator.t("REGISTRATION"); // Matrícula
const TEXT_REGISTER_NEW_LOCATION = I18nTranslator.t("REGISTER_NEW_LOCATION"); // Cadastrar novo local
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM"); // Confirmar

function AdditionalServices({
  nextStep,
  prevStep,
  handleChange = () => {},
  values,
}) {
  const [additionalService, setAdditionalService] = useState(
    values.additional_service || null
  );
  const [selectedService, setSelectedService] = useState(null);
  const [botaoCheck, setBotaoCheck] = useState(false);
  const [buttonClass, setButtonClass] = useState("");
  const [botaoCheckNota, setBotaoCheckNota] = useState(false);

  const handleAdditionalService = (value) => {
    handleChange("additional_service", value);
    setAdditionalService(value);
    if (!value) setTimeout(() => nextStep(), 200);
  };

  const next = () => {
    nextStep();
  };

  function handleChecked(checked) {
    handleChange("additional_service", checked);
    setcheckedRow(checked);
  }

  const mudarCorBotaoCSS = (twste) => {
    twste === true
      ? setButtonClass("primary tem-analise")
      : setButtonClass("");
  };

  const mudarCorBotaoCSSNota = (botaoCheckNota) => {
    botaoCheckNota === true
      ? setButtonClass("primary tem-analise")
      : setButtonClass("");
  };

  useEffect(() => {
    mudarCorBotaoCSS(botaoCheck);
  }, [botaoCheck]);

  useEffect(() => {
    mudarCorBotaoCSSNota(botaoCheckNota);
  }, [botaoCheckNota]);

  

  let auxiliar = ''
  if(values.protocolo_selecionado === undefined){
    values.protocolo_selecionado = []
  }
    let quantidade = values.protocolo_selecionado.filter(word => word.spa_ativo == "S" || word.spa_ativo == "").length
     auxiliar = quantidade > 0 ? "primary tem-analise" : ''
  
  return selectedService ? (
    <React.Fragment>{selectedService}</React.Fragment>
  ) : (
    <>
      <Vspace />
      <Row className="start-xs">
        <Col>
          <Title>
            {values.edit && values.slo_codigo
              ? `Editar OS ${values.slo_codigo}`
              : I18nTranslator.t("REGISTER_SERVICE_ORDER")}
          </Title>
        </Col>
      </Row>
      <Vspace className="hom" />
      <Box className="ghost">
        <Group className="vc fluid g3">
          <Text>
            {I18nTranslator.t("ADDITIONAL_SERVICES")}:{" "}
            {I18nTranslator.t("WOULD_YOU_LIKE_TO_HIRE_ADDITIONAL_SERVICES")}
          </Text>
          <Group className="g1">
            <Link
              onClick={() => handleAdditionalService(true)}
              className={`wac-button small ${
                additionalService === true
                  ? "primary"
                  : additionalService === false
                  ? "outlined"
                  : ""
              }`}
            >
              {I18nTranslator.t("YES")}
            </Link>
            <Link
              onClick={() => handleAdditionalService(false)}
              className={`wac-button small ${
                additionalService === true
                  ? "outlined"
                  : additionalService === false
                  ? "primary"
                  : ""
              }`}
            >
              {I18nTranslator.t("NO")}
            </Link>
          </Group>
        </Group>
      </Box>
      <Box className="ghost">
        <Group className="mhs g3">
          {values &&
          values.produto &&
          values.produto.pro_aflatoxina === "S" &&
          Object.keys(values.branch).length ? (
            <Button
              id="botaoDosTeste"
              type="button"
              onClickChecked={handleChecked}
              className={`wac-button square ${buttonClass} ${auxiliar}`}
              disabled={!additionalService}
              onClick={() =>
                setSelectedService(
                  <ProtocolTest
                    prevStep={() => setSelectedService(null)}
                    nextStep={() => setSelectedService(null)}
                    values={values}
                    handleChange={handleChange}
                    setBotaoCheck={mudarCorBotaoCSS(values)}
                  />
                )
              }
              to="/ticket-register/additional-services/aflactoxina-test"
            >
              <Icon size="lg" component={<SampleIcon />} />
              <Vspace />
              <Paragraph>{I18nTranslator.t("TEST_ANALYSIS")}</Paragraph>
            </Button>
          ) : null}
          <Button
            disabled={!additionalService}
            className={`wac-button square ${
              additionalService === true
                ? "primary"
                : additionalService === false
                ? "outlined"
                : ""
            }`}
            onClick={() =>
              setSelectedService(
                <Form
                  prevStep={() => setSelectedService(null)}
                  nextStep={() => setSelectedService(null)}
                  values={values}
                  handleChange={handleChange}
                />
              )
            }
          >
            <Icon size="lg" component={<InvoiceIcon />} />
            <Vspace />
            <Paragraph>{I18nTranslator.t("EXCHANGE_OF_INVOICE")}</Paragraph>
          </Button>
        </Group>
        <Vspace className="lg" />
        <Group className="spread fluid g3">
          <Button onClick={prevStep} className="wac-button m-full outlined">
            {I18nTranslator.t("BACK")}
          </Button>
          {additionalService ? (
            <Button onClick={next} className="wac-button m-full" >
              {I18nTranslator.t("NEXT")}
            </Button>
          ) : (
            ""
          )}
        </Group>
      </Box>
    </>
  );
}

export default AdditionalServices;
