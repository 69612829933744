/* eslint-disable */
import React, { useEffect, useState } from "react";
import Vspace from "components/commons/Vspace";
import { Steps, Step } from "components/commons/Steps";
import GeneralInformation from "./GeneralInformation";
import AdditionalServices from "./AdditionalServices";
import LotAndDestination from "./LotAndDestination";
import Resume from "./Resume";
import Conclusion from "./Conclusion";
import { userAccessService } from "core/user-access/UserAccessService";
import { I18nTranslator } from "core/i18n/I18nTranslator";
import DateUtil from "core/utils/DateUtil";
import { OSService } from "core/os/OSService";

const ActualStep = ({ step, nextStep, prevStep, fields = {}, handleChange}) => {
  switch (step) {
    case 1:
      return (
        <GeneralInformation 
          nextStep={nextStep}
          handleChange={handleChange}
          values={fields}
        />
      )
    case 2:
      return (
        <AdditionalServices 
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={fields}
        />
      )
    case 3:
      return (
        <LotAndDestination 
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={fields}
        />
      )

    case 4: 
      return (
        <Resume 
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={fields}
        />
      )
    case 5:
      return (
        <Conclusion values={fields} />
      )
  }
}



function TicketRegister(props) {
  const user = userAccessService.getUser() || {};

  const defaultFields = {
    "edit": false,
    "slo_codigo": 0,
    "mat_nome": user.mat_nome,
    "regional": {},
    "branch": {},
    "service": {},
    "transport": {},
    "tipo_local_servico_index": 3,
    "terminal": {},
    "moegao": {},
    "porto": {},
    "supervisao": {},
    "armazem": {},
    "tipo_embarque": {},
    "produto": {},
    "tipo_produto": {},
    "itens_classificacao": [],
    "lista_embarque": [],
    "lista_embarque_produtores": [],
    "linha_embarque_selecionada": {},
    "linha_embarque_ativa": {},
    "linha_embarque_check": false,
    "filter": false,
    "additional_service": null,
    "cpt_codigo": 0,
    "spa_forn_fita_afla": "",
    "transportadoras": [],
    "tipo_mercado": "",
    "data_solicitacao": DateUtil.getToday(),
    "data_retirada": DateUtil.getToday(),
    "qtd_caminhoes": 0,
    "tamanho_lote": 0,
    "lote_estimado": "",
    "contrato_compra": "",
    "nome_contato": "",
    "telefone_contato": "",
    "destinos": [],
    "teste": [],
    "slo_cif_fob": "",
    "slo_qtd_outros_testes": 0,
    "slo_imprime_arquivos": "",
    "lista_destino": [],
    "lista_destino_selecionada": [],
    "lista_destino_ativa": {},
    "lista_destino_check": false,
    "filterDestino": false,
    "tipo_local": ''
  }

  const TEXT_GENERAL_INFORMATION = I18nTranslator.t("GENERAL_INFORMATION"); // Informações gerais
  const TEXT_ADDITIONAL_SERVICES = I18nTranslator.t("ADDITIONAL_SERVICES"); // Serviços adicionais
  const TEXT_LOT_AND_DESTINY = I18nTranslator.t("LOT_AND_DESTINY"); // Lote e destino

  const params = new URLSearchParams(window.location.search);
  const slo_codigo = params.get('os');
  const [loading, setLoading] = useState(slo_codigo);
  const [step, setStep] = useState(1);
  const [fields, setFields] = useState(defaultFields);

  const TEXT_ENTRY = I18nTranslator.t("ENTRY");
  const TEXT_EXIT = I18nTranslator.t("EXIT");

  useEffect(() => {
    userAccessService.checkPermissionPage("addOS")
    if (slo_codigo) {
    userAccessService.checkPermissionPage("editOS")
      OSService.findOs(slo_codigo)
      .then(response => {
        if (!response.success) {
          switch(response.error) {
            case "permission_error":
              document.location.href = "/permission-error"
              break;
            default:
              document.location.href = "/not-found"
          }
          return false;
        }

        let dataFields = { ...defaultFields };
        dataFields.edit = true;
        dataFields.slo_codigo = response.os.slo_codigo;
        dataFields.mat_nome = response.os.filial.matriz.mat_nome;
        dataFields.branch = response.os.filial;
        dataFields.regional = response.os.filial.regional;
        dataFields.service = response.os.servico;
        dataFields.transport = response.os.transporte;
        dataFields.tipo_embarque = response.os.slo_tipomoega === "ENTRADA" ? { label: TEXT_ENTRY, value: 'ENTRADA' } : { label: TEXT_EXIT, value: 'SAIDA' };
        dataFields.supervisao = response.os.base || {};
        if (response.os.tle_codigo == 1) {
          dataFields.tipo_local_servico_index = 0;
          dataFields.terminal = response.os.transbordo;
        }
        else if (response.os.tle_codigo == 7) {
          dataFields.tipo_local_servico_index = 1;
          dataFields.moegao = response.os.transbordo;
        }
        else if (response.os.tle_codigo == 2) {
          dataFields.tipo_local_servico_index = 2;
          dataFields.porto = response.os.transbordo;
        }
        else {
          dataFields.tipo_embarque = {};
          dataFields.linha_embarque_selecionada = response.os.local_embarque ? response.os.local_embarque : '';
          dataFields.lista_embarque = response.os.local_embarque ? [response.os.local_embarque] : [];
          dataFields.linha_embarque_check = response.os.local_embarque !== null;
          dataFields.filter = response.os.local_embarque !== null;
          dataFields.supervisao = {};
        }

        dataFields.produto = response.os.produto;
        dataFields.itens_classificacao = response.os.produto.itens_classificacao;
        dataFields.tipo_produto = response.os.tipo_produto_grupo;
        dataFields.additional_service = false;

        let protocolos = response.os.protocolo_analise;
        if (Array.isArray(protocolos)) {
          dataFields.additional_service = true;
        }
        dataFields.teste= protocolos;
        dataFields.protocolo_selecionado = protocolos;

        if (response.os.transportadoras) {
          dataFields.transportadoras = response.os.transportadoras;
        }

        dataFields.tipo_mercado = response.os.slo_mercadoexterno === 'S' ? 'Externo' : 'Interno';
        dataFields.data_solicitacao = response.os.slo_dtsolicitacao;
        dataFields.data_retirada = response.os.slo_dtretirada;
        dataFields.qtd_caminhoes = response.os.slo_qtd_caminhoes_dia;
        dataFields.tamanho_lote = response.os.slo_lote;
        dataFields.lote_estimado = response.os.slo_tamanhoestimado === 'S' ? 'Sim' : 'Não';
        dataFields.contrato_compra = response.os.slo_contratocompra;
        dataFields.nome_contato = response.os.slo_contato;
        dataFields.telefone_contato = response.os.slo_fone;
        let destinos = response.os.destinos;
        if (Array.isArray(destinos)) {
          for (let i = 0; i < destinos.length; i++) {
            destinos[i].uf_selected = destinos[i].cidade.estado;
            destinos[i].filter_city_list = [];
          }
        }
        dataFields.destinos = destinos;
        dataFields.slo_cif_fob = response.os.slo_cif_fob;
        dataFields.slo_qtd_outros_testes = response.os.slo_qtd_outros_testes;
        dataFields.slo_realiza_teste_analise = response.os.slo_realiza_teste_analise;
        dataFields.slo_imprime_arquivos = response.os.slo_imprime_arquivos;

  
        dataFields.lista_destino_selecionada = response.os.lista_destino_selecionada;

        
        // NOVO MODELO DESTINO
        let destSelecionado = response.os.lista_destino_selecionada;
          if(Array.isArray(destSelecionado)){
            for (let i = 0; i < destSelecionado.length; i++) {
              destSelecionado[i].cidNome = destSelecionado[i].cidade.cid_nome;
              destSelecionado[i].estSigla = destSelecionado[i].cidade.estado.est_sigla;
              destSelecionado[i].dest_nome = destSelecionado[i].slo_cidade;
              destSelecionado[i].dest_cpfcnpj = destSelecionado[i].dest_cpfcnpj;
              destSelecionado[i].dest_codigo = destSelecionado[i].dest_codigo;
              destSelecionado[i].dest_insc_estadual = destSelecionado[i].dest_insc_estadual;
              destSelecionado[i].dest_cpfcnpj = destSelecionado[i].dest_cpfcnpj;

            } 
          }
          dataFields.lista_destino_selecionada = destSelecionado;

        setFields(dataFields);
        console.log(response)
      })
      .catch(error => console.error(error))
      .finally(() => setTimeout(() => setLoading(null), 500))
    }
  }, []);

  // Scroll to the top of the page.
  const scrollToTop = () => {
    document.getElementById("main-content").scrollTop = 0;
  }

  // Proceed to next step.
  const nextStep = () => {
    setStep(step + 1);
    scrollToTop();
  }

  // Go back to prev step.
  const prevStep = () => {
    setStep(step - 1);
    scrollToTop();
  }

  // Handle fields change.
  const handleChangeField = (input, value) => {
    let fieldsCopy = fields;
    fieldsCopy[input] = value;
    setFields(fieldsCopy);
  }

  return !loading ? (
    <React.Fragment>
      <Vspace />
      {
        step !== 5 ?
        (<Steps>
            <Step
              counter='1'
              className={step === 1 && 'active'}
              link='/ticket-register'>
              {TEXT_GENERAL_INFORMATION}
            </Step>
            <Step
              counter='2'
              className={step === 2 && 'active'}
              link='/ticket-register'>
              {TEXT_ADDITIONAL_SERVICES}
            </Step>
            <Step
              counter='3'
              className={step >= 3 && 'active'}
              link='/ticket-register'>
              {TEXT_LOT_AND_DESTINY}
            </Step>
          </Steps>) : null
      }
      <ActualStep 
        step={step} 
        fields={fields} 
        nextStep={nextStep} 
        prevStep={prevStep} 
        handleChange={handleChangeField} 
      />
    </React.Fragment>
  ) : <div className="loading" />
}

export default TicketRegister;
