import React, {useEffect, useState} from 'react';
import {Tabs,TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import AdvancedFilter from '../TypeServiceLocationDestino/components/AdvancedFilter';
import {Text, Title} from 'components/commons/Typography';
import FilterList from './components/FilterList';
import TextUtil from 'core/utils/TextUtil';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import { Destino } from "../../../../../core/destino/destino";
import Button from 'components/commons/Button';

function TypeServiceLocationDestino({className, values, handleChange})  {

   
    const TEXT_BOARDING = I18nTranslator.t("BOARDING"); // Embarque.

    const [advancedFilter, setAdvancedFilter] = useState(true)
    const [checkedRow, setcheckedRow] = useState(values.lista_destino_check || false);
    const [destinoList, setDestinoList] = useState([]);
    const [boardList, setBoardList] = useState(values.lista_destino || []);
    const [loadingBoardList, setLoadingBoardList] = useState(false);
    const [loadingDestinoList, setLoadingDestinoList] = useState(false);
    const [filteredBoardList, setFilteredBoardList] = useState(values.lista_destino || []);
    const [defaultSearch, setDefaultSearch] = useState('');
    const [filter, setFilter] = useState(values.filterDestino || false);

    const formBoardLocationIdd = "form_board_location";

    useEffect(() => {
        setTimeout(() => getDestinoList(), 700);
    }, []);

    function getDestinoList (parameters = {}) {
        setLoadingDestinoList(true);
    
        Destino.getDestinoList(parameters)
        .then((response) => {
            if (Array.isArray(response)) { 
            setDestinoList(response);
            }
            })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoadingDestinoList(false))
      }

    function handleAdvancedFilter() {
        setAdvancedFilter(advancedFilter === false)
    }

    function handleChecked(checked) {
        handleChange('lista_destino_check', checked);
        setcheckedRow(checked)
    }

    function handleSubmitBoardLocation(e) {

        let formElements = document.getElementById(formBoardLocationIdd).elements;
        let buttonFilter = document.getElementById(`${formBoardLocationIdd}_button`);
        let likeFilterElements = ["dest_nome_like", "dest_cpfcnpj"];
        let filterParameters = {};

        for (let i = 0; i < formElements.length; i++) {
            let item = formElements.item(i);
            if (item.name === "") continue;
            if (item.name === "dest_cpfcnpj_like") item.value = TextUtil.removeFormat(item.value);
            filterParameters[item.name] = (item.value !== '' && likeFilterElements.indexOf(item.name) > -1) ? `%${item.value}%` : item.value;
        }

        buttonFilter.disabled = true;
        setLoadingBoardList(true);

        Destino.filter(filterParameters)
            .then((response) => {
                if (Array.isArray(response)) {
                    handleChange('lista_destino', response);
                    setDestinoList(response);
                    setFilteredBoardList(response)
                }

            })
            .catch(() => {
            })
            .finally(() => {
                buttonFilter.disabled = false;
                setLoadingBoardList(false);
            });
    }

    function handleSearch(value) {
        try {
            
            if (!value.length) setFilteredBoardList(boardList);

            let filterData = boardList.filter(
                (row) => JSON.stringify(row).toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBoardList(filterData);
        } catch (error) {
            
        }
    }

    return (
        <div className={className} style={{ marginTop: `-30px` }}>
            <Tabs className={`wac-tabs-featured mv ${checkedRow ? 'item-checked-04' : ''}`}>
                <TabPanel>
                    <Group className='fluid g5'>
                        <Title className='s11 fw500' style={{ paddingTop: `20px` }}>
                            Selecione um ou mais Destinos
                        </Title>
                        <Vspace className='hom'/>
                    </Group>
                    <Vspace/>
                    <AdvancedFilter
                        id={formBoardLocationIdd}
                        advancedFilter={advancedFilter}
                        onClick={handleAdvancedFilter}
                        onSubmit={handleSubmitBoardLocation}
                        onFilter={() => {
                            setFilter(true);
                            handleChange('filter', true)
                        }}
                    />
                    <Vspace/>
                        {
                             filter || values.lista_destino_selecionada >= [1] ?
                                (
                                    <FilterList
                                        data={filteredBoardList}
                                        values={values}
                                        handleChange={handleChange}
                                        onClickChecked={handleChecked}
                                        showPaginator={!checkedRow}
                                        loading={loadingBoardList}
                                        onCreateNewBoardLoaction={(objectBoardLocation) => {
                                            // Oculta o filtro avançado.
                                            setAdvancedFilter(false);
                                            // Alinha o scroll da página até a tabela.
                                            document.getElementById(formBoardLocationIdd).scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'center'
                                            });
                                            // Adiciona o novo local de embarque à lista.
                                            let copyFilteredBoardList = filteredBoardList;
                                            copyFilteredBoardList.push(objectBoardLocation.lista_destino);
                                            setFilteredBoardList(copyFilteredBoardList);
                                            // Filtra pelo ID do local novo.
                                            setDefaultSearch(objectBoardLocation.lista_destino.dest_codigo);
                                            handleSearch(parseInt(objectBoardLocation.lista_destino.dest_codigo).toString());
                                        }}
                                    />
                                ): null
                        }
                    
                </TabPanel>
                <Vspace className='sm hod'/>
            </Tabs>
        </div>
    );
}

export default TypeServiceLocationDestino;
