import React, {useEffect, useState} from 'react';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Group from 'components/commons/Group';
import Input from 'components/commons/Input';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import TextUtil from 'core/utils/TextUtil';
import {UFService} from 'core/uf/UFService';
import {CityService} from 'core/city/CityService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import {I18nTranslator} from 'core/i18n/I18nTranslator';

// Traduções.
const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
const TEXT_CITY = I18nTranslator.t('CITY');
const TEXT_SELECT = I18nTranslator.t('SELECT');
const TEXT_SELECT_STATE = I18nTranslator.t('SELECT_STATE');
const TEXT_DESTINO_LOCATION = I18nTranslator.t("BOARD_DESTINO");
const TEXT_BOARD_DESTINO_NAME = I18nTranslator.t("BOARD_DESTINO_NAME"); // Nome do Local de Embarque.
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_CLEAR_FIELDS = I18nTranslator.t("CLEAR_FIELDS"); // Limpar campos
const TEXT_FILTRATE = I18nTranslator.t("FILTRATE"); // Filtrar
const TEXT_INSCRICAO = I18nTranslator.t("STATE_REGISTRATION");
const TEXT_INSCRICAO_NOME = I18nTranslator.t("STATE_REGISTRATION_NOME");





const AdvancedFilter = ({ id, advancedFilter, onSubmit, onFilter } ) => {
  const [cnpj, setCnpj] = useState('');
  // UF.
  const [ufList, setUfList] = useState([]);
  const [loadingUfList, setLoadingUfList] = useState(false);
  const [ufSelected, setUfSelected] = useState(null);

  // Cidade.
  const [cityList, setCityList] = useState([]);
  const [loadingCityList, setLoadingCityList] = useState(false);
  const [filterCityList, setFilterCityList] = useState([]);
  const [citySelected, setCitySelected] = useState(null);

  useEffect(() => {
    setTimeout(() => getUfList(), 1000);
    setTimeout(() => getCityList(), 2000);
  }, []);

  // Busca lista de estados (UF).
  const getUfList = () => {
    setLoadingUfList(true);
  
    UFService.getUfList()
    .then((response) => {
    if (Array.isArray(response)) {
      setUfList(response);
    }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingUfList(false));
  };

  // Busca lista de cidades.
  const getCityList = () => {
    setLoadingCityList(true);

    CityService.getCityList()
    .then((response) => {
      if (Array.isArray(response)) {
        setCityList(response);
        setFilterCityList(response);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingCityList(false))
  }

  // Manipula o evento de mudança no campo UF
  // Filtra as Cidades apartir do Estado (UF) selecionado.
  const handleChangeUf = (e, newUf) => {
    let uf = newUf ? newUf.est_sigla : '';
    
    if (ufSelected && ufSelected.est_sigla !== uf) {
      setCitySelected(null);
    }
    
    setUfSelected(newUf);

    if (uf === "") {
      setFilterCityList(cityList);
      return;
    }

    setUfSelected(newUf);
    
    if (cityList) {
      let filterCity = cityList.filter((city) => {
        return city.estado.est_sigla === uf;
      });
  
      setFilterCityList(filterCity);
    }
  }

  // Manipula o evento de mudança no campo Cidade.
  const handleChangeCity = (e, newValue) => {
    setCitySelected(newValue ? newValue : null);
  }

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      onFilter();
      onSubmit(e);
    } catch(error) {}
  }

  const onReset = () => {
    setCitySelected(null);
    setUfSelected(null);
  }

  return (
    <React.Fragment>
      <form id={id} onSubmit={handleSubmit}>
        <Box className={`collapsed ${ advancedFilter === true ? 'active' : ''}`}>
          <Row className="bottom-xs g4">
            <Col className="col-xs-4 col-lg-4 col-md-12">
              <Input
                id="local-embarque-01l"
                name="dest_nome_like"
                label={TEXT_DESTINO_LOCATION}
                placeholder={TEXT_BOARD_DESTINO_NAME}
              />
              <Vspace />
              <Input
                id="produtor-01"
                name="dest_insc_estadual_like"
                label={TEXT_INSCRICAO}
                placeholder={TEXT_INSCRICAO_NOME}
              />
            </Col>
            <Col className="col-xs-4 col-lg-4 col-md-12">
              <Row className='botton-xs'>
                <Col className="col-xs-3 col-lg-3 col-md-3">
                  <Autocomplete
                    id="estSigla_like"
                    options={ ufList }
                    loading={loadingUfList}
                    loadingText={TEXT_LOADING}
                    noOptionsText={TEXT_NO_OPTIONS}
                    onChange={handleChangeUf}
                    getOptionLabel={(option) => option.est_sigla}
                    disableAnimation={true}
                    renderInput={ (params) => 
                      <>
                        <TextField 
                          {...params}
                           autoComplete='off'
                          label="UF"
                          placeholder={"..."}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined" 
                        />
                        <input 
                          type="hidden" 
                          name="est_sigla"
                          value={ ufSelected ? ufSelected.est_sigla : '' }
                        />
                      </>
                    }
                  />
                </Col>
                <Col>
                  <Autocomplete
                    id="dest_cidade_like"
                    value={citySelected}
                    options={ ufSelected ? filterCityList : [] }
                    loading={loadingCityList}
                    loadingText={TEXT_LOADING}
                    noOptionsText={ ufSelected ? TEXT_NO_OPTIONS : TEXT_SELECT_STATE}
                    onChange={handleChangeCity}
                    getOptionLabel={(option) => option.cid_nome}
                    disableAnimation={true}
                    renderInput={ (params) => 
                      <>
                        <TextField 
                          {...params}
                           autoComplete='off'
                          label={TEXT_CITY}
                          placeholder={ufSelected ? TEXT_SELECT : TEXT_SELECT_STATE}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined" 
                        />
                        <input 
                          type="hidden" 
                          name="cid_codigo"
                          value={ citySelected ? citySelected.cid_codigo : '' }
                        />
                      </>
                    }
                  />
                </Col>
              </Row>
              <Vspace />
              <Input
                id="cnpj-number4"
                name="dest_cpfcnpj_like"
                value={cnpj}
                onInput={(e) => setCnpj(e.target.value)}
                onFocus={(e) => setCnpj(TextUtil.removeFormat(e.target.value))}
                onBlur={(e) => setCnpj(TextUtil.formatCnpj(e.target.value))}
                label={TEXT_EIN}
                placeholder="00.000.000/0000-00"
              />
            </Col>
            <Col className="col-xs-4 col-lg-4 col-md-12">
              <Group className="r g2 m-spread">
                <Button type="reset" className="outlined" onClick={onReset}>
                  {TEXT_CLEAR_FIELDS}
                </Button>
                <Button type="submit" id={`${id}_button`} onClick={handleSubmit}>
                  Pesquisar
                </Button>
              </Group>
            </Col>
          </Row>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default AdvancedFilter;
