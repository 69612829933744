import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";
import * as _ from "lodash";

export class OSService {


    static getOSAndamento(): Promise<any[]> {
        const url = configuration.BACKEND_BASE_URL + "JSON_CLICONNECT/" +
            (userAccessService.getEmpresa() === "TODAS" ? "getOSAndamentoUnificado.php" : "getOSAndamento.php");

        const body = {
            empresa: userAccessService.getEmpresa(),
            mat_cnpj: userAccessService.getUser().mat_cnpj,
            ent_cnpj: '-',
            cli_cnpj: (userAccessService.getUser().cli_cnpj || '-')
        };

        const newVar: any = {
            body: JSON.stringify(body),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, newVar)
                .then(async (response) => response.text())
                .then(async (response: string) => {
                    if (response && response.includes("Nenhuma O.S. encontrada")) {
                        resolve([])
                    } else if (!response || response.trim().includes("false") || response.trim() === "ERRO") {
                        reject({userInvalid: true});
                    } else {
                        const fator = 0.0001;
                        let ultimoItem: any = null;

                        const data = _.map(JSON.parse(response), (i) => {
                            i.qtdCargas = Number(i.qtdCargas);
                            i.volumeCargas = Number(i.volumeCargas);

                            // Afasta as marcações de pontos muito próximos (visível apenas com zoom)
                            if(ultimoItem && (Math.abs(i.longitude - ultimoItem.longitude) < fator) && (Math.abs(i.latitude - ultimoItem.latitude) < fator))
                                i.longitude = (parseFloat(i.longitude) - fator).toFixed(7);
                            
                            ultimoItem = i;

                            return i;
                        });
                        resolve(data);
                    }
                }).catch((error) => {
                console.log("error", error);
                reject({
                    requestError: true
                });

            });

        });
    }

    static getFila(slo_codigo: any, empresa: any) {
        const url = configuration.BACKEND_BASE_URL + "JSON_CLICONNECT/getFila.php";

        const body = {
            empresa: empresa,
            slo_codigo,
        };

        const newVar: any = {
            body: JSON.stringify(body),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        };

        return new Promise((resolve, reject) => {
            //
            // resolve([
            //     {
            //         fila_placa: "ASB-2222",
            //         fila_hora: "00:00",
            //         fila_motorista_nome: "Felippe",
            //         fila_transportadora: "Freire Transportadora"
            //     },
            //     {
            //         fila_placa: "ASB-3333",
            //         fila_hora: "11:00",
            //         fila_motorista_nome: "João",
            //         fila_transportadora: "Corcovado"
            //     },
            // ]);
            // return;

            fetch(url, newVar)
                .then(async (response) => response.text())
                .then(async (response: string) => {
                    if (response && response.includes("Fila nao encontrada")) {
                        resolve([]);
                    } else if (!response || response.trim().includes("false") || response.trim() === "ERRO") {
                        reject({userInvalid: true});
                    } else {
                        resolve(JSON.parse(response));
                    }
                }).catch((error) => {
                console.log("error", error);
                reject({
                    requestError: true
                });

            });

        });
    }

    static getCargas(slo_codigo: any, empresa: any) {
        const url = configuration.BACKEND_BASE_URL + "JSON_CLICONNECT/getCargas.php";

        const body = {
            empresa: empresa,
            slo_codigo
        };

        const newVar: any = {
            body: JSON.stringify(body),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, newVar)
                .then(async (response) => response.text())
                .then(async (response: string) => {
                    if (response && response.includes("Carga nao encontrada")) {
                        resolve([]);
                    } else if (!response || response.trim().includes("false") || response.trim() === "ERRO") {
                        reject({userInvalid: true});
                    } else {
                        const list = JSON.parse(response);

                        list.forEach((it: any) => {
                            it.itensClassificacaoArray = it.itens_classificacao
                                .split(";")
                                .filter((i: any) => i !== "")
                                .map((i: any) => {
                                    const [text, value] = i.substring(2).split("->");
                                    return {
                                        text: text !== undefined ? text.trim() : text,
                                        key: text !== undefined ? text.trim() : text,
                                        value: value !== undefined ? value.trim() : value,
                                    };
                                });
                        });

                        resolve(list);
                    }
                }).catch((error) => {
                    console.log("error", error);
                    reject({
                        requestError: true
                    });

                }
            );
        });
    }

    public static mapQualidadePonderada(place: any) {

        const topics = [
            {
                key: "Umidade",
                text: "Umidade",
                defaultValue: "0.00"
            },
            {
                key: "Materias_Estranhas_Impurezas",
                text: "Materias Estranhas/Impurezas",
                defaultValue: "0.00"
            },
            {
                key: "Quebrados",
                text: "Quebrados",
                defaultValue: "0.00"
            },
            {
                key: "Total_de_Avariados",
                text: "Total de Avariados",
                defaultValue: "0.00"
            },
            {
                key: "Aflatoxina",
                text: "Aflatoxina",
                defaultValue: "0.00"
            },
            {
                key: "Amarelos",
                text: "Amarelos",
                defaultValue: "0.00"
            },
            {
                key: "Ardidos_e_Queimados",
                text: "Ardidos e Queimados",
                defaultValue: "0.00"
            },
            {
                key: "Barriga_Branca",
                text: "Barriga Branca",
                defaultValue: "0.00"
            },
            {
                key: "Cor_GS9_1_2_3_8",
                text: "Cor_GS9 1 2 3 8",
                defaultValue: "0.00"
            },
            {
                key: "Danificados",
                text: "Danificados",
                defaultValue: "0.00"
            },
            {
                key: "Don_Quantativo",
                text: "Don Quantativo",
                defaultValue: "0.00"
            },
            {
                key: "Esverdeados",
                text: "Esverdeados",
                defaultValue: "0.00"
            },
            {
                key: "Fermentados",
                text: "Fermentados",
                defaultValue: "0.00"
            },
            {
                key: "FN",
                text: "FN",
                defaultValue: "0.00"
            },
            {
                key: "Fumo_Quantativo",
                text: "Fumo Quantativo",
                defaultValue: "0.00"
            },
            {
                key: "Gessados",
                text: "Gessados",
                defaultValue: "0.00"
            },
            {
                key: "Granulometria",
                text: "Granulometria",
                defaultValue: "0.00"
            },
            {
                key: "Linter",
                text: "Linter",
                defaultValue: "0.00"
            },
            {
                key: "Local",
                text: "Local",
                defaultValue: "0.00"
            },
            {
                key: "Manchados_e_picados",
                text: "Manchados e picados",
                defaultValue: "0.00"
            },
            {
                key: "Mofados",
                text: "Mofados",
                defaultValue: "0.00"
            },
            {
                key: "Numero_do_Lacre",
                text: "Numero do Lacre",
                defaultValue: "0.00"
            },
            {
                key: "Ocra_Quantativo",
                text: "Ocra Quantativo",
                defaultValue: "0.00"
            },
            {
                key: "PH",
                text: "PH",
                defaultValue: "0.00"
            },
            {
                key: "Picados",
                text: "Picados",
                defaultValue: "0.00"
            },
            {
                key: "Zea_Quantativo",
                text: "Zea Quantativo",
                defaultValue: "0.00"
            },
        ];

        return place.qualidadePonderada.map((qualidadePonderada: any) => {
            return _.chain(topics)
                .filter((topic: any) => qualidadePonderada[topic.key] !== topic.defaultValue)
                .cloneDeep()
                .map((topic: any) => {
                    topic.value = qualidadePonderada[topic.key];
                    return topic;
                })
                .value();
        });
    }

    static sendComentario(comentario: any) {
        const url = configuration.BACKEND_BASE_URL + "JSON_CLICONNECT/setComentarios.php";

        const newVar: any = {
            body: JSON.stringify(comentario),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, newVar)
                .then(() => {
                    resolve();
                }).catch((error) => {
                console.log("error", error);
                reject({
                    requestError: true
                });

            });

        });
    }

    static getOsList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/os?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

    static findOs(os: any) {
        const url = configuration.AUTH_BASE_URL + "cliconnect/find/os?os=" + os;

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => resolve(response))
            .catch(() => reject(false));
        });
    }

    
    static getServiceList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/servico?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

    static getClassificationItems(os: any) {
        if (!os) return null;
        
        const url = configuration.AUTH_BASE_URL + "cliconnect/classificacao_os?os=" + os;

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

    static create(data: object) {
        console.log(data)
        if (!data) return false;

        const url = configuration.AUTH_BASE_URL + "cliconnect/os";
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            },
            body: JSON.stringify(data)
        }

        return new Promise((resolve, reject) => {
            fetch(url, config)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

    static update(data: object) {
        if (!data) return false;

        const url = configuration.AUTH_BASE_URL + "cliconnect/os";
        const config = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            },
            body: JSON.stringify(data)
        }

        return new Promise((resolve, reject) => {
            fetch(url, config)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

}