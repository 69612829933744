import React, { useEffect, useState } from 'react';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Group from 'components/commons/Group';
import Input from 'components/commons/Input';
import DatePicker from 'components/commons/DatePicker';
import { Row, Col } from 'components/commons/FlexBoxGrid';
import { ClientService } from 'core/client/ClientService';
import { UFService } from 'core/uf/UFService';
import { CityService } from 'core/city/CityService';
import { OSService } from 'core/os/OSService';
import { ProductService } from 'core/product/ProductService';
import DateUtil from 'core/utils/DateUtil';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import { Text } from 'components/commons/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const AdvancedFilter = (props) => {
  // Props.
  let { filterActive } = props;

  // Regional.
  const [regionalList, setRegionalList] = useState([]);
  const [loadingRegionalList, setLoadingRegionalList] = useState(false);
  const [regionalSelected, setRegionalSelected] = useState(null);

  // Filial.
  const [filialList, setFilialList] = useState([]);
  const [loadingFilialList, setLoadingFilialList] = useState(false);
  const [filterFilialList, setFilterFilialList] = useState([]);
  const [filialSelected, setFilialSelected] = useState(null);

  // UF.
  const [ufList, setUfList] = useState([]);
  const [loadingUfList, setLoadingUfList] = useState(false);
  const [ufSelected, setUfSelected] = useState(null);

  // Cidade.
  const [cityList, setCityList] = useState([]);
  const [loadingCityList, setLoadingCityList] = useState(false);
  const [filterCityList, setFilterCityList] = useState([]);
  const [citySelected, setCitySelected] = useState(null);

  // Serviço.
  const [serviceList, setServiceList] = useState([]);
  const [loadingServiceList, setLoadingServiceList] = useState(false);
  const [serviceSelected, setServiceSelected] = useState(null);

  // Produto.
  const [productList, setProductList] = useState([]);
  const [loadingProductList, setLoadingProductList] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  
  useEffect(() => {
    setTimeout(() => getRegionalList(), 1000);
    setTimeout(() => getFilialList(), 2000);
    setTimeout(() => getUfList(), 3000);
    setTimeout(() => getCityList(), 4000);
    setTimeout(() => getServiceList(), 5000);
    setTimeout(() => getProductList(), 6000);
  }, []);

  // Traduções.
  const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
  const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
  const TEXT_SYSTEM_ORDER = I18nTranslator.t('SYSTEM_ORDER');
  const TEXT_FILL_FIELD = I18nTranslator.t('FILL_FIELD');
  const TEXT_CONTRACT = I18nTranslator.t('CONTRACT');
  const TEXT_REGIONAL_CUSTOMER = I18nTranslator.t('REGIONAL_CUSTOMER');
  const TEXT_BRANCH_CUSTOMER = I18nTranslator.t('BRANCH_CUSTOMER');
  const TEXT_CITY = I18nTranslator.t('CITY');
  const TEXT_SERVICE = I18nTranslator.t('SERVICE');
  const TEXT_PRODUCT = I18nTranslator.t('PRODUCT');
  const TEXT_END_DATE = I18nTranslator.t('END_DATE');
  const TEXT_SERVICE_LOCATION = I18nTranslator.t('SERVICE_LOCATION');
  const TEXT_CAR_PLATE = I18nTranslator.t('CAR_PLATE');
  const TEXT_DESTINY = I18nTranslator.t('DESTINY');
  const TEXT_CLEAR_FIELDS = I18nTranslator.t('CLEAR_FIELDS');
  const TEXT_FILTRATE = I18nTranslator.t('FILTRATE');
  const TEXT_REQUEST_PERIOD = I18nTranslator.t('REQUEST_PERIOD');
  const TEXT_SELECT = I18nTranslator.t('SELECT');
  const TEXT_SELECT_STATE = I18nTranslator.t('SELECT_STATE');

  // Busca lista de regionais.
  const getRegionalList = () => {
    setLoadingRegionalList(true);

    ClientService.getRegionalList()
    .then((response) => {
    if (Array.isArray(response)) {
      setRegionalList(response);
    }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingRegionalList(false));
  };

  // Busca lista de filiais.
  const getFilialList = () => {
    setLoadingFilialList(true);

    ClientService.getFilialList()
    .then((response) => {
      if (Array.isArray(response)) {
        setFilialList(response);
        setFilterFilialList(response);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingFilialList(false))
  }

  // Busca lista de estados (UF).
  const getUfList = () => {
    setLoadingUfList(true);
  
    UFService.getUfList()
    .then((response) => {
    if (Array.isArray(response)) {
      setUfList(response);
    }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingUfList(false));
  };

  // Busca lista de cidades.
  const getCityList = () => {
    setLoadingCityList(true);

    CityService.getCityList()
    .then((response) => {
      if (Array.isArray(response)) {
        setCityList(response);
        setFilterCityList(response);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingCityList(false))
  }

  // Busca lista de serviços.
  const getServiceList = () => {
    setLoadingServiceList(true);

    OSService.getServiceList()
    .then((response) => {
      if (Array.isArray(response)) {
        setServiceList(response);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingServiceList(false))
  };

  // Busca lista de produtos.
  const getProductList = () => {
    setLoadingProductList(true);

    ProductService.getProductList()
    .then((response) => {
    if (Array.isArray(response)) { 
      setProductList(response);
    }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => setLoadingProductList(false))
  };

  // Manipula o evento de mudança no campo Cliente Regional
  // Filtra os Clientes Filiais apartir do Cliente Regional selecionado.
  const handleChangeRegional = (e, newRegional) => {
    let cnpj = newRegional ? newRegional.ent_cpfcnpj : '';
    
    if (regionalSelected && cnpj !== regionalSelected.ent_cpfcnpj) {
      setFilialSelected(null);
    }

    setRegionalSelected(newRegional);
  
    if (cnpj === ""){
      setFilterFilialList(filialList);
      return;
    }

    if (filialList) {
      let filterFilial = filialList.filter((filial) => {
        return filial.regional.ent_cpfcnpj === cnpj;
      });
  
      setFilterFilialList(filterFilial);
    }
  }

  // Manipula o evento de mudança no campo Cliente Filial.
  const handleChangeFilial = (e, newValue) => {
    setFilialSelected(newValue ? newValue : null);
  }

  // Manipula o evento de mudança no campo UF
  // Filtra as Cidades apartir do Estado (UF) selecionado.
  const handleChangeUf = (e, newUf) => {
    let uf = newUf ? newUf.est_sigla : '';
    
    if (ufSelected && ufSelected.est_sigla !== uf) {
      setCitySelected(null);
    }
    
    setUfSelected(newUf);

    if (uf === "") {
      setFilterCityList(cityList);
      return;
    }

    setUfSelected(newUf);
    
    if (cityList) {
      let filterCity = cityList.filter((city) => {
        return city.estado.est_sigla === uf;
      });
  
      setFilterCityList(filterCity);
    }
  }

  // Manipula o evento de mudança no campo Cidade.
  const handleChangeCity = (e, newValue) => {
    setCitySelected(newValue ? newValue : null);
  }

  // Manipula o evento de mundança no campo Serviço.
  const handleChangeService = (e, newValue) => {
    setServiceSelected(newValue ? newValue : null);
  }

  // Manipula o evento de mudança no campo Produto.
  const handleChangeProduct = (e, product) => {
    setProductSelected(product ? product : null);
  }

  return (
    <>
      <Box className={`collapsed ${ filterActive === true ? 'active' : ''}`}>
        <Row className="bottom-xs g4">
          <Col className="col-xs-2 col-lg-12">
            <Input
              id="field_os"
              type="number"
              name="os"
              label={TEXT_SYSTEM_ORDER}
              placeholder={TEXT_FILL_FIELD}
            />
          </Col>
          <Col className="col-xs-2 col-lg-12">
            <Input
              id="field_contrato_os"
              name="contrato_compra_os"
              label={TEXT_CONTRACT}
              placeholder={TEXT_FILL_FIELD}
            />
          </Col>
          <Col className="col-xs-4 col-lg-12">
            <Autocomplete
              id="field_cliente_regional"
              options={regionalList}
              loading={loadingRegionalList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              getOptionLabel={(option) => option.ent_nome}
              onChange={handleChangeRegional}
              disableAnimation={true}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                    label={TEXT_REGIONAL_CUSTOMER}
                     autoComplete='off'
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                  <input 
                    type="hidden" 
                    name="ent_cnpj" 
                    value={ regionalSelected ? regionalSelected.ent_cpfcnpj : '' }
                  />
                </>
              }
            />
          </Col>
          <Col className="col-xs-4 col-lg-12">
            <Autocomplete
              id="field_cliente_filial"
              value={filialSelected}
              options={ filterFilialList }
              loading={loadingFilialList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              getOptionLabel={(option) => option.cli_nome}
              onChange={handleChangeFilial}
              disableAnimation={true}
              renderInput={ (params) =>
                <>
                  <TextField 
                    {...params} 
                    label={TEXT_BRANCH_CUSTOMER}
                    placeholder={TEXT_SELECT}
                     autoComplete='off'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                 <input 
                    type="hidden" 
                    name="cli_cnpj"
                    value={ filialSelected ? filialSelected.cli_cpfcnpj : '' }
                  />
                </> 
              }
            />
          </Col>
          <Col className="col-xs-4 col-lg-12">
            <Row>
              <Col className="col-xs-3 col-lg-4">
                <Autocomplete
                  id="field_uf"
                  options={ ufList }
                  loading={loadingUfList}
                  loadingText={TEXT_LOADING}
                  noOptionsText={TEXT_NO_OPTIONS}
                  onChange={handleChangeUf}
                  getOptionLabel={(option) => option.est_sigla}
                  disableAnimation={true}
                  renderInput={ (params) => 
                    <>
                      <TextField 
                        {...params}
                         autoComplete='off'
                        label="UF"
                        placeholder={TEXT_SELECT}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined" 
                      />
                      <input 
                        type="hidden" 
                        name="cid_uf"
                        value={ ufSelected ? ufSelected.est_sigla : '' }
                      />
                    </>
                  }
                />
              </Col>
              <Col className="col-xs-9 col-lg-8">
                <Autocomplete
                  id="field_cidade"
                  value={citySelected}
                  options={ ufSelected ? filterCityList : [] }
                  loading={loadingCityList}
                  loadingText={TEXT_LOADING}
                  noOptionsText={ ufSelected ? TEXT_NO_OPTIONS : TEXT_SELECT_STATE}
                  onChange={handleChangeCity}
                  getOptionLabel={(option) => option.cid_nome}
                  disableAnimation={true}
                  renderInput={ (params) => 
                    <>
                      <TextField 
                        {...params}
                         autoComplete='off'
                        label={TEXT_CITY}
                        placeholder={ufSelected ? TEXT_SELECT : TEXT_SELECT_STATE}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined" 
                      />
                      <input 
                        type="hidden" 
                        name="cid_codigo"
                        value={ citySelected ? citySelected.cid_codigo : '' }
                      />
                    </>
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-xs-4 col-lg-12">
            <Autocomplete
              id="field_servico"
              options={ serviceList }
              loading={loadingServiceList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              onChange={handleChangeService}
              getOptionLabel={(option) => option.serv_descricao}
              disableAnimation={true}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params} 
                    label={TEXT_SERVICE}
                     autoComplete='off'
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                  <input
                    type="hidden"
                    name="serv_codigo"
                    value={ serviceSelected ? serviceSelected.serv_codigo : '' }
                  />
                </>
              }
            />
          </Col>
          <Col className="col-xs-4 col-lg-12">
            <Autocomplete
              id="field_produto"
              options={ productList }
              loading={loadingProductList}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              onChange={handleChangeProduct}
              getOptionLabel={(option) => option.pro_nome}
              disableAnimation={true}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={TEXT_PRODUCT}
                    placeholder={TEXT_SELECT}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                  <input
                    type="hidden"
                    name="produto_codigo"
                    value={ productSelected ? productSelected.pro_codigo : '' }
                  />
                </>
              }
            />
          </Col>
          <Col className="col-xs-6 col-lg-12">
            <Text className="fw500 s12 ksm">
              {TEXT_END_DATE}
            </Text>
            <Vspace className="sm"/>
            <Box className="form-group">
              <Row>
                <Col className="col-xs-6 col-lg-12">
                  <DatePicker
                    id="field_data_finalizacao"
                    name="data_finalizacao"
                    label={I18nTranslator.t("INITIAL")}
                  />
                </Col>
                <Col className="col-xs-6 col-lg-12">
                  <DatePicker
                    id="field_data_finalizacao"
                    name="data_finalizacao"
                    label={I18nTranslator.t("FINAL")}
                  />
                </Col>
              </Row>
            </Box>
          </Col>
          <Col className="col-xs-6 col-lg-12">
            <Text className="fw500 s12 ksm">
              {TEXT_REQUEST_PERIOD}
            </Text>
            <Vspace className="sm"/>
            <Box className="form-group">
              <Row>
                <Col className="col-xs-6 col-lg-12">
                  <DatePicker
                    id="field_data_solicitacao_inicial"
                    name="data_solicitacao_inicial"
                    defaultValue={DateUtil.getSevenDaysBeforeToday()}
                    label={I18nTranslator.t("INITIAL")}
                  />
                </Col>
                <Col className="col-xs-6 col-lg-12">
                  <DatePicker
                    id="field_data_solicitacao_final"
                    name="data_solicitacao_final"
                    defaultValue={DateUtil.getToday()}
                    label={I18nTranslator.t("FINAL")}
                  />
                </Col>
              </Row>
            </Box>
          </Col>
          <Col className="col-xs-3 col-lg-12">
            <Input
              id="field_local_servico"
              name="slo_localemb"
              filter="like"
              label={TEXT_SERVICE_LOCATION}
              placeholder={TEXT_FILL_FIELD}
            />
          </Col>
          <Col className="col-xs-3 col-lg-12">
            <Input
              id="field_placa"
              name="placa"
              filter="like"
              label={TEXT_CAR_PLATE}
              placeholder={TEXT_FILL_FIELD}
            />
          </Col>
          <Col className="col-xs-3 col-lg-12">
            <Input
              id="field_destino"
              name="destino"
              filter="like"
              label={TEXT_DESTINY}
              placeholder={TEXT_FILL_FIELD}
            />
          </Col>
        </Row>

        <Vspace className="lg" />

        <Group className="r g2 m-spread">
          <Button type="reset" className="outlined">
            {TEXT_CLEAR_FIELDS}
          </Button>
          <Button type="submit" className="" id="btn_advanced_filter_os">
            {TEXT_FILTRATE}
          </Button>
        </Group>
      </Box>
      <Vspace className='hom'/>
    </>
  );
};

export default AdvancedFilter;
