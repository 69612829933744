/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from '@material-ui/core';

const Paginator = ({
 onOffsetChange,
 onChange,
 pageSize,
 paginatorSize,
 rowsPerPageOptions,
 setRowsLabel
}) => {

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const innerTotalPages = Math.ceil(paginatorSize/pageSize);
    let innerSelectedPage = currentPage;
    if ((innerSelectedPage + 1) > innerTotalPages) {
      innerSelectedPage = innerTotalPages - 1;
      handlePageChange(innerSelectedPage);
    }
  }, [paginatorSize]);

  const handlePageChange = (selectedPage) => {
    const totalPages = Math.ceil(paginatorSize/pageSize);
    if (selectedPage < 0 || (selectedPage + 1) > totalPages) return;
    const newOffset = selectedPage * pageSize;
    setCurrentPage(selectedPage);
    onOffsetChange(newOffset);
    onChange(selectedPage);
  };

  if (paginatorSize > pageSize && pageSize > 0) {
    return (
      <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        labelDisplayedRows={setRowsLabel}
        rowsPerPage={pageSize}
        page={currentPage || 0}
        count={paginatorSize}
        onChangePage={(event, page) => handlePageChange(page)}
      />
    );
  }
  return null;

};

Paginator.defaultProps = {
  onOffsetChange: () => {},
  onChange: () => {},
  pageSize: 10,
  paginatorSize: 0,
  rowsPerPageOptions: [],
};

Paginator.propTypes = {
  // Function to update offset value, it returns a number.
  onOffsetChange: PropTypes.func,
  // It returns a number with the current page.
  onChange: PropTypes.func,
  // The size of each page.
  pageSize: PropTypes.number,
  // The total amount of pages.
  paginatorSize: PropTypes.number.isRequired,
  // Need clarification in material documentation
  rowsPerPageOptions: PropTypes.array,
  // Function to update rows label, it returns { from, to, count } and expect a string as output.
  setRowsLabel: PropTypes.func
}

export default Paginator;