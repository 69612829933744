import React from "react";
import AvatarPng from 'assets/img/avatar.png';

const ChatMessage = ({avatarLogo = AvatarPng, chat_msg_texto, chat_msg_data, position}) => {
    let classNameString = "chat-incoming"
    if (position === "right") {
        classNameString = "chat-sent"
    }
    return (
        <div className={classNameString}>
            {<img src={avatarLogo} alt="Avatar" className="chat-avatar"/>}
            <div className="chat-dialog">
                <span className="chat-message">
                  {chat_msg_texto}
                </span>
                <span className="chat-timestamp">
                    {chat_msg_data}
                </span>
            </div>
        </div>
    );
}
export default ChatMessage