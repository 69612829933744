import { configuration } from "config";
import { userAccessService } from "core/user-access/UserAccessService";


export class BoardLocation {
    static filter(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/local_embarque?${urlSearchParams.toString()}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static filterProducers(loc_codigo: any) {
        const url = `${configuration.AUTH_BASE_URL}cliconnect/produtor_local_embarque?loc_codigo=${loc_codigo}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }

    static create(params: object) {
        const url = `${configuration.AUTH_BASE_URL}cliconnect/local_embarque`;

        const config: object = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            },
            body: JSON.stringify(params)
        }

        console.log(config)

        return new Promise((resolve, reject) => {
            fetch(url, config)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }
}