import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";
import {I18nTranslator} from 'core/i18n/I18nTranslator';

export class ProductService {

    static getProductList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/produto?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }

    static getProductTypeList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/tipo_produto?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
        });
    }

    static getClassificationItems(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/item_classificacao_produto?" + searchParams.toString();;

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error));
        });
    }

    static getTranslation(name: any) {
        let token = `PRODUCT_${name}`;
        let translation = I18nTranslator.t(token);
        return (translation === token ? name : translation)
    }
}