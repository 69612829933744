import React from 'react';
import TextField from '@material-ui/core/TextField';
import './Autocomplete.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Suggestion {
    label: string;
}

let suggestions: Suggestion[] = [];


const suggestionsContainItem = (value: string) => {
    let contains = false;

    suggestions.map((item: any) => {
        if (item.label === value)
            contains = true;

        return null;
    });

    return contains;
};

const populateOptions = (items: any, fields: string[]) => {
    if (items) {
        // Mapeia a lista de itens para incluir os dados nas sugestões
        items.map((item: any) => {
            // Mapeia os campos passados para extrair os dados para as sugestões
            fields.map((field: string) => {
                if (item[field] !== "-" && !suggestionsContainItem(item[field])) {
                    suggestions.push({label: item[field]});
                }
                return null;
            });
            return null;
        });
    }

    // Ordena alfabeticamente as opções mostradas no autocomplete
    suggestions = suggestions.sort(function(a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return suggestions;
};

export default function CustomAutocomplete (props: any) {

    return (
        <div>
            <Autocomplete
                freeSolo
                disableClearable
                options={populateOptions(props.items, props.fields).map(option => option.label)}
                onChange={(event:any, value:any) => {
                    props.onChange(value !== undefined ? value : '');
                }}
                renderInput={(params:any) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    fullWidth
                    InputProps={{ ...params.InputProps, type: 'search', autoComplete: 'disabled' }}
                    className={"autocomplete"}
                />
                )}
            />
        </div>
    );
}