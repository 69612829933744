/* eslint-disable */
import React, {useEffect, useState} from "react";
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import {Title} from 'components/commons/Typography';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import Input from 'components/commons/Input';
import InputFile from "../../../../../../../../components/commons/InputFile";
import {CargoService} from "../../../../../../../../core/cargo/CargoService";
import {I18nTranslator} from "../../../../../../../../core/i18n/I18nTranslator";
import FunctionsUtil from "../../../../../../../../core/utils/FunctionsUtil";
import TextUtil from "../../../../../../../../core/utils/TextUtil";


const TEXT_SELECT_A_FILE = I18nTranslator.t('SELECT_A_FILE');
const TEXT_ATTACH_DOCUMENTS = I18nTranslator.t('ATTACH_DOCUMENTS');
const TEXT_CANCEL = I18nTranslator.t('CANCEL');
const TEXT_SIGN_UP = I18nTranslator.t('SIGN_UP');


const AttachFile = (props) => {


    // Props.
    const {
        onClick,
        dataLaudo = [],
    } = props;

    const defaultFiles = {
        "ord_cte": {
            "name": "ord_cte",
            "file": [],
            "link": "",
            "label": "CT-e",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_contrato_repom": {
            "name": "ord_contrato_repom",
            "file": [],
            "link": "",
            "label": "CONTRATO REPOM",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_contrato": {
            "name": "ord_contrato",
            "file": [],
            "label": "Contrato",
            "link": "",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_mdfe": {
            "name": "ord_mdfe",
            "file": [],
            "link": "",
            "label": "MDF-e",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_rae": {
            "name": "ord_rae",
            "file": [],
            "link": "",
            "label": "RAE ou NF50",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_nota": {
            "name": "ord_nota",
            "file": [],
            "link": "",
            "label": "NOTA FISCAL",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_agendamento": {
            "name": "ord_agendamento",
            "file": [],
            "link": "",
            "label": "AGENDAMENTO",
            "preview": TEXT_SELECT_A_FILE
        },
        "ord_outros": {
            "name": "ord_outros",
            "file": [],
            "link": "",
            "label": "OUTROS",
            "preview": TEXT_SELECT_A_FILE
        }
    }

    var {
        ordem_servico,
        iac_nota_fiscal,
        iac_codigo,
        iac_cpf_motorista,
        iac_placa,
        iac_nota_fiscal_origem,
    } = dataLaudo
    var {slo_codigo} = ordem_servico


    const [files, setFiles] = useState({...defaultFiles});
    const [numeroCartao, setNumeroCartao] = useState("");


    function handdleDocumentList(iac_codigo) {

        CargoService.getDocumentsList({"iac_codigo": iac_codigo})
            .then((response) => {
                    let data = FunctionsUtil.verifyResponseNotUndefined(response[0], response[0], {})
                    let newFiles = {...defaultFiles}
                    Object.keys(newFiles).map(key => {
                            if (typeof data[key] === "string") {
                                newFiles[key].preview = (TextUtil.summarize(data[key].split("/").pop(), 20, 3))
                                newFiles[key].link = data[key]
                            }
                        }
                    )
                    setNumeroCartao(data["ord_cartao_repom"])
                    setFiles(newFiles)
                console.log(files)
                }
            )
            .catch((error) => {
                console.error(error);
            })

    }

    useEffect(() => {
            if (iac_codigo) {
                handdleDocumentList(iac_codigo)
            }
        },
        [dataLaudo])

    const FileList = ({files, onFileChange}) => {
        return (
            Object.keys(files).map((key) => {
                let file = files[key]
                return (
                    <>
                        <InputFile
                            id={file.name}
                            label={file.label}
                            defaultPlaceHolder={file.preview}
                            link={file.link}
                            onChange={(e) => onFileChange(e, key)}
                            accept="application/pdf"
                        />
                        <Vspace className='lg'/>
                    </>
                );
            })
        )
    }


    function handleUpload() {

        var data = new FormData();
        data.append("iac_codigo", iac_codigo)
        data.append("file_cte", files["ord_cte"].file)
        data.append("file_nota", files["ord_nota"].file)
        data.append("file_agendamento", files["ord_agendamento"].file)
        data.append("file_contrato_repom", files["ord_contrato_repom"].file)
        data.append("file_outros", files["ord_outros"].file)
        data.append("file_mdfe", files["ord_mdfe"].file)
        data.append("file_contrato", files["ord_contrato"].file)
        data.append("file_rae", files["ord_rae"].file)
        data.append("num_cartao_repom", numeroCartao)

        // envia a menssagem
        CargoService.uploadAttachFile(data)
            .then(() => {
                onClick()
            })
            .catch((error) => {
            });
    }

    function handleChangeInput(e){
        console.log(e.value())
        // setNumeroCartao(e.value)
    }
    return (
        <Box>
            <Button onClick={onClick} className="button-close"/>
            <Title className='s11 fw500'>
                {TEXT_ATTACH_DOCUMENTS}
            </Title>
            <hr/>
            <Row className='g3'>
                <Col className='col-xs-3 col-lg-12 col-md-12 hlr'>
                    <Input
                        disabled="disabled"
                        label='Ordem de Serviço'
                        value={slo_codigo}
                    />
                    <Vspace className='lg'/>
                    <Input
                        disabled="disabled"
                        label='Placa'
                        value={iac_placa}
                    />
                    <Vspace className='lg'/>
                    <Input
                        disabled="disabled"
                        label='CPF Motorista'
                        value={iac_cpf_motorista}
                    />
                    <Vspace className='lg'/>
                    <Input
                        disabled="disabled"
                        label='N.F.'
                        value={iac_nota_fiscal}
                    />
                    <Vspace className='lg'/>
                    <Input
                        disabled="disabled"
                        label='Chave da NF-e do produtor'
                        value={iac_nota_fiscal_origem}
                    />
                    <Vspace className='lg'/>
                    <Input
                        label='Cartão Repom'
                        className="wac-input"
                        type="text"
                        onInput={(e) => setNumeroCartao(e.target.value)}
                        id="ord_cartao_repom"
                        value={numeroCartao}
                    />
                </Col>
                <Col className='col-xs col-lg-6 col-md-12'>
                    {
                        <FileList
                            files={files}
                            onFileChange={(e, key) => {
                                if (e.target.files.length) {
                                    let newFiles = files
                                    newFiles[key] = {
                                        ...newFiles[key],
                                        "file": e.target.files[0],
                                        "preview": e.target.files[0].name
                                    }
                                    setFiles(newFiles)
                                }
                            }
                            }
                        />
                    }
                </Col>
            </Row>
            <Vspace className='xlg'/>
            <hr/>
            <Group className='spread fluid'>
                <Button
                    onClick={onClick} className='outlined'>
                    {TEXT_CANCEL}
                </Button>
                <Button onClick={handleUpload} type="submit">
                    {TEXT_SIGN_UP}
                </Button>
            </Group>
        </Box>
  )
}

export default AttachFile