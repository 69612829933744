import React, {useEffect, useState} from "react";
import Button from "components/commons/Button";
import {OSService} from "core/os/OSService";
import {I18nTranslator} from "core/i18n/I18nTranslator";
import AdvancedFilter from "./components/InboxList/AdvancedFilter";
import DataTable from "components/commons/DataTable/DataTable";
import DateUtil from "core/utils/DateUtil";
import TicketDetails from "./components/TicketDetails/TicketDetails";
import BoardingList from "./components/BoardingList/BoardingList";
import PopoverContent from "./components/PopoverContent";
import Modal from "components/commons/Modal";
import {Snackbar} from "@material-ui/core";

// Filtro avançado.
const FilterForm = React.memo(({handleSubmit, filterActive}) => (
    <form id="form_os_advanced_filter" onSubmit={handleSubmit} className="no-print">
        <AdvancedFilter filterActive={filterActive}/>
    </form>
));

const FilterSubmitButton = React.memo(({filterActive, handleFilterActive}) => (
    <Button
        onClick={handleFilterActive}
        children={I18nTranslator.t('ADVANCED_FILTER')}
        className={`filter has-arrow ${filterActive === true ? 'active' : ''}`}
    />
));

function TicketPanel() {
    // Traduções.
    const TEXT_DATE = I18nTranslator.t('DATE');
    const TEXT_SERVICE = I18nTranslator.t('SERVICE');
    const TEXT_PRODUCT = I18nTranslator.t('PRODUCT');
    const TEXT_COORD = I18nTranslator.t('COORD');
    const TEXT_SUPERV = I18nTranslator.t('SUPERV');
    const TEXT_CLIENT = I18nTranslator.t('CLIENT');
    const TEXT_SERVICE_LOCATION = I18nTranslator.t('SERVICE_LOCATION');
    const TEXT_DESTINY = I18nTranslator.t('DESTINY');
    const TEXT_CONTRACT = I18nTranslator.t('CONTRACT');
    const TEXT_BOARDED = I18nTranslator.t('BOARDED');
    const TEXT_BALANCE = I18nTranslator.t('BALANCE');
    const TEXT_TOTAL = I18nTranslator.t('TOTAL');
    const TEXT_NOTHING_FOUND = I18nTranslator.t('NOTHING_FOUND');
    const TEXT_RECENTLY_CREATED = I18nTranslator.t('RECENTLY_CREATED');
    const TEXT_IN_PROGRESS = I18nTranslator.t('IN_PROGRESS');
    const TEXT_CANCELEDS = I18nTranslator.t('CANCELEDS');
    const TEXT_RECENTLY_COMPLETED = I18nTranslator.t('RECENTLY_COMPLETED');
    const TEXT_SET_UP_COLUMNS = I18nTranslator.t("SET_UP_COLUMNS");
    const TEXT_SELECT = I18nTranslator.t("SELECT");
    const TEXT_ALL = I18nTranslator.t("ALL");
    const TEXT_NONE = I18nTranslator.t("NONE");
    const TEXT_STATUS = I18nTranslator.t("STATUS");
    const TEXT_EXPORT = I18nTranslator.t("EXPORT");
    const TEXT_SERVICE_ORDERS = I18nTranslator.t('SERVICE_ORDERS');
    const TEXT_SERVER_DOWN = I18nTranslator.t("SERVER_DOWN_MESSAGE");
    const TEXT_SEARCH = I18nTranslator.t("SEARCH");

    // Lista de ordem de serviço
    const [osList, setOsList] = useState([]);
    const [loadingOsList, setLoadingOsList] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState();

    // Snackbar.
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function showSnackBar(message) {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    }

    useEffect(() => {
        // Filtra automaticamente as ordens de serviço assim que a tela é carregada.
        let btnAdvancedFiler = document.getElementById('btn_advanced_filter_os');
        if (btnAdvancedFiler) btnAdvancedFiler.click();
    }, []);

    // Status.
    const statusList = {
        'new': TEXT_RECENTLY_CREATED,
        'pending': TEXT_IN_PROGRESS,
        'canceled': TEXT_CANCELEDS,
        'complete': TEXT_RECENTLY_COMPLETED
    }

    // Colunas.
    const columns = [
        'UF',
        'O.S.',
        I18nTranslator.t('DATE'),
        TEXT_SERVICE,
        TEXT_PRODUCT,
        `${TEXT_COORD}./${TEXT_SUPERV}.`,
        TEXT_CLIENT,
        TEXT_SERVICE_LOCATION,
        TEXT_DESTINY,
        TEXT_CONTRACT,
        TEXT_BOARDED,
        TEXT_BALANCE,
        TEXT_TOTAL
    ];

    const getStatusName = (status) => {
        let dicionario = {
            'A': 'pending',
            'C': 'canceled',
            'F': 'complete',
            'N': 'new'
        }

        if (dicionario[status]) {
            return dicionario[status];
        }

        return null;
    }

    // Exibe o modal e adiciona um conteudo para o mesmo
    const showModal = (content) => {
        try {
            setModalContent(content);
            setModalIsOpen(true);
        } catch (error) {
            console.error(error);
        }
    }

    const serviceOrderTableRowFactory = (os) => {
        const {
            local_embarque,
            servico,
            produto,
            base,
            filial
        } = os;
        const {cidade} = local_embarque;
        const {estado} = cidade;
        const baseFilial = base ? base.filial : {};
        const baseCidade = base ? base.cidade : {};

        // Formata as casas decimais de um número
        const formatStringNumber = (num) => {
            if (!num) return 0;
            return num.toLocaleString('de-DE', {minimumFractionDigits: 2})
        }

        return {
            'status': getStatusName(os.situacao || ''),
            '': (
                <PopoverContent
                    sloCodigo={os.slo_codigo}
                    handleListarEmbarque={
                        () => showModal(
                            <BoardingList
                                serviceOrderCode={os.slo_codigo || ''}
                                handleClose={() => setModalIsOpen(false)}
                            />
                        )
                    }
                    handleVisualizarDados={
                        () => showModal(
                            <TicketDetails
                                dataObject={os}
                                handleClose={() => setModalIsOpen(false)}
                            />
                        )
                    }
                />
            ),
            'UF': (estado.est_sigla || ''),
            'O.S.': (os.slo_codigo || ''),
            [TEXT_DATE]: DateUtil.formatStringDate(os.slo_dtsolicitacao || ''),
            [TEXT_SERVICE]: (servico.serv_descricao || ''),
            [TEXT_PRODUCT]: (produto ? produto.pro_nome : ''),
            [`${TEXT_COORD}./${TEXT_SUPERV}.`]: (`${(baseFilial.cli_nome || '')}/${(baseCidade.cid_nome || '')}`),
            [TEXT_CLIENT]: (filial.cli_nome || ''),
            [TEXT_SERVICE_LOCATION]: `${(os.slo_localemb || '')} - ${(cidade.cid_nome || '')}, ${(estado.est_sigla || '')} ${os.slo_nome_produtor ? (`${os.slo_nome_produtor}`) : ''}`,
            [TEXT_DESTINY]: `${(os.slo_cidade || '')}, ${(os.slo_uf || '')}`,
            [TEXT_CONTRACT]: (os.slo_contratocompra || ''),
            [TEXT_BOARDED]: formatStringNumber(os.peso_embarcado === 0 ? '0' : os.peso_embarcado),
            [TEXT_BALANCE]: formatStringNumber(os.slo_totallote - os.peso_embarcado || 0),
            [TEXT_TOTAL]: formatStringNumber(os.slo_totallote || 0)
        }
    }

    // Manipula o envio do formulario
    function handleSubmit(e) {
        e.preventDefault();
        // Busca os elementos do filtro avançado e armazena em um objeto de parâmetros
        let formElements = document.getElementById('form_os_advanced_filter').elements;
        let buttonFilter = document.getElementById('btn_advanced_filter_os');
        let likeFilterElements = ["destino", "slo_localemb"];
        let filterParameters = {};

        for (let i = 0; i < formElements.length; i++) {
            let item = formElements.item(i);
            if (item.name === "") continue;
            filterParameters[item.name] = (item.value !== '' && likeFilterElements.indexOf(item.name) > -1) ? `%${item.value}%` : item.value;
        }

        // Desabilita o botão e altera o estado da tabela para 'carregando'
        buttonFilter.disabled = true;
        setLoadingOsList(true);

        // Busca a lista de ordens de serviço e atualiza os estados
        OSService.getOsList(filterParameters)
            .then((response) => {
                if (Array.isArray(response)) {
                    let osList = response.map((os) => {
                        return serviceOrderTableRowFactory(os);
                    });
                    setOsList(osList);
                }
            })
            .catch((error) => {
                console.log(error)
                showSnackBar(TEXT_SERVER_DOWN);
            })
            .finally(() => {
                buttonFilter.disabled = false;
                setLoadingOsList(false);
            });
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                key={`snackbar`}
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={9000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{snackbarMessage}</span>}
            />
            <DataTable
                id={"table_service_order_list"}
                className={"f-3"}
                loading={loadingOsList}
                columns={columns}
                data={osList}
                filename={TEXT_SERVICE_ORDERS}
                statusList={statusList}
                triggerAdvancedFilter={
                    <FilterSubmitButton
                        filterActive={filterActive}
                        handleFilterActive={() => setFilterActive(prevState => !prevState)}
                    />
                }
                advancedFilter={
                    <FilterForm
                        handleSubmit={handleSubmit}
                        filterActive={filterActive}
                    />
                }
                i18n={{
                    NOTHING_FOUND: TEXT_NOTHING_FOUND,
                    SET_UP_COLUMNS: TEXT_SET_UP_COLUMNS,
                    SELECT: TEXT_SELECT,
                    ALL: TEXT_ALL,
                    NONE: TEXT_NONE,
                    STATUS: TEXT_STATUS,
                    EXPORT: TEXT_EXPORT,
                    SEARCH: TEXT_SEARCH
                }}
            />
            <Modal
                className={`lg ${modalIsOpen ? 'active' : ''}`}>
                {modalContent}
            </Modal>
        </>
    );
}

export default TicketPanel;