import React, { useState } from "react";
import Table from "components/commons/Table";
import { Text, Title } from 'components/commons/Typography';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import CollapsedRowContent from './CollapsedRowContent';
import Modal from 'components/commons/Modal';
import TextUtil from "core/utils/TextUtil";
import Paginator from "components/commons/Paginator";
import { BoardLocation } from "core/board-location/BoardLocation";
import NewBoardLocation from "./NewBoardLocation";
import { I18nTranslator } from "core/i18n/I18nTranslator";

const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND"); // Nada encontrado
const TEXT_VIEW_LINKED_PRODUCERS = I18nTranslator.t("VIEW_LINKED_PRODUCERS"); // ver produtores vinculados
const TEXT_EDIT_BOARDING_LOCATION = I18nTranslator.t("EDIT_BOARDING_LOCATION"); // editar local de embarque
const TEXT_SEARCH_RESULT = I18nTranslator.t("SERCH_RESULT"); // Resultado da busca
const TEXT_BOARD_LOCATION = I18nTranslator.t("BOARD_LOCATION"); // Local de Embarque
const TEXT_CITY = I18nTranslator.t("CITY"); // Cidade
const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição Estadual
const TEXT_REGISTRATION = I18nTranslator.t("REGISTRATION"); // Matrícula
const TEXT_REGISTER_NEW_LOCATION = I18nTranslator.t("REGISTER_NEW_LOCATION"); // Cadastrar novo local
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM"); // Confirmar

const FilterList = ({ onClickChecked, data, loading, handleChange, values, onCreateNewBoardLoaction, showPaginator = true }) => {
  const [ newBoardLocation, setNewBoardLocation ] = useState(false)
  const [ selectRow, setSelectRow ] = useState(values.linha_embarque_selecionada || {});
  const [ activeRow, setActiveRow] = useState(values.linha_embarque_ativa || {});
  const [ offset, setOffset ] = useState(0);
  const [ producerList, setProducerList ] = useState(values.lista_embarque_produtores || []);
  const [ loadingProducerList, setLoadingProducerList ] = useState(false);

  const pageSize = 10;


  function handleSelectRow(row) {
    if (!values.linha_embarque_check) {
      handleChange('linha_embarque_selecionada', row)
      setSelectRow(row);
    }
  }

  function handleRow(row) {
    handleChange('linha_embarque_ativa', row)
    setActiveRow(row)
  } 
  

  function handleNewBoardLocation() {
    setNewBoardLocation( newBoardLocation === false )
  }

  function getProducerList(loc_codigo) {
    setLoadingProducerList(true);
    BoardLocation.filterProducers(loc_codigo)
    .then((response) => {
      if(Array.isArray(response)) {
        handleChange('lista_embarque_produtores', response);
        setProducerList(response);
      }
    })
    .catch(error => {})
    .finally(() => setLoadingProducerList(false))
  }

  function renderBody(selectRow) {
    if (!data.length) {
      return (
        <tr>
          <td colSpan="6">{TEXT_NOTHING_FOUND}.</td>
        </tr>
      )
    }
   

    let rows = (pageSize ? data.slice(offset, offset + pageSize) : data);
    return rows.map(row => {
      let sumarizeLocNome = row.loc_nome;
      let locNomeProps = {};
      if (row.loc_nome && row.loc_nome.length > 30) {
        sumarizeLocNome = TextUtil.summarize(row.loc_nome, 30);
        locNomeProps.status = row.loc_nome;
      }

      let sumarizeCityUf = `${row.cidNome}/${row.estSigla}` || '';
      let cityUfProps = {};
      if (sumarizeCityUf.length > 25) {
        cityUfProps.status = sumarizeCityUf;
        sumarizeCityUf = TextUtil.summarize(sumarizeCityUf, 25, 3);
      }

      let sumarizeIe = row.loc_ie;
      let ieProps = {};
      if (row.loc_ie && row.loc_ie.length > 20) {
        ieProps.status = row.loc_ie;
        sumarizeIe = TextUtil.summarize(row.loc_ie, 20);
      }

      let selected = selectRow.loc_codigo === row.loc_codigo ? 'selected' : '';
      let active = activeRow.loc_codigo === row.loc_codigo ? 'active' : '';
      
      return (
        <React.Fragment key={row.loc_codigo}>
          <tr onClick={() => handleSelectRow(selected === 'selected' ? {} : row)}
            onT 
            className={`collapsed ${selected} ${active}`}>
            <td>
              {row.loc_codigo}
            </td>
            <td {...locNomeProps}>
              <Text className='uppercase'>
                {sumarizeLocNome}
              </Text>
            </td>
            <td {...cityUfProps}>
              <Text className='uppercase'>
                {sumarizeCityUf}
              </Text>
            </td>
            <td>
              {TextUtil.formatCpfCnpj(row.loc_cpfcnpj)}
            </td>
            <td {...ieProps}>
              {sumarizeIe}
            </td>
            <td></td>
            <td onClick={
              (e) => {
                e.stopPropagation();
                handleRow(active ? {} : row);
                if (!active) getProducerList(row.loc_codigo);
              }
            }>
              <div className="hide-when-checked">
                <button className="collapsed-row-trigger" />
                  {TEXT_VIEW_LINKED_PRODUCERS.toLowerCase()}
              </div>
              <Button className="show-when-checked inline" onClick={() => onClickChecked(false)}>
                {TEXT_EDIT_BOARDING_LOCATION.toLowerCase()}
              </Button>
            </td>
          </tr>
          <tr className="collapsed-row">
            <td colSpan="1000">
              <CollapsedRowContent
                producerList={producerList} 
                loading={loadingProducerList} 
                selectedRow={selectRow}
                onClickRow={handleSelectRow}
                onClickChecked={onClickChecked}
                locCodigo={row.loc_codigo}
                onCreateNewProducer={() => getProducerList(row.loc_codigo)}
              />
            </td>
          </tr>
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <Box>
        <Title className="s13 uppercase">
          {TEXT_SEARCH_RESULT}
        </Title>
        <Vspace />
        <div className={`wac-table-container ${loading ? 'loading' : ''}`}>
          <Table className='featured'>
            <thead>
              <tr>
                <th>ID</th>
                <th>{TEXT_BOARD_LOCATION}</th>
                <th>{TEXT_CITY}/UF</th> 
                <th>{TEXT_ITIN}/{TEXT_EIN}</th>
                <th>{TEXT_STATE_REGISTRATION}</th>
                <th>{TEXT_REGISTRATION}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderBody(selectRow)}
            </tbody>
          </Table>
          {
            showPaginator ?
            (<Paginator
              paginatorSize={data.length}
              pageSize={pageSize}
              onOffsetChange={setOffset}
              setRowsLabel={({ from, to, count }) => `${from}-${to} of ${count}`}
            />) : ''
          }
        </div>
        <Vspace />
        {
          data.length ?
          (<Group className="r g2">
            <Button className={`${ Object.keys(selectRow).length ? 'hide' : ''}`} onClick={handleNewBoardLocation}>
              {TEXT_REGISTER_NEW_LOCATION}
            </Button>
            <Button className={`${ Object.keys(selectRow).length ? '' : 'hide'}`} onClick={() => onClickChecked(true)}>
              {TEXT_CONFIRM}
            </Button>
          </Group>) : null
        }
      </Box>
      <Modal className={`md ${ newBoardLocation ? 'active' : ''}`}>
        <NewBoardLocation 
          onClick={handleNewBoardLocation} 
          onCreateNewBoardLoaction={onCreateNewBoardLoaction}/>
      </Modal>
    </>
  );
};

export default FilterList