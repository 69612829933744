import React, {Fragment} from 'react';
import InfoWindow from "./InfoWindow";

// InfoWindow component

class Marker extends React.Component {
    render() {
        return (
            <Fragment>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "40px",
                        height: "40px",
                        background: `url(${this.props.image})`,
                        backgroundSize: `100%`,
                        userSelect: "none",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        zIndex: "5"
                    }}
                    onClick={(e) => this.props.onPinClick(e, this.props.place.slo_codigo)}
                >
                    {this.props.children}
                </div>

                {this.props.showInfo ? (
                    <InfoWindow place={this.props.place} openFullDetails={this.props.openFullDetails} />
                ) : null }
            </Fragment>
        )
    }
}

export default Marker;