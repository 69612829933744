import React, {useEffect, useState} from 'react';
import {Paragraph, Text, Title} from "../../../components/commons/Typography";
import {I18nTranslator} from "../../../core/i18n/I18nTranslator";
import Box from "../../../components/commons/Box";
import Vspace from "../../../components/commons/Vspace";
import Image from "../../../components/commons/Image";
import {AvisosService} from "../../../core/avisos/AvisosService";
import {Skeleton} from "@material-ui/lab";
import {Button, Link} from "@material-ui/core";
import DateUtil from "../../../core/utils/DateUtil";
import { Route } from 'react-router-dom'

const NoticeCard = ({ notice, loading = false }) => {
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        if (notice && notice.img_url) {
            loadImage(notice.img_url)
        }
    }, [notice])

    const loadImage = (url) => {
        setLoadingImage(true)
        fetch(url)
            .then(response => response.blob())
            .then(image => setImageUrl(URL.createObjectURL(image)))
            .catch(() => setImageUrl(url))
            .finally(() => setTimeout(() => setLoadingImage(false), 250))
    }

    const convertTextToDOM = (mensagem) => {

        if (mensagem) {
            let string = mensagem.replace(/\/\/n|\/\/r/gm,'\n');
            let urlString = string.match(/\bhttps?:\/\/\S+/gi)
            if(urlString){
                urlString.forEach((url) => {
                let newString = `@##${url}@##`
                string = string.replace(url, newString)
                }) 
            }
            let a = string.split('@##')
            let c = []
            a.forEach((b)=>{
                if (b.includes('https')){
                    c.push(<React.Fragment><br/><Link className="wac-text primary fw500" href={b} target="_blank">{b}</Link></React.Fragment>)
                } else {
                    c.push(b)
                }
            })
            return c   
        }
        return null
    }

    return (
        <Box className={'wac-notice-card'}>
            <Title className={"s11"}>
                { loading ? <Skeleton width={"60%"}/> : notice ? notice.mc_titulo : '' }
            </Title>
            <Paragraph className={"grey-2"} title={notice ? `${DateUtil.formatStringDateTime(notice.mc_dt_cadastro)} ${DateUtil.formatStringTime(notice.mc_dt_cadastro).substr(0, 5)}` : ''}>
                { loading ? <Skeleton width={"30%"}/> : notice ? DateUtil.howLongHasPassed(notice.mc_dt_cadastro, I18nTranslator.getLanguage()) : ''}
            </Paragraph>
            { loading ? null : <Vspace /> }
            <Paragraph>
                { loading ? <Skeleton /> : (notice && notice.mc_mensagem ? convertTextToDOM(notice.mc_mensagem) : null) }
            </Paragraph>
            { loading ? null : <Vspace /> }
            { loading || loadingImage ? <Skeleton variant={"rect"} height={500} /> :
                (imageUrl ? <Link href={imageUrl} target="_blank" download><Image src={imageUrl} /></Link> : null) }
        </Box>
    )
}

const NoticeBoard = () => {
    const [notices, setNotices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const params = new URLSearchParams(document.location.search)
    const id = params.get('id')

    const limit = 5;
    let lastScrollTop = 0

    const getNotices = (filter = {limit: limit, page: (notices.length/limit) + 1}) => {
        if (loading) return;
        if (id) filter.mc_id = id
        setLoading(true)
        setError(false)
        AvisosService.getList(filter)
            .then(response => {
                if (response.length) {
                    let newNotices = [...notices, ...response];
                    newNotices = newNotices.filter((notice, index, self) => {
                        return index === self.findIndex(t => t.mc_id === notice.mc_id)
                    })
                    setNotices(newNotices);
                }
            })
            .catch(error => setError(true))
            .finally(() => setLoading(false))
    }

    const onScroll = (e) => {
        if (id) return;
        let element = e.target
        let diff = element.scrollHeight - element.scrollTop;
        let nearBottomValue = element.clientHeight + 200;
        let nearbottom = Math.abs(diff - nearBottomValue) <= 50;
        let scrollDown = element.scrollTop > lastScrollTop
        lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop
        if (nearbottom && scrollDown) {
            getNotices({ limit: limit, page: (notices.length/limit) + 1 })
        }
    }

    useEffect(() => {
        getNotices()
    }, [])

    useEffect(() => {
        let mainContent = document.getElementById('main-content');
        mainContent.addEventListener("scroll", onScroll)
        return () => {
            mainContent.removeEventListener("scroll", onScroll)
        }
    }, [onScroll])

    return (
        <div>
            <Title>{I18nTranslator.t("NOTICE_BOARD")}</Title>
            <Vspace />
            {
                error ? (
                        <Text>{I18nTranslator.t("ERROR_LOADING_PAGE")}</Text>
                    ) :
                    (<React.Fragment>
                        { notices ?
                            notices.map(notice => {
                                return (
                                    <React.Fragment key={notice.mc_id}>
                                        <NoticeCard notice={notice} />
                                        <Vspace className={"lg"}/>
                                    </React.Fragment>
                                )
                            }
                        ) : null }
                        { loading ? (
                            <React.Fragment>
                                <NoticeCard loading={loading} />
                                <Vspace className={"lg"}/>
                                { !id ? <NoticeCard loading={loading} /> : null}
                            </React.Fragment>
                        ) : !notices.length ? (<Text>{I18nTranslator.t("NOTHING_FOUND")}</Text>) : null }
                        {
                            id ? (
                                <Route render={({ history }) => (
                                    <Button className={'fc'} onClick={() => history.push('/notice-board')}>
                                        <Text>{I18nTranslator.t("SEE_ALL")}</Text>
                                    </Button>
                                )} />
                            ) : null
                        }
                    </React.Fragment>)

            }
        </div>
    )
}

export default NoticeBoard