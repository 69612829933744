import pt from '../../assets/i18n/pt.json'
import en from '../../assets/i18n/en.json'
import es from '../../assets/i18n/es.json'

const translates: any = {
    pt,
    en,
    es
};

const id = 'sys-language';

export class I18nTranslator {
    static language = localStorage.getItem(id) || "pt";

    static t(token: string) {
        return translates[I18nTranslator.language][token] || token;
    }

    static setLanguage(lang: string) {
        I18nTranslator.language = lang;
        localStorage.setItem(id, lang);
    }

    static getLanguage() {
        return this.language;
    }

}