import React from "react";

const Header = ({ className, children }) => {
  return (
    <div className={`wac-header ${className}`}>
      {children}
    </div>
  );
};

export default Header;
