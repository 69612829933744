import React from "react";

const Group = ({ className, children }) => {
  return (
    <div className={`wac-group ${className}`}>
      {children}
    </div>
  );
};

export default Group;
