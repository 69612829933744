import React, {useState} from "react";
import Table from "components/commons/Table";
import {Text} from 'components/commons/Typography';
import Button from 'components/commons/Button';
import Modal from 'components/commons/Modal';
import {PlusCircleIcon} from 'components/Icons';
import Icon from 'components/commons/Icon';
import TextUtil from "core/utils/TextUtil";
import {I18nTranslator} from "core/i18n/I18nTranslator";
import ProducerLink from "./ProducerLink";
import {ProducerService} from "core/producer/ProducerService";

const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND");
const TEXT_EDIT_BOARDING_LOCATION = I18nTranslator.t("EDIT_BOARDING_LOCATION"); // editar local de embarque
const TEXT_LINKED_PRODUCERS = I18nTranslator.t("LINKED_PRODUCERS"); // Produtores vinculados
const TEXT_LINK_NEW_PRODUCER = I18nTranslator.t("LINK_NEW_PRODUCER"); // Vincular novo produtor

const CollapsedRowContent = ({ producerList, loading, selectedRow, onClickRow, onClickChecked, onCreateNewProducer, locCodigo}) => {

  const [productLink, setProductLink ] = useState(false);
  const [loadingProducerList, setLoadingProducerList] = useState(false);
  const [list, setProducerList] = useState([]);
  
  const getProducerList = () => {
      setLoadingProducerList(true);
      let params = {};
      if (Array.isArray(producerList)) {
        params.pro_id_not_in = Object.values(producerList).reduce((accumulator, {produtor}) => {
          return accumulator + (accumulator !== '' ? ',' : '')  + produtor.pro_id
        }, '');
      }

      ProducerService.filter(params)
      .then(response => {
        if (Array.isArray(response)) {
          setProducerList(response);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setLoadingProducerList(false))
  }

  function handleProductLink() {
    if (!productLink === true) 
      setTimeout(() => getProducerList(), 300);

    setProductLink(!productLink)
  }

  function renderBody() {
    if (!producerList || !producerList.length) {
      return (
        <tr>
          <td colSpan="8">{TEXT_NOTHING_FOUND}.</td>
        </tr>
      )
    }

    return producerList.map((row) => {
      let { produtor, local_embarque } = row;
      let selected = selectedRow && selectedRow.produtor && selectedRow.produtor.pro_id === produtor.pro_id;
      return (
        <tr className={selected ? 'selected' : ''} onClick={() => onClickRow(selected ? {} : row)}>
          <td>{produtor.pro_id || ''}</td>
          <td>
            <Text className='uppercase'>
              {produtor.pro_nome || ''}
            </Text>
          </td>
          <td>
            <Text className='uppercase'>
              { local_embarque ? 
                (local_embarque.cidade ? 
                  local_embarque.cidade.cid_nome + "/" + local_embarque.cidade.estado.est_sigla : '') 
                : '' 
              }
            </Text>
          </td>
          <td>{ TextUtil.formatCpf(produtor.pro_cpf) || '' }</td>
          <td>{ produtor.pro_inscricao_estadual || '' }</td>
          <td>{ produtor.pro_matricula || '' }</td>
          <td></td>
          <td>
            <Button className="show-when-checked inline"
              onClick={(e) => {
                e.stopPropagation();
                onClickChecked(false);
              }}
            >
              {TEXT_EDIT_BOARDING_LOCATION.toLowerCase()}
            </Button>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className={`wac-table-container`}>
      <Table className={loading ? 'loading' : ''}>
        <thead>
          <tr>
            <th colSpan="2">{TEXT_LINKED_PRODUCERS}</th>
            <th></th>
            <th></th> 
            <th></th>
            <th></th>
            <th></th>
            <th>
            <Button className='inline' onClick={handleProductLink}>
              <Icon className="sm" component={<PlusCircleIcon />} />
              <Text className='s14 fw600'>
                {TEXT_LINK_NEW_PRODUCER}
              </Text>
            </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          { renderBody() }
        </tbody>
      </Table>
      <Modal className={`lg ${ productLink ? 'active' : ''}`}>
        <ProducerLink 
          loading={loadingProducerList}
          list={list}
          onClick={handleProductLink} 
          onCreateNewProducer={onCreateNewProducer} 
          locCodigo={locCodigo}
        />
      </Modal>
    </div>
  );
};

export default CollapsedRowContent