/* eslint-disable */
import React, { useEffect, useReducer, useState, useRef } from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import { Title } from 'components/commons/Typography';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import { UFService } from 'core/uf/UFService';
import { CityService } from 'core/city/CityService';
import { Snackbar, TextField } from '@material-ui/core';
import TextUtil from 'core/utils/TextUtil';
import { Destino } from 'core/destino/destino';
import { Option, Select } from 'components/commons/Select';
import Feedback from 'components/commons/Feedback';
import Swal from 'sweetalert2'
import './newBoard.scss';
import ReactDOM from 'react-dom';

// Traduções.
const TEXT_LOADING = `${I18nTranslator.t('LOADING')}...`;
const TEXT_NO_OPTIONS = I18nTranslator.t('NO_OPTION');
const TEXT_CITY = I18nTranslator.t('CITY');
const TEXT_SELECT = I18nTranslator.t('SELECT');
const TEXT_SELECT_STATE = I18nTranslator.t('SELECT_STATE');
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM");
const TEXT_SAVING = I18nTranslator.t("SAVING"); // Salvando
const TEXT_SUCCESS = I18nTranslator.t("SUCCESS"); // Sucesso
const TEXT_ERROR_REGISTER_LOCATION = I18nTranslator.t("ERROR_REGISTER_LOCATION"); // Ocorreu um erro ao tentar cadastrar o local, tente novamente mais tarde!
const TEXT_REGISTER_NEW_LOCATION_DESTINO = I18nTranslator.t("REGISTER_NEW_LOCATION_DESTINO");
const TEXT_ENTER_THE_NAME = I18nTranslator.t("ENTER_THE_NAME"); // Informe o nome
const TEXT_ENTER_THE_ENDERECO = I18nTranslator.t("ENTER_THE_ENDERECO");
const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD"); // Preencher campo
const TEXT_CANCEL = I18nTranslator.t("CANCEL"); // Cancelar
const TEXT_INFORM_EIN = I18nTranslator.t("INFORM_EIN"); // Informe o CNPJ
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição Estadual

const initialState = {
  uf: { list: [], filter: [], loading: false, selected: null, value: '', success: null, label: '' },
  nomeDestino: { value: '', success: null },
  inscricao_estadual: { value: '', success: null },
  cidade: { list: [], filter: [], loading: false, selected: null, value: '', success: null, label: '' },
  cnpj: { value: '', success: null },
  latitude: { value: '', success: null },
  longitude: { value: '', success: null },
  dest_codigo_tipo_local: { value: '', success: null },
  cep: { value: '', success: null },
  endereco: { value: '', success: null, success: null },
  button_submit: { value: TEXT_CONFIRM, disabled: false },
  complemento: { value: '', success: null },
  nome: { value: '', success: null },
  dest_cidade: { list: [], filter: [], loading: false, selected: null, value: '', success: null, label: '' },
  snackbar: { active: false, value: '' },
}

const ACTIONS = {
  CHANGE_LIST: 'change_list',
  CHANGE_VALUE: 'change_value',
  CHANGE_VALUE_AND_VALIDATE: 'validate'
}

function reducer(state, action) {
  let name = action.payload ? action.payload.name : '';
  let value = action.payload ? action.payload.value : '';
  switch(action.type) {
    case ACTIONS.CHANGE_LIST:
      let listObject = { ...state[name] }
      if (action.payload.list) listObject.list = action.payload.list;
      if (action.payload.filter) listObject.filter = action.payload.filter;
      if (typeof action.payload.loading === 'boolean') listObject.loading = action.payload.loading;
      if (typeof action.payload.selected === 'object') listObject.selected = action.payload.selected;
      if (typeof action.payload.value === 'string') listObject.value = action.payload.value;
      if (typeof action.payload.label === 'string') listObject.label = action.payload.label;
      return { ...state, [name]: listObject }

    case ACTIONS.CHANGE_VALUE:
      return { ...state, [name]: { ...state[name], ...action.payload, value: value } }

    case ACTIONS.CHANGE_VALUE_AND_VALIDATE:
      return { ...state, [name]: { ...state[name], value: value, success: (value !== '') } }

    default:
      return state;
  }
}


const NewBoardLocation = ({ onClick, onCreateNewBoardLoaction }) => {

  const tipoLocal = [
    { name: "ARMAZEM/FAZENDA/SILO", value: "9"},
    { name: "PORTO - TERMINAL", value: "2"},
    { name: "SILO BAG", value: "5"},
    { name: "TRANSBORDO - MOEGÕES", value: "7"},
    { name: "TRANSBORDO - TERMINAL", value: "1"},
  ];

  const [state, dispatch] = useReducer(reducer, initialState);
  
  const handleList = ({ name, list, loading, selected, filter, value, label }) => {
    dispatch({ type: ACTIONS.CHANGE_LIST, payload: { name, list, loading, selected, filter, value, label }})
  }

  const handleChange = e => {
    dispatch({ type: ACTIONS.CHANGE_VALUE, payload: { name: e.target.getAttribute('name'), value: e.target.value } })
  }

  const handleChangeValidate = e => {
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: e.target.value, name: e.target.getAttribute('name') } })
  }

  const handleButtonSubmit = (value, disabled = false) => {
    dispatch({ type: ACTIONS.CHANGE_VALUE, payload: { value: value, disabled: disabled, name: 'button_submit' } });
  }

  const handleSnackbar = (value, active = true) => {
    dispatch({ type: ACTIONS.CHANGE, payload: { value: value, name: 'snackbar', active: active } })
  }

  const [uf, setUf] = useState();
  const [cidade, setCidade] = useState('');
  const [endereco,setEndereco] = useState('');
  const [ibge, setIbge] = useState('');
  const [mudanca, setMudanca] = useState('');
  const [tiraEstado, setTiraEstado] = useState(true);
  const [valorTipoLocal, setValorTipoLocal] = useState(true);
  

  useEffect(() => {
    setTimeout(() => getUfList(), 1000);
    setTimeout(() => getCityList(), 2000);
  }, []);

  // Busca lista de estados (UF).
  const getUfList = () => {
    handleList({ name: 'uf', loading: true });
  
    UFService.getUfList()
    .then((response) => {
      if (Array.isArray(response)) {
        handleList({ name: 'uf', list: response, loading: false });
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() =>handleList({ name: 'uf', loading: false }));
  };

  // Busca lista de cidades.
  const getCityList = () => {
    handleList({ name: 'cidade', loading: true });

    CityService.getCityList()
    .then((response) => {
      if (Array.isArray(response)) {
        handleList({ name: 'cidade', list: response, filter: response });
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => handleList({ name: 'cidade', loading: false }))
  }

  // Manipula o evento de mudança no campo UF
  // Filtra as Cidades apartir do Estado (UF) selecionado.
  const handleChangeUf = (e, newUf) => {
    let uf = newUf ? newUf.est_sigla : '';
    
    if (state.uf.selected && state.uf.selected.est_sigla !== uf) {
      handleList({ name: 'cidade', selected: null, value: '', label: '' });
    }
    
    handleList({ name: 'uf', selected: newUf });
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: newUf ? newUf.est_sigla : '', name: 'uf' }})

    if (uf === "") {
      handleList({ name: 'cidade', filter: [], selected: null, value: '', label: '' });
      return;
    }
    
    if (state.cidade.list) {
      let filterCity = state.cidade.list.filter((city) => {
        return city.estado.est_sigla === uf;
      });

      handleList({ name: 'cidade', filter: filterCity })
    }
  }

  
  
  // Manipula o evento de mudança no campo Cidade.
  const handleChangeCity = (e, selectedCity) => {
    handleList({ name: 'cidade', selected: selectedCity, value: selectedCity ? selectedCity.cid_codigo : '', label: selectedCity ? selectedCity.cid_nome : '' });
    dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: selectedCity ?  selectedCity.cid_codigo : '', name: 'cidade' }})
  }

  function formataCep(d){
    d=d.replace(/^(\d{5})(\d)/,"$1-$2")
    return d
}

  const handleSubmit = e => {
    e.preventDefault();
    
    handleButtonSubmit(`${TEXT_SAVING}...`, true);

    let cepFormatado = formataCep(state.cep.value);
    
    let params = {
      dest_nome: state.nomeDestino.value,
      dest_cidade: ibge ? ibge : state.cidade.value,
      dest_cpfcnpj: state.cnpj.value,
      dest_insc_estadual: state.inscricao_estadual.value,
      dest_cep: cepFormatado ? cepFormatado : '',
      dest_endereco: state.endereco.value ? state.endereco.value : endereco,
      dest_complemento: state.complemento.value,
      dest_latitude: state.latitude.value,
      dest_longitude: state.longitude.value,
      dest_codigo_tipo_local: state.dest_codigo_tipo_local.value == null ? '' : state.dest_codigo_tipo_local.value.value ,
    }

    Destino.create(params)
    .then((response) => {
      if(response.success == true){
        Swal.fire({
          position: 'center',
          title: 'Dados Salvos com Sucesso, Obrigado !',
          background: '#59B224',
          color: 'white',
          showConfirmButton: false,
          timer: 3000
        })
        setTimeout(() => {
          cancelForm();
          setUf();
          setCidade();
          setEndereco();
          setIbge();
          state.cidade.selected = null;
          state.uf.selected = null;
          state.uf.value = '';
          state.cep.value = '';
          state.cnpj.value = '';
          var input = document.getElementById("tipoLocal");
          setNativeValue(input, '');
          setValorTipoLocal(false);
          onClick();
        }, 4000);
      }else{
        Swal.fire({
          position: 'center',
          title: response.mensagem,
          background: '#fa3344',
          color: 'white',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
    .catch((error) => errorValidator(error))
    .finally(() => handleButtonSubmit(TEXT_CONFIRM))
  
  }

  function setNativeValue(element, value) {
    value = '';
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    event.simulated = true;
    let tracker = element._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
}

  const cancelForm = () => { 
    document.getElementById("tipoLocal").value = "";
    document.getElementById("form").reset();
  }
  const validateAllElements = () => {
    Object.keys(state).map(key => {
      let row = state[key];
      if (typeof row === 'object') {
        dispatch({ type: ACTIONS.CHANGE_VALUE_AND_VALIDATE, payload: { value: state[key].value, name: key } })
      }
    })
  }

  const errorValidator = (error) => {
    handleButtonSubmit(TEXT_CONFIRM);
    switch(error) {
      case 'not_enough_params':
        validateAllElements();
        return;
      default:
        handleSnackbar(TEXT_ERROR_REGISTER_LOCATION);
        return;
    }
  }

  function onBlurCep(ev){
      
      if(ev.target.value == ''){
        setUf('');
        setCidade('');
        setEndereco('');
        setIbge('');
        return;
      }
      if(ev.target.value.length && ev.target.value.length !== 8){
        return;
      }

      fetch(`https://viacep.com.br/ws/${ev.target.value}/json/`)
        .then((res) => res.json())
        .then((data) => {
            setUf(data.uf);
            setCidade(data.localidade);
            setEndereco(data.logradouro);
            setIbge(data.ibge);
          });

        setMudanca(true);
  }
    
  return (
    <Box>
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
          }}
          key={`snackbar`}
          open={state.snackbar.active}
          onClose={() => handleSnackbar('', false)}
          autoHideDuration={4000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{state.snackbar.value}</span>}
      />
      <Button onClick={onClick} className="button-close" />
      <Title className='s11'>
        {TEXT_REGISTER_NEW_LOCATION_DESTINO}
      </Title>
      <Vspace className='sm'/>
      <Title className='s16'>
        Ao informar o máximo de dados possíveis, você estará colaborando com a evolução do sistema
      </Title>
      <Vspace className='lg'/>
      <form id="form" onSubmit={handleSubmit}>
  
        <Row className='bottom-xs'>
        

          {/* CAMPO DO DESTINO */}

          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={`Nome do Destino *`}
              name='nomeDestino'
              feedback='Informe o Nome do Destino'
              required
              severity={state.nome.success === false && state.nomeDestino.value == '' && 'danger'}
              onChange={(e) => {
                e.target.value = TextUtil.capitalizeFirstLetters(e.target.value);
                handleChangeValidate(e);
              }}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DO CEP */}

          <Col className='col-xs-4 col-lg-2 col-md-12'>
            <Input 
              label={`CEP`}
              name='cep'
              onBlur={onBlurCep}
              defaultValue={state.nome.value}
              feedback= 'Informe o CEP'
              onChange={(e) => {
                e.target.value = TextUtil.capitalizeFirstLetters(e.target.value);
                handleChangeValidate(e);
              }}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DA UF */}

          <Col className='col-xs-2 col-lg-2 col-md-12'>
            <Autocomplete
              id="field_uf"
              name="field_uf"
              value={state.uf.selected}
              options={state.uf.list}
              loading={state.uf.loading}
              loadingText={TEXT_LOADING}
              noOptionsText={TEXT_NO_OPTIONS}
              onChange={handleChangeUf}
              getOptionLabel={(option) => option.est_sigla}
              disableAnimation={true}
              renderInput={(params) => 
                <>
                  <TextField 
                    {...params}
                    autoComplete='off'
                    label="UF *"
                    placeholder={uf === undefined && state.cep === "" ? "..." : uf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>

          {/* CAMPO DA CIDADE */}

          <Col className='col-xs-6 col-lg-10 col-md-12'>
            <Autocomplete
              id="field_cidade"
              value={state.cidade.selected}
              options={state.uf.selected ? state.cidade.filter : []}
              loading={state.cidade.loading}
              loadingText={TEXT_LOADING}
              noOptionsText={state.uf.selected ? TEXT_NO_OPTIONS : TEXT_SELECT_STATE}
              onChange={handleChangeCity}
              getOptionLabel={(option) => option.cid_nome}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                    autoComplete='off'
                    required={(ibge == '' && state.cidade.selected == null) || (ibge == undefined && state.cidade.selected == null) }
                    label={TEXT_CITY}
                    placeholder={cidade === undefined ? (state.uf.selected ? TEXT_SELECT : TEXT_SELECT_STATE) : cidade}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>

          {/* CAMPO DO ENDERECO */}

          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={`Endereço`}
              name='endereco'
              defaultValue= {endereco === undefined ? '' : endereco}
              feedback={TEXT_ENTER_THE_ENDERECO}
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
           
          {/* CAMPO DO COMPLEMENTO */} 

          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label='Complemento'
              name='complemento'
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DO CPF/CNPJ */}

          <Col className='col-xs-6 col-lg-12 col-md-12'>
            <Input 
              label={`CPF/CNPJ`}
              name='cnpj'
              defaultValue={state.cnpj.value}
              feedback={`${TEXT_INFORM_EIN}!`}
              onChange={(e) => {
                e.target.value = TextUtil.formatCpfCnpj(e.target.value);
                handleChangeValidate(e);
              }}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DO TIPO DO LOCAL */}
          <Col className='col-xs-6 col-lg-10 col-md-12'>
            <Autocomplete
              id='tipoLocal'
              name='tipoLocal'
              options={tipoLocal}
              noOptionsText='...'
              onChange={(event, value) => state.dest_codigo_tipo_local.value = value}
              getOptionLabel={(option) => option.name}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                    autoComplete='off'
                    label={'Tipo do Local'}
                    placeholder='...'
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col>

          {/* CAMPO DA INSCRICAO ESTADUAL */}

          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={TEXT_STATE_REGISTRATION}
              name='inscricao_estadual'
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DA LATITUDE */}

          <Col className='col-xs-6 col-lg-12 col-md-12'>
            <Input 
              label='Latitude'
              name='latitude'
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>

          {/* CAMPO DA LONGITUDE */}

          <Col className='col-xs-6 col-lg-12 col-md-12'>
            <Input 
              label='Longitude'
              name='longitude'
              onChange={handleChange}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
        </Row>
        <Vspace className='xxlg'/>
        <hr />
        <Group className='spread fluid'>
          <Button type="reset" onClick={onClick} className='outlined'>
            {TEXT_CANCEL}
          </Button>
          <Button type="submit" disabled={state.button_submit.disabled}>
            {state.button_submit.value}
          </Button>
        </Group>
      </form>
    </Box>
  )
}

export default NewBoardLocation