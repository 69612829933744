import React from "react";

// It must have mask (dd/mm/aaaa) as a workaround for MAC OS Safari (IOS works fine)
const DatePicker = ({ className, placeholder, id, label, feedback, severity, ...props }) => {
  return (
    <label className='control'>
      <div htmlFor={id} className='wac-label' >
        {label}
      </div>
      <div className={`wac-datepicker ${className}`}>
        <input 
          id={id}
          className={`wac-input ${severity}`}
          type="date" 
          placeholder={placeholder} 
          {...props}
        />
        <span className='wac-input-feedback'>
          {feedback}
        </span>
      </div>
    </label>
  )
};

export default DatePicker;