/* eslint-disable */
import React, {useReducer} from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Box from 'components/commons/Box';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import TextUtil from 'core/utils/TextUtil';
import {ProducerService} from 'core/producer/ProducerService';
import {Snackbar} from '@material-ui/core';
import {I18nTranslator} from 'core/i18n/I18nTranslator';

const TEXT_ERROR_LINK_PRODUCER = I18nTranslator.t("ERROR_LINK_PRODUCER"); // Ocorreu um erro ao tentar vincular o produtor, tente novamente mais tarde!
const TEXT_ENTER_THE_NAME = I18nTranslator.t("ENTER_THE_NAME"); // Informe o nome
const TEXT_INFORM_ITIN_EIN = I18nTranslator.t("INFORM_ITIN_EIN"); // Informe o CPF/CNPJ
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM"); // Confirmar
const TEXT_LINKING = I18nTranslator.t("LINKING"); // Vinculando
const TEXT_SUCCESS = I18nTranslator.t("SUCCESS"); // Sucesso
const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD"); // Preencher campo
const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
const TEXT_NAME = I18nTranslator.t("NAME"); // Nome.
const TEXT_STATE_REGISTRATION = I18nTranslator.t("STATE_REGISTRATION"); // Inscrição estadual
const TEXT_REGISTRATION = I18nTranslator.t("REGISTRATION"); // Matrícula
const TEXT_CANCEL = I18nTranslator.t("CANCEL"); // Cancelar

const ACTIONS = {
  VALIDATE: 'validate',
  CHANGE: 'change',
  RESET_STATE: 'reset_state'
}

const validatorState = {
  "pro_nome": { success: null, feedback: `${TEXT_ENTER_THE_NAME}!`, value: '' },
  "pro_cpf": { success: null, feedback: `${TEXT_INFORM_ITIN_EIN}!`, value: '' },
  "pro_inscricao_estadual": { success: null, feedback: '', value: '' },
  "pro_matricula": { success: null, feedback: '', value: '' },
}

const initialState = {
  ...validatorState,
  "snackbar": { active: false, value: '' },
  "button_submit": { disabled: false, value: TEXT_CONFIRM, className: '' }
}

function reducer(state, action) {
  let inputValue = action.payload && action.payload.value;
  let inputName = action.payload && action.payload.name;

  switch (action.type) {
    case ACTIONS.VALIDATE:
      return { ...state, [inputName]: { ...state[inputName], success: ((inputValue && inputValue.length > 0) || (state[inputName].feedback === '' || false)), value: inputValue} }

    case ACTIONS.CHANGE:
      return { ...state, [inputName]: { ...state[inputName], value: inputValue, ...action.payload } }

    case ACTIONS.RESET_STATE:
      console.log("initialState", initialState);
      return initialState;

    default:
      return state;
  }
}

const ProducerRegister = ({ onClick, onCreateNewProducer, locCodigo }) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleValidate = e => {
    dispatch({ type: ACTIONS.VALIDATE, payload: { value: e.target.value, name: e.target.getAttribute('name') } });
  }

  const handleInput = e => {
    dispatch({ type: ACTIONS.CHANGE, payload: { value: e.target.value, name: e.target.getAttribute('name') } })
  }

  const handleSnackbar = (value, active = true) => {
    dispatch({ type: ACTIONS.CHANGE, payload: { value: value, name: 'snackbar', active: active } })
  }

  const handleSubmitButton = (value, disabled, className = '') => {
    dispatch({ type: ACTIONS.CHANGE, payload: { value: value, name: 'button_submit', disabled: disabled, className: className} })
  }

  const resetState = () => {
    dispatch({ type: ACTIONS.RESET_STATE })
  }

  const validateAllElements = () => {
    let params = { "loc_codigo": locCodigo }
    Object.keys(validatorState).map(key => {
      let row = validatorState[key];
      params[key] = state[key].value;
      if (typeof row === 'object' && row.feedback !== '') {
        dispatch({ type: ACTIONS.VALIDATE, payload: { value: state[key].value, name: key } })
      }
    })
    return params;
  }

  const errorValidator = (error) => {
    switch(error) {
      case 'not_enough_params':
        validateAllElements();
        return;
      default:
        handleSnackbar(TEXT_ERROR_LINK_PRODUCER);
        return;
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    
    if (state.button_submit.className !== "") return;

    const params = validateAllElements();

    handleSubmitButton(`${TEXT_LINKING}...`, true);
    ProducerService.create(params)
    .then((response) => {
      if (!response.success) {
        errorValidator();
        handleSubmitButton(TEXT_CONFIRM);
        return;
      }
      handleSubmitButton(`${TEXT_SUCCESS}!`, false, "primary");
      onCreateNewProducer(response);
      
      setTimeout(() => { 
        resetState();
      }, 2000)
    })
    .catch((error) => { 
      errorValidator(error);
      handleSubmitButton(TEXT_CONFIRM);
    });
  }

  return (
    <Box>
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
          }}
          key={`snackbar`}
          open={state.snackbar.active}
          onClose={() => handleSnackbar('', false)}
          autoHideDuration={4000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{state.snackbar.value}</span>}
      />
      <form onSubmit={handleSubmit}>
        <Row className='bottom-xs'>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input
              label={`${TEXT_NAME} *`}
              name='pro_nome'
              onChange={(e) => {
                e.target.value = TextUtil.capitalizeFirstLetters(e.target.value)
                handleValidate(e)
              }}
              onInput={handleInput}
              placeholder={TEXT_FILL_FIELD}
              value={state.pro_nome.value}
              feedback={state.pro_nome.feedback}
              severity={state.pro_nome.success === false && 'danger'}
            />
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={`${TEXT_ITIN}/${TEXT_EIN} *`}
              name='pro_cpf'
              onChange={(e) => {
                e.target.value = TextUtil.formatCpfCnpj(e.target.value)
                handleValidate(e);
              }}
              onInput={handleInput}
              placeholder={TEXT_FILL_FIELD}
              value={state.pro_cpf.value}
              feedback={state.pro_cpf.feedback}
              severity={state.pro_cpf.success === false && 'danger'}/>
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input
              label={TEXT_STATE_REGISTRATION}
              name='pro_inscricao_estadual'
              onInput={handleInput}
              onChange={handleValidate}
              value={state.pro_inscricao_estadual.value}
              feedback={state.pro_inscricao_estadual.feedback}
              severity={state.pro_inscricao_estadual.success === false && 'danger'}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
          <Col className='col-xs-12 col-lg-12 col-md-12'>
            <Input 
              label={TEXT_REGISTRATION}
              name='pro_matricula'
              onInput={handleInput}
              onChange={handleValidate}
              value={state.pro_matricula.value}
              feedback={state.pro_inscricao_estadual.feedback}
              severity={state.pro_matricula.success === false && 'danger'}
              placeholder={TEXT_FILL_FIELD}/>
          </Col>
        </Row>
        <Vspace className='xxlg'/>
        <hr />
        <Group className='spread fluid'>
          <Button type="reset" onClick={onClick} className='outlined'>
            {TEXT_CANCEL}
          </Button>
          <Button type="submit" className={state.button_submit.className}  disabled={state.button_submit.disabled}>
            { state.button_submit.value }
          </Button>
        </Group>
      </form>
    </Box>
  )
}

export default ProducerRegister