import { userAccessService } from 'core/user-access/UserAccessService';
import React, { useState, useEffect } from 'react'
import {Title} from "../../../components/commons/Typography";
import './speed.scss';
import {I18nTranslator} from "../../../core/i18n/I18nTranslator";
import imgSucess from "../../../assets/sucess.png"
import imgExclamacao from "../../../assets/exclamacao.png"

export default function Speed() {

    const user = userAccessService.getUser();
    const dados = {
        "locale":"pt_br",
        "6t14f1QRsm":{
           "usu_codigo":user.usu_codigo,
           "usu_cpf":user.usu_cpf,
           "usu_nome":user.usu_nome,
           "usu_email":user.usu_email
        }
     };
    const jsonDados = JSON.stringify(dados)
    const [redirect, setRedirect] = useState(false);
    const [enviaRequisicao, setEnviaRequisicao] = useState(true);
    const [mensagemMobile, setMensagemMobile] = useState(false);
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
    };
    const style = { width: '100%', height: '100%' };
    const styleImg = { width: '80px' };
    const styleImgExc = { width: '80px' };
    const styleForm = { display: 'none' };

    function redirecionamentoSpeed() {
        document.getElementById("btSend").click();
    };

    function realizaRequisicao () {
        if(enviaRequisicao == true) {
            setTimeout(() => {
                redirecionamentoSpeed();
                setRedirect(true);
                setEnviaRequisicao(false);
            }, 2000);
        }
    }

    function ScreenSize() {
        useEffect(() => {
          window.addEventListener('resize', detectSize)
          return () => {
            window.removeEventListener('resize', detectSize)
          }
        }, [windowDimenion])
    }
        
    useEffect(() => {
        if(windowDimenion.winWidth > 500 ) {
            realizaRequisicao();
        }else{
            setMensagemMobile(true);
        }
    },[])

    return (
    <div style={style}>
        <div>
            <Title>Speed</Title>
        </div> 
        <form  action="http://speed.geneslab.com.br/account/loginExterno" target="_blank" enctype="multipart/form-data" method="post" novalidate style={styleForm}>
            <input type="text" name="key" value="6t14f1QRsm" /><br/>
            <textarea rows="15" cols="150" name="json">{jsonDados}</textarea><br/>
            <input id="btSend" type="submit" value="Send"/>
        </form> 
        {redirect == false && mensagemMobile == false ? 
        <div id="root">
            <div class="loader-container">
                <span class='titulo'>{I18nTranslator.t("REDIRECT_SPEED")}</span>
                <div class="loader">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        : '' }
        {redirect == true ? 
        <div id="root">
            <div class="loader-container">
                <span class='titulo'>{I18nTranslator.t("SUCESS_REDIRECT_SPEED")}</span>
                <div className='wac-steps-progress-item'>
                    <img src={imgSucess} alt="imgSucess" style={styleImg}/>
                </div>
            </div>
        </div>
        : '' }
        {mensagemMobile == true ? 
        <div id="root">
            <div class="loader-container">
                <span class='tituloExclamacao'>{I18nTranslator.t("MSG_SPEED_MOBILE")}</span>
                <div className='wac-steps-progress-item'>
                    <img src={imgExclamacao} alt="imgExclamacao" style={styleImgExc}/>
                </div>
            </div>
        </div>
        : '' }
    </div>
    );
}
