/* eslint-disable */
import React, {useEffect, useState} from 'react';
import MainRoutes from './MainRoutes';
import Header from 'components/commons/Header';
import Group from 'components/commons/Group';
import Image from 'components/commons/Image';
import LogoHeader from 'assets/img/logo-genesis.png';
import LogoFooter from 'assets/img/logo-footer.png';
import {Menu, MenuItem} from 'components/commons/Menu';
import {Content, Footer, Layout, LayoutMain, Sidebar,} from 'components/commons/Layout';
import {ChartIcon, ClockIcon, EditIcon, HomeIcon, LogoutIcon, BoardIcon, SpeedIcon} from 'components/Icons';
import Icon from 'components/commons/Icon';
import Vspace from 'components/commons/Vspace';
import {UserService} from 'core/user/UserService';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import {userAccessService} from 'core/user-access/UserAccessService';
import {OneSignalService} from "core/onesignal/OneSignalService";
import {configuration} from "../../config";

import {Link} from "react-router-dom";
import ConfigDropdown from "./components/ConfigDropdown";
import NotificationDropdown from "./components/NotificationDropdown";
import NotificationWhatsApp from "./components/NotificationWhatsApp";
import {Text} from "../../components/commons/Typography";
import TermsOfUse from "./components/TermsOfUse/index.js";
import { TermoDeUso } from 'core/termoDeUso/termoDeUso';


function Main() {

  const [menuActive, setMenuActive] = useState(false);

  // Itens do menu
  const [menuInbox, setMenuInbox] = useState(false);
  const [menuCadastrarOs, setMenuCadastrarOs] = useState(false);
  const [menuEditarOs, setMenuEditarOs] = useState(false);
  const [menuTempoReal, setMenuTempoReal] = useState(false);
  const [menuPanorama, setMenuPanorama] = useState(false);
  const [menuMuralAvisos, setMenuMuralAvisos] = useState(false);
  const [logout, setLogout] = useState(false);
  const [timeoutMenuId, setTimeoutMenuId] = useState(undefined);
  const [muda, setMuda] = useState(1);
  const [verTermo, setVerTermo] = useState("");
  const [abre, setAbre] = useState(true);
  const [menuSpeed, setMenuSpeed] = useState(false);

  const user = userAccessService.getUser();

  const onBlurHandlerMenu = () => {
    setTimeoutMenuId(
        setTimeout(() => closeMenu())
    )
  }
  const onFocusHandlerMenu = () => {
    clearTimeout(timeoutMenuId)
  }

  const closeMenu = () => {
    setMenuActive(false)
  }

  const OneSignal = window.OneSignal  || [];

  useEffect(() => {
    handleEndpoint(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    OneSignal.push(() => {
      saveUserId();
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          saveUserId();
        }
      })
      OneSignal.init(
          {
            appId: configuration.ONE_SIGNAL_APP_ID,
            promptOptions: {
              exampleNotificationTitleDesktop: "Cliconnect",
              exampleNotificationTitleMobile: "Cliconnect",
              exampleNotificationMessageDesktop: "Cliconnect",
              exampleNotificationMessageMobile: "Cliconnect",
              exampleNotificationCaption: I18nTranslator.t("YOU_CAN_UNSUB_ANY_TIME"),
              actionMessage: I18nTranslator.t("WE_WOULD_LIKE_TO_SEND_YOU_NOTIFICATIONS"),
              acceptButtonText: I18nTranslator.t("ALLOW"),
              cancelButtonText: I18nTranslator.t("NOT_TODAY"),
              showCredit: false,
            },
            welcomeNotification: {
              "disable": true,
              "title": "Cliconnect",
              "message": I18nTranslator.t("THANKS_FOR_SUBSCRIBE"),
            }
          },
      )
    })
  }, [])

  const saveUserId = () => {
    OneSignal.getUserId((user) => {
      if (user) {
        OneSignalService.saveUserId(user)
            .then((response) => {
              if (response && response.success) {
                let user = userAccessService.getUser()
                if (user) OneSignal.setExternalUserId(user.usu_cpf);
              }
            })
            .catch((error) => {
              console.error(error);
            });
      }
    })
  }

  var dicionarioMenuEndpoint = {
    '/inbox': setMenuInbox,
    '/ticket-register': setMenuCadastrarOs,
    '/ticket-editing': setMenuEditarOs,
    '/real-time': setMenuTempoReal,
    '/panorama': setMenuPanorama,
    '/notice-board': setMenuMuralAvisos,
    '/speed' : setMenuSpeed
  };

  function handleEndpoint(endpointName, redirect = false) {
    if (redirect) document.location.href = endpointName;

    if (endpointName === "/") {
      setMenuInbox(true);
      return;
    }

    for (const [key, method] of Object.entries(dicionarioMenuEndpoint)) {
      method(endpointName.includes(key));
    }
  }

  function handleMenu() {
    setMenuActive(menuActive === false);
  }

  const disconnect = () => {
    setLogout(true);
    UserService.logout()
        .finally(() => setTimeout(() => setLogout(false), 3000));
  }


  return (
      <LayoutMain>
        <Header>
          <Group className='spread vc'>
            <button
                className="layout-burguer-menu hod"
                onClick={handleMenu}
            />
            <Link to={'/inbox'}>
              <Image src={LogoHeader} alt='logo' height='25px' className='main-logo clickable'/>
            </Link>
            <Group className='spread'>
              <NotificationWhatsApp />  
              <NotificationDropdown />
              <ConfigDropdown />
            </Group>
          </Group>
        </Header>
        <Layout>
          <Sidebar
              className={ menuActive ? 'active' : '' }
              onClick={handleMenu}
              onBlur={onBlurHandlerMenu}
              onFocus={onFocusHandlerMenu}
          >
            <Menu>
              <MenuItem link='/inbox'
                        className={menuInbox ? 'active' : ''}
                        onClick={(e) => handleEndpoint('/inbox')}
              >
                <Icon component={<HomeIcon />} />
                <span className="wac-menu-link-label">Inbox</span>
              </MenuItem>
              {
                userAccessService.hasPermission("addOS") ?
                    (<MenuItem
                        link='/ticket-register'
                        className={menuCadastrarOs || menuEditarOs ? 'active' : ''}
                        onClick={(e) => handleEndpoint('/ticket-register')}
                    >
                      <Icon component={<EditIcon />} />
                      <span className="wac-menu-link-label">{I18nTranslator.t("OS_REGISTRATION")}</span>
                    </MenuItem>): ""
              }
              <MenuItem link='/real-time'
                        className={menuTempoReal ? 'active' : ''}
                        onClick={(e) => handleEndpoint('/real-time')}
              >
                <Icon component={<ClockIcon />} />
                <span className="wac-menu-link-label">{I18nTranslator.t("REAL_TIME")}</span>
              </MenuItem>
              {
                user && user.usu_usuario_panorama === 'S' ?
                    (<MenuItem link='/panorama'
                               className={menuPanorama ? 'active' : ''}
                               onClick={() => handleEndpoint('/panorama')}>
                      <Icon component={<ChartIcon />} />
                      <span className="wac-menu-link-label">Panorama BI</span>
                    </MenuItem>) : null
              }
              <MenuItem link='/notice-board'
                        className={menuMuralAvisos ? 'active' : ''}
                        onClick={(e) => handleEndpoint('/notice-board')}
              >
                <Icon component={<BoardIcon />} />
                <span className="wac-menu-link-label">{I18nTranslator.t("NOTICE_BOARD")}</span>
              </MenuItem>
              {
                userAccessService.hasPermission("mainSpeed") ?
                  (<MenuItem link='/speed'
                            className={menuSpeed ? 'active' : ''}
                            onClick={(e) => handleEndpoint('/speed')}  
                  >
                    <Icon component={<SpeedIcon />} />
                    <span className="wac-menu-link-label">Speed</span>
                  </MenuItem>) : ""
              }
              <MenuItem link='/' className='hod' onClick={disconnect}>
                <Icon component={<LogoutIcon />}/>
                <span className="wac-menu-link-label">
                {logout ? `${I18nTranslator.t("DISCONNECTING")}...` : I18nTranslator.t("DISCONNECT")}
              </span>
              </MenuItem>
            </Menu>
            {menuActive ? <Text className={"bottom-item-menu"}>{configuration.VERSION_NAME}</Text> : ''}
          </Sidebar>
          <Content id="main-content" onClick={closeMenu}>
            <MainRoutes />
            <Vspace className='xxlg hom'/>
            <Vspace className='hod'/>
            <Footer>
              <Image src={LogoFooter} alt='logo' height='25px' />
            </Footer>
          </Content>
        </Layout>
        <TermsOfUse /> 
      </LayoutMain>
  );
}
export default Main;
