import React from "react";

const Icon = ({ className, component, size, color, ...props }) => {
  return (
    <div {...props} className={`wac-icon ${className} ${size} ${color}`}>
      {component}
    </div>
  );
};

export default Icon;
