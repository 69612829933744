/* eslint-disable */
import React, {useEffect, useState} from 'react';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import {Text} from 'components/commons/Typography';
import Table from 'components/commons/Table';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import Vspace from 'components/commons/Vspace';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import {RatingItemService} from "../../../../../../../core/cargo/RatingItemService";

var Barcode = require('react-barcode');


const ShowLaudo = (props) => {
    // Props.
    var {
        handleClose,
        dataObject
    } = props;


    var {
        ordem_servico,
        CidEmbarque,
        iac_peso,
        iac_nome_motorista,
        iac_cpf_motorista,
        iac_rg_motorista,
        iac_cnh_motorista,
        iac_antt,
        iac_ordemcoleta,
        iac_peso,
        iac_nota_fiscal_origem,
        iac_codigo,
        iac_peso_tara,
        iac_placa,
        iac_peso_bruto,
        iac_danif_insetos,
        iac_sementes_toxicas,
        iac_nota_fiscal,
        iac_nota_fiscal_dtemissao,
        iac_nota_fiscal_cfop,
        iac_nota_fiscal_valorunit,
        iac_tipo_produto,
        transportadora
    } = dataObject
    var {local_embarque, slo_contratocompra,produto} = ordem_servico
    var {pro_nome} = produto
    var {loc_nome, loc_razao, loc_cpfcnpj, loc_ie, loc_endereco, loc_telefone} = local_embarque

    if (transportadora) {
        var {transp_nome, transp_cnpj, transp_endereco, transp_fone} = transportadora
    }


    // Traduções.
    const TEXT_BOARD_LOCATION = I18nTranslator.t("BOARD_LOCATION");
    const TEXT_SOCIAL_REASON = I18nTranslator.t("SOCIAL_REASON");
    const TEXT_SHIPPING_INFORMATION = I18nTranslator.t("SHIPPING_INFORMATION");
    const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
    const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
    const TEXT_ADDRESS = I18nTranslator.t("ADDRESS");
    const TEXT_IE = I18nTranslator.t("IE");
    const TEXT_PHONE = I18nTranslator.t("PHONE");
    const TEXT_BOARD_CITY = I18nTranslator.t("BOARD_CITY");
    const TEXT_CLASSIFICATION_DATA = I18nTranslator.t("CLASSIFICATION_DATA");
    const TEXT_PRODUCT = I18nTranslator.t("PRODUCT");
    const TEXT_HUMIDITY = I18nTranslator.t("HUMIDITY");
    const TEXT_TOTAL_DAMAGES = I18nTranslator.t("TOTAL_DAMAGES");
    const TEXT_BURNT_BURNT = I18nTranslator.t("BURNT_BURNT");
    const TEXT_BURNT = I18nTranslator.t("BURNT");
    const TEXT_CHOPPED = I18nTranslator.t("CHOPPED");
    const TEXT_LIVE_INSECTS = I18nTranslator.t("LIVE_INSECTS");
    const TEXT_PH = I18nTranslator.t("PH");
    const TEXT_INSECTS = I18nTranslator.t("INSECTS");
    const TEXT_MOLDY = I18nTranslator.t("MOLDY");
    const TEXT_GREEN = I18nTranslator.t("GREEN");
    const TEXT_PRODUCT_TYPE = I18nTranslator.t("PRODUCT_TYPE");
    const TEXT_IMPURE_STRANGE_MATERIALS = I18nTranslator.t("IMPURE_STRANGE_MATERIALS");
    const TEXT_BROKEN_KNEADED_PARTY = I18nTranslator.t("BROKEN_KNEADED_PARTY");
    const TEXT_FERMENTED = I18nTranslator.t("FERMENTED");
    const TEXT_TEMPERATURE = I18nTranslator.t("TEMPERATURE");
    const TEXT_PMS = I18nTranslator.t("PMS");
    const TEXT_TOXIC_RESTRICTIVE_SEEDS = I18nTranslator.t("TOXIC_RESTRICTIVE_SEEDS");
    const TEXT_CARRIER_INFORMATION = I18nTranslator.t("CARRIER_INFORMATION");
    const TEXT_SHIPPING_COMPANY = I18nTranslator.t("SHIPPING_COMPANY");
    const TEXT_CNH = I18nTranslator.t("CNH");
    const TEXT_CITY = I18nTranslator.t("CITY");
    const TEXT_DRIVER_DATA = I18nTranslator.t("DRIVER_DATA");
    const TEXT_Driver = I18nTranslator.t("Driver");
    const TEXT_RG = I18nTranslator.t("RG");
    const TEXT_ANTT = I18nTranslator.t("ANTT");
    const TEXT_YES = I18nTranslator.t("YES");
    const TEXT_NO = I18nTranslator.t("NO");
    const TEXT_ERROR_WHILE_TRYING_LOAD_INFORMATION = I18nTranslator.t("ERROR_WHILE_TRYING_LOAD_INFORMATION");
    const TEXT_LOADING_ORDER = I18nTranslator.t("LOADING_ORDER");
    const TEXT_INVOICE = I18nTranslator.t("INVOICE");
    const TEXT_ISSUE_DATE = I18nTranslator.t("ISSUE_DATE");
    const TEXT_TARE_WEIGHT = I18nTranslator.t("TARE_WEIGHT");
    const TEXT_NET_WEIGHT = I18nTranslator.t("NET_WEIGHT");
    const TEXT_GROSS_WEIGHT = I18nTranslator.t("GROSS_WEIGHT");
    const TEXT_CFOP = I18nTranslator.t("CFOP");
    const TEXT_UNITARY_VALUE = I18nTranslator.t("UNITARY_VALUE");
    const TEXT_CAR_PLATE = I18nTranslator.t("CAR_PLATE");
    const TEXT_TOTAL_VALUE = I18nTranslator.t("TOTAL_VALUE");
    const TEXT_CONTRACT = I18nTranslator.t("CONTRACT");
    const TEXT_LOAD_INFORMATION = I18nTranslator.t("LOAD_INFORMATION");

    const traducaoDadosClassificacao = {
        "UMIDADE" : TEXT_HUMIDITY,
        "QUEIMADOS" : TEXT_BURNT,
        "ARDIDOSEQUEIMADOS" : TEXT_BURNT_BURNT,
        "PICADOS" : TEXT_CHOPPED,
        "MOFADOS" : TEXT_MOLDY,
        "ESVERDEADOS" : TEXT_GREEN,
        "MATRIASESTRANHASEIMPUREZAS" : TEXT_IMPURE_STRANGE_MATERIALS,
        "TEMPERATURA" : TEXT_TEMPERATURE,
        "FERMENTADOS" : TEXT_FERMENTED,
        "INSETOSVIVOS" : TEXT_LIVE_INSECTS,
        "TIPODOPRODUTO" : TEXT_PRODUCT_TYPE,
        "AVARIADOSTOTAIS" : TEXT_TOTAL_DAMAGES,
        "PARTQUEBAMASSADOS" : TEXT_BROKEN_KNEADED_PARTY,
        "PH": TEXT_PH,
        "PMS": TEXT_PMS,
    }





    const defaultData = {
        [TEXT_SHIPPING_INFORMATION]: {
            [TEXT_BOARD_LOCATION]: loc_nome,
            [TEXT_SOCIAL_REASON]: loc_razao,
            [`${TEXT_ITIN}/${TEXT_EIN}`]: loc_cpfcnpj,
            [TEXT_IE]: loc_ie,
            [TEXT_ADDRESS]: loc_endereco,
            [TEXT_PHONE]: loc_telefone,
            [TEXT_BOARD_CITY]: CidEmbarque,
        },
        [TEXT_CLASSIFICATION_DATA]: {},
        [TEXT_CARRIER_INFORMATION]: {
            [TEXT_SHIPPING_COMPANY]: (transp_nome || ""),
            [TEXT_EIN]: (transp_cnpj || ""),
            [TEXT_ADDRESS]: (transp_endereco || ""),
            [TEXT_PHONE]: (transp_fone || ""),
            [TEXT_CITY]: transportadora && (transportadora.cidade.cid_nome != null)? transportadora.cidade.cid_nome + "-" + transportadora.cidade.estado.est_sigla : ""
        },
        [TEXT_DRIVER_DATA]: {
            [TEXT_Driver]: iac_nome_motorista,
            [TEXT_ITIN]: iac_cpf_motorista,
            [TEXT_CNH]: iac_cnh_motorista,
            [TEXT_RG]: iac_rg_motorista,
            [TEXT_ANTT]: iac_antt
        },
        [TEXT_LOAD_INFORMATION]: {
            [TEXT_LOADING_ORDER]: iac_ordemcoleta,
            [TEXT_TARE_WEIGHT]: iac_peso_tara,
            [TEXT_INVOICE]: iac_nota_fiscal,
            [TEXT_NET_WEIGHT]:  `${iac_peso} Tons` ,
            [TEXT_ISSUE_DATE]: iac_nota_fiscal_dtemissao,
            [TEXT_GROSS_WEIGHT]: `${iac_peso_bruto} Tons` ,
            [TEXT_CFOP]: iac_nota_fiscal_cfop,
            [TEXT_UNITARY_VALUE]: `${iac_nota_fiscal_valorunit} Tons` ,
            [TEXT_CAR_PLATE]: iac_placa,
            [TEXT_TOTAL_VALUE]: `${iac_nota_fiscal_valorunit * iac_peso} Tons` ,
            [TEXT_CONTRACT]: slo_contratocompra,
        }
    };

    console.log(dataObject)
    // State.
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        // Busca os itens de classificação (Dados dos Padrões do Serviço).
        getRatingItems();
    }, [iac_codigo]);

    const getRatingItems = () => {
        var er = /[^a-z0-9]/gi;
        setLoading(true);
        RatingItemService.filter({'iac_codigo':iac_codigo})
            .then((response) => {
                if (Array.isArray(response)) {
                    let newData = defaultData;
                    newData[TEXT_CLASSIFICATION_DATA][TEXT_PRODUCT] = pro_nome;
                    newData[TEXT_CLASSIFICATION_DATA][TEXT_PRODUCT_TYPE] = iac_tipo_produto;
                    response.map((row) => {
                        newData[TEXT_CLASSIFICATION_DATA][traducaoDadosClassificacao[row.item_nome.toUpperCase().replace(er, "")]] = (row.pi_valor === "--" ? '0.00%' : `${row.pi_valor}%`);
                    });

                    newData[TEXT_CLASSIFICATION_DATA][TEXT_INSECTS] = (iac_danif_insetos === 'S' ? TEXT_YES : TEXT_NO);
                    newData[TEXT_CLASSIFICATION_DATA][TEXT_TOXIC_RESTRICTIVE_SEEDS] = (iac_sementes_toxicas === 'S' ? TEXT_YES : TEXT_NO);


                    setData(newData);
                }
            })
            .catch()
            .finally(() => setLoading(false));
    }
    const renderSections = (data) => {
        try {
            return Object.keys(data).map((key, index) => {
                return (
                    <div key={index}>
                        <Table>
                            <thead>
                            <tr className={`has-arrow`}>
                                <th>
                                    <Text className="fw700 uppercase" children={key}/>
                                </th>
                            </tr>
                            </thead>
                        </Table>
                        <Box>
                            <Row>
                                {
                                    Object.keys(data[key]).map((keyRow, index) => {
                                        return (
                                            <Col key={index} className={`col-xs-6 col-lg-12`}>
                                                <Text
                                                    className={`fw700`}
                                                    children={keyRow}/>:&nbsp;
                                                <Text
                                                    className={`fw500`}
                                                    children={data[key][keyRow]}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <Vspace/>

                        </Box>
                    </div>
                );
            });
        } catch (error) {
            return (<Text>{TEXT_ERROR_WHILE_TRYING_LOAD_INFORMATION}</Text>);
        }
    }
    let barcode = ""
    if (iac_nota_fiscal_origem.length === 44) {
        barcode = <Barcode value={iac_nota_fiscal_origem}/>
    }

    return (
        <Box>
            <Button onClick={handleClose} className="button-close"/>
            <div className={`scroll-cols ${loading ? 'loading' : ''}`}>
                {renderSections(data)}
            </div>
            <div>
                <Box>
                    <Row className={`col-xs-6 col-lg-12 f-h-center`}>
                        {barcode}
                    </Row>
                </Box>
            </div>
        </Box>
    )
}

export default ShowLaudo