/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Group from 'components/commons/Group';
import Vspace from 'components/commons/Vspace';
import Box from 'components/commons/Box';
import { Link } from 'react-router-dom';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import { Text, Title } from 'components/commons/Typography';
import { Option, Select } from 'components/commons/Select';
import { ProtocoloAnaliseService } from 'core/protocolo_analise/ProtocoloAnaliseService';
import { I18nTranslator } from 'core/i18n/I18nTranslator';

function Aflatoxin({ prevStep, nextStep, handleChange, values }) {
  const [protocoloList, setProtocoloList] = useState([]);
  const [loadingProtocoloList, setLoadingProtocoloList] = useState(false);
  const [protocolo, setProtocolo] = useState(values.cpt_codigo || '');
  const [fita, setFita] = useState(values.spa_forn_fita_afla || '');

  const getProtocoloAnalise = () => {
    setLoadingProtocoloList(true);
    ProtocoloAnaliseService.filter({ cli_cpfcnpj: values.branch.cli_cpfcnpj, ptc_ativo: 'S', cpt_ativo: 'S' })
    .then((response) => {
      if (Array.isArray(response)) {
        setProtocoloList(response);
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => setLoadingProtocoloList(false));
  }

  useEffect(() => {
    getProtocoloAnalise();
  }, []);

  const handleChangeProtocolo = e => {
    let cpt_codigo = parseInt(e.target.value);
    let selectedObj = protocoloList.find(obj => obj.cpt_codigo === cpt_codigo);
    let fita = selectedObj ? selectedObj.cpt_forn_fita : '';
    setProtocolo(cpt_codigo);
    setFita(fita);
    handleChange('cpt_codigo', cpt_codigo);
    handleChange('spa_forn_fita_afla', fita);
    handleChange('protocolo', selectedObj);
  }

  return (
    <>
      <Vspace />
      <Row className='start-xs'>
        <Col>
          <Title>{I18nTranslator.t("REGISTER_SERVICE_ORDER")}</Title>
        </Col>
      </Row>
      <Vspace />
      <Box className='ghost'>
        <Row className='start-xs'>
          <Col>
            <Text className='s11 fw500'>{I18nTranslator.t("")}</Text>
          </Col>
        </Row>
        <Vspace className='lg'/>
        <Row className='bottom-xs'>
          <Col className='col-xs-6 col-lg-6 col-md-12'>
            <Select
              id='cpt_codigo'
              name='cpt_codigo'
              label={I18nTranslator.t("ANALYSIS_PROTOCOL")}
              value={protocolo}
              onChange={handleChangeProtocolo}
            >
              {
                loadingProtocoloList ? 
                (<Option value='' selected>{I18nTranslator.t("LOADING")}...</Option>) 
                :
                protocoloList.length ? 
                [
                  <Option value="">{I18nTranslator.t("SELECT")}</Option>,
                  protocoloList.map(
                    (row) => {
                      return (
                        <Option value={row.cpt_codigo}>
                          {row.ptc_descricao}
                        </Option>
                      )
                    }
                  )
                ] : (<Option value='' selected>{I18nTranslator.t("NOTHING_FOUND")}</Option>)
              }
            </Select>
          </Col>
          
          <Col className='col-xs-6 col-lg-6 col-md-12'>
            <Select
              disabled
              id='spa_forn_fita_afla'
              name='spa_forn_fita_afla'
              label={I18nTranslator.t("RIBBON_SUPPLIER")}
              value={fita}
            >
                {
                  loadingProtocoloList ? 
                  (<Option value='' selected>{I18nTranslator.t("LOADING")}...</Option>)
                  :
                  (<React.Fragment>
                    <Option value=''>...</Option>
                    <Option value='RS' selected={fita === 'RS'}>R-Bio Semi</Option>
                    <Option value='RQ' selected={fita === 'RQ'}>R-Bio Quant</Option>
                    <Option value='O' selected={fita === 'O'}>{I18nTranslator.t("OTHER_BRANDS")}</Option>
                    <Option value='N' selected={fita === 'N'}>{I18nTranslator.t("NOT_DECLARED")}</Option>
                  </React.Fragment>)
                }
            </Select>
          </Col>
        </Row>
        <Vspace className='xxxlg'/>
        <Group className='spread'>
          <Link 
            className='wac-button outlined' 
            onClick={prevStep}>
            {I18nTranslator.t("BACK")}
          </Link>
          <Link 
            className='wac-button' 
            onClick={nextStep}>
            {I18nTranslator.t("CONFIRM")}
          </Link>
        </Group>
      </Box>
    </>
  );
}

export default Aflatoxin;
