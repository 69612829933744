import React from "react";
import Group from "components/commons/Group";
import Vspace from "components/commons/Vspace";
import { Link } from "react-router-dom";
import { Paragraph, Text } from "components/commons/Typography";
import Icon from 'components/commons/Icon';
import { CheckIcon } from 'components/Icons';

function Conclusion({ values }) {
  return (
    <>
    <Vspace />
      <Group className="fh fw vc fdc hc">
        <Icon className='xlg' 
          component={
            <CheckIcon />
          }
        />
        <Vspace />
        <Text className='s11 fw600'>
          { values.edit ? 'Sua ordem de serviço foi atualizada!' : 'Sua ordem de serviço foi lançada!' }
        </Text>
        <Vspace />
        {
          values.edit ? '' :
          (<React.Fragment>
            <Paragraph className='mw320 f-h-center'>
              Alguns itens ainda podem ser editados, basta clicar na parte de edição da sua OS criada.
            </Paragraph>
            <Vspace />
            <Link 
              onClick={() => window.location.reload()}
              className='wac-button'>
              Cadastrar nova OS
            </Link>
          </React.Fragment>)
        }
        
        <Vspace />
        <Link 
        className='wac-button secondary'
        to='/inbox'>
          Voltar para Inbox
        </Link>
      </Group>
    </>
  );
}

export default Conclusion;
