import { Col, Row } from 'components/commons/FlexBoxGrid';
import Icon from 'components/commons/Icon';
import { Text, Title } from 'components/commons/Typography';
import Vspace from 'components/commons/Vspace';
import { SearchIcon } from 'components/Icons';
import React from 'react';

const NotFound = () => {
    return (
        <>
            <Row>
                <Col className={'col-md-12 f-h-center'}>
                    <Vspace className={'lg'} />
                    <Icon className={`lg`} component={<SearchIcon />} />
                    <Vspace className={'lg'} />
                    <Title className={'s1'}>404</Title>
                    <Text className={'s8'}>A URL solicitada não foi encontrado neste servidor</Text>
                </Col>
            </Row>
        </>
    )
}

export default NotFound;