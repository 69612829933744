import React from "react";
import Icon from "../Icon";
import Button from 'components/commons/Button';


const ChatAction = ({onInput,buttonDisabled,message,onFocus}) => {

    return (
        <div className="chat-actions">
            <input type="text" onInput={onInput} onFocus={onFocus} className="chat-input" name="input_text_message" id="input_text_message" placeholder="Digite uma mensagem" value={message} />
            <Button disabled={buttonDisabled} className='featured-square' type="submit" id="btn_send_message">
                <Icon
                    component={
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.269 2.49408C21.585 1.41605 20.5839 0.415044 19.5059 0.731012L1.59985 5.97927C0.344441 6.34723 0.211414 8.07276 1.39559 8.62879L7.39582 11.4462C7.85564 11.6621 8.39575 11.6161 8.81243 11.3255L14.9656 7.03438L10.6767 13.1907C10.3865 13.6073 10.3407 14.1471 10.5564 14.6066L13.3713 20.6036C13.9273 21.7879 15.6529 21.655 16.0209 20.3995L21.269 2.49408Z"
                                fill="#4F4F4F"/>
                        </svg>
                    }/>
            </Button>
        </div>
    );
}
export default ChatAction