let config = {
    BACKEND_BASE_URL: (process.env.REACT_APP_WEBSERVICE_URL || 'http://localhost/webservice_geld') + '/mGeld2/',
    AUTH_BASE_URL: (process.env.REACT_APP_AUTH_SERVER_URL || 'http://oauth.server') + '/',
    SYS_LAUDO_GENESLAB: "https://app.geneslab.com.br/classificacao/ordemservico/painel_cargas/ajax/ajax.getLaudo.php?laudo=",
    SYS_LAUDO_CLASSNORTE: "https://app.classnorte.com/classificacao/ordemservico/painel_cargas/ajax/ajax.getLaudo.php?laudo=",
    SYS_LAUDO_GR: "https://app.grclassificacoes.com/classificacao/ordemservico/painel_cargas/ajax/ajax.getLaudo.php?laudo=",
    AUTH_CLICONNECT_CODE: "63ae0fa3a6776724d45129f026e78cfd",
    ONE_SIGNAL_APP_ID: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    VERSION_NAME: process.env.REACT_VERSION_NAME || ''
};

export let configuration = config;

