import React from 'react';

export const TableHead = ({ columns }) => {
  return (
      <thead>
          <tr>
              { columns.map(column => <th>{column}</th>) }
          </tr>
      </thead>
  )
}

const defaultI18n = {
  NOTHING_FOUND: 'Nothing found'
}
export const TableBody = ({ columns, data, offset, pageSize, statusList = {}, i18n = defaultI18n, onClick = () => {}, maxCell, onMaxCell = (cellValue) => { return cellValue }  }) => {
  let rows = (pageSize ? data.slice(offset, offset + pageSize) : data);
  return (
      <tbody>
      {
          Array.isArray(rows) && rows.length ? 
          (
            rows.map((row) => {
              let statusName = statusList[row.status] || row.status || '';
              let statusClassName = statusList[row.status] ? `has-status ${row.status}` : '';
              return (
                  <tr className={row.className || statusClassName} onClick={(e) => onClick(e, row)}>
                      { columns.map((column, index) => {
                          let rowProps = {};
                          let value = row[column] || '';
                          if (!index && statusName) rowProps.status = statusName;
                          if (maxCell && value.length > maxCell)  {
                            rowProps.status = value;
                            value = onMaxCell(value);
                          }
                          return <td {...rowProps}>{value}</td>
                        }) 
                      }
                  </tr>
              )
            })
          ) : (<tr><td colSpan={columns.length}>{i18n.NOTHING_FOUND}</td></tr>)
      }
      </tbody>
  )
}

const Table = ({ id, className, children }) => {
  return (
    <table id={id} className={`wac-table ${className}`}>
      {children}
    </table>
  );
};

export default Table;