import React from 'react';
import PropTypes from 'prop-types';
import { MailIcon } from 'components/Icons';
import Icon from '../Icon';

const MailLink = ({email, subject, body}) => {
    if (email) {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
        
        return (
            <span style={{wordBreak: 'break-all'}}>
            {email}&nbsp;
            <a href={`mailto:${email}${params}`} style={{verticalAlign: 'sub'}}>
                <Icon className="sm" component={<MailIcon />} />
            </a>
            </span>
        )
    }
    
    return null;
}

MailLink.defaultProps = {
    email: "",
    subject: "",
    body: ""
}

MailLink.propTypes = {
    email: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
}

export default MailLink;