/* eslint-disable */
import React from "react";
import Group from "components/commons/Group";
import Vspace from "components/commons/Vspace";
import { Text, Title } from "components/commons/Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TicketPanel from './modules/TicketPanel';
import CargoPanel from './modules/CargoPanel';
import 'react-tabs/style/react-tabs.css';
import { I18nTranslator } from "core/i18n/I18nTranslator";

const tabIndexList = {
  "service_orders": 0,
  "cargo_panel": 1
}
function Inbox() {
  const windowUrl = window.location.href;
  const defaultIndex = tabIndexList[windowUrl.split('#').pop()] || 0;
  const handleUrl = (href) => document.location.href = href;
  return (
    <>
      <Title>
        {I18nTranslator.t('INBOX')}
      </Title>
      <Vspace />
      <Tabs defaultIndex={defaultIndex}>
        <Group className="spread">
          <TabList>
            <Tab onClick={() => handleUrl('#service_orders')}>
              <Text className="s10">
                {I18nTranslator.t('SERVICE_ORDERS')}
              </Text>
            </Tab>
            <Tab onClick={() => handleUrl('#cargo_panel')}>
              <Text className="s10">
                {I18nTranslator.t('CARGO_PANEL')}
              </Text>
            </Tab>
          </TabList>
        </Group>
        <TabPanel>
          <TicketPanel />
        </TabPanel>
        <TabPanel>
          <CargoPanel />
        </TabPanel>
      </Tabs>
    </>
  );
}

export default Inbox;
