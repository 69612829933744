import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from "../../../../components/commons/Dropdown";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Icon from "../../../../components/commons/Icon";
import {SideTruckIcon} from "../../../../components/Icons";

const NotificationDropdown = ({children, onClick = () => { }, disabled, totalMessages = 0}) => {
    const [active, setActive] = useState(false);
    const [timeoutId, setTimeoutId] = useState(undefined);

    const handleClick = (e) => {
        setActive(!active);
        onClick(e);
    }

    const handleBlur = () => {
        setTimeoutId(setTimeout(() => setActive(false)));
    }

    const handleFocus = () => {
        clearTimeout(timeoutId);
    }

    return (
        <div onBlur={handleBlur} onFocus={handleFocus}>
            <Dropdown
                className={`form-input sm mla ${active ? 'active' : ''}`}
                trigger={
                    <IconButton color="inherit" onClick={handleClick} disabled={disabled}>
                        <Badge color={"secondary"} badgeContent={totalMessages}>
                            <Icon
                                className=""
                                size=""
                                color={disabled ? "fill-grey-3" : "fill-grey-1"}
                                component={<SideTruckIcon/>}
                            />
                        </Badge>
                    </IconButton>
                }
            >
                { children }
            </Dropdown>
        </div>
    )
}

NotificationDropdown.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    totalMessages: PropTypes.number,
};

export default NotificationDropdown;
