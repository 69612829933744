import React from "react";

export const Select = ({ id, name, width, className, label, feedback, children, ...props }) => {
  return (
    <div className={`wac-input-group ${width}`}>
      <label htmlFor={id} className='wac-label' >
        {label}
      </label>
      <div className={`wac-select ${className}`}>
        <select id={id} name={name} {...props}>
          {children}
        </select>
      </div>
      <span className='wac-input-feedback'>
        {feedback}
      </span>
    </div>
  )
};

export const Option = ({ value, className, children, selected }) => {
  return (
    <option value={value} className={`wac-select ${className}`} selected={selected}>
      {children}
    </option>
  )
};