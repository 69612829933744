class UserAccessService {

    loggedUser: any;

    constructor() {
        const inLocal = localStorage.getItem('loggedUser');
        if (inLocal) {
            this.loggedUser = JSON.parse(inLocal);
        }
    }

    getUser() {
        return this.loggedUser ? this.loggedUser.user : null;
    }

    getData() {
        return this.loggedUser ? this.loggedUser : null;
    }

    getSessionId() {
        return this.loggedUser ? this.loggedUser.session_id : null;
    }

    setUser(user: any) {
        this.loggedUser = user;
        
        if (user === null) {
            localStorage.removeItem('loggedUser');
        } else {
            localStorage.setItem('loggedUser', JSON.stringify(user));

            let lastUser = {
                cpf: this.loggedUser.user.usu_cpf,
                empresa: this.loggedUser.empresa
            };
            localStorage.setItem('lastUser', JSON.stringify(lastUser));
        }
    }

    isAuthenticated() {
        return this.loggedUser != null;
    }

    public getEmpresa() {
        return this.loggedUser ? this.loggedUser.empresa : "GENESLAB";
    }

    
    public getLastUser() {
        let lastLoggedUser = localStorage.getItem('lastUser');
        if(lastLoggedUser !== null)
            return JSON.parse(lastLoggedUser);
        return null;
    }
    public hasPermission(permission: string) {
        let user = this.getUser()
        return user && user.usu_permissoes && user.usu_permissoes.includes(permission)
    }

    public checkPermissionPage(permission: string) {
        if (!this.hasPermission(permission)){
            document.location.href = '/permission-error'
        }
    }
}

export const userAccessService = new UserAccessService();


