import React from "react";

export const Dropdown = ({ trigger, children, className, noPadding, ...props }) => {

  return (
    <div className={`wac-dropdown ${className}`} {...props}>
      <div className='wac-dropdown-trigger'>
        {trigger}
      </div>
      <div className={`wac-dropdown-box ${noPadding ? 'no-padding' : ''}`}>
        {children}
      </div>
    </div>
  );
}; 