/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import { Paragraph, Text, Title } from 'components/commons/Typography';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Vspace from 'components/commons/Vspace';
import { CargoService } from 'core/cargo/CargoService';
import DateUtil from 'core/utils/DateUtil';
import SummaryList from './SummaryList';
import DetailedList from './DetailedList';
import { I18nTranslator } from 'core/i18n/I18nTranslator';

const BoardingList = ({ handleClose, serviceOrderCode }) => {

  // Traduções.
  const TEXT_BOARDING_LIST = I18nTranslator.t("BOARDING_LIST");
  const TEXT_SUMMARY = I18nTranslator.t("SUMMARY");
  const TEXT_DETAILED = I18nTranslator.t("DETAILED");
  const TEXT_ACRONYM_SERVICE_ORDER = "O.S.";
  
  // States.
  const [cargoList, setCargoList] = useState([]);
  const [summaryCargoList, setSummaryCargoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detailedSearchValue, setDetailedSearchValue] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Parâmetros do serviço.
  const serviceParameters = {
    os: serviceOrderCode,
    iac_excluir_plan: 'N'
  }

  // Busca as cargas de determinada O.S.
  const getCargoList = (serviceParameters) => {
    setIsLoading(true);
    CargoService.filter(serviceParameters)
    .then(response => {
      if (Array.isArray(response)) {
        
        let summaryGroup = [];

        let summaryFactory = () => {
          return {
            iac_data: '',
            volume_total: 0,
            volume_embarcado: 0,
            cargas_total: 0,
            cargas_embarcadas: 0,
            total_classificadores: 0
          }
        }

        let classificadorPorData = [];
        let cargoList = response.map((row) => {
          row.formatted_iac_data_class = DateUtil.formatStringDate(row.iac_data_class);
          
          if (!summaryGroup[row.iac_data_class]) {
            summaryGroup[row.iac_data_class] = summaryFactory();
            summaryGroup[row.iac_data_class].iac_data = row.formatted_iac_data_class;
          }

          if (!classificadorPorData[row.iac_data_class]) {
            classificadorPorData[row.iac_data_class] = [];
          }

          
          if (row.iac_excluir_plan === 'N') {
            if (['EMB', 'PFPB'].indexOf(row.iac_tipo) > -1) {
              summaryGroup[row.iac_data_class].volume_embarcado += parseFloat(row.iac_peso); 
              summaryGroup[row.iac_data_class].cargas_embarcadas++;
            }

            summaryGroup[row.iac_data_class].volume_total += parseFloat(row.iac_peso);
            summaryGroup[row.iac_data_class].cargas_total++;
            
            if (classificadorPorData[row.iac_data_class].indexOf(row.class_nome) === -1) {
              summaryGroup[row.iac_data_class].total_classificadores++;
            }

            classificadorPorData[row.iac_data_class].push(row.class_nome);
          }
            

          return row;
        });

        let summaryList = Object.keys(summaryGroup).map(key => summaryGroup[key]);
        
        setSummaryCargoList(summaryList);
        setCargoList(cargoList);
      }
    })
    .catch(error => console.error(error))
    .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (serviceOrderCode)
      getCargoList(serviceParameters);
    
  }, [serviceOrderCode]);

  return (
    <Box>
      <Button onClick={handleClose} className="button-close" />
      <Title className='s11 fw500'>
        {TEXT_BOARDING_LIST}
        <Paragraph children={`${TEXT_ACRONYM_SERVICE_ORDER} ${serviceOrderCode}`} />
      </Title>
      <Vspace />

      <Tabs selectedIndex={selectedTabIndex}>
        <Group className="spread fluid">
          <TabList>
            <Tab onClick={() => setSelectedTabIndex(0)}>
              <Text className="s10">
                {TEXT_SUMMARY}
              </Text>
            </Tab>
            <Tab onClick={() => setSelectedTabIndex(1)}>
              <Text className="s10">
                {TEXT_DETAILED}
              </Text>
            </Tab>
          </TabList>
        </Group>
        <TabPanel>
          <SummaryList 
            rows={summaryCargoList} 
            isLoading={isLoading} 
            actions={{
              onClickSearchButton: (row) => {
                setDetailedSearchValue(row.iac_data)
                setSelectedTabIndex(1)
              }
            }}
          />
        </TabPanel>
        <TabPanel>
          <DetailedList 
            rows={cargoList} 
            isLoading={isLoading} 
            defaultSearchValue={detailedSearchValue}
            onSearchInputHandler={(e) => { setDetailedSearchValue(e.target.value) }}
          />
        </TabPanel>
      </Tabs>
    </ Box>
  )
}

export default BoardingList