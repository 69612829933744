import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";

export class AnaliseTesteTipo {

    static filter(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/tipo_protocolo?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };
        
        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then((response) => {
                resolve(response);
            })
            .catch(() => reject(false));
        });
    }
}