/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import { Text } from 'components/commons/Typography';
import Vspace from 'components/commons/Vspace';
import Search from 'components/commons/Search';
import Table from 'components/commons/Table';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import Input from 'components/commons/Input';
import DatePicker from 'components/commons/DatePicker';
import { EyeIcon } from 'components/Icons';
import DateUtil from 'core/utils/DateUtil';
import TextUtil from 'core/utils/TextUtil';
import { TablePagination } from '@material-ui/core';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import Icon from 'components/commons/Icon';
import { configuration } from 'config';
import DropdownExport from './DropdownExport';

// Traduções.
const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND");
const TEXT_PREPOSITION_OF = I18nTranslator.t("PREPOSITION_OF");
const TEXT_SEARCH = I18nTranslator.t("SEARCH");
const TEXT_ADVANCED_FILTER = I18nTranslator.t("ADVANCED_FILTER");
const TEXT_DATE = I18nTranslator.t("DATE");
const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD");
const TEXT_CLEAR_FIELDS = I18nTranslator.t("CLEAR_FIELDS");
const TEXT_FILTRATE = I18nTranslator.t("FILTRATE");
const TEXT_VIEW_APPRAISAL = I18nTranslator.t("VIEW_APPRAISAL");
const TEXT_BOARD = I18nTranslator.t("CAR_PLATE");
const TEXT_INVOICE = I18nTranslator.t("INVOICE");
const TEXT_APPRAISAL = I18nTranslator.t("APPRAISAL");
const TEXT_CLASSIFIER = I18nTranslator.t("CLASSIFIER");
const TEXT_VOLUME = I18nTranslator.t("VOLUME");
const TEXT_EXPORT = I18nTranslator.t("EXPORT");
const TEXT_EXPORT_SELECTION = I18nTranslator.t("EXPORT_SELECTION");

const openLaudo = (laudo) => {
  let urlBase = configuration.SYS_LAUDO_GENESLAB;
  window.open(`${urlBase}${laudo}`, "_system")
}

const PopoverContent = ({ handleVisualizarLaudo }) => {
  var [ activePopover, setPopover] = useState(false);
  var [ timeOutId, setTimeOutId ] = useState();

  function handlePopover(e) {
    e.stopPropagation();
    setPopover(activePopover === false);
  }

  function onBlurHandler() {
    setTimeOutId(
      setTimeout(() => setPopover(false))
    )
  }

  function onFocusHandler() {
    clearTimeout(timeOutId)
  }
  
  return (
    <>
      <div 
        className={`wac-featured-popover ${activePopover ? 'active' : ''}`}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
      >
        <button 
          onClick={handlePopover}
          className="wac-featured-popover-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="5" viewBox="0 0 18 5" fill="none"><path d="M2.392 4.192C1.83733 4.192 1.368 4 0.984 3.616C0.6 3.232 0.408 2.76267 0.408 2.208C0.408 1.65333 0.6 1.184 0.984 0.8C1.368 0.416 1.83733 0.224 2.392 0.224C2.92533 0.224 3.37333 0.416 3.736 0.8C4.12 1.184 4.312 1.65333 4.312 2.208C4.312 2.76267 4.12 3.232 3.736 3.616C3.37333 4 2.92533 4.192 2.392 4.192ZM9.11075 4.192C8.55608 4.192 8.08675 4 7.70275 3.616C7.31875 3.232 7.12675 2.76267 7.12675 2.208C7.12675 1.65333 7.31875 1.184 7.70275 0.8C8.08675 0.416 8.55608 0.224 9.11075 0.224C9.64408 0.224 10.0921 0.416 10.4548 0.8C10.8388 1.184 11.0308 1.65333 11.0308 2.208C11.0308 2.76267 10.8388 3.232 10.4548 3.616C10.0921 4 9.64408 4.192 9.11075 4.192ZM15.8295 4.192C15.2748 4.192 14.8055 4 14.4215 3.616C14.0375 3.232 13.8455 2.76267 13.8455 2.208C13.8455 1.65333 14.0375 1.184 14.4215 0.8C14.8055 0.416 15.2748 0.224 15.8295 0.224C16.3628 0.224 16.8108 0.416 17.1735 0.8C17.5575 1.184 17.7495 1.65333 17.7495 2.208C17.7495 2.76267 17.5575 3.232 17.1735 3.616C16.8108 4 16.3628 4.192 15.8295 4.192Z" fill="#4F4F4F"/></svg>
        </button>
        <div className="wac-featured-popover-content">
          <button onClick={handlePopover} className="wac-featured-drawer-trigger" />
          <Group className='fdc g3'>
            <Button 
              onClick={(e) => {
                e.stopPropagation();
                handleVisualizarLaudo(e)
              }}
              className='inline'>
              <Icon 
                className='df2'
                component={ <EyeIcon /> } />
              <Text className='s13 white uppercase'>
                {TEXT_VIEW_APPRAISAL}
              </Text>
            </Button>
          </Group>
        </div>
      </div>
    </>
  );
};

const DetailedList = (props) => {
  // Props. 
  const {
    rows = [], 
    isLoading, 
    defaultSearchValue, 
    onSearchInputHandler = () => {},
    paginationSize = 10
  } = props;

  // States.
  const [isOpen, setOpen] = useState(false);
  const [filterList, setFilterList] = useState(rows);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filterId, setFilterId] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterBoard, setFilterBoard] = useState('');
  const [filterInvoice, setFilterInvoice] = useState('');
  const [filterReport, setFilterReport] = useState('');
  const [filterClassifier, setFilterClassifier] = useState('');
  const [filterVolume, setFilterVolume] = useState('');
  const [selection, setSelection] = useState([]);
  const [exportList, setExportList] = useState(filterList);

  useEffect(() => {
    setFilterList(rows);
    setExportList(rows);
    setSelection([]);

    if (defaultSearchValue) {
      filter(defaultSearchValue);
    }
  }, [rows]);

  const toggleSelection = (row, index) => {
    try {
      let id = `#detailed_row_${index}`;
      let nodeTableRowList = Array.from(document.querySelectorAll(id));
      let selected = false;

      nodeTableRowList.map(element => {
        selected = element.className.includes("selected");
        element.className = `has-status ${selected ? 'other' : 'selected'}`;
      });

      let selectionList = selection;
      
      if (!selected) {
        selectionList.push(row);
      } else {
        selectionList.splice(selectionList.findIndex(x => x.iac_codigo === row.iac_codigo), 1)
      }

      setSelection(selectionList);
      setExportList(selectionList.length ? selectionList : filterList);
    }
    catch (error) { console.log(error)}
  }

  const renderTable = () => {
    if (filterList.length === 0) {
      return (
        <tr style={{textAlign: 'center', marginBottom: '15px'}}>
          <td colSpan="9">{TEXT_NOTHING_FOUND}</td>
        </tr>
      )
    }

    let list = filterList;
    if (paginationSize > 0) {
      list = list.slice(offset, offset + paginationSize);
    }

    return list.map((row, index)=> {
      let selected = selection.findIndex(x => x.iac_codigo === row.iac_codigo) > -1;
      let clickHandler = () => toggleSelection(row, index);

      return (
        <tr 
          key={index} 
          id={`detailed_row_${index}`} 
          className={`has-status ${selected ? 'selected' : 'other'}`} 
          onClick={clickHandler}
        >
          <td>
            <PopoverContent
              handleVisualizarLaudo={
                () => openLaudo(row.iac_laudo)
              }
            />
          </td>
          <td>
            {row.iac_codigo}
          </td>
          <td>
            {row.formatted_iac_data_class}
          </td>
          <td>
            {row.iac_placa}
          </td>
          <td>
            {row.iac_nota_fiscal}
          </td>
          <td status={`MOB-${row.iac_laudo}`}>
            MOB-{TextUtil.summarize(row.iac_laudo, 7, 3)}
          </td>
          <td status={row.class_nome}>
            {TextUtil.summarize(row.class_nome, 20)}
          </td>
          <td>
            {row.iac_peso}
          </td>
        </tr>
      )
    })
  }


  const filter = (textToFind) => {
    let filterResultLits = rows.filter((row) => 
      JSON.stringify(row).includes(textToFind)
    );

    setFilterList(filterResultLits)
  }

  const advancedFilterHandler = () => {
    let filterResultList = rows.filter((row) => {
        let filterIdCondition = true;
        if (filterId !== '') {
          filterIdCondition = parseInt(filterId) === row.iac_codigo;
        }

        let filterDateCondition = true;
        if (filterDate !== '') {
          filterDateCondition = DateUtil.formatStringDate(filterDate) === row.formatted_iac_data_class;
        }

        let filterBoardCondition = true;
        if (filterBoard !== '') {
          filterBoardCondition = filterBoard === row.iac_placa;
        }

        let filterInvoiceCondition = true;
        if (filterInvoice !== '') {
          filterInvoiceCondition = filterInvoice === row.iac_nota_fiscal;
        }

        let filterReportCondition = true;
        if (filterReport !== '') {
          filterReportCondition = filterReport === row.iac_laudo || filterReport === `MOB-${row.iac_laudo}`;
        }

        let filterClassifierCondition = true;
        if (filterClassifier !== '' && row.classificador) {
          filterClassifierCondition = row.classificador.includes(filterClassifier);
        }

        let filterVolumeCondition = true;
        if (filterVolume !== '') {
          filterVolumeCondition = parseInt(filterVolume) === row.iac_peso;
        }

        return filterIdCondition && 
          filterDateCondition &&  
          filterBoardCondition && 
          filterInvoiceCondition && 
          filterReportCondition && 
          filterClassifierCondition && 
          filterVolumeCondition;
      }
    )

    setFilterList(filterResultList);
  }

  const resetAdvancedFilter = () => {
    setFilterId('');
    setFilterDate('');
    setFilterBoard('');
    setFilterInvoice('');
    setFilterReport('');
    setFilterClassifier('');
    setFilterVolume('');
  }

  // Renderiza a paginação da tabela.
  function generatePaginator() {
    let totalPages = Math.ceil(filterList.length/paginationSize);
    let selectedPage = currentPage;

    if ((selectedPage + 1) > totalPages) {
      selectedPage = totalPages -1;
      handlePageChange(selectedPage);
    }
    
    return (
      <>
        <TablePagination 
          component="div"
          rowsPerPageOptions={[]}
          labelDisplayedRows={({from, to, count}) => `${from}-${to} ${TEXT_PREPOSITION_OF} ${count}`}
          rowsPerPage={paginationSize}
          page={selectedPage}
          count={filterList.length}
          onChangePage={(e, page) => handlePageChange(page)}
        />
      </>
    );
  }

  // Manipula o evento de alterar página.
  function handlePageChange(selectedPage) {
    let totalPages = Math.ceil(filterList.length/paginationSize);
    if (selectedPage < 0 || (selectedPage + 1) > totalPages) return;

    setCurrentPage(selectedPage);
    setOffset(selectedPage * paginationSize);
  }

  return (
    <>
      <Box className="mobile-box">
        <div className="wac-overlaid-button">
          {
            exportList.length ?
            (
              <DropdownExport 
                key={exportList}
                list={exportList}
                label={selection.length ? TEXT_EXPORT_SELECTION : TEXT_EXPORT}
              />
            ) : ''
          }
        </div>
        <div class="wac-vspace lg"></div>
        <Group className="vc g5 mv">
          <Search 
            placeholder={TEXT_SEARCH} 
            onKeyUp={(e) => filter(e.target.value)} 
            onInput={onSearchInputHandler}
            defaultValue={defaultSearchValue}
          />
          <Button 
            className={`filter has-arrow`}
            onClick={() => setOpen(!isOpen)}
          >
            { TEXT_ADVANCED_FILTER }
          </Button>
        </Group>
        <Vspace />
        <form id={`form_detailed_filter`} onSubmit={(e) => e.preventDefault()}>
          <Box className={`collapsed ${ isOpen ? 'active' : '' }`}>
            <Row className={`bottom-xs g4`}>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_id`}
                  type={`number`}
                  value={filterId}
                  onChange={(e) => setFilterId(e.target.value)}
                  label={`ID`}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <DatePicker
                  id={`field_date`}
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                  label={TEXT_DATE}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_board`}
                  type={`text`}
                  value={filterBoard}
                  onChange={(e) => setFilterBoard(e.target.value)}
                  label={TEXT_BOARD}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_invoice`}
                  type={`text`}
                  value={filterInvoice}
                  onChange={(e) => setFilterInvoice(e.target.value)}
                  label={TEXT_INVOICE}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_report`}
                  type={`text`}
                  value={filterReport}
                  onChange={(e) => setFilterReport(e.target.value)}
                  label={TEXT_APPRAISAL}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_classificator`}
                  type={`text`}
                  value={filterClassifier}
                  onChange={(e) => setFilterClassifier(e.target.value)}
                  label={TEXT_CLASSIFIER}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-3 col-lg-12`}>
                <Input
                  id={`field_volume`}
                  type={`number`}
                  value={filterVolume}
                  onChange={(e) => setFilterVolume(e.target.value)}
                  label={TEXT_VOLUME}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
            </Row>
            <Vspace className={`lg`} />
            <Group className={`r g2 m-spread`}>
              <Button 
                type="reset" 
                className="outlined"
                onClick={resetAdvancedFilter}
              >
                {TEXT_CLEAR_FIELDS}
              </Button>
              <Button 
                type="submit"
                onClick={advancedFilterHandler}
              >
                {TEXT_FILTRATE}
              </Button>
            </Group>
          </Box>
        </form>
      </Box>
      <Vspace />
      <div className={`fixed-cols-table f-1 f-2 ${isLoading ? 'loading' : ''}`}>
        <div className="fixed-cols">
          <Table className="featured">
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>{TEXT_DATE}</th>
                <th>{TEXT_BOARD}</th>
                <th>{TEXT_INVOICE}</th>
                <th>{TEXT_APPRAISAL}</th>
                <th>{TEXT_CLASSIFIER}</th>
                <th>{TEXT_VOLUME}</th>
              </tr>
            </thead>
            <tbody>
              { renderTable() }
            </tbody>
          </Table>
        </div>
        <div className="scroll-cols">
          <Table className="featured">
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>{TEXT_DATE}</th>
                <th>{TEXT_BOARD}</th>
                <th>{TEXT_INVOICE}</th>
                <th>{TEXT_APPRAISAL}</th>
                <th>{TEXT_CLASSIFIER}</th>
                <th>{TEXT_VOLUME}</th>
              </tr>
            </thead>
            <tbody>
              { renderTable() }
            </tbody>
          </Table>
          { generatePaginator() }
        </div>
      </div>
    </>
  )
}

export default DetailedList