import React from "react";
import { Link } from "react-router-dom";

export const Menu = ({ className, children }) => {
  return (
    <div className={`wac-menu ${className}`}>
      {children}
    </div>
  );
};

export const MenuItem = ({ className, link, children, onClick }) => {
  return (
    <div className={`wac-menu-item ${className}`}>
      <Link className='wac-menu-link' to={link} onClick={onClick} >
          {children}
      </Link>
    </div>
  );
};