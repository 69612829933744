export default class DateUtil {

    static getMonday() {
        let date = new Date();
        let day = date.getDay();
        let diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff)).toISOString().split('T')[0];
    }

    static getToday() {
        let d = new Date();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString().split('T')[0];
    }

    static getFirstDateOfMonth() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return new Date(year, month, 1).toISOString().split('T')[0];
    }

    static formatStringDate(stringDate: string) {
        if (!stringDate) return null;
        
        let date = new Date(`${stringDate}T00:00:01`);

        return date.toLocaleDateString();
    }

    static formatStringDateTime(stringDate: string) {
        let date = new Date(`${stringDate}`);

        return `${date.toLocaleDateString()}`;
    }
    static formatStringTime(stringDate: string) {
        let date = new Date(`${stringDate}`);

        return `${date.toLocaleTimeString()}`;
    }

    static getSevenDaysBeforeToday() {
        let d = new Date();
        d.setDate(d.getDate() - 7);

        return new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString().split('T')[0];
    }

    static howLongHasPassed(stringDate: string, language: string = 'pt') {
        const today = new Date()
        const targetDate = new Date(stringDate)

        // @ts-ignore
        const delta = Math.abs(today - targetDate) / 1000;
        const days = Math.floor(delta/86400)
        const weeks = Math.floor(days/7)
        const months = Math.floor(days/30)
        const years = Math.floor(days/365)
        const centuries = Math.floor(years/100)
        const hours = Math.floor(delta / 3600) % 24
        const minutes = Math.floor(delta / 60) % 60

        const dictionary = {
            pt: {
                centuries: 'séculos',
                century: 'século',
                years: 'anos',
                year: 'ano',
                months: 'mêses',
                month: 'mês',
                weeks: 'semanas',
                week: 'semana',
                days: 'dias',
                day: 'dia',
                hours: 'horas',
                hour: 'hora',
                minutes: 'minutos',
                minute: 'minuto',
                x_times_ago: 'há {{times}}',
                now: 'agora pouco'
            },
            en: {
                centuries: 'centuries',
                century: 'century',
                years: 'years',
                year: 'year',
                months: 'months',
                month: 'month',
                weeks: 'weeks',
                week: 'week',
                days: 'days',
                day: 'day',
                hours: 'hours',
                hour: 'hour',
                minutes: 'minutes',
                minute: 'minute',
                x_times_ago: '{{times}} ago',
                now: 'just now'
            },
            es: {
                centuries: 'siglos',
                century: 'siglo',
                years: 'años',
                year: 'año',
                months: 'meses',
                month: 'mes',
                weeks: 'semanas',
                week: 'semana',
                days: 'días',
                day: 'día',
                hours: 'horas',
                hour: 'hora',
                minutes: 'minutos',
                minute: 'minuto',
                x_times_ago: 'Hace {{times}}',
                now: 'ahora poco'
            },
        }
        const t = (token: string) => {
            // @ts-ignore
            let d = dictionary[language]
            if (d) {
                return d[token] ? d[token] : token
            }
            return token
        }

        let result = 'now';
        if (centuries > 0) {
            result = `${centuries} ${centuries > 1 ? t('centuries') : t('century')}`
        }
        else if (years > 0) {
            result = `${years} ${years > 1 ? t('years') : t('year')}`
        }
        else if (months > 0) {
            result = `${months} ${months > 1 ? t('months') : t('month')}`
        }
        else if (weeks > 0) {
            result = `${weeks} ${weeks > 1 ? t('weeks') : t('week')}`
        }
        else if (days > 0) {
            result = `${days} ${days > 1 ? t('days') : t('day')}`
        }
        else if (hours > 0) {
            result = `${hours} ${hours > 1 ? t('hours') : t('hour')}`
        }
        else if (minutes > 0) {
            result = `${minutes} ${minutes > 1 ? t('minutes') : t('minute')}`
        }

        return result == 'now' ? t('now') : t('x_times_ago').replace("{{times}}", result);
    }
}