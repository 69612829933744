import React from 'react';

export const Radio = ({ children, label, className }) => {
  return (
    <>
      <h3 class={`wac-label`}>
      {label}
      </h3>
      <div class={`wac-radio-button-group ${className}`}>
      {children}
      </div>
    </> 
  )
};

export const RadioOption = ({ id, name, value, className, isChecked, onChange, label }) => {
  return (
    <label htmlFor={id} className={`wac-radio ${className}`}>
      <input 
        id={id}
        type='radio'
        name={name}
        onChange={onChange}
        defaultChecked={isChecked}
        value={value} />
      <span className="wac-radio-label">
        {label ? label : value}
      </span>
    </label>
  )
};

export const ToggleCard = ({ id, icon, name, value, onClick }) => {
  return (
    <label htmlFor={id} className={`wac-radio-card`} >
      <input 
        id={id} 
        type="radio" 
        name={name}
        value={value}
        onClick={onClick}/>
      <div className={`wac-radio-card-token`}>
        <span className={`wac-radio-card-icon`}>
          {icon}
        </span>
        <span className={`wac-radio-card-label`}>
          {value}
        </span>
      </div>
    </label>
  );
};