import React from "react";

export const Checkbox = ({ id, type, size, label, isChecked, ...props}) => {
  return (
    <label htmlFor={id} className={`wac-checkbox ${type}`} >
      <input id={id} type="checkbox" {...props} checked={isChecked} />
      <span className={`wac-checkbox-token ${size}`}></span>
      <span className="wac-checkbox-label">
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
