import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";

export class CarryingService {

    static getList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/transportadora?" + searchParams.toString();

        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, config)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }

    static create(params: any) {
        const url = configuration.AUTH_BASE_URL + "cliconnect/transportadora";
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            },
            body: JSON.stringify(params)
        }

        return new Promise((resolve, reject) => {
            fetch(url, config)
            .then(response => response.json())
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    }
}