import {configuration} from '../../config';
import fetch from 'isomorphic-fetch';
import {userAccessService} from "../user-access/UserAccessService";

export class OneSignalService {


    static saveUserId(id: any) {
        const url = `${configuration.AUTH_BASE_URL}cliconnect/notification/subscribe`;

        if (id == null){
            id = 0
        }

        const headerConfig: any = {
            method: "POST",
            body: JSON.stringify({id}),
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {

            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => {
                    resolve(response);
                })
                .catch(() => reject(false));
        });
    }
}