import React from "react";
import Group from '../Group';

const InputButton = ({ className, id, children, label, placeholder, feedback ,disabled=""}) => {
  return (
    <div className={`control ${className}`}>
      <label htmlFor={id} className='wac-label' >
        {label}
      </label>
      <Group className="form-group">
        <input
            disabled={disabled}
          id={id} 
          className="wac-input"
          type="text" 
          placeholder={placeholder}
        />
      {children}
      </Group>
      <span className='wac-input-feedback'>
        {feedback}
      </span>
    </div>
  )
};

export default InputButton;
