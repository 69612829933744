import React, {useState} from "react";
import Icon from "components/commons/Icon";
import {Text} from 'components/commons/Typography';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import {EditIcon, EyeIcon, TruckIcon} from 'components/Icons';
import {I18nTranslator} from "core/i18n/I18nTranslator";
import {userAccessService} from "../../../../../../core/user-access/UserAccessService";

const TEXT_LIST_SHIPMENTS = I18nTranslator.t("LIST_SHIPMENTS");
const TEXT_VIEW_DATA = I18nTranslator.t("VIEW_DATA")

const PopoverContent = ({handleListarEmbarque, handleVisualizarDados, sloCodigo}) => {

  var [activePopover, setPopover] = useState(false);
  var [timeOutId, setTimeOutId] = useState();

  function handlePopover() {
    setPopover(activePopover === false);
  }

  function onBlurHandler() {
    setTimeOutId(
      setTimeout(() => setPopover(false))
    )
  }

  function onFocusHandler() {
    clearTimeout(timeOutId)
  }
  
  return (
    <>
      <div 
        className={`wac-featured-popover ${activePopover ? 'active' : ''} no-print`}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
      >
        <button 
          onClick={handlePopover}
          className="wac-featured-popover-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="5" viewBox="0 0 18 5" fill="none"><path d="M2.392 4.192C1.83733 4.192 1.368 4 0.984 3.616C0.6 3.232 0.408 2.76267 0.408 2.208C0.408 1.65333 0.6 1.184 0.984 0.8C1.368 0.416 1.83733 0.224 2.392 0.224C2.92533 0.224 3.37333 0.416 3.736 0.8C4.12 1.184 4.312 1.65333 4.312 2.208C4.312 2.76267 4.12 3.232 3.736 3.616C3.37333 4 2.92533 4.192 2.392 4.192ZM9.11075 4.192C8.55608 4.192 8.08675 4 7.70275 3.616C7.31875 3.232 7.12675 2.76267 7.12675 2.208C7.12675 1.65333 7.31875 1.184 7.70275 0.8C8.08675 0.416 8.55608 0.224 9.11075 0.224C9.64408 0.224 10.0921 0.416 10.4548 0.8C10.8388 1.184 11.0308 1.65333 11.0308 2.208C11.0308 2.76267 10.8388 3.232 10.4548 3.616C10.0921 4 9.64408 4.192 9.11075 4.192ZM15.8295 4.192C15.2748 4.192 14.8055 4 14.4215 3.616C14.0375 3.232 13.8455 2.76267 13.8455 2.208C13.8455 1.65333 14.0375 1.184 14.4215 0.8C14.8055 0.416 15.2748 0.224 15.8295 0.224C16.3628 0.224 16.8108 0.416 17.1735 0.8C17.5575 1.184 17.7495 1.65333 17.7495 2.208C17.7495 2.76267 17.5575 3.232 17.1735 3.616C16.8108 4 16.3628 4.192 15.8295 4.192Z" fill="#4F4F4F"/></svg>
        </button>
        <div className="wac-featured-popover-content">
          <button onClick={handlePopover} className="wac-featured-drawer-trigger"/>
          <Group className='fdc g3'>
            {userAccessService.hasPermission("editOS") ? <Button
                onClick={() => document.location.href = `/ticket-editing?os=${sloCodigo}`}
                className='inline'>
              <Icon
                  className='df2'
                  component={<EditIcon/>}/>
              <Text className='s13 white uppercase'>
                Editar
              </Text>
            </Button> : ""}
            <Button
                onClick={handleListarEmbarque}
                className='inline'>
              <Icon
                  className='df2'
                  component={<TruckIcon/>}/>
              <Text className='s13 white uppercase'>
                {TEXT_LIST_SHIPMENTS}
              </Text>
            </Button>
            <Button 
              onClick={handleVisualizarDados}
              className='inline'>
              <Icon 
                className='df2'
                component={ <EyeIcon /> } />
              <Text className='s13 white uppercase'>
                {TEXT_VIEW_DATA}
              </Text>
            </Button>
          </Group>
        </div>
      </div>
    </>
  );
};

export default PopoverContent