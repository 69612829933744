/* eslint-disable */
import React, {useEffect} from 'react';
import Group from 'components/commons/Group';
import Checkbox from 'components/commons/Checkbox';
import Switch from 'components/commons/Switch';
import Button from 'components/commons/Button';
import Vspace from 'components/commons/Vspace';
import {Text} from 'components/commons/Typography';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {Dropdown} from 'components/commons/Dropdown';
import {I18nTranslator} from "../../../../../../../core/i18n/I18nTranslator";

// Traduções.
const TEXT_DATE = I18nTranslator.t('DATE');
const TEXT_CAR_PLATE = I18nTranslator.t('CAR_PLATE');
const TEXT_CARRIER = I18nTranslator.t('CARRIER');
const TEXT_CLASSIFIER = I18nTranslator.t('CLASSIFIER');
const TEXT_SUPERV = I18nTranslator.t('SUPERV');
const TEXT_CLIENTFILIAL = I18nTranslator.t('BRANCH_CUSTOMER');
const TEXT_MENIFESTADO = I18nTranslator.t('MANIFESTADO');
const TEXT_HORA_DOC_DEVOLVIDO = I18nTranslator.t('HORA_DOC_DEVOLVIDO');
const TEXT_BOARD_LOCATION = I18nTranslator.t('BOARD_LOCATION');
const TEXT_ISSUE_CTE = I18nTranslator.t('ISSUE_CTE');
const TEXT_HOUR_DOC_ATTACHMENT = I18nTranslator.t('HOUR_DOC_ATTACHMENT');
const TEXT_WEIGHT = I18nTranslator.t('WEIGHT');
const TEXT_INVOICE = I18nTranslator.t('INVOICE');
const TEXT_GELD_FIELD = I18nTranslator.t('GELD_FIELD');
const TEXT_MANIFESTED = I18nTranslator.t('MANIFESTED');
const TEXT_ALL = I18nTranslator.t('ALL');
const TEXT_NONE = I18nTranslator.t('NONE');
const TEXT_CLIREGIONAL = I18nTranslator.t('REGIONAL_CUSTOMER');
const TEXT_ATENDENTE = I18nTranslator.t('ATENDENTE');
const TEXT_STATUS = I18nTranslator.t('STATUS_CARGA');

const DropdownColumns = ({
                             onClick, showDropdownColumns, handleChange = () => {
    }, activeColumns = [1, 2, 3, 4]
                         }) => {

    function getCheckBoxDomList() {
        let columnsRow = document.getElementById('columns-row');
        if (!columnsRow) return [];
        return columnsRow.querySelectorAll("[type='checkbox']");
    }

    useEffect(() => {
        handleChecked();
    }, []);

    function handleChecked() {
        let checkboxDomList = getCheckBoxDomList();
        for (let i = 0; i < checkboxDomList.length; i++) {
            let elememt = checkboxDomList[i];
            let order = typeof checkboxDomList[i].dataset.order != 'undefined' ? parseInt(checkboxDomList[i].dataset.order) : 0;

            elememt.checked = (activeColumns.indexOf(order) > -1);
        }
    }

    function handleOnClick() {
        handleChecked();
        onClick();
    }

    function handleSwitch(type) {
        let checkboxDomList = getCheckBoxDomList();

        for (let i = 0; i < checkboxDomList.length; i++) {
            let element = checkboxDomList[i];
            let checked = checkboxDomList[i].checked;

            if ((type === 'all' && !checked) || (type === 'none' && checked)) {
                element.click();
            }
        }
    }

    function handleChangeCheckbox(e) {
        let checkboxDomList = getCheckBoxDomList();
        let totalChecked = 0;
        for (let i = 0; i < checkboxDomList.length; i++) {
            if (checkboxDomList[i].checked) totalChecked++;
        }

        let radioAll = document.getElementById('radioAll');
        let radioNone = document.getElementById('radioNone');

        if (totalChecked === checkboxDomList.length) {
            if (radioAll) radioAll.checked = true;
        } else if (totalChecked === 0) {
            if (radioNone) radioNone.checked = true;
        } else {
            radioAll.checked = false;
            radioNone.checked = false;
        }

        handleChange(e);
    }


    return (
        <Dropdown
            className={`mla hom ${showDropdownColumns ? 'active' : ''}`}
            trigger={
                <Button onClick={handleOnClick} className='inline'>Configurar colunas</Button>
            }>
            <Button onClick={handleOnClick} className="button-close"/>
            <Text className='s11'>Selecionar</Text>
            <Vspace className='sm'/>
            <Group className='g5'>
                <Switch
                    id='radioAll'
                    label={TEXT_ALL}
                    onClick={() => handleSwitch('all')}
                    type='radio'
                    name='radio-group-01'
                />
                <Switch
                    id='radioNone'
                    label={TEXT_NONE}
                    onClick={() => handleSwitch('none')}
                    type='radio'
                    name='radio-group-01'
                />
            </Group>
            <hr className='sm'/>
            <Row id="columns-row">
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="1"
                        type='circle'
                        label='OS'
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="2"
                        type='circle'
                        label={TEXT_CLIREGIONAL}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="3"
                        type='circle'
                        label={TEXT_CLIENTFILIAL}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="4"
                        type='circle'
                        label={TEXT_DATE}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="5"
                        type='circle'
                        label={TEXT_HOUR_DOC_ATTACHMENT}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="6"
                        type='circle'
                        label={TEXT_HORA_DOC_DEVOLVIDO}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="7"
                        type='circle'
                        label={TEXT_CAR_PLATE}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="8"
                        type='circle'
                        label={TEXT_WEIGHT}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="9"
                        type='circle'
                        label={TEXT_INVOICE}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="10"
                        type='circle'
                        label={TEXT_CARRIER}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="11"
                        type='circle'
                        label={TEXT_BOARD_LOCATION}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="12"
                        type='circle'
                        label={TEXT_SUPERV}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="13"
                        type='circle'
                        label={TEXT_CLASSIFIER}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="14"
                        type='circle'
                        label={TEXT_GELD_FIELD}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="15"
                        type='circle'
                        label={TEXT_MENIFESTADO}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="16"
                        type='circle'
                        label={TEXT_ATENDENTE}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="17"
                        type='circle'
                        label={TEXT_MANIFESTED}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="18"
                        type='circle'
                        label={TEXT_ISSUE_CTE}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
                <Col className='col-xs-6'>
                    <Checkbox
                        data-order="19"
                        type='circle'
                        label={TEXT_STATUS}
                        onChange={handleChangeCheckbox}
                    />
                </Col>
            </Row>
        </Dropdown>
    );
};

export default DropdownColumns;
