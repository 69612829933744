/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import Vspace from 'components/commons/Vspace';
import Search from 'components/commons/Search';
import Table from 'components/commons/Table';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import Input from 'components/commons/Input';
import DatePicker from 'components/commons/DatePicker';
import { SearchIcon } from 'components/Icons';
import DateUtil from 'core/utils/DateUtil';
import { TablePagination } from '@material-ui/core';
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import Icon from 'components/commons/Icon';


const SummaryList = (props) => {
  
    // Traduções.
    const TEXT_DETAILED = I18nTranslator.t("DETAILED");
    const TEXT_NOTHING_FOUND = I18nTranslator.t("NOTHING_FOUND");
    const TEXT_PREPOSITION_OF = I18nTranslator.t("PREPOSITION_OF");
    const TEXT_SEARCH = I18nTranslator.t("SEARCH");
    const TEXT_ADVANCED_FILTER = I18nTranslator.t("ADVANCED_FILTER");
    const TEXT_DATE = I18nTranslator.t("DATE");
    const TEXT_TOTAL_VOLUME = I18nTranslator.t("TOTAL_VOLUME");
    const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD");
    const TEXT_BOARDED_VOLUME = I18nTranslator.t("BOARDED_VOLUME");
    const TEXT_TOTAL_CARGO = I18nTranslator.t("TOTAL_CARGO");
    const TEXT_BOARDED_CARGO = I18nTranslator.t("BOARDED_CARGO");
    const TEXT_CLASSIFIERS = I18nTranslator.t("CLASSIFIERS");
    const TEXT_CLEAR_FIELDS = I18nTranslator.t("CLEAR_FIELDS");
    const TEXT_FILTRATE = I18nTranslator.t("FILTRATE");

    // Props. 
    const {
      rows = [], 
      isLoading, 
      actions = {
         onClickSearchButton: (row) => {} 
      },
      paginationSize = 10
    } = props;
  
    // States.
    const [isOpen, setOpen] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [filterDate, setFilterDate] = useState('');
    const [filterTotalVolume, setFilterTotalVolume] = useState('');
    const [filterShippedVolume, setFilterShippedVolume] = useState('');
    const [filterCargoTotal, setFilterCargoTotal] = useState('');
    const [filterShippedCargo, setFilterShippedCargo] = useState('');
    const [filterClassifiers, setFilterClassifiers] = useState('');
    
  
    useEffect(() => {
      setFilterList(rows);
    }, [rows]);
  
    // Renderiza a tabela de acordo com o filtro
    const renderTable = () => {
      if (filterList.length === 0) {
        return (
          <tr style={{textAlign: 'center', marginBottom: '15px'}}>
            <td colSpan="9">{TEXT_NOTHING_FOUND}</td>
          </tr>
        )
      }
  
      let list = filterList;
      if (paginationSize > 0) {
        list = list.slice(offset, offset + paginationSize);
      }
  
      return list.map(row => {
        return (
          <tr className={`has-status other`}>
            <td status={TEXT_DETAILED}>
              <a onClick={() => actions.onClickSearchButton(row)}>
                <Icon 
                  className='white xsm hod'
                  component={
                    <SearchIcon />
                  }
                />
                <Icon 
                  className='dark xsm hom'
                  component={
                    <SearchIcon />
                  }
                />
              </a>
            </td>
            <td>{row.iac_data}</td>
            <td>{row.volume_total.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
            <td>{row.volume_embarcado.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</td>
            <td>{row.cargas_total}</td>
            <td>{row.cargas_embarcadas}</td>
            <td>{row.total_classificadores}</td>
          </tr>
        )
      })
    }
  
    // Realiza o filtro ao digitar
    const filterOnKeyUpHandler = (e) => {
      let textToFind = e.target.value;
      let filterResultLits = rows.filter((row) => 
        JSON.stringify(row).includes(textToFind)
      );
  
      let timeout;
      clearTimeout(timeout);
      timeout = setTimeout(() => setFilterList(filterResultLits), 700);
    }
  
    // Realiza o filtro avançado
    const advancedFilterHandler = () => {
      let filterResultList = rows.filter((row) => {
          let filterDateCondition = true;
          if (filterDate !== '')
            filterDateCondition = DateUtil.formatStringDate(filterDate) === row.iac_data;
  
          let filterTotalVolumeCondition = true;
          if (filterTotalVolume !== '')
            filterTotalVolumeCondition = parseInt(filterTotalVolume) === row.volume_total;
  
          let filterShippedVolumeCondition = true;
          if (filterShippedVolume !== '')
            filterShippedVolumeCondition = parseInt(filterShippedVolume) === row.volume_embarcado;
  
          let filterCargoTotalCondition = true;
          if (filterCargoTotal !== '')
            filterCargoTotalCondition = parseInt(filterCargoTotal) === row.cargas_total;
  
          let filterShippedCargoCondition = true;
          if (filterShippedCargo !== '')
            filterShippedCargoCondition = parseInt(filterShippedCargo) === row.cargas_embarcadas;
  
          let filterClassifiersCondition = true;
          if (filterClassifiers !== '')
            filterClassifiersCondition = parseInt(filterClassifiers) === row.total_classificadores;
  
          return filterDateCondition &&
           filterTotalVolumeCondition && 
           filterShippedVolumeCondition && 
           filterCargoTotalCondition && 
           filterShippedCargoCondition && 
           filterClassifiersCondition;
        }
      );
  
      setFilterList(filterResultList);
    }
  
    // Reseta o filtro avançado
    const resetAdvancedFilter = () => {
      setFilterDate('');
      setFilterTotalVolume('');
      setFilterShippedVolume('');
      setFilterCargoTotal('');
      setFilterShippedCargo('');
      setFilterClassifiers('');
    }
  
    // Renderiza a paginação da tabela.
    function generatePaginator() {
      let totalPages = Math.ceil(filterList.length/paginationSize);
      let selectedPage = currentPage;
  
      if ((selectedPage + 1) > totalPages) {
        selectedPage = totalPages -1;
        handlePageChange(selectedPage);
      }
      
      return (
        <>
          <TablePagination 
            component="div"
            rowsPerPageOptions={[]}
            labelDisplayedRows={({from, to, count}) => `${from}-${to} ${TEXT_PREPOSITION_OF} ${count}`}
            rowsPerPage={paginationSize}
            page={selectedPage}
            count={filterList.length}
            onChangePage={(e, page) => handlePageChange(page)}
          />
        </>
      );
    }
  
    // Manipula o evento de alterar página.
    function handlePageChange(selectedPage) {
      let totalPages = Math.ceil(filterList.length/paginationSize);
      if (selectedPage < 0 || (selectedPage + 1) > totalPages) return;
  
      setCurrentPage(selectedPage);
      setOffset(selectedPage * paginationSize);
    }
  
    return (
      <>
        <Box className="mobile-box">
          <Group className="vc g5 mv">
            <Search placeholder={TEXT_SEARCH} onKeyUp={filterOnKeyUpHandler} />
            <Button 
              className={`filter has-arrow`}
              onClick={() => setOpen(!isOpen)}
            >
              {TEXT_ADVANCED_FILTER}
            </Button>
          </Group>
        </Box>
        <Vspace />
        <Box className={`collapsed ${ isOpen ? 'active' : '' }`}>
          <form id={`form_summary_filter`} onSubmit={(e) => e.preventDefault()}>
            <Row className={`bottom-xs g4`}>
              <Col className={`col-xs-4 col-lg-12`}>
                <DatePicker
                  id={`field_date`}
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                  label={TEXT_DATE}
                />
              </Col>
              <Col className={`col-xs-4 col-lg-12`}>
                <Input
                  id={`field_total_volume`}
                  type={`number`}
                  value={filterTotalVolume}
                  onChange={(e) => setFilterTotalVolume(e.target.value)}
                  label={TEXT_TOTAL_VOLUME}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-4 col-lg-12`}>
                <Input
                  id={`field_boarded_volume`}
                  type={`number`}
                  value={filterShippedVolume}
                  onChange={(e) => setFilterShippedVolume(e.target.value)}
                  label={TEXT_BOARDED_VOLUME}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-4 col-lg-12`}>
                <Input
                  id={`field_total_cargo`}
                  type={`number`}
                  value={filterCargoTotal}
                  onChange={(e) => setFilterCargoTotal(e.target.value)}
                  label={TEXT_TOTAL_CARGO}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-4 col-lg-12`}>
                <Input
                  id={`field_boarded_cargo`}
                  type={`number`}
                  value={filterShippedCargo}
                  onChange={(e) => setFilterShippedCargo(e.target.value)}
                  label={TEXT_BOARDED_CARGO}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
              <Col className={`col-xs-4 col-lg-12`}>
                <Input
                  id={`field_classificators`}
                  type={`number`}
                  value={filterClassifiers}
                  onChange={(e) => setFilterClassifiers(e.target.value)}
                  label={TEXT_CLASSIFIERS}
                  placeholder={TEXT_FILL_FIELD}
                />
              </Col>
            </Row>
            <Vspace className={`lg`} />
            <Group className={`r g2 m-spread`}>
              <Button 
                type="reset" 
                className="outlined"
                onClick={resetAdvancedFilter}
              >
                {TEXT_CLEAR_FIELDS}
              </Button>
              <Button 
                type="submit"
                onClick={advancedFilterHandler}
              >
                {TEXT_FILTRATE}
              </Button>
            </Group>
          </form>
        </Box>
        <Vspace />
        <div className={`fixed-cols-table f-1 f-2 ${isLoading ? 'loading' : ''}`}>
          <div className="fixed-cols">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>{TEXT_DATE}</th>
                  <th>{TEXT_TOTAL_VOLUME}</th>
                  <th>{TEXT_BOARDED_VOLUME}</th>
                  <th>{TEXT_TOTAL_CARGO}</th>
                  <th>{TEXT_BOARDED_CARGO}</th>
                  <th>{TEXT_CLASSIFIERS}</th>
                </tr>
              </thead>
              <tbody>
                { renderTable() }
              </tbody>
            </Table>
          </div>
          <div className="scroll-cols">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>{TEXT_DATE}</th>
                  <th>{TEXT_TOTAL_VOLUME}</th>
                  <th>{TEXT_BOARDED_VOLUME}</th>
                  <th>{TEXT_TOTAL_CARGO}</th>
                  <th>{TEXT_BOARDED_CARGO}</th>
                  <th>{TEXT_CLASSIFIERS}</th>
                </tr>
              </thead>
              <tbody>
                { renderTable() }
              </tbody>
            </Table>
            { generatePaginator() }
          </div>
        </div>
      </>
    )
  }

  export default SummaryList;