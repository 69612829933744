import React from 'react';
import ReactDOM from 'react-dom';


const Modal = ({ className, size, children, ...props }) => {
  return ReactDOM.createPortal(
    <div className={`wac-modal ${className}`} {...props}>
      <div className={`wac-modal-content ${size}`}>
        { children }
      </div>
    </div>,
    document.querySelector("#modal-root")
  );
};

export default Modal