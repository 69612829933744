import PropTypes from "prop-types";
import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '../Typography';

export const Steps = ({ className, children, id }) => {
  return (
    <ol className={`wac-wizard ${className}`} id={id}>
      {children}
    </ol>
  );
};

export const Step = ({ className, onClick, children, link, counter }) => {
  return (
    <li className={`wac-step ${className}`}>
      <Link onClick={onClick} to={link}>
        <span className='hom'>
          {counter}
        </span>
        <Text className='s13'>
          {children}
        </Text>
      </Link>
    </li>
  );
};

Step.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  counter: PropTypes.any,
  link: PropTypes.string,
  onClick: PropTypes.func
}

Step.defaultProps = {
  onClick: () => {}
};