/* eslint-disable */
import React, { useState } from "react";
import Button from "components/commons/Button";
import { Text } from "components/commons/Typography";
import { Dropdown } from 'components/commons/Dropdown';
import 'react-tabs/style/react-tabs.css';
import { I18nTranslator } from "core/i18n/I18nTranslator";
import { CargoService } from "core/cargo/CargoService";


function DropdownExport({ label = I18nTranslator.t('EXPORT'), list }) {

  const [dropdownExport, openDropdown] = useState(false);

  function handleDropdownExport() {
    openDropdown(dropdownExport === false);
  }
  
  function downloadXml() {
    handleDropdownExport();
    
    let iac_laudos = "";
    let os = "";
    list.map((obj) => {
      if (iac_laudos !== "") {
        iac_laudos += ",";
      }

      os = obj.ordem_servico.slo_codigo;
      iac_laudos += obj.iac_laudo;
    });
    
    let fileName = `${os} - ${I18nTranslator.t("BOARDING_LIST")}.xml`;

    CargoService.downloadXmlList(iac_laudos, fileName);
  }

  return (
    <>
      <Dropdown
        className={`form-input sm mla ${ dropdownExport ? 'active' : ''}`}
        trigger={
          <Button 
            onClick={handleDropdownExport} 
            className="outlined" 
            title={label}
          >
            {label}
          </Button>
        }>
        <Button className='inline' onClick={downloadXml}>
          <Text className='s11'>
            XML
          </Text>
        </Button>
      </Dropdown>
    </>
  );
}

export default DropdownExport;
