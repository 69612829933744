import React from 'react';
import Group from 'components/commons/Group';
import Box from 'components/commons/Box';
import Button from 'components/commons/Button';
import {Text, Title} from 'components/commons/Typography';
import {Col, Row} from 'components/commons/FlexBoxGrid';

const BranchData = ({ onClick, data }) => {

  return (
    <Box>
      <Button onClick={onClick} className="button-close" />
      <Title className='s12'>
        Dados da Filial
      </Title>
      {
        typeof data === 'object' &&
        Object.keys(data).length > 0 ?
        (<React.Fragment>
          <Row className='g2'>
            <Col>
              <Group className='fdc'>
                <Text className='s13 fw600 uppercase'>uf</Text>
                <Text className='s13 uppercase'>{data && data.cidade ? data.cidade.estado.est_sigla : ''}</Text>
              </Group>
            </Col>
            <Col>
              <Group className='fdc'>
                <Text className='s13 fw600 uppercase'>Cidade</Text>
                <Text className='s13 uppercase'>{data && data.cidade ? data.cidade.cid_nome : ''}</Text>
              </Group>
            </Col>
          </Row>
          <Row className='g2'>
            <Col>
              <Group className='fdc'>
                <Text className='s13 fw600 uppercase'>CNPJ</Text>
                <Text className='s13 uppercase'>{data ? data.cli_cpfcnpj : ''}</Text>
              </Group>
            </Col>
            <Col>
              <Group className='fdc'>
                <Text className='s13 fw600 uppercase'>Inscrição Estadual</Text>
                <Text className='s13 uppercase'>{data ? data.cli_insc_estadual : ''}</Text>
              </Group>
            </Col>
          </Row>
          <Row className='g2'>
            <Col>
              <Group className='fdc'>
                <Text className='s13 fw600 uppercase'>E-mail Contato</Text>
                <Text className='s13 uppercase'>{data ? data.cli_email : ''}</Text>
              </Group>
            </Col>
          </Row>
        </React.Fragment>) : (<Row><Col><Text className='s13 fw600 uppercase'>Nada encontrado.</Text></Col></Row>)
      }     
    </Box>
  )
}

export default BranchData