import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core'
import Button from 'components/commons/Button'
import { I18nTranslator } from 'core/i18n/I18nTranslator';
import React, { useState } from 'react'

const KEY = "cookie_consent_accept";
const cookieLocalStorageHandler = {
    get: () => {
        return localStorage.getItem(KEY)
    },
    set: (accept) => {
        localStorage.setItem(KEY, typeof accept === 'string' ? accept : accept.toString())
    } 
}

export default function CookieConsent() {
    const [open, setOpen] = useState(!cookieLocalStorageHandler.get())
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const COOKIE_POLICY = I18nTranslator.t("COOKIE_POLICY")
    const COOKIE_CONSENT_MESSAGE = I18nTranslator.t("COOKIE_CONSENT_MESSAGE")
    const I_AGREE = I18nTranslator.t("I_AGREE")

    const handleClose = () => {
        setOpen(false)
        cookieLocalStorageHandler.set(true)
    }

    return (
        <div>
            <Dialog 
                fullScreen={fullScreen} 
                open={open}
                aria-labelledby="cookie-consent-title"
            >
                <DialogTitle id="cookie-consent-title">
                    {COOKIE_POLICY}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {COOKIE_CONSENT_MESSAGE}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="primary">
                        {I_AGREE}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
