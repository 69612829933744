/* eslint-disable */
import React, {useState} from "react";
import Table from "components/commons/Table";
import DateUtil from "core/utils/DateUtil";
import PopoverContent from './PopoverContent';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import OptionsContent from './OptionsContent';
import Modal from 'components/commons/Modal';
import Paginator from "components/commons/Paginator";
import Chat from "components/commons/Chat/Chat";
import CollapsedRowContent from "./CollapsedRowContent";
import {ChatService} from "../../../../../../../core/chat/ChatService";
import ShowLaudo from "../ShowLaudo/ShowLaudo";
import AttachFile from "./AttachFile/AttachFile";

const InboxList = (props) => {

    // Props.
    const {
        cargoList = [],
        loading = false,
        paginationSize = 0,
        activeColumns,
        showDropdownColumns,
        handleChangeColumns,
        onClickColumns
    } = props;

    // Traduções.
    const TEXT_PREPOSITION_OF = I18nTranslator.t('PREPOSITION_OF');
    const TEXT_DATE = I18nTranslator.t('DATE');
    const TEXT_CLIENT = I18nTranslator.t('CLIENT');
    const TEXT_CAR_PLATE = I18nTranslator.t('CAR_PLATE');
    const TEXT_CARRIER = I18nTranslator.t('CARRIER');
    const TEXT_CLASSIFIER = I18nTranslator.t('CLASSIFIER');
    const TEXT_SUPERV = I18nTranslator.t('SUPERV');
    const TEXT_CLIENTFILIAL = I18nTranslator.t('BRANCH_CUSTOMER');
    const TEXT_BOARD_LOCATION = I18nTranslator.t('BOARD_LOCATION');
    const TEXT_MENIFESTADO = I18nTranslator.t('MANIFESTADO');
    const TEXT_ISSUE_CTE = I18nTranslator.t('ISSUE_CTE');
    const TEXT_NO = I18nTranslator.t('NO');
    const TEXT_HOUR_DOC_ATTACHMENT = I18nTranslator.t('HOUR_DOC_ATTACHMENT');
    const TEXT_YES = I18nTranslator.t('YES');
    const TEXT_CLIREGIONAL = I18nTranslator.t('REGIONAL_CUSTOMER');
    const TEXT_WEIGHT = I18nTranslator.t('WEIGHT');
    const TEXT_INVOICE = I18nTranslator.t('INVOICE');
    const TEXT_GELD_FIELD = I18nTranslator.t('GELD_FIELD');
    const TEXT_NOTHING_FOUND = I18nTranslator.t('NOTHING_FOUND');
    const TEXT_MANIFESTED = I18nTranslator.t('MANIFESTED');
    const TEXT_IN_PROGRESS = I18nTranslator.t('IN_PROGRESS');
    const TEXT_NO_STATUS = I18nTranslator.t('NO_STATUS');
    const TEXT_RECENTLY_COMPLETED = I18nTranslator.t('RECENTLY_COMPLETED');
    const TEXT_HORA_DOC_DEVOLVIDO = I18nTranslator.t('HORA_DOC_DEVOLVIDO');
    const TEXT_ATENDENTE = I18nTranslator.t('ATENDENTE');
    const TEXT_STATUS = I18nTranslator.t('STATUS_CARGA');


    // Colunas.
    const columns = [
        <OptionsContent
            showDropdownColumns={showDropdownColumns}
            activeColumns={activeColumns}
            handleChange={handleChangeColumns}
            onClick={onClickColumns}
        />,
        'O.S.',
        TEXT_CLIREGIONAL,
        TEXT_CLIENTFILIAL,
        TEXT_DATE,
        TEXT_HOUR_DOC_ATTACHMENT,
        TEXT_HORA_DOC_DEVOLVIDO,
        TEXT_CAR_PLATE,
        TEXT_WEIGHT,
        TEXT_INVOICE,
        TEXT_CARRIER,
        TEXT_BOARD_LOCATION,
        TEXT_SUPERV,
        TEXT_CLASSIFIER,
        TEXT_GELD_FIELD,
        TEXT_MENIFESTADO,
        TEXT_ATENDENTE,
        TEXT_MANIFESTED,
        TEXT_ISSUE_CTE,
        TEXT_STATUS,
    ];


    const [offset, setOffset] = useState(0);
    const [modalContent, setModalContent] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const [collapsedRowList, setCollapsedRowList] = useState([])

    const [activeModalAttach, setModalAttach] = useState(false)

    const [activeChat, setChat] = useState(false)
    const [iacCodigoChatActive, setIacCodigoChatActive] = useState(0)
    const [iacPlacaChatActive, setIacPlacaChatActive] = useState(0)
    const [messagesList, setMessagesList] = useState([]);
    const [files, setFiles] = useState([]);

    const [intervalChat, setIntervalChat] = useState(null);



    function toggleCollapseRow(id, collapsed) {
        let newCollapsedRowList = [...collapsedRowList, id]
        if (collapsed) newCollapsedRowList = collapsedRowList.filter(item => item !== id)
        setCollapsedRowList(newCollapsedRowList)
    }

    function handleChat(iac_codigo = 0, iac_placa = 0) {

        if (activeChat) {
            clearInterval(intervalChat);
        } else {
            setMessagesList([])
            setIacCodigoChatActive(iac_codigo)
            setIacPlacaChatActive(iac_placa)
        }

        setChat(activeChat => !activeChat);
    }


    // Exibe o modal e adiciona um conteudo para o mesmo
    function showModal(content) {
        try {
            setModalContent(content);
            setModalIsOpen(true);
        } catch (error) {
            console.error(error);
        }
    }


    function handleModalAttach() {
        setModalAttach(activeModalAttach === false);
    }



    function handleSubmit(e, message) {
        e.preventDefault();

        if (!message.length) return

        let filter_parameter = {
            'msg': message,
            "iac_codigo": iacCodigoChatActive,
        }


        // envia a menssagem
        ChatService.sendNewMessage(filter_parameter)
        .then(() => {
        })
        .catch((error) => {
        });


    }

     function handleLoadingMessage(filterParameters) {

        ChatService.loadingMessage(filterParameters)
            .then((response) => {
                response.list_mensagem.map((row) => {
                    if (row.chat_msg_tipo_usuario === "A") {
                        row.position = "right"
                    } else {
                        row.position = "left"
                    }
                })
                setMessagesList(response.list_mensagem);
            })
            .catch((error) => {
                console.error(error);
            })
     }

    function renderOsTable() {
        const validateProperty = (obj, prop) => {
            return obj && obj.hasOwnProperty(prop) ? obj[prop] : null;
        }
        // Retorna o nome da classe para o respectivo status.
        const getStatusClass = (status) => {
            let dicionarioClasseCss = {
                'A': 'pending',
                'SS': 'canceled',
                'F': 'complete',
            }


            return (typeof dicionarioClasseCss[status] != 'undefined') ? dicionarioClasseCss[status] : '';
        }

        // Retorna o nome do status.
        const getStatusName = (status) => {
            let dicionario = {
                'A': TEXT_IN_PROGRESS,
                'SS': TEXT_NO_STATUS,
                'F': TEXT_RECENTLY_COMPLETED,
            }

            if (dicionario[status]) {
                return dicionario[status];
            }

            return null;
        }

        let list = cargoList;
        if (paginationSize > 0) {
            list = list.slice(offset, offset + paginationSize);
        }

        return list.map((cargo, i) => {
            console.log(cargo)
            var {
                ordem_servico,
                chat,
                iac_codigo,
                iac_fotos,
                class_nome,
                iac_imprime_arquivos,
                nome_atendente,
                status,
                iac_laudo,
                iac_nota_fiscal,
                iac_dt_servidor,
                bas_cidade,
                iacf_data_ult_anexo,
                iac_placa,
                regional,
                iac_fotos_doc_enviadas,
                iac_hora_fotos_doc_enviadas,
                data_hora_manifestado,
                iac_manifesto_via_integrador,
                CidEmbarque,
                numero_mensagem,
                iac_nota_fiscal_origem,
                iac_peso,
                iac_manifesto,
                transportadora,
                nome_status
            } = cargo
            var {slo_codigo, filial,slo_faturar_geldnocampo} = ordem_servico
            var {cli_nome} = filial
            let nome_transportadora=""
            if (transportadora){
                var {transp_nome} = transportadora
                nome_transportadora=transp_nome
            }

            var progress = 10;
            if (status === "F") {
                progress = 100
            } else if (iac_manifesto === "S") {
                progress = 70
            } else if (iac_fotos_doc_enviadas === "S") {
                progress = 50
            } else if (iacf_data_ult_anexo) {
                progress = 30
            }

            if(iac_hora_fotos_doc_enviadas){
                var dataServidor = new Date(iac_dt_servidor)
                var horaLaudo = dataServidor.toLocaleTimeString(navigator.language,{hour:'2-digit',minute:"2-digit",second:"2-digit"})
                var a = horaLaudo.split(':')
                var b = iac_hora_fotos_doc_enviadas.split(':')
                var secondsLaudo = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
                var secondsHoraEnviado = (+b[0]) * 60 * 60 + (+b[1]) * 60 + (+b[2])
                var resultado = secondsLaudo - secondsHoraEnviado
                    if(resultado > 0 ){
                        var data = new Date('2021-01-01 '+ iac_hora_fotos_doc_enviadas)
                        data.setHours(data.getHours() + 1)
                        iac_hora_fotos_doc_enviadas = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()
                    }
            }
          
            return (
                <>
                    <tr className={`has-status ${getStatusClass(status)} collapsed ${collapsedRowList.includes(iac_codigo) ? 'active' : ''}`}>
                        <td status={getStatusName(status)}>
                            <PopoverContent onClick={handleModalAttach}
                                            onClickStatus={() => toggleCollapseRow(iac_codigo, collapsedRowList.includes(iac_codigo))}
                                            onClickChat={
                                                () => {
                                                    setIntervalChat(
                                                        setInterval(() => {
                                                            handleLoadingMessage({'iac_codigo': iac_codigo});
                                                        }, 1500)
                                                    )
                                                    handleChat(iac_codigo, iac_placa)
                                                }
                                            }
                                            handleVisualizarDados={
                                                () => {
                                                    showModal(
                                                        <ShowLaudo
                                                            dataObject={cargo}
                                                            handleClose={() => setModalIsOpen(false)}
                                                        />
                                                    )
                                                }
                                            }
                                            handleAnexarDocumentos={
                                                () => {
                                                    showModal(
                                                        <AttachFile
                                                            onClick={() => setModalIsOpen(false)}
                                                            defaultFiles={files}
                                                            dataLaudo={cargo}
                                                        />

                                                    )
                                                    setModalIsOpen(true)
                                                }
                                            }
                                            handleVisualizarGaleriaImagem={
                                                () => {
                                                    window.open('http://app.geneslab.com.br/classificacao/ordemservico/painel_cargas/pdf/impressao.php?ID=' + iac_laudo + '&CHAVE=' + iac_nota_fiscal_origem + '&cli_geld_campo_api_nfe=N&keyReg=' + iac_codigo + '&nf_ret=&nf_aux=', '_blank');
                                                }
                                            }
                                            galeriaImagem={iac_fotos === "S" && iac_nota_fiscal_origem.length > 40 ? "S" : "N"}
                                            componentWillUnmount={() => clearInterval(intervalChat)}
                                            numero_mensagem={numero_mensagem}
                                            id_chat={chat} />

                            <Chat onClickCloseChat={handleChat}
                                  activeChat={activeChat}
                                  title={iacPlacaChatActive}
                                  id={chat}
                                  messageList={messagesList}
                                  onSubmit={(e, message) => {
                                      handleSubmit(e, message)
                                  }} />
                        </td>
                        <td>{slo_codigo}</td>
                        <td>{regional}</td>
                        <td>{cli_nome}</td>
                        <td>{DateUtil.formatStringDateTime(iac_dt_servidor)} {DateUtil.formatStringTime(iac_dt_servidor)}</td>
                        <td>{iac_hora_fotos_doc_enviadas ? (`${DateUtil.formatStringDateTime(iac_dt_servidor)} ${(iac_hora_fotos_doc_enviadas)}`) : 'Não Anexado'}</td>
                        <td>{data_hora_manifestado ? (`${DateUtil.formatStringDateTime(data_hora_manifestado)} ${DateUtil.formatStringTime(data_hora_manifestado)}`): 'Não Manifestado'}</td>
                        <td>{iac_placa}</td>
                        <td>{iac_peso}</td>
                        <td>{iac_nota_fiscal}</td>
                        <td>{nome_transportadora ? nome_transportadora : "-"}</td>
                        <td>{CidEmbarque ? CidEmbarque : "-"}</td>
                        <td>{bas_cidade ? bas_cidade : "-"}</td>
                        <td>{class_nome ? class_nome : "-"}</td>
                        <td>{slo_faturar_geldnocampo === "C" ? TEXT_CLIENT : TEXT_CARRIER}</td>
                        <td>{iac_manifesto_via_integrador ? (iac_manifesto_via_integrador === "S" ? 'Integrador' : 'Manual'): ""}</td>
                        <td>{nome_atendente}</td>
                        <td>{iac_manifesto ? (iac_manifesto === "S" ? TEXT_YES : TEXT_NO) : "-"}</td>
                        <td>{iac_imprime_arquivos ? (iac_imprime_arquivos === "S" ? TEXT_YES : TEXT_NO) : "-"}</td>
                        <td>{nome_status !== null ? nome_status : 'Não Informado'}</td>
                        <td>
                            <button
                                onClick={() => toggleCollapseRow(iac_codigo, collapsedRowList.includes(iac_codigo))}
                                className="collapsed-row-trigger"/>
                        </td>
                    </tr>
                    <tr className="collapsed-row">
                        <td colSpan="1000">
                            <CollapsedRowContent id={iac_codigo} progress={progress} onBlur={()=>setCollapsedRowList([])}/>
                        </td>
                    </tr>
                </>

            )
        })
    }

    // Renderiza o cabeçalho da tabela.
    function renderTHeader() {
        let thList = columns.map((colName) => {
            return (
                <th>{colName}</th>
            )
        });

        return (
            <thead>
            <tr>
                {thList}
                <th></th>
            </tr>

            </thead>
        );
    }

    // Retorna uma string com a lista de classes CSS das colunas que devem ser ocultadas.
    function getClassNameHiddenColumns() {
        try {
            let classNameList = [];
            for (let i = 2; i < columns.length; i++) {
                if (activeColumns && activeColumns.indexOf(i) === -1) {
                    classNameList.push(`h-${i + 1}`);
                }
            }
            return classNameList.join(" ");
        } catch (error) {
            console.error(error)
        }
    }


// Manipula o envio da menssagem


    return (
        <>
            <div className={`fixed-cols-table f-1 f-2 ${getClassNameHiddenColumns()}`}>
                <div className="fixed-cols">
                    <Table className={loading ? 'loading' : ''}>
                        {renderTHeader()}
                        <tbody>
                        {
                            cargoList.length > 0 ?
                                renderOsTable() : (
                                    <tr style={{textAlign: 'center', marginBottom: '15px'}}>
                                        <td colSpan="20">{TEXT_NOTHING_FOUND}</td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </div>
                <div className="scroll-cols">
                    <Table className={loading ? 'loading' : ''}>
                        {renderTHeader()}
                        <tbody>
                        {

                            cargoList.length > 0 ?
                                renderOsTable() : (
                                    <tr style={{textAlign: 'center'}}>
                                        <td colSpan="22">{TEXT_NOTHING_FOUND}</td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                    <Paginator
                        paginatorSize={cargoList.length}
                        pageSize={paginationSize}
                        onOffsetChange={setOffset}
                        setRowsLabel={({from, to, count}) => `${from}-${to} ${TEXT_PREPOSITION_OF} ${count}`}
                    />
                </div>
            </div>
            <Modal
                className={`lg ${modalIsOpen ? 'active' : ''}`}>
                {modalContent}
            </Modal>


        </>
    );
};

export default InboxList