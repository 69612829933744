import {configuration} from "../../config";
import {userAccessService} from "../user-access/UserAccessService";
import fetch from "isomorphic-fetch";

export class AvisosService {
    static getList(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/mensagens?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => resolve(response))
                .catch(() => reject(false));
        });
    }

    static readMessage(filter_parameter: any) {
        const searchParams = new URLSearchParams(filter_parameter);
        const url = configuration.AUTH_BASE_URL + "cliconnect/ler_mensagem?" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        };

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
                .then(response => response.json())
                .then((response) => resolve(response))
                .catch(() => reject(false));
        });
    }
}