import { configuration } from "config";
import { I18nTranslator } from "core/i18n/I18nTranslator";
import { userAccessService } from "core/user-access/UserAccessService";


export class TransportService {
    static getList(filterParameters: any) {
        const searchParams = new URLSearchParams(filterParameters);
        const url = configuration.AUTH_BASE_URL + "cliconnect/transporte" + searchParams.toString();

        const headerConfig: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
                .then(response => response.json())
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }

    static getTranslation(name: any) {
        let token = `TRANSPORT_${name}`;
        let translation = I18nTranslator.t(token);
        return (translation === token ? name : translation)
    }
}