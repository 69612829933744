import React, {useEffect, useState, useReducer, Component, useCallback} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Group from 'components/commons/Group';
import Button from 'components/commons/Button';
import Vspace from 'components/commons/Vspace';
import Search from 'components/commons/Search';
import AdvancedFilter from './components/AdvancedFilter';
import {Col, Row} from 'components/commons/FlexBoxGrid';
import {Text, Title} from 'components/commons/Typography';
import FilterList from './components/FilterList';
import Box from 'components/commons/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import {TranshipmentService} from 'core/transhipment/TranshipmentService';
import {BaseService} from 'core/base/BaseService';
import {BoardLocation} from 'core/board-location/BoardLocation';
import TextUtil from 'core/utils/TextUtil';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import { render } from 'react-dom';
import { PinDropSharp } from '@material-ui/icons';
import {Radio, RadioOption} from 'components/commons/Radio';
import './embarque.scss';
import Feedback from 'components/commons/Feedback';


function TypeServiceLocation({className, values, handleChange}) {
    
    const TEXT_SERVICE_LOCATION_TYPE = I18nTranslator.t('SERVICE_LOCATION_TYPE'); // Tipo de local de serviço
    const TEXT_TRANSHIPMENT = I18nTranslator.t("TRANSHIPMENT"); // Transbordo
    const TEXT_TERMINAL = I18nTranslator.t("TERMINAL"); // Terminal
    const TEXT_BIG_HOPPERS = I18nTranslator.t("BIG_HOPPERS"); // Moegões
    const TEXT_SHIP_PORT = I18nTranslator.t("PORT"); // Porto.
    const TEXT_STORAGE = I18nTranslator.t("STORAGE"); // Armazém.
    const TEXT_FARM = I18nTranslator.t("FARM"); // Fazenda.
    const TEXT_SILO = I18nTranslator.t("SILO"); // Silo.
    const TEXT_SELECT = I18nTranslator.t("SELECT"); // Selecionar.
    const TEXT_BOARD_LOCATION = I18nTranslator.t("BOARD_LOCATION"); // Local de embarque.
    const TEXT_TYPE_OF_SHIPMENT = I18nTranslator.t("TYPE_OF_SHIPMENT"); // Tipo de embarque.
    const TEXT_RESPONSIBLE_SUPERVISION = I18nTranslator.t("RESPONSIBLE_SUPERVISION"); // Supervisão responsável.
    const TEXT_ENTRY = I18nTranslator.t("ENTRY"); // Entrada.
    const TEXT_EXIT = I18nTranslator.t("EXIT"); // Saída.
    const TEXT_LOADING = I18nTranslator.t("LOADING"); // Carregando.
    const TEXT_BOARDING = I18nTranslator.t("BOARDING"); // Embarque.
    const TEXT_SEARCH = I18nTranslator.t("SEARCH"); // Buscar.
    const TEXT_ADVANCED_FILTER = I18nTranslator.t("ADVANCED_FILTER"); // Filtro avançado.

    const [advancedFilter, setAdvancedFilter] = useState(true)
    const [checkedRow, setcheckedRow] = useState(values.linha_embarque_check || false);
    const [terminalList, setTerminalList] = useState([]);
    const [moegaoList, setMoegaoList] = useState([]);
    const [portoList, setPortoList] = useState([]);
    const [baseList, setBaseList] = useState([]);
    const [loadingBaseList, setLoadingBaseList] = useState(false);
    const [boardList, setBoardList] = useState(values.lista_embarque || []);
    const [loadingBoardList, setLoadingBoardList] = useState(false);
    const [filteredBoardList, setFilteredBoardList] = useState(values.lista_embarque || []);
    const [defaultSearch, setDefaultSearch] = useState('');
    const [filter, setFilter] = useState(values.filter || false);
    const [filtroTpEmbarque, setFiltroTpEmbarque] = useState(values.tipo_local_servico_index);

    const shipmentTypeList = [
        {label: TEXT_ENTRY, value: 'ENTRADA'},
        {label: TEXT_EXIT, value: 'SAIDA'}
    ];
    const formBoardLocationId = "form_board_location";

    useEffect(() => {
        setTimeout(() => getTranshipmentList(), 700);
    }, []);

    function getTranshipmentList(parameters = {}) {
        TranshipmentService.getTranshipmentList(parameters)
            .then(response => {
                if (Array.isArray(response)) {
                    setTerminalList(response.filter(transhipment => transhipment.trans_tipo === 'T'));
                    setMoegaoList(response.filter(transhipment => transhipment.trans_tipo === 'M'));
                    setPortoList(response.filter(transhipment => transhipment.trans_tipo === 'P'));
                }
            })
            .catch(error => console.error(error));
    }

    function getBaseList(parameters = {}) {
        setLoadingBaseList(true);
        BaseService.filter(parameters)
            .then(response => {
                if (Array.isArray(response)) {
                    setBaseList(response);
                }
            })
            .catch(error => console.error(error))
            .finally(() => setLoadingBaseList(false))
    }

    function handleAdvancedFilter() {
        setAdvancedFilter(advancedFilter === false)
    }

    function handleChecked(checked) {
        handleChange('linha_embarque_check', checked);
        setcheckedRow(checked)
    }

    function handleChangeTranshipment(e, newTranshipment, type) {
        handleChange(type, newTranshipment);
        if (newTranshipment) {
            getBaseList({trans_codigo: newTranshipment.trans_codigo, filterByFilialName: 'S'});
            return;
        }

        setBaseList([]);
    }

    function getTranshipmentLabel(transhipment) {
        if (transhipment) {
            let {trans_nome, cidade} = transhipment;
            let {estado} = cidade || {};
            return estado && trans_nome ? `(${estado.est_sigla}) ${trans_nome}` : '';
        }

        return '';
    }

    function getBaseLabel(base) {
        if (base) {
            let {filial, cidade} = base;
            return filial && cidade ? `${filial.cli_nome} - ${cidade.cid_nome}` : '';
        }
        return '';
    }
    
    function handleSelectTab(index) {
        setAdvancedFilter(index === 3);
        handleChange('tipo_local_servico_index', index);
        handleChange('tipo_local', 2);
        setFiltroTpEmbarque(index);
    }

    function handleSubmitBoardLocation(e) {
        let formElements = document.getElementById(formBoardLocationId).elements;
        let buttonFilter = document.getElementById(`${formBoardLocationId}_button`);
        let likeFilterElements = ["loc_nome", "pro_nome"];
        let filterParameters = {};

        for (let i = 0; i < formElements.length; i++) {
            let item = formElements.item(i);
            if (item.name === "") continue;
            if (item.name === "loc_cnpj") item.value = TextUtil.removeFormat(item.value);
            filterParameters[item.name] = (item.value !== '' && likeFilterElements.indexOf(item.name) > -1) ? `%${item.value}%` : item.value;
        }

        buttonFilter.disabled = true;
        setLoadingBoardList(true);

        BoardLocation.filter(filterParameters)
            .then((response) => {
                if (Array.isArray(response)) {
                    handleChange('lista_embarque', response);
                    setBoardList(response);
                    setFilteredBoardList(response)
                }
            })
            .catch(() => {
            })
            .finally(() => {
                buttonFilter.disabled = false;
                setLoadingBoardList(false);
            });
    }

    function handleSearch(value) {
        try {
            if (!value.length) setFilteredBoardList(boardList);

            let filterData = boardList.filter(
                (row) => JSON.stringify(row).toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBoardList(filterData);
        } catch (error) {
        }
    }

    function handleChangeEmbarque(e, newValue) {
        handleChange('slo_cif_fob', newValue);
    }

    const [validation, setValidation] = useState({
        product_embarque: { feedback: "Informe um tipo de embarque!", error: false },
      });

    return (
        <div className={className}>
            <Row className='start-xs'>
                <Col>
                    <Text className='s11 fw500'>{TEXT_SERVICE_LOCATION_TYPE}</Text>
                </Col>
            </Row>
            <Vspace className='sm'/>
            <Tabs
                onSelect={handleSelectTab}
                defaultIndex={values.tipo_local_servico_index}
                className={`wac-tabs-featured mv ${checkedRow ? 'item-checked-04' : ''}`}
            >
                { values.service.serv_codigo == 55 ? (
                <TabList>
                    <Tab>
                        <Button className='f1 ns'
                                onClick={handleChangeTranshipment}>{TEXT_TRANSHIPMENT} - {TEXT_TERMINAL}</Button>
                    </Tab>
                </TabList> ) : '' }
                { values.service.serv_codigo != 55 ? (
                <TabList>
                    <Tab>
                        <Button className='f1 ns'
                                onClick={handleChangeTranshipment}>{TEXT_TRANSHIPMENT} - {TEXT_TERMINAL}</Button>
                    </Tab>
                    <Tab>
                        <Button className='f1 ns'
                                onClick={handleChangeTranshipment}>{TEXT_TRANSHIPMENT} - {TEXT_BIG_HOPPERS}</Button>
                    </Tab>
                    <Tab>
                        <Button className='f1 ns'
                                onClick={handleChangeTranshipment}>{TEXT_SHIP_PORT} - {TEXT_TERMINAL}</Button>
                    </Tab>
                    <Tab>
                        <Button className='f1 ns'
                                onClick={handleChangeTranshipment}>{TEXT_STORAGE}/{TEXT_FARM}/{TEXT_SILO}</Button>
                    </Tab>
                </TabList> ) : '' }
                <TabPanel>
                    { values.service.serv_codigo == 55 ? (
                    <Col class="tipoEmbarque" style={{ marginTop: `-1px`, width: `calc(100% + 15px)`, backgroundColor: `#E4F0D0`, margin: `-2px 0px 0px -16px` }}>
                        <Col style={{ marginLeft: `10px`, marginTop: `-18px`}}>
                        <Text className={'s11 fw500'}>
                        {I18nTranslator.t("TYPE_OF_SHIPMENT")}
                        </Text>
                        <Vspace className='sm' />
                        <Radio className={'overflow-mobile'} style={{ marginLeft: `-8px`}} >
                        <RadioOption
                            name='slo_cif_fob'
                            label='CIF'
                            value='C'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "C"}
                        />
                        </Radio>
                        <Vspace className='sm' />
                        {
                            values.slo_cif_fob == "" && Object.values(values.produto).length != 0 ? 
                            (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
                        }
                        </Col>
                    </Col> ) : ''}
                    { values.service.serv_codigo != 55 ? (
                    <Col class="tipoEmbarque" style={{ marginTop: `-1px`, width: `calc(100% + 15px)`, backgroundColor: `#E4F0D0`, margin: `-2px 0px 0px -16px` }}>
                        <Col style={{ marginLeft: `10px`, marginTop: `-18px`}}>
                        <Text className={'s11 fw500'}>
                        {I18nTranslator.t("TYPE_OF_SHIPMENT")}
                        </Text>
                        <Vspace className='sm' />
                        <Radio className={'overflow-mobile'} style={{ marginLeft: `-8px`}} >
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='CIF'
                            value='C'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "C"}
                        /> ) : '' }
                         { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='FOB'
                            value='F'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "F"}
                        /> ) : '' }
                        { filtroTpEmbarque == 0 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='TRANSBORDO'
                            value='T'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "T"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 2 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='PORTO'
                            value='P'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "P"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='OUTROS '
                            value=''
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === ''}
                        /> ) : '' }
                        </Radio>
                        <Vspace className='sm' />
                        {
                            values.slo_cif_fob == "" && Object.values(values.produto).length != 0 ? 
                            (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
                        }
                        </Col>
                    </Col> ) : ''}
                    <Vspace className='hom'/>
                    <Title className='s11 fw500'>
                        {TEXT_SELECT} {TEXT_TRANSHIPMENT} - {TEXT_TERMINAL}
                    </Title>
                    <Vspace/>
                    <Box>
                        <Row>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_transhipment_01"
                                    defaultValue={values.terminal}
                                    options={terminalList}
                                    getOptionLabel={(option) => getTranshipmentLabel(option)}
                                    onChange={(e, newValue) => handleChangeTranshipment(e, newValue, 'terminal')}
                                    disableanimation="true"
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={`${TEXT_TRANSHIPMENT} - ${TEXT_TERMINAL}`}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_type_of_shipment_01"
                                    disableanimation="true"
                                    options={shipmentTypeList}
                                    defaultValue={values.tipo_embarque}
                                    onChange={(event, newValue) => handleChange('tipo_embarque', newValue)}
                                    getOptionLabel={(option) => option.label  || ''}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_TYPE_OF_SHIPMENT}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_responsible_supervision_01"
                                    disableanimation="true"
                                    loading={loadingBaseList}
                                    loadingText={TEXT_LOADING}
                                    options={baseList}
                                    getOptionLabel={(option) => getBaseLabel(option)}
                                    defaultValue={values.supervisao}
                                    onChange={(e, newValue) => handleChange('supervisao', newValue)}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_RESPONSIBLE_SUPERVISION}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                    </Box>
                </TabPanel>
                <TabPanel>
                <Col class="tipoEmbarque"  style={{ marginTop: `-1px`, width: `calc(100% + 15px)`, backgroundColor: `#E4F0D0`, margin: `-2px 0px 0px -16px` }}>
                        <Col style={{ marginLeft: `10px`, marginTop: `-18px`}}>
                        <Text className={'s11 fw500'}>
                        {I18nTranslator.t("TYPE_OF_SHIPMENT")}
                        </Text>
                        <Vspace className='sm' />
                        <Radio className={'overflow-mobile'} style={{ marginLeft: `-8px`}} >
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='CIF'
                            value='C'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "C"}
                        /> ) : '' }
                         { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='FOB'
                            value='F'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "F"}
                        /> ) : '' }
                        { filtroTpEmbarque == 0 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='TRANSBORDO'
                            value='T'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "T"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 2 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='PORTO'
                            value='P'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "P"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='OUTROS '
                            value=''
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === ''}
                        /> ) : '' }
                        </Radio>
                        <Vspace className='sm' />
                        {
                            values.slo_cif_fob == "" && Object.values(values.produto).length != 0 ? 
                            (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
                        }
                        </Col>
                    </Col>
                    <Vspace className='hom'/>
                    <Title className='s11 fw500'>
                        {TEXT_SELECT} {TEXT_TRANSHIPMENT} - {TEXT_BIG_HOPPERS}
                    </Title>
                    <Vspace/>
                    <Box>
                        <Row>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_transhipment_02"
                                    options={moegaoList}
                                    defaultValue={values.moegao}
                                    onChange={(e, newValue) => handleChangeTranshipment(e, newValue, 'moegao')}
                                    getOptionLabel={(option) => getTranshipmentLabel(option)}
                                    disableanimation="true"
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={`${TEXT_TRANSHIPMENT} - ${TEXT_BIG_HOPPERS}`}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_type_of_shipment_02"
                                    disableanimation="true"
                                    options={shipmentTypeList}
                                    defaultValue={values.tipo_embarque}
                                    onChange={(event, newValue) => handleChange('tipo_embarque', newValue)}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_TYPE_OF_SHIPMENT}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_responsible_supervision_02"
                                    disableanimation="true"
                                    loading={loadingBaseList}
                                    loadingText={TEXT_LOADING}
                                    options={baseList}
                                    getOptionLabel={(option) => getBaseLabel(option)}
                                    defaultValue={values.supervisao}
                                    onChange={(e, newValue) => handleChange('supervisao', newValue)}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_RESPONSIBLE_SUPERVISION}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                    </Box>
                </TabPanel>
                <TabPanel>
                <Col class="tipoEmbarque"  style={{ marginTop: `-1px`, width: `calc(100% + 15px)`, backgroundColor: `#E4F0D0`, margin: `-2px 0px 0px -16px` }}>
                        <Col style={{ marginLeft: `10px`, marginTop: `-18px`}}>
                        <Text className={'s11 fw500'}>
                        {I18nTranslator.t("TYPE_OF_SHIPMENT")}
                        </Text>
                        <Vspace className='sm' />
                        <Radio className={'overflow-mobile'} style={{ marginLeft: `-8px`}} >
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='CIF'
                            value='C'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "C"}
                        /> ) : '' }
                         { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='FOB'
                            value='F'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "F"}
                        /> ) : '' }
                        { filtroTpEmbarque == 0 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='TRANSBORDO'
                            value='T'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "T"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 2 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='PORTO'
                            value='P'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "P"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='OUTROS '
                            value=''
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === ''}
                        /> ) : '' }
                        </Radio>
                        <Vspace className='sm' />
                        {
                            values.slo_cif_fob == "" && Object.values(values.produto).length != 0 ? 
                            (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
                        }
                        </Col>
                    </Col>
                    <Vspace className='hom'/>
                    <Title className='s11 fw500'>
                        {TEXT_SELECT} {TEXT_SHIP_PORT} - {TEXT_TERMINAL}
                    </Title>
                    <Vspace/>
                    <Box>
                        <Row>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_transhipment_02"
                                    disableanimation="true"
                                    defaultValue={values.porto}
                                    onChange={(e, newValue) => handleChangeTranshipment(e, newValue, 'porto')}
                                    options={portoList}
                                    getOptionLabel={(option) => getTranshipmentLabel(option)}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={`${TEXT_SHIP_PORT} - ${TEXT_TERMINAL}`}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_type_of_shipment_03"
                                    disableanimation="true"
                                    options={shipmentTypeList}
                                    defaultValue={values.tipo_embarque}
                                    onChange={(event, newValue) => handleChange('tipo_embarque', newValue)}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_TYPE_OF_SHIPMENT}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                            <Col className={`col-xs-4 col-lg-4 col-md-12`}>
                                <Autocomplete
                                    id="field_responsible_supervision_03"
                                    disableanimation="true"
                                    loading={loadingBaseList}
                                    loadingText={TEXT_LOADING}
                                    options={baseList}
                                    getOptionLabel={(option) => getBaseLabel(option)}
                                    defaultValue={values.supervisao}
                                    onChange={(e, newValue) => handleChange('supervisao', newValue)}
                                    renderInput={(params) =>
                                        <>
                                            <TextField
                                                {...params}
                                                autoComplete='off'
                                                label={TEXT_RESPONSIBLE_SUPERVISION}
                                                placeholder={TEXT_SELECT}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                    </Box>
                </TabPanel>
                <TabPanel>
                <Col class="tipoEmbarque"  style={{ marginTop: `-1px`, width: `calc(100% + 15px)`, backgroundColor: `#E4F0D0`, margin: `-2px 0px 0px -16px` }}>
                        <Col style={{ marginLeft: `10px`, marginTop: `-18px`}}>
                        <Text className={'s11 fw500'}>
                        {I18nTranslator.t("TYPE_OF_SHIPMENT")}
                        </Text>
                        <Vspace className='sm' />
                        <Radio className={'overflow-mobile'} style={{ marginLeft: `-8px`}} >
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='CIF'
                            value='C'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "C"}
                        /> ) : '' }
                         { filtroTpEmbarque == 1 || filtroTpEmbarque == 3 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='FOB'
                            value='F'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "F"}
                        /> ) : '' }
                        { filtroTpEmbarque == 0 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='TRANSBORDO'
                            value='T'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "T"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 || filtroTpEmbarque == 2 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='PORTO'
                            value='P'
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === "P"}
                        /> ) : '' }
                        { filtroTpEmbarque == 1 ? (
                        <RadioOption
                            name='slo_cif_fob'
                            label='OUTROS '
                            value=''
                            onChange={(e) => handleChangeEmbarque("slo_cif_fob", e.target.value)}
                            severity={values.slo_cif_fob == ""}
                            isChecked={values.slo_cif_fob === ''}
                        /> ) : '' }
                        </Radio>
                        <Vspace className='sm' />
                        {
                            values.slo_cif_fob == "" && Object.values(values.produto).length != 0 ? 
                            (<Feedback severity={'danger'} message={validation.product_embarque.feedback} />) : ''
                        }
                        </Col>
                    </Col>
                    <Vspace className='hom'/>
                    <Title className='s11 fw500'>
                        {TEXT_SELECT} {TEXT_BOARD_LOCATION}
                    </Title>
                    <Vspace/>
                    <Group className='fluid g5'>
                        <Search
                            className='light'
                            placeholder={TEXT_SEARCH}
                            onInput={(e) => handleSearch(e.target.value)}
                            defaultValue={defaultSearch}/>
                        <Button onClick={handleAdvancedFilter}
                                className={`filter has-arrow ${advancedFilter === true ? 'active' : ''}`}>
                            {TEXT_ADVANCED_FILTER}
                        </Button>
                    </Group>
                    <Vspace/>
                    <AdvancedFilter
                        id={formBoardLocationId}
                        advancedFilter={advancedFilter}
                        onClick={handleAdvancedFilter}
                        onSubmit={handleSubmitBoardLocation}
                        onFilter={() => {
                            setFilter(true);
                            handleChange('filter', true)
                        }}
                    />
                    <Vspace/>
                    <div className="keep-when-checked">
                        <Text className='s11 fw500 show-when-checked'>
                            {TEXT_BOARDING}
                        </Text>
                        {
                            filter ?
                                (
                                    <FilterList
                                        data={filteredBoardList}
                                        values={values}
                                        handleChange={handleChange}
                                        onClickChecked={handleChecked}
                                        showPaginator={!checkedRow}
                                        loading={loadingBoardList}
                                        onCreateNewBoardLoaction={(objectBoardLocation) => {
                                            // Oculta o filtro avançado.
                                            setAdvancedFilter(false);
                                            // Alinha o scroll da página até a tabela.
                                            document.getElementById(formBoardLocationId).scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'center'
                                            });
                                            // Adiciona o novo local de embarque à lista.
                                            let copyFilteredBoardList = filteredBoardList;
                                            copyFilteredBoardList.push(objectBoardLocation.local_embarque);
                                            setFilteredBoardList(copyFilteredBoardList);
                                            // Filtra pelo ID do local novo.
                                            setDefaultSearch(objectBoardLocation.local_embarque.loc_codigo);
                                            handleSearch(parseInt(objectBoardLocation.local_embarque.loc_codigo).toString());
                                        }}
                                    />
                                ) : null
                        }
                    </div>
                </TabPanel>
                <Vspace className='sm hod'/>
            </Tabs>
        </div>
    );
}

export default TypeServiceLocation;
