import React from "react";

const Box = ({ className, children, onClick, id}) => {
  return (
    <div className={`wac-box ${className}`} onClick={onClick} id={id}>
      {children}
    </div>
  );
};

export default Box;
