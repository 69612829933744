import React, { useState } from 'react';
import {IconButton} from "@material-ui/core";
import {Dropdown} from "components/commons/Dropdown";
import Button from "components/commons/Button";
import {Text} from "components/commons/Typography";
import {I18nTranslator} from "core/i18n/I18nTranslator";
import {UserService} from "core/user/UserService";
import {AccountCircle} from "@material-ui/icons";


const ConfigDropdown = () => {
    const [active, setActive] = useState(false)
    const [disconnect, setDisconnect] = useState(false)
    const [timeoutId, setTimeoutId] = useState(undefined)

    const handleDropdown = () => {
        setActive(prevState => !prevState)
    }

    const logout = () => {
        setDisconnect(true);
        UserService
            .logout()
            .finally(
                () => setTimeout(() => setDisconnect(false), 3000)
            );
    }

    const handleBlur = () => {
        setTimeoutId(
            setTimeout(() => setActive(false))
        )
    }

    const handleFocus = () => {
        clearTimeout(timeoutId)
    }

    return (
        <div onBlur={handleBlur} onFocus={handleFocus}>
            <Dropdown
                className={`form-input sm mla hom ${ active ? 'active' : ''}`}
                trigger={
                    <IconButton onClick={handleDropdown}>
                        <AccountCircle />
                    </IconButton>
                }>
                <Button className='inline' onClick={logout}>
                    <Text className='uppercase'>
                        { disconnect ? `${I18nTranslator.t("DISCONNECTING")}...` : 'Logout' }
                    </Text>
                </Button>
            </Dropdown>
        </div>
    )
}

export default ConfigDropdown
