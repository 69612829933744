import { Col, Row } from 'components/commons/FlexBoxGrid';
import Icon from 'components/commons/Icon';
import { Text, Title } from 'components/commons/Typography';
import Vspace from 'components/commons/Vspace';
import { LockIcon } from 'components/Icons';
import React from 'react';

const PermissionError = () => {
    return (
        <>
            <Row>
                <Col className={'col-md-12 f-h-center'}>
                    <Vspace className={'lg'} />
                    <Icon className={`lg`} component={<LockIcon />} />
                    <Vspace className={'lg'} />
                    <Title className={'s1'}>403</Title>
                    <Text className={'s8'}>Você não tem permissão para entrar aqui!</Text>
                </Col>
            </Row>
        </>
    )
}
export default PermissionError