/* eslint-disable */
import React  from 'react';
import Icon from 'components/commons/Icon';
import {Text} from 'components/commons/Typography';
import {EyeIcon, MapPinIcon, PaperClipIcon, SuccessIcon, UserIcon,} from 'components/Icons';
import {I18nTranslator} from "../../../../../../../core/i18n/I18nTranslator";


const TEXT_MANIFESTED = I18nTranslator.t('MANIFESTED');
const TEXT_CLIENT_DOCUMENT = I18nTranslator.t('CLIENT_DOCUMENT');
const TEXT_ATTACH_DOCUMENT = I18nTranslator.t("ATTACH_DOCUMENT");
const TEXT_ISSUE_REPORT = I18nTranslator.t("ISSUE_REPORT");
const TEXT_FINISHED = I18nTranslator.t("FINISHED");


const CollapsedRowContent = (props) => {


    // Props.
    const {
        id,
        progress,
        onBlur
    } = props;


    return (
        <div className='collapsed-container' id={id} onBlur={onBlur}>
            <button className="status-panel-trigger"/>
            <div className='collapsed-inner'>
                <div className='wac-steps-progress'>
                    <div className='wac-steps-progress-bar'>
                        <span style={{width: progress + '%'}}/>
                    </div>

                    <div className='wac-steps-progress-item'>
                        <Icon color='grey-2' component={<EyeIcon/>}/>
                        <Text className='s15 uppercase fw500'>{TEXT_ISSUE_REPORT}</Text>
                    </div>

                    <div className='wac-steps-progress-item'>
                        <Icon color='grey-2' component={<PaperClipIcon/>}/>
                        <Text className='s15 uppercase fw500'>{TEXT_ATTACH_DOCUMENT}</Text>
                    </div>

                    <div className='wac-steps-progress-item'>
                        <Icon component={<UserIcon/>}/>
                        <Text className='s15 uppercase fw500'>{TEXT_CLIENT_DOCUMENT}</Text>
                    </div>

                    <div className='wac-steps-progress-item'>
                        <Icon component={<SuccessIcon/>}/>
                        <Text className='s15 uppercase fw500'>{TEXT_MANIFESTED}</Text>
                    </div>

                    <div className='wac-steps-progress-item'>
                        <Icon color='grey-2' component={<MapPinIcon/>}/>
                        <Text className='s15 uppercase fw500'>{TEXT_FINISHED}</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollapsedRowContent;
