import React from 'react';
import Button from "@material-ui/core/Button";
import {Text} from "../../../../components/commons/Typography";
import PropTypes from 'prop-types';

const NotificationMessageButton = ({ key, message, time, onClick = () => {} }) => {
    return (
        <Button key={key} className={"inline"} onClick={onClick}>
            <Text className={"s13"}>{message}</Text>
            <Text className={"s14"}>{time}</Text>
        </Button>
    )
}

NotificationMessageButton.propTypes = {
    key: PropTypes.any,
    message: PropTypes.string,
    time: PropTypes.string,
    onClick: PropTypes.func,
}

export default NotificationMessageButton;