import React, {useEffect} from 'react';
import {PrivateRoute} from 'App';
import {Route, Switch} from 'react-router-dom';
import Inbox from './Inbox'
import TicketRegister from './TicketRegister'
import RealTime from './RealTime/Main.tsx'
import NotFound from './NotFound';
import PanoramaBI from './PanoramaBI';
import {userAccessService} from 'core/user-access/UserAccessService';
import PermissionError from './PermissionError';
import {UserService} from 'core/user/UserService';
import NoticeBoard from "./NoticeBoard";
import Speed from "./Speed";

const MainRoutes = () => {
    const user = userAccessService.getUser();

    useEffect(() => {
        checkSession();
        const interval = setInterval(() => {
            checkSession();
        }, 30000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const checkSession = () => {
        if (userAccessService.isAuthenticated) {
            UserService.validateSession()
        }
    }

    return (
        <Switch>
            <PrivateRoute exact path='/' component={Inbox}/>
            <PrivateRoute path='/inbox' component={Inbox}/>
            <PrivateRoute path='/ticket-register' component={TicketRegister}/>
            <PrivateRoute path='/ticket-editing' component={TicketRegister}/>
            <PrivateRoute path='/real-time' component={RealTime}/>
            {
                user && user.usu_usuario_panorama === 'S' ?
                    (<PrivateRoute path='/panorama' component={PanoramaBI}/>) : null
            }
            <PrivateRoute path='/permission-error' component={PermissionError}/>
            <PrivateRoute path='/notice-board' component={(props) => <NoticeBoard {...props} key={Date.now()}/>}/>
            <PrivateRoute path='/speed' component={Speed}/>
            <Route path='*' exact component={NotFound}/>
        </Switch>
    );
};
export default MainRoutes;
