import React, {useState} from "react";
import Button from 'components/commons/Button';
import ChatAction from "./ChatAction";
import ChatMessage from "./ChatMessage";
import {CargoService} from "../../../core/cargo/CargoService";

const Chat = (props) => {

    const [message, setMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // Props.
    const {
        onClickCloseChat,
        activeChat,
        messageList,
        id,
        title,
        onSubmit,
    } = props;

    function handleSubmit(e) {
        setButtonDisabled(true)
        onSubmit(e, message)
        setMessage("")
        setButtonDisabled(false)
    }

    function handleOnFocus(){
        CargoService.chatVisualization(id)
            .then((response) => {
                    console.log(response)
                }
            )
            .catch((error) => {
                console.error(error);
            })
    }


    return (
        <>
            <div className={`chat-container reverse-scroll ${activeChat ? 'active' : ''}`}>
                <header className="chat-header">
                    <Button onClick={onClickCloseChat} className="button-close"/>
                    {title}
                </header>
                <div className="chat-body">
                    <div className="chat-scroll">
                        {

                            messageList.map((row) => <ChatMessage {...row}/>)
                        }
                    </div>
                </div>
                <form id="form_send_messages" onSubmit={handleSubmit}>
                    <ChatAction message={message} onFocus={handleOnFocus} buttonDisabled={buttonDisabled}
                                onInput={(e) => setMessage(e.target.value)}/>
                </form>
            </div>
        </>
    );
};
export default Chat;