/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from 'components/commons/Button';
import Box from 'components/commons/Box';
import { Title, Paragraph, Text } from 'components/commons/Typography';
import Table from 'components/commons/Table';
import DateUtil from 'core/utils/DateUtil';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import Vspace from 'components/commons/Vspace';
import PhoneLink from 'components/commons/PhoneLink';
import MailLink from 'components/commons/MailLink';
import { OSService } from 'core/os/OSService';
import { I18nTranslator } from 'core/i18n/I18nTranslator';

const TicketDetails = (props) => {
  // Props.
  var { 
    handleClose,
    dataObject
  } = props;

  // Dados da O.S.
  var {
    slo_codigo,
    slo_dtsolicitacao,
    slo_dtretirada,
    slo_dttermino,
    slo_mercadoexterno,
    slo_contratocompra,
    slo_solicitante,
    slo_fone,
    slo_email,
    slo_contato,
    slo_lote,
    slo_tamanhoestimado,
    nome_usuario_cadastro,
    slo_localemb,
    servico,
    produto,
    transportadora,
    filial,
    local_embarque
  } = dataObject;

  // Dados do Cliente.
  var {
    cli_cpfcnpj,
    cli_nome,
    cli_cep,
    cli_fone,
    cli_endereco
  } = filial;
  var cidadeFilial = filial.cidade;
  var estadoFilial = cidadeFilial.estado;

  // Dados do Local do Serviço.
  var {
    tipo,
    cidade
  } = local_embarque;
  var { estado} = cidade;

  // Traduções.
  const TEXT_CLIENT_DATA = I18nTranslator.t("CLIENT_DATA");
  const TEXT_ITIN = I18nTranslator.t("ITIN"); // CPF
  const TEXT_EIN = I18nTranslator.t("EIN"); // CNPJ
  const TEXT_NAME = I18nTranslator.t("NAME");
  const TEXT_STATE = I18nTranslator.t("STATE");
  const TEXT_CITY = I18nTranslator.t("CITY");
  const TEXT_ZIP_CODE = I18nTranslator.t("ZIP_CODE");
  const TEXT_ADDRESS = I18nTranslator.t("ADDRESS");
  const TEXT_CONTACT_PERSON = I18nTranslator.t("CONTACT_PERSON");
  const TEXT_CONTACT_PHONE = I18nTranslator.t("CONTACT_PHONE");
  const TEXT_SERVICE_ORDER_DATA = I18nTranslator.t("SERVICE_ORDER_DATA");
  const TEXT_SERVICE = I18nTranslator.t("SERVICE");
  const TEXT_REGISTERED_BY = I18nTranslator.t("REGISTERED_BY");
  const TEXT_START_DATE = I18nTranslator.t("START_DATE");
  const TEXT_END_FORECAST = I18nTranslator.t("END_FORECAST");
  const TEXT_PRODUCT = I18nTranslator.t("PRODUCT");
  const TEXT_MARKET_TYPE = I18nTranslator.t("MARKET_TYPE");
  const TEXT_BL_CONTRACT = I18nTranslator.t("BL_CONTRACT");
  const TEXT_REQUESTER = I18nTranslator.t("REQUESTER");
  const TEXT_SERVICE_LOCATION = I18nTranslator.t("SERVICE_LOCATION");
  const TEXT_TYPE = I18nTranslator.t("TYPE");
  const TEXT_COUNTRY = I18nTranslator.t("COUNTRY");
  const TEXT_SHIPPING_COMPANY = I18nTranslator.t("SHIPPING_COMPANY");
  const TEXT_LOCAL_PHONE = I18nTranslator.t("LOCAL_PHONE");
  const TEXT_EMAIL = I18nTranslator.t("EMAIL");
  const TEXT_SERVICE_STANDARDS = I18nTranslator.t("SERVICE_STANDARDS");
  const TEXT_LOT_SIZE = I18nTranslator.t("LOT_SIZE");
  const TEXT_ESTIMATED_LOT = I18nTranslator.t("ESTIMATED_LOT");
  const TEXT_YES = I18nTranslator.t("YES");
  const TEXT_NO = I18nTranslator.t("NO");
  const TEXT_SERVICE_ORDER = I18nTranslator.t("SERVICE_ORDER");
  const TEXT_ERROR_WHILE_TRYING_LOAD_INFORMATION = I18nTranslator.t("ERROR_WHILE_TRYING_LOAD_INFORMATION");
  const TEXT_UNINFORMED = I18nTranslator.t("UNINFORMED");
  const TEXT_EXTERNAL = I18nTranslator.t("EXTERNAL");
  const TEXT_INTERNAL = I18nTranslator.t("INTERNAL");

  var tipo_mercado = !slo_mercadoexterno ? TEXT_UNINFORMED : (slo_mercadoexterno === "S" ? TEXT_EXTERNAL : TEXT_INTERNAL);

  const defaultData = {
    [TEXT_CLIENT_DATA] : {
        [`${TEXT_ITIN}/${TEXT_EIN}`]: cli_cpfcnpj,
        [TEXT_NAME]: cli_nome,
        [TEXT_STATE]: estadoFilial ? estadoFilial.est_sigla : '',
        [TEXT_CITY]: cidadeFilial ? cidadeFilial.cid_nome : '',
        [TEXT_ZIP_CODE]: cli_cep,
        [TEXT_ADDRESS]: cli_endereco,
        [TEXT_CONTACT_PERSON]: slo_contato,
        [TEXT_CONTACT_PHONE]: <PhoneLink tel={cli_fone} />
    },
    [TEXT_SERVICE_ORDER_DATA]: {
        [TEXT_SERVICE]: servico ? servico.serv_descricao : '',
        [TEXT_REGISTERED_BY] : nome_usuario_cadastro,
        [TEXT_START_DATE]: DateUtil.formatStringDate(slo_dtretirada),
        [TEXT_END_FORECAST]: DateUtil.formatStringDate(slo_dttermino),
        [TEXT_PRODUCT]: produto ? produto.pro_nome : '',
        [TEXT_MARKET_TYPE]: tipo_mercado,
        [TEXT_BL_CONTRACT]: slo_contratocompra,
        [TEXT_REQUESTER]: slo_solicitante
    },
    [TEXT_SERVICE_LOCATION] : {
        [TEXT_TYPE]: tipo,
        [TEXT_NAME]: slo_localemb,
        [TEXT_COUNTRY]: estado ? estado.pais : '',
        [TEXT_STATE]: estado ? estado.est_sigla : '',
        [TEXT_CITY]: cidade ? cidade.cid_nome : '',
        [TEXT_SHIPPING_COMPANY]: transportadora ? transportadora.transp_nome : '',
        [TEXT_LOCAL_PHONE]: <PhoneLink tel={slo_fone} />,
        [TEXT_EMAIL]: <MailLink subject={`${TEXT_SERVICE_ORDER} ${slo_codigo}`} email={slo_email} />
    },
    [TEXT_SERVICE_STANDARDS]: {
        [TEXT_LOT_SIZE]: slo_lote ? `${slo_lote} tons` : '',
        [TEXT_ESTIMATED_LOT]: slo_tamanhoestimado ? TEXT_YES : TEXT_NO
    }
  };

  // State.
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    // Busca os itens de classificação (Dados dos Padrões do Serviço).
    getRatingItems();
  }, [slo_codigo]);

  const getRatingItems = () => {
    setLoading(true);
    OSService.getClassificationItems(slo_codigo)
    .then((response) => {
      if (Array.isArray(response)) {
        let newData = defaultData;

        response.map((row) => {
          newData[TEXT_SERVICE_STANDARDS][row.item_nome] = (row.itemo_padrao ? `${row.itemo_padrao}%` : '');
        });

        setData(newData);
      }
    })
    .catch()
    .finally(() => setLoading(false));
  }

  const renderSections = (data) => {
    try {
      return Object.keys(data).map((key, index) => {
        return (
          <div key={index}>
            <Table>
              <thead>
                <tr className={`has-arrow`}>
                  <th>
                    <Text className={`fw700`} children={key} />
                  </th>
                </tr>
              </thead>
            </Table>
            <Box>
              <Row>
                { 
                  Object.keys(data[key]).map((keyRow, index) => {
                    return (
                      <Col key={index} className={`col-xs-6 col-lg-12`}>
                        <Text 
                          className={`fw700`} 
                          children={keyRow} />:&nbsp;
                        <Text 
                          className={`fw500`}
                          children={data[key][keyRow]} />
                      </Col>
                    )
                  })
                }
              </Row>
              <Vspace />
            </Box>
          </div>
        );
      });
    }
    catch (error) {
      return (<Text>{TEXT_ERROR_WHILE_TRYING_LOAD_INFORMATION}</Text>);
    }
  }

  return (
    <Box>
      <Button onClick={handleClose} className="button-close" />
      <Title className='s11 fw500'>
        {TEXT_SERVICE_ORDER}
        <Paragraph children={`${slo_codigo} - ${DateUtil.formatStringDate(slo_dtsolicitacao)}`} />
      </Title>
      <hr />
      <div className={`scroll-cols ${loading ? 'loading' : ''}`} >
          { renderSections(data) }
        </div>
    </Box>
  )
}

export default TicketDetails