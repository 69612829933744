import React, { useEffect, useReducer, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from "@material-ui/core/Badge";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import IconButton from "@material-ui/core/IconButton";
import {I18nTranslator} from "../../../../core/i18n/I18nTranslator";
import Button from 'components/commons/Button';
import Vspace from 'components/commons/Vspace';
import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/commons/Input';
import { Col, Row } from 'components/commons/FlexBoxGrid';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Title } from 'components/commons/Typography';
import Feedback from 'components/commons/Feedback';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WhatsApp } from 'core/whatsapp/WhatsApp';
import {userAccessService} from 'core/user-access/UserAccessService';
import { stubTrue } from 'lodash';

const NotificationWhatsApp = (onClick) => {

const [muda, setMuda] = useState(1);

useEffect(()=>{
  getnovoNumero();
},[muda]);

const TEXT_FILL_FIELD = I18nTranslator.t("FILL_FIELD"); // Preencher campo
const TEXT_SAVING = I18nTranslator.t("SAVING"); // Salvando
const TEXT_SUCCESS = I18nTranslator.t("SUCCESS"); // Sucesso
const TEXT_ERROR_REGISTER_LOCATION = I18nTranslator.t("ERROR_REGISTER_LOCATION"); // Ocorreu um erro ao tentar cadastrar o local, tente novamente mais tarde!
const TEXT_CONFIRM = I18nTranslator.t("CONFIRM");

  const user = userAccessService.getUser();
 
  
  const handleSubmit = (e) => {

    e.preventDefault();
 
    let params = {
        usu_whatsapp: telefone,
        usu_periodicidade_whatsapp: statePerio.value.value ? statePerio.value.value : null,
    }
    WhatsApp.create(params)
    .then((response) => {
        setMsgSucesso(true);
        
    })
    .catch((error) => {
        setMsgSucesso(true)
      })
    .finally(() => setTimeout(() => {
        handleClose();
        setState(false);
        setAtiva(true);
        setMuda(muda + 1);
      }, 1500))
  }

  const [ativa, setAtiva] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [telefone, setTelefone] = useState("");
  const [state, setState] = React.useState({
    checkedG: false,
  });

  const [msgSucesso, setMsgSucesso] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const [statePerio, setStatePerio] = React.useState({value: "", success: null })

  function handleChangePerio(event) {
    setStatePerio(event.target.value);
  }

  
  const [validation, setValidation] = useState({
    market_type: { feedback: "Por favor, aceite os termos de uso para prosseguir", error: false },
    telefone: { feedback: "Por favor, digite corretamente o número de telefone", error: false },
  });

  const validate = () => {
    let error = false;
    let newValidation = {...validation};

    Object.keys(newValidation).map((key) => {
      newValidation[key].error = false;
    });

    if (state.checkedG === false) {
      newValidation.market_type.error = error = true;
    }

    if (telefone === "" || telefone < 14) {
        newValidation.telefone.error = error = true;
    }

    setValidation(newValidation);
    return error;
  }

  const next = () => {
    if (!validate()) handleClose();
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTelefone("")
    setMsgSucesso(false);
    setOpen(false);
    setMsgError(false);
  };
  

  const handleChangeCampo1 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if(ativa){
        setAtiva(false);
    } else{
        setAtiva(true)
    }
  };


  function handleTelefone(event) {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    var str = event.target.value.replace(/[^0-9]/g, "").slice(0, 11);
  
    const result = str.replace(regex, "($1)$2-$3");
  
    setTelefone(result);
  }

  const GreenCheckbox = withStyles({
    root: {
      color: '#59B224',
      '&$checked': {
        color: '#59B224',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const tipoLocal = [
    { name: "1 VEZ AO DIA", value: "1"},
    { name: "2 VEZ AO DIA", value: "2"},
    { name: "3 VEZ AO DIA", value: "3"},
    { name: "4 VEZ AO DIA", value: "4"},
    { name: "5 VEZ AO DIA", value: "5"},
  ];

  const [pegaNumero, setPegaNumero] = useState([]);

  const getnovoNumero = (e) => {

    // e.preventDefault();
    
    WhatsApp.getnovoNumero()
    .then((response) => {
      setPegaNumero(response.whatsApp);
      
    })
    .catch((error) => {
      console.error(error);
    })
    .finally();
  };

  
  
  
  return (
    <div onSubmit={handleSubmit}>
     <IconButton color="inherit">
        <Badge color={"secondary"}  onClick={handleClickOpen}>
            <WhatsAppIcon className={"color-white-mobile"} color={"action"} />
        </Badge>
     </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="cookie-consent-title">{I18nTranslator.t("NOTIFICATIONS WHATS")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {I18nTranslator.t("AUTORIZAR NOTIFICACAO")}
          <Vspace className='sm'/>
          { pegaNumero.usu_whatsapp != null ? <Feedback severity={"danger"} message='Já existe um número cadastrado, para substituir por favor insira outro !' /> : ''}
          </DialogContentText>
          <Col className='col-xs-13 col-lg-2 col-md-12' style={{ marginLeft: `-7px` }}>
            <Input 
              label='Número de Telefone :'
              name='numeroTelefone'
              value={telefone}
              onChange={handleTelefone.bind(this)}
              placeholder={pegaNumero.usu_whatsapp}/>
          </Col>
          {
              validation.telefone.error ?
              (<Feedback severity={"danger"} message={validation.telefone.feedback} />) : ''
          }
          <Vspace className='sm'/>

          {/* Descomentar para ativar a periodicidade */}

          {/* <Col className='col-xs-6 col-lg-10 col-md-12' style={{ marginLeft: `-7px` }}>
            <Autocomplete
              id='periodicidade'
              name='periodicidade'
              options={tipoLocal}
              noOptionsText='...'
              onChange={(event, value) => statePerio.value = value}
              getOptionLabel={(option) => option.name}
              renderInput={ (params) => 
                <>
                  <TextField 
                    {...params}
                     autoComplete='off'
                    label={'Periodicidade :'}
                    placeholder='...'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined" 
                  />
                </>
              }
            />
          </Col> */}
          <Vspace className='sm'/>
            <Title className='s14'>
            {I18nTranslator.t("TERMOS")}
            </Title>
            <Vspace className='sm'/>
          <FormControlLabel
            className='s16'
            control={
            <GreenCheckbox checked={state.checkedG} onChange={handleChangeCampo1} name="checkedG" />}
            label={<Title className='s14'>{I18nTranslator.t("LIDO")}</Title>}
         />
            {
              validation.market_type.error ?
              (<Feedback severity={"danger"} message={validation.market_type.feedback} />) : ''
            }
        </DialogContent>
        <DialogContent>
        { msgSucesso  ? (<Feedback severity={"success"} message='Número Cadastrado com Sucesso !' />) : ''}
        { msgError  ? (<Feedback severity={"danger"} message='Não foi possivel cadastrar, tente novamente !' />) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className = "danger">
            Cancelar
          </Button>
          <Button onClick={getnovoNumero,next, handleSubmit} className ="primary" disabled={ativa || telefone == ""}>
            Ativar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NotificationWhatsApp