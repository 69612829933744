import { configuration } from "config";
import { userAccessService } from "core/user-access/UserAccessService";


export class RatingItemService {
    static filter(filter_parameter: any) {
        const urlSearchParams = new URLSearchParams(filter_parameter);
        const url = `${configuration.AUTH_BASE_URL}cliconnect/classificacao_carga?${urlSearchParams.toString()}`;

        const headerConfig: object = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Client-Code": configuration.AUTH_CLICONNECT_CODE,
                "Session-ID": userAccessService.getSessionId()
            }
        }

        return new Promise((resolve, reject) => {
            fetch(url, headerConfig)
            .then(response => response.json())
            .then(response => {
                if (response.error)
                    reject(response.error);

                resolve(response);
            })
            .catch(() => reject(false))
        })
    }
}