/* eslint-disable */
import React, {useState} from 'react';

import {CssBaseline, Grid, makeStyles, Paper, Snackbar, Typography} from '@material-ui/core';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import Vspace from 'components/commons/Vspace';
import Box from 'components/commons/Box';
import Image from 'components/commons/Image';
import LogoGenesis from 'assets/img/logo-genesis.png';
import LogoCliconnect from 'assets/logo.png';
import LogoGenesisAmarelo from 'assets/img/logo-genesis-amarelo.png';
import BackgroundGenesisgroup from 'assets/img/background-genesisgroup.jpg';
import PortugueseIcon from 'assets/img/lang_pt.png';
import SpanishIcon from 'assets/img/lang_es.png';
import EnglishIcon from 'assets/img/lang_en.png';
import './SignInSide.scss';
import {userAccessService} from 'core/user-access/UserAccessService';
import {Redirect, withRouter} from 'react-router';
import {UserService} from 'core/user/UserService';
import {I18nTranslator} from 'core/i18n/I18nTranslator';
import TextUtil from 'core/utils/TextUtil';
import { Option, Select } from 'components/commons/Select';
import CookieConsent from 'view/main/CookieConsent';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            Copyright © Genesisgroup {new Date().getFullYear()}.
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    image: {
        backgroundImage: `url(${BackgroundGenesisgroup})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light'
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        ['@media (max-width: 599px)']: {
            margin: '20px'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    center: {
        display: 'flex',
        margin: '0',
        flexDirection: 'column',
        alignItems: 'center'
    },
    fullHeight: {
        height: '100%'
    },
    marginAuto: {
        margin: 'auto'
    },
    responsiveImage: {
        margin: 'auto',
        height: 'auto',
        maxWidth: '650px',
        width: '100%'
    },
    headerLogo: {
        height: '30px'
    },
    countries: {},
    flag: {
        display: 'inline-block',
        margin: '0 12px'
    },
    flagSelected: {
        borderRadius: '100%',
        padding: '1px',
        boxShadow: '0px 0px 0px 3px #e1dcdc'
    },
    flag_button: {
        cursor: 'pointer'
    }
}));

const getTranslation = () => {
  return {
    AUTHENTICATING: I18nTranslator.t("AUTHENTICATING"),
    SIGN_IN: I18nTranslator.t("SIGN_IN"),
    USERNAME: I18nTranslator.t('USERNAME'),
    PASSWORD: I18nTranslator.t('PASSWORD'),
    ALL: I18nTranslator.t('ALL'),
    NOTHING_FOUND: I18nTranslator.t("NOTHING_FOUND"),
    COMPANY: I18nTranslator.t('COMPANY')
  }
}

export default function SignInSide() {
    const classes = useStyles();
    const [lang, setLang] = useState(I18nTranslator.getLanguage());
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        user: userAccessService.getLastUser() !== null
            ? userAccessService
                .getLastUser()
                .cpf
            : '',
        password: '',
        empresa: 'GENESLAB',
        openSnackbar: false
    });

    const [i18n, setI18n] = useState(getTranslation())
    const handleLanguage = (lang) => {
      setLang(lang);
      I18nTranslator.setLanguage(lang);
      setI18n(getTranslation());
    }
    
    const handleChange = (name) => (event) => {
        setValues({
            ...values,
            [name]: event.target.value
        });
    };

    const handleCloseSnackbar = () => (ev, reason) => {
        setValues({
            ...values,
            "openSnackbar": false
        });
    };

    const openSnackbar = (message, timeout = 1500) => {
        setValues({
            ...values,
            ...{
                "messageSnackbar": message,
                "openSnackbar": true
            }
        });

        setTimeout(() => {
            setValues({
                ...values,
                ...{
                    "openSnackbar": false
                }
            });
        }, timeout);
    };

    const signIn = (history) => {
      setLoading(true);
      UserService
      .login(TextUtil.removeFormat(values.user), values.password, values.empresa)
      .then(() => {
          history.push('/');
      })
      .catch(({message}) => {
          /* Aumenta o tempo de exibição da mensagem, se o texto for longo */
          if (message.length > 140) 
              openSnackbar(message, 4500);
          else 
              openSnackbar(message);

          }
      )
      .finally(() => setLoading(false));
    };

    const ButtonSignIn = withRouter(({history, loading}) => (
      <Button
        className={`${loading ? 'outlined' : 'primary'} full`}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          signIn(history);
        }}
      >
        {loading ? `${i18n.AUTHENTICATING}...` : i18n.SIGN_IN}
      </Button>
    ));

    if (userAccessService.isAuthenticated()) {
        return (<Redirect to={{
            pathname: "/"
        }}/>);
    }

    return (
        <React.Fragment>
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}>
                    <div className={`${classes.center} ${classes.fullHeight}`}>
                        <Image
                            alt='logo-cliconnect'
                            className={`${classes.responsiveImage} hide-m`}
                            src={LogoCliconnect}/>
                        <Image
                            alt='logo-genesisgroup'
                            className="hide-m m-auto"
                            src={LogoGenesisAmarelo}/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Image
                            alt='logo-cliconnect'
                            className={`${classes.responsiveImage} hide-d`}
                            src={LogoCliconnect}/>
                        <Image
                            alt='logo-genesis'
                            className={`${classes.headerLogo} hide-m`}
                            src={LogoGenesis}/>
                    </div>
                    <div className={`${classes.center} countries`}>
                        <ul>
                            <li className={classes.flag}>
                                <a className={classes.flag_button} onClick={() => handleLanguage('pt')}>
                                    <Image
                                        className={lang === 'pt' ? classes.flagSelected : ''}
                                        src={PortugueseIcon}
                                        alt="pt_BR"/>
                                </a>
                            </li>
                            <li className={classes.flag}>
                                <a className={classes.flag_button} onClick={() => handleLanguage('en')}>
                                    <Image
                                        className={lang === 'en' ? classes.flagSelected : ''}
                                        src={EnglishIcon}
                                        alt="en_US"/>
                                </a>
                            </li>
                            <li className={classes.flag}>
                                <a className={classes.flag_button} onClick={() => handleLanguage('es')}>
                                    <Image
                                        className={lang === 'es' ? classes.flagSelected : ''}
                                        src={SpanishIcon}
                                        alt="es_ES"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Box className={classes.paper}>
                        <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
                            <Input 
                              id="username" 
                              label={`${i18n.USERNAME} *`}
                              defaultValue={userAccessService.getLastUser() !== null ? TextUtil.formatCpf(userAccessService.getLastUser().cpf) : ''}
                              name="username"
                              onChange={handleChange('user')}
                              onInput={(e) => e.target.value = TextUtil.formatCpf(e.target.value)}
                              required
                            />
                            <Vspace className="lg"/>
                            <Input
                                id="password"
                                type="password"
                                label={`${i18n.PASSWORD} *`}
                                onChange={handleChange('password')}
                                name="password"
                                required
                            />
                            <Vspace className="lg"/>
                            {/* <Select
                                label={i18n.COMPANY}
                                onChange={(e) => setValues({...values, empresa: e.target.value})}
                            >
                                <Option value="TODAS">{i18n.ALL}</Option>
                                <Option value="GENESLAB">GENESLAB</Option>
                                <Option value="CLASSNORTE">CLASSNORTE</Option>
                                <Option value="GR">GR</Option>
                            </Select> */}
                            <Vspace className="lg"/>
                            <ButtonSignIn loading={loading} />
                            <Vspace className="lg"/>
                            <Copyright/>
                        </form>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                key={`snackbar`}
                open={values.openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={4000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{values.messageSnackbar}</span>}
            />
            <CookieConsent />
        </React.Fragment>
    );
}