import React from "react";

const ToogleCard = ({ className, id, name, icon, label, defaultChecked, onChange, disabled,onClick }) => {
  return (
  <label htmlFor={id} className={`wac-toggle-card ${className}`}>
    <input id={id} type="radio" name={name} defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} onClick={onClick} />
    <div className="wac-toggle-card-box">
      <div className="wac-toggle-card-icon">
        {icon}
      </div>
      <div className="wac-toggle-card-label">
        {label}
      </div>
    </div>
  </label>
  )
};

export default ToogleCard;
